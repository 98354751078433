._ContentGuidelinesList_jgj8e_1 {
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
._ContentGuidelinesList_jgj8e_1._center_jgj8e_9 {
  align-items: center;
}
._ContentGuidelinesList_jgj8e_1._start_jgj8e_12 {
  align-items: flex-start;
}