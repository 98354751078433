/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_3lxlg_157 {
  display: flex;
}

._justify-content-space-between_3lxlg_161 {
  justify-content: space-between;
}

._tabular-nums_3lxlg_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_3lxlg_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._LastSyncedTime_3lxlg_178 {
  color: #aeaeae;
  font-size: 0.875rem;
}