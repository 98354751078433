/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_h3mc0_157 {
  display: flex;
}

._justify-content-space-between_h3mc0_161 {
  justify-content: space-between;
}

._tabular-nums_h3mc0_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_h3mc0_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
:root {
  --spacing-xxxl: 80px;
  --spacing-xxl: 48px;
  --spacing-xl: 32px;
  --spacing-lg: 24px;
  --spacing-md: 16px;
  --spacing-sm: 12px;
  --spacing-xs: 8px;
  --spacing-xxs: 4px;
}

._black_h3mc0_200 {
  color: #1a1818;
}

._uploadBox_h3mc0_204 {
  margin-top: 16px;
  margin-bottom: 16px;
}