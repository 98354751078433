/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_x7dpy_157 {
  display: flex;
}

._justify-content-space-between_x7dpy_161 {
  justify-content: space-between;
}

._tabular-nums_x7dpy_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_x7dpy_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._InvalidMembers_x7dpy_178 ._header_x7dpy_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._InvalidMembers_x7dpy_178 ._subheader_x7dpy_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._InvalidMembers_x7dpy_178 {
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}
._InvalidMembers_x7dpy_178 ._header_x7dpy_178 {
  text-align: center;
}
._InvalidMembers_x7dpy_178 ._subheader_x7dpy_188 {
  color: #8f8d91;
  text-align: center;
}
._InvalidMembers_x7dpy_178 ._summary_x7dpy_211 {
  margin-top: 1.25rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}
._InvalidMembers_x7dpy_178 ._summary_x7dpy_211 ._table_x7dpy_217 {
  flex: 1;
}
._InvalidMembers_x7dpy_178 ._summary_x7dpy_211 ._table_x7dpy_217 .ant-select {
  width: 200px;
}
._InvalidMembers_x7dpy_178 ._summary_x7dpy_211 ._table_x7dpy_217 .ant-select-selection-item {
  white-space: pre;
}
._InvalidMembers_x7dpy_178 ._summary_x7dpy_211 ._footer_x7dpy_226 {
  margin-top: 2.8125rem;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
._InvalidMembers_x7dpy_178 ._summary_x7dpy_211 ._footer_x7dpy_226 ._button_x7dpy_233 {
  margin: 0.5rem;
}