/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1x82y_157 {
  display: flex;
}

._justify-content-space-between_1x82y_161 {
  justify-content: space-between;
}

._tabular-nums_1x82y_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1x82y_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ProgramList_1x82y_178 {
  display: flex;
  flex-direction: column;
}
._ProgramList_1x82y_178 ._projectsHeader_1x82y_182 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
._ProgramList_1x82y_178 ._projectsHeader_1x82y_182 ._projectStatusSelect_1x82y_189 {
  width: 170px;
}
._ProgramList_1x82y_178 ._projectsHeader_1x82y_182 ._filters_1x82y_192 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
._ProgramList_1x82y_178 ._projectsHeader_1x82y_182 ._yearSelect_1x82y_199 {
  width: 7.5rem;
}
._ProgramList_1x82y_178 ._title_1x82y_202 {
  margin-bottom: 1.5rem;
}
._ProgramList_1x82y_178 ._content_1x82y_205 {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -12px;
}
._ProgramList_1x82y_178 ._content_1x82y_205 ._mainCard_1x82y_212 {
  width: 25%;
  padding: 0 12px;
  margin-bottom: 24px;
  cursor: pointer;
}
@media (max-width: 1399px) {
  ._ProgramList_1x82y_178 ._content_1x82y_205 ._mainCard_1x82y_212 {
    width: 33.333%;
  }
}
@media (max-width: 767px) {
  ._ProgramList_1x82y_178 ._content_1x82y_205 ._mainCard_1x82y_212 {
    width: 50%;
  }
}
@media (max-width: 575px) {
  ._ProgramList_1x82y_178 ._content_1x82y_205 ._mainCard_1x82y_212 {
    width: 100%;
  }
}
._ProgramList_1x82y_178 ._content_1x82y_205 ._mainCard_1x82y_212 ._image_1x82y_233,
._ProgramList_1x82y_178 ._content_1x82y_205 ._mainCard_1x82y_212 img {
  height: 350px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
._ProgramList_1x82y_178 ._content_1x82y_205 ._archived_1x82y_240 img {
  opacity: 0.5;
}