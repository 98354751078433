/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1le2y_157 {
  display: flex;
}

._justify-content-space-between_1le2y_161 {
  justify-content: space-between;
}

._tabular-nums_1le2y_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1le2y_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._accordionHeader_1le2y_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 1.5rem;
  border: 0.0625rem solid #dadcde;
  background: #fdfdfd;
  color: #8f8d91;
  cursor: pointer;
}
._accordionHeader_1le2y_178:first-child {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
._accordionHeader_1le2y_178:not(:first-child) {
  border-top: none;
}
._accordionHeader_1le2y_178:hover {
  background-color: #eff5f9;
}
._accordionHeader_1le2y_178 ._completedIcon_1le2y_198 {
  color: #3996e0;
}
._accordionHeader_1le2y_178 ._upArrow_1le2y_201 {
  transform: rotate(180deg);
}
._accordionHeader_1le2y_178 ._title_1le2y_204 {
  display: flex;
  flex-grow: 1;
  margin-left: 1rem;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: -0.02rem;
}
._accordionHeader_1le2y_178 ._title_1le2y_204._active_1le2y_213 {
  color: #1a1818;
}