/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_q9tke_157 {
  display: flex;
}

._justify-content-space-between_q9tke_161 {
  justify-content: space-between;
}

._tabular-nums_q9tke_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_q9tke_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_q9tke_157 {
  display: flex;
}

._justify-content-space-between_q9tke_161 {
  justify-content: space-between;
}

._tabular-nums_q9tke_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_q9tke_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._wrapper_q9tke_355 ._button_q9tke_355 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._wrapper_q9tke_355 ._button_q9tke_355 {
  min-width: 2.25rem;
}
._wrapper_q9tke_355 ._button_q9tke_355 > svg:first-of-type:not(:only-child) {
  margin-right: 0.25rem;
}

._wrapper_q9tke_355 ._button_q9tke_355:focus, ._wrapper_q9tke_355 ._button_q9tke_355:hover {
  border-color: #167cf4;
  color: #167cf4;
}

._wrapper_q9tke_355._disabled_q9tke_377 ._button_q9tke_355 {
  background-color: #fdfdfd !important;
  cursor: no-drop;
  pointer-events: none;
}
._wrapper_q9tke_355 ._button_q9tke_355:hover, ._wrapper_q9tke_355 ._button_q9tke_355._active_q9tke_382 {
  background: #fafafa;
}
._wrapper_q9tke_355 ._button_q9tke_355:disabled {
  border-color: #bfbfbf !important;
  background-color: #f5f5f5 !important;
}