._content_1yklw_1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  overflow: hidden;
}

._memberDetailsDrawer_1yklw_11 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-left: 0 !important;
}