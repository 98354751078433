/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1u8eb_157 {
  display: flex;
}

._justify-content-space-between_1u8eb_161 {
  justify-content: space-between;
}

._tabular-nums_1u8eb_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1u8eb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ImageUpload_1u8eb_178 {
  margin-bottom: 1.5rem;
}
._ImageUpload_1u8eb_178 ._thumbnailUpload_1u8eb_181 {
  padding-right: 0.5rem;
}
._ImageUpload_1u8eb_178 ._header_1u8eb_184 {
  font-size: inherit;
}
._ImageUpload_1u8eb_178 ._header_1u8eb_184 ._helpText_1u8eb_187 {
  margin-left: 0.375rem;
}
._ImageUpload_1u8eb_178 ._header_1u8eb_184 ._helpText_1u8eb_187 ._icon_1u8eb_190 {
  color: #8C8C8C;
}
._ImageUpload_1u8eb_178 ._fields_1u8eb_193 {
  display: flex;
}
._ImageUpload_1u8eb_178 ._fields_1u8eb_193 ._fieldSizeFix_1u8eb_196 {
  max-height: 8rem;
  max-width: 8rem;
}
._ImageUpload_1u8eb_178 ._hintText_1u8eb_200 {
  color: #505256;
  font-size: 0.75rem;
  padding-left: 1rem;
  position: relative;
  margin-top: 0.625rem;
}
._ImageUpload_1u8eb_178 ._hintText_1u8eb_200 ._icon_1u8eb_190 {
  color: #FAAD14;
  left: 0;
  position: absolute;
  top: 0.1875rem;
}