/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_19kie_157 {
  display: flex;
}

._justify-content-space-between_19kie_161 {
  justify-content: space-between;
}

._tabular-nums_19kie_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_19kie_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._DeleteStageModal_19kie_178 ._title_19kie_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._DeleteStageModal_19kie_178 ._text_19kie_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._DeleteStageModal_19kie_178 ._descriptions_19kie_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._DeleteStageModal_19kie_178 {
  padding: 2rem !important;
  border-radius: 0 !important;
}
._DeleteStageModal_19kie_178 ._title_19kie_178 {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
._DeleteStageModal_19kie_178 ._title_19kie_178 ._icon_19kie_217 {
  margin-right: 1rem;
  color: #faad14;
  font-size: 1.25rem;
}
._DeleteStageModal_19kie_178 ._body_19kie_222 {
  padding-left: 2.25rem;
}
._DeleteStageModal_19kie_178 ._text_19kie_188 {
  margin-bottom: 0.5rem;
}
._DeleteStageModal_19kie_178 ._descriptions_19kie_198 {
  margin-bottom: 2rem;
  padding-left: 1.25rem;
}
._DeleteStageModal_19kie_178 ._actions_19kie_232 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
._DeleteStageModal_19kie_178 ._actions_19kie_232 ._button_19kie_238 {
  margin-right: 1rem;
}
._DeleteStageModal_19kie_178 ._status_19kie_241 {
  overflow: hidden;
  margin-bottom: 2rem;
}
._DeleteStageModal_19kie_178 ._status_19kie_241 ._label_19kie_245 {
  margin-bottom: 0.5rem;
}
._DeleteStageModal_19kie_178 ._status_19kie_241 ._label_19kie_245._failed_19kie_248 {
  color: #da3737;
}
._DeleteStageModal_19kie_178 ._status_19kie_241 ._label_19kie_245._success_19kie_251 {
  color: #4eb468;
}
._DeleteStageModal_19kie_178 ._status_19kie_241 ._instructions_19kie_254 {
  margin-bottom: 1rem;
}
._DeleteStageModal_19kie_178 ._progressBar_19kie_257 .ant-progress-outer {
  margin-right: calc(-2.5em - 8px); /* stylelint-disable-line */
  padding-right: calc(2.5em + 8px); /* stylelint-disable-line */
}
._DeleteStageModal_19kie_178 ._progressBar_19kie_257 .ant-progress-text {
  width: 2.5em; /* stylelint-disable-line */
}