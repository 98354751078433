/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1wj1t_157 {
  display: flex;
}

._justify-content-space-between_1wj1t_161 {
  justify-content: space-between;
}

._tabular-nums_1wj1t_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1wj1t_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._statusWrapper_1wj1t_178 {
  flex-wrap: nowrap;
}

._buttonWrapper_1wj1t_182 {
  padding-right: 0 !important;
  padding-left: 0 !important;
  font-weight: normal;
}

._badgeIcon_1wj1t_188 {
  margin-right: 0.5rem;
}