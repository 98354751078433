/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_frl6a_157 {
  display: flex;
}

._justify-content-space-between_frl6a_161 {
  justify-content: space-between;
}

._tabular-nums_frl6a_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_frl6a_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._AssignPostTile_frl6a_178 {
  display: inline-block;
  width: 20.25rem;
  padding: 0.625rem 1.875rem 0.625rem 0;
  border-radius: 0.5rem;
  transition: background-color 0.15s cubic-bezier(0.4, 0, 1, 1);
  will-change: background-color;
}
._AssignPostTile_frl6a_178:hover ._image_frl6a_186,
._AssignPostTile_frl6a_178:hover ._video_frl6a_187 {
  filter: brightness(0.5);
}
._AssignPostTile_frl6a_178 ._media_frl6a_190 {
  position: relative;
  min-height: 6.25rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
}
._AssignPostTile_frl6a_178 ._media_frl6a_190 ._assignButton_frl6a_196 {
  display: none;
}
._AssignPostTile_frl6a_178 ._media_frl6a_190:hover ._assignButton_frl6a_196 {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  display: block;
  width: 18.375rem;
  height: 18.375rem;
}
._AssignPostTile_frl6a_178 ._media_frl6a_190 ._imageContainer_frl6a_207 {
  border: 0.0625rem solid #ececec;
  border-radius: 0.5rem;
}
._AssignPostTile_frl6a_178 ._media_frl6a_190 ._image_frl6a_186 {
  width: 18.375rem;
  height: 18.375rem;
  background-position: center top;
  /* scale bg image proportionately */
  object-fit: cover;
  background-repeat: no-repeat;
}
._AssignPostTile_frl6a_178 ._media_frl6a_190 ._video_frl6a_187 {
  border: 0.0625rem solid #ececec;
  border-radius: 0.5rem;
  transition: filter 0.15s cubic-bezier(0.4, 0, 1, 1);
  will-change: filter;
}
._AssignPostTile_frl6a_178 ._media_frl6a_190 ._checkbox_frl6a_225 {
  position: absolute;
  top: 0.25rem;
  left: 0.375rem;
}
._AssignPostTile_frl6a_178 ._media_frl6a_190 ._search_frl6a_230 {
  position: absolute;
  top: 0.875rem;
  right: 6.25rem;
  color: #fdfdfd;
  cursor: pointer;
}
._AssignPostTile_frl6a_178 ._details_frl6a_237 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2.25rem;
  margin-bottom: 0.75rem;
}
._AssignPostTile_frl6a_178 ._details_frl6a_237 ._avatar_frl6a_244 {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1.25rem;
  cursor: pointer;
}
._AssignPostTile_frl6a_178 ._details_frl6a_237 ._avatar_frl6a_244 ._avatarImage_frl6a_251 {
  width: 2rem;
  border-radius: 6.25rem;
}
._AssignPostTile_frl6a_178 ._details_frl6a_237 ._info_frl6a_255 {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  padding-right: 0.625rem;
  font-size: 0.75rem;
  line-height: 1.5rem;
  white-space: nowrap;
}
._AssignPostTile_frl6a_178 ._details_frl6a_237 ._info_frl6a_255 ._name_frl6a_266 {
  overflow: hidden;
  width: 100%;
  font-weight: 600;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
}
._AssignPostTile_frl6a_178 ._details_frl6a_237 ._info_frl6a_255 ._name_frl6a_266 a {
  color: #1a1818;
  text-decoration: none;
}
._AssignPostTile_frl6a_178 ._details_frl6a_237 ._info_frl6a_255 ._name_frl6a_266 a:hover {
  color: #4fa7ee;
}
._AssignPostTile_frl6a_178 ._details_frl6a_237 ._info_frl6a_255 ._date_frl6a_283 {
  font-weight: 300;
}
._AssignPostTile_frl6a_178 ._details_frl6a_237 ._actions_frl6a_286 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
._AssignPostTile_frl6a_178 ._details_frl6a_237 ._actions_frl6a_286 ._visit_frl6a_292 {
  width: 1rem;
  height: 1rem;
  background-image: url("/etc/aspirex/app/config/esbuild/../../src/app/assets/svgs/linkOut_dark.svg");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
._AssignPostTile_frl6a_178 ._TagList_frl6a_301 {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  text-align: left;
}
._AssignPostTile_frl6a_178 ._tag_frl6a_307 {
  position: relative;
  display: inline-block;
  height: 1.5rem;
  margin: 0 0.1875rem 0.3125rem 0;
  padding: 0.3125rem 0.375rem;
  border: 0.0625rem solid #ececec;
  border-radius: 0.25rem;
  background-color: #fdfdfd;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-transform: capitalize;
  cursor: pointer;
}

._networkIcon_frl6a_323 {
  padding-top: 6px;
  width: 1.5rem;
  height: 1.5rem;
  bottom: -0.75rem;
  right: -0.5rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 624.9375rem;
  z-index: 1;
}

._avatarContainer_frl6a_338 {
  margin-right: 0.75rem;
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  z-index: 0;
}
._avatarContainer_frl6a_338 > a {
  width: 100%;
  height: 100%;
  display: block;
}