/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1lk4i_157 {
  display: flex;
}

._justify-content-space-between_1lk4i_161 {
  justify-content: space-between;
}

._tabular-nums_1lk4i_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1lk4i_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._PayeeSetup_1lk4i_178 ._title_1lk4i_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._PayeeSetup_1lk4i_178 ._subtitle_1lk4i_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._PayeeSetup_1lk4i_178 ._label_1lk4i_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._PayeeSetup_1lk4i_178 {
  padding: 2.625rem;
}
._PayeeSetup_1lk4i_178 ._title_1lk4i_178 {
  margin-bottom: 0.625rem;
  text-align: center;
}
._PayeeSetup_1lk4i_178 ._subtitle_1lk4i_188 {
  margin-bottom: 1.875rem;
  text-align: center;
}
._PayeeSetup_1lk4i_178 ._form_1lk4i_219 {
  max-width: 18.75rem;
  margin: auto;
}
._PayeeSetup_1lk4i_178 ._buttons_1lk4i_223 {
  margin-top: 1.875rem;
  text-align: center;
}
._PayeeSetup_1lk4i_178 ._buttons_1lk4i_223 > * {
  margin-right: 0.9375rem;
}
._PayeeSetup_1lk4i_178 ._label_1lk4i_198 {
  margin-top: 1.25rem;
  margin-bottom: 0.3125rem;
}