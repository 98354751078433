/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_p2h8e_157 {
  display: flex;
}

._justify-content-space-between_p2h8e_161 {
  justify-content: space-between;
}

._tabular-nums_p2h8e_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_p2h8e_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._NewContent_p2h8e_178 {
  width: 100%;
  padding: 2rem 0;
}