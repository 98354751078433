/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_ghej9_157 {
  display: flex;
}

._justify-content-space-between_ghej9_161 {
  justify-content: space-between;
}

._tabular-nums_ghej9_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_ghej9_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MemberPaymentList_ghej9_178 {
  padding: 1rem;
}
._MemberPaymentList_ghej9_178 ._toolbar_ghej9_181 {
  display: flex;
}
._MemberPaymentList_ghej9_178 ._display_indicator_ghej9_184 {
  align-items: left;
  justify-content: flex-start;
  padding-top: 0.625rem;
}
._MemberPaymentList_ghej9_178 ._new_payment_button_ghej9_189 {
  margin-left: auto;
}
._MemberPaymentList_ghej9_178 ._none_placeholder_ghej9_192 {
  margin-top: 1.5rem;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background: #e6e4e7;
  color: #8f8d91;
  font-weight: 600;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.0625rem;
  letter-spacing: 0.021875rem;
  text-align: center;
}