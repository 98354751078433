._Thread_1jiie_1 {
  width: 100%;
  display: flex;
  gap: 12px;
  max-width: 1000px;
}
._Thread_1jiie_1 ._automationIcon_1jiie_7 {
  width: 12px;
  height: 12px;
  position: relative;
  right: 11px;
  top: 14px;
}
._Thread_1jiie_1 ._content_1jiie_14 {
  flex: 1;
  padding: 12px 16px;
  background-color: var(--gray-2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
}
._Thread_1jiie_1 ._content_1jiie_14 ._mainMessage_1jiie_24 {
  display: flex;
  gap: 6px;
  flex-direction: column;
  font-size: 14px;
  margin-bottom: 6px;
}
._Thread_1jiie_1 ._content_1jiie_14 ._mainMessage_1jiie_24 ._subject_1jiie_31 {
  color: var(--gray-9);
  font-weight: 600;
}
._Thread_1jiie_1 ._content_1jiie_14 ._mainMessage_1jiie_24 ._snippet_1jiie_35 {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--gray-8);
}
._Thread_1jiie_1 ._content_1jiie_14 ._reply_1jiie_42 {
  display: flex;
  gap: 8px;
}
._Thread_1jiie_1 ._content_1jiie_14 ._reply_1jiie_42:first-child {
  margin-top: 8px;
}
._Thread_1jiie_1 ._content_1jiie_14 ._reply_1jiie_42 ._replyContent_1jiie_49 {
  flex: 1;
  padding: 8px 12px;
  background-color: var(--gray-1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
._Thread_1jiie_1 ._content_1jiie_14 ._reply_1jiie_42 ._replyContent_1jiie_49 ._replySnippet_1jiie_58,
._Thread_1jiie_1 ._content_1jiie_14 ._reply_1jiie_42 ._replyContent_1jiie_49 ._replyDate_1jiie_59 {
  color: var(--gray-8);
  font-size: 14px;
  line-height: 1.5;
}
._Thread_1jiie_1 ._content_1jiie_14 ._messageHTMLContent_1jiie_64 {
  color: var(--gray-8);
  font-size: 14px;
  line-height: 1.5;
}
._Thread_1jiie_1 ._content_1jiie_14 ._composerContainer_1jiie_69 {
  width: 100%;
  display: flex;
  gap: 12px;
}
._Thread_1jiie_1 ._content_1jiie_14 ._composerContainer_1jiie_69 ._composer_1jiie_69 {
  flex: 1;
  background-color: var(--gray-1);
  border: 1px solid var(--gray-5);
  border-radius: 8px;
}
._Thread_1jiie_1 ._content_1jiie_14 ._composerContainer_1jiie_69 ._emailComposer_1jiie_80 {
  height: 100% !important;
}
._Thread_1jiie_1 ._content_1jiie_14 ._composerContainer_1jiie_69 ._emailComposer_1jiie_80._hide_1jiie_83 {
  display: none;
}
._Thread_1jiie_1 ._content_1jiie_14 ._replyButton_1jiie_86 {
  width: 92px;
}
._Thread_1jiie_1 ._content_1jiie_14 ._mainDate_1jiie_89 {
  color: var(--gray-8);
  font-size: 14px;
}
._Thread_1jiie_1 ._content_1jiie_14 ._callToAction_1jiie_93 {
  display: flex;
  gap: 16px;
}
._Thread_1jiie_1 ._content_1jiie_14 ._callToAction_1jiie_93 .ant-btn {
  padding: 4px 0;
}