/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_g57v5_157 {
  display: flex;
}

._justify-content-space-between_g57v5_161 {
  justify-content: space-between;
}

._tabular-nums_g57v5_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_g57v5_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
:root {
  --spacing-xxxl: 80px;
  --spacing-xxl: 48px;
  --spacing-xl: 32px;
  --spacing-lg: 24px;
  --spacing-md: 16px;
  --spacing-sm: 12px;
  --spacing-xs: 8px;
  --spacing-xxs: 4px;
}

._FindCreatorsPage_g57v5_200 {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: var(--white);
}
._FindCreatorsPage_g57v5_200 ._title_g57v5_209 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 32px 32px 0;
}
._FindCreatorsPage_g57v5_200 ._title_g57v5_209 h1 {
  margin: 0;
  font-size: 1.375rem;
}
._FindCreatorsPage_g57v5_200 ._divider_g57v5_219 {
  margin: 1.875rem;
}