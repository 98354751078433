/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1d5p2_157 {
  display: flex;
}

._justify-content-space-between_1d5p2_161 {
  justify-content: space-between;
}

._tabular-nums_1d5p2_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1d5p2_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MemberDetailPage_1d5p2_178 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  background-color: #fdfdfd;
}