/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_983kw_157 {
  display: flex;
}

._justify-content-space-between_983kw_161 {
  justify-content: space-between;
}

._tabular-nums_983kw_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_983kw_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ListHeader_983kw_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._ListHeader_983kw_178 {
  margin-bottom: -0.75rem;
}
._ListHeader_983kw_178 ._row_983kw_191 {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
._ListHeader_983kw_178 ._row_983kw_191 ._messageComposer_983kw_196 {
  border-radius: 0.5rem;
  color: inherit;
}
._ListHeader_983kw_178 ._row_983kw_191 ._messageComposer_983kw_196,
._ListHeader_983kw_178 ._row_983kw_191 > *:not([class*=approveReject]),
._ListHeader_983kw_178 ._approveReject_983kw_202 > * {
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
}
._ListHeader_983kw_178 ._reject_983kw_206 {
  background-color: #e9e8ea;
  color: #da3737;
}
._ListHeader_983kw_178 ._reject_983kw_206:hover, ._ListHeader_983kw_178 ._reject_983kw_206:active {
  color: #da3737;
}

._menuButton_983kw_214 {
  display: flex;
  align-items: center;
  width: 36px;
  height: 36px;
  padding-left: 8px;
}