/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_amhwo_157 {
  display: flex;
}

._justify-content-space-between_amhwo_161 {
  justify-content: space-between;
}

._tabular-nums_amhwo_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_amhwo_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MemberIGDMActivityItem_amhwo_178 ._header_amhwo_178 ._label_amhwo_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._MemberIGDMActivityItem_amhwo_178 ._body_amhwo_188 ._actions_amhwo_188 ._action_amhwo_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.214;
  color: #1a1818;
}

._MemberIGDMActivityItem_amhwo_178 ._body_amhwo_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._MemberIGDMActivityItem_amhwo_178 ._header_amhwo_178 ._date_amhwo_208 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._MemberIGDMActivityItem_amhwo_178 {
  display: flex;
  flex-direction: column;
}
._MemberIGDMActivityItem_amhwo_178 ._header_amhwo_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.25rem;
}
._MemberIGDMActivityItem_amhwo_178 ._header_amhwo_178 ._appIcon_amhwo_259 {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1.125rem;
}
._MemberIGDMActivityItem_amhwo_178 ._header_amhwo_178 ._gmailIcon_amhwo_264 {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1.125rem;
  background: url("/etc/aspirex/app/config/esbuild/../../src/app/assets/svgs/gmail.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
._MemberIGDMActivityItem_amhwo_178 ._header_amhwo_178 ._date_amhwo_208 {
  flex-shrink: 0;
  margin-left: auto;
  color: #8f8d91;
}
._MemberIGDMActivityItem_amhwo_178 ._body_amhwo_188 {
  display: flex;
  flex-direction: column;
  margin-left: 0.625rem;
  padding-bottom: 1.875rem;
  padding-left: 1.75rem;
  border-left: 0.0625rem solid #d5d3d8;
}
._MemberIGDMActivityItem_amhwo_178 ._body_amhwo_188 ._actions_amhwo_188 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
}
._MemberIGDMActivityItem_amhwo_178 ._body_amhwo_188 ._actions_amhwo_188 ._action_amhwo_188 {
  margin-right: 1.25rem;
  color: #3996e0;
  cursor: pointer;
  user-select: none;
  transition: color 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: color;
}
._MemberIGDMActivityItem_amhwo_178 ._body_amhwo_188 ._actions_amhwo_188 ._action_amhwo_188:hover {
  color: #4fa7ee;
}