/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1tf5p_157 {
  display: flex;
}

._justify-content-space-between_1tf5p_161 {
  justify-content: space-between;
}

._tabular-nums_1tf5p_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1tf5p_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MemberMissingDetails_1tf5p_178 ._header_1tf5p_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._MemberMissingDetails_1tf5p_178 ._message_1tf5p_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._MemberMissingDetails_1tf5p_178 {
  text-align: center;
}
._MemberMissingDetails_1tf5p_178 ._header_1tf5p_178 {
  margin: 1rem 0;
}
._MemberMissingDetails_1tf5p_178 ._message_1tf5p_188 {
  margin-bottom: 1.5rem;
}
._MemberMissingDetails_1tf5p_178 ._button_1tf5p_207 {
  margin: 0 1rem;
}