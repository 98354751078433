/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_9flyd_157 {
  display: flex;
}

._justify-content-space-between_9flyd_161 {
  justify-content: space-between;
}

._tabular-nums_9flyd_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_9flyd_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._LoginView_9flyd_178 ._title_9flyd_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._LoginView_9flyd_178 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
._LoginView_9flyd_178 ._card_9flyd_229 {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
._LoginView_9flyd_178 ._title_9flyd_178 {
  margin-bottom: 1.25rem;
}
._LoginView_9flyd_178 ._title_9flyd_178 ._name_9flyd_238 {
  color: #3996e0;
}
._LoginView_9flyd_178 ._notice_9flyd_241 {
  margin-bottom: 1.25rem;
}
._LoginView_9flyd_178 ._button_9flyd_244 {
  width: 11.25rem;
  margin-bottom: 2.5rem;
}