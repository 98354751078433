._CustomTooltip_16ry2_1 {
  min-width: 195px;
  background-color: #FFFFFF;
  padding: 8px 12px;
  border: 1px solid #F0F0F0;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
}
._CustomTooltip_16ry2_1 ._rowText_16ry2_13 {
  margin-right: 8px;
}
._CustomTooltip_16ry2_1 ._rowCircle_16ry2_16 {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 18px;
}
._CustomTooltip_16ry2_1 ._rowDivider_16ry2_22 {
  border-top: 1px solid #d9d9d9;
}