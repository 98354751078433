._Issues_p39zu_1 {
  margin-bottom: 24px;
}
._Issues_p39zu_1 ._item_p39zu_4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
}
._Issues_p39zu_1 ._item_p39zu_4 ._avatar_p39zu_10, ._Issues_p39zu_1 ._item_p39zu_4 ._checkbox_p39zu_10 {
  flex-shrink: 0;
}
._Issues_p39zu_1 ._item_p39zu_4 ._info_p39zu_13 {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}