/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_aqfva_157 {
  display: flex;
}

._justify-content-space-between_aqfva_161 {
  justify-content: space-between;
}

._tabular-nums_aqfva_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_aqfva_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._IGMessageList_aqfva_209 {
  display: flex;
  flex-direction: column;
  height: 100%;
}
._IGMessageList_aqfva_209 ._threadHeader_aqfva_214 {
  flex-shrink: 0;
}
._IGMessageList_aqfva_209 ._list_aqfva_217 {
  flex: 1;
  overflow-y: auto;
  padding: 1rem 1.375rem;
}
._IGMessageList_aqfva_209 ._list_aqfva_217 ._item_aqfva_222 {
  margin-bottom: 1.25rem;
}
._IGMessageList_aqfva_209 ._list_aqfva_217 ._createMessageForm_aqfva_225 {
  border: 0.0625rem solid rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  background: #fdfdfd;
  box-shadow: 0 0.0625rem 0.375rem rgba(26, 24, 24, 0.08);
}