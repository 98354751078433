/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_sf7v1_157 {
  display: flex;
}

._justify-content-space-between_sf7v1_161 {
  justify-content: space-between;
}

._tabular-nums_sf7v1_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_sf7v1_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._AutomationDate_sf7v1_209 ._title_sf7v1_209 {
  color: #1f1f21;
}
._AutomationDate_sf7v1_209 ._date_sf7v1_212 {
  color: #505256;
}