/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_nqweh_157 {
  display: flex;
}

._justify-content-space-between_nqweh_161 {
  justify-content: space-between;
}

._tabular-nums_nqweh_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_nqweh_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._CommunityIdentity_nqweh_178 {
  display: flex;
  flex-direction: row;
  margin-top: 1.875rem;
}
._CommunityIdentity_nqweh_178 ._left_nqweh_183 {
  flex: 1;
  min-width: 12.5rem;
  margin-right: 1.875rem;
}
._CommunityIdentity_nqweh_178 ._right_nqweh_188 {
  flex: 2;
}