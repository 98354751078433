/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_13mow_157 {
  display: flex;
}

._justify-content-space-between_13mow_161 {
  justify-content: space-between;
}

._tabular-nums_13mow_165 {
  font-variant-numeric: tabular-nums;
}

html ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_13mow_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
html {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
html ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
html ::-webkit-scrollbar-track, html ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
html ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
html {
  height: 100%;
  font-size: 16px;
}
html body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
}
html body #app {
  height: 100%;
}
*, ::after, ::before {
  box-sizing: border-box;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

:root {
  --ant-primary: #167cf4;
  --ant-secondary: #f58984;
}

.ant-refresh * {
  font-family: var(--refresh-font-family);
}
.ant-refresh .ant-btn, .ant-refresh .ant-input, .ant-refresh .ant-input-affix-wrapper, .ant-refresh .ant-alert, .ant-refresh .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-refresh .ant-select-dropdown, .ant-refresh .ant-dropdown-menu .ant-dropdown-menu-item, .ant-refresh .ant-select-item, .ant-refresh .ant-tooltip-inner, .ant-refresh .ant-popover-inner, .ant-refresh .ant-menu, .ant-refresh .ant-menu-item {
  border-radius: var(--radius);
}
.ant-refresh .ant-checkbox, .ant-refresh .ant-checkbox-inner {
  border-radius: calc(var(--radius) / 2);
}
.ant-refresh .ant-card, .ant-refresh .ant-modal-content {
  border-radius: calc(2 * var(--radius));
}
.ant-refresh .ant-btn.ant-btn-default, .ant-refresh [button-theme=button-info] {
  background-color: hsl(var(--card));
  color: hsl(var(--card-foreground));
  border: 1px solid hsl(var(--refresh-grey-3));
}
.ant-refresh .ant-btn.ant-btn-default:hover:not(.ant-btn-primary):not(.ant-btn-dangerous):not([disabled]):not(.ant-btn-link):not(.ant-btn-text), .ant-refresh .ant-btn.ant-btn-default:focus:not(.ant-btn-primary):not(.ant-btn-dangerous):not([disabled]):not(.ant-btn-link):not(.ant-btn-text), .ant-refresh [button-theme=button-info]:hover:not(.ant-btn-primary):not(.ant-btn-dangerous):not([disabled]):not(.ant-btn-link):not(.ant-btn-text), .ant-refresh [button-theme=button-info]:focus:not(.ant-btn-primary):not(.ant-btn-dangerous):not([disabled]):not(.ant-btn-link):not(.ant-btn-text) {
  background-color: hsl(var(--refresh-grey-1));
  border: 1px solid hsl(var(--refresh-grey-5));
  color: hsl(var(--refresh-grey-5));
}
.ant-refresh .ant-btn.ant-btn-default:active:not(.ant-btn-primary):not(.ant-btn-dangerous):not([disabled]):not(.ant-btn-link):not(.ant-btn-text), .ant-refresh [button-theme=button-info]:active:not(.ant-btn-primary):not(.ant-btn-dangerous):not([disabled]):not(.ant-btn-link):not(.ant-btn-text) {
  background-color: hsl(var(--refresh-grey-3));
  color: hsl(var(--refresh-grey-5));
}
.ant-refresh .ant-btn.ant-btn-default:disabled:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link):not(.ant-btn-ghost), .ant-refresh [button-theme=button-info]:disabled:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link):not(.ant-btn-ghost) {
  background-color: hsl(var(--refresh-grey-1)) !important;
  border: 1px solid hsl(var(--refresh-grey-2)) !important;
  color: hsl(var(--refresh-grey-4)) !important;
}
.ant-refresh .ant-btn.ant-btn-default.ant-btn-dangerous, .ant-refresh [button-theme=button-info].ant-btn-dangerous {
  background-color: hsl(var(--card));
  color: hsl(var(--destructive));
  border: 1px solid hsl(var(--destructive));
}
.ant-refresh .ant-btn.ant-btn-default.ant-btn-dangerous:hover, .ant-refresh .ant-btn.ant-btn-default.ant-btn-dangerous:focus, .ant-refresh [button-theme=button-info].ant-btn-dangerous:hover, .ant-refresh [button-theme=button-info].ant-btn-dangerous:focus {
  background-color: hsl(var(--refresh-grey-1));
  border: 1px solid hsl(var(--refresh-red-5));
  color: hsl(var(--refresh-red-5));
}
.ant-refresh .ant-btn.ant-btn-default.ant-btn-dangerous:active, .ant-refresh [button-theme=button-info].ant-btn-dangerous:active {
  background-color: hsl(var(--refresh-grey-3));
  border: 1px solid hsl(var(--refresh-red-6));
  color: hsl(var(--refresh-red-6));
}
.ant-refresh .ant-btn.ant-btn-default.ant-btn-dangerous:disabled, .ant-refresh [button-theme=button-info].ant-btn-dangerous:disabled {
  background-color: hsl(var(--refresh-red-2));
  border: 0;
}
.ant-refresh .ant-btn.ant-btn.ant-btn-primary.ant-btn-primary.ant-btn-primary, .ant-refresh [button-theme=button-primary] {
  background-color: hsl(var(--primary));
  color: hsl(var(--primary-foreground));
  border: 0;
}
.ant-refresh .ant-btn.ant-btn.ant-btn-primary.ant-btn-primary.ant-btn-primary:hover, .ant-refresh [button-theme=button-primary]:hover {
  background-color: hsl(var(--refresh-grey-5));
  color: hsl(var(--primary-foreground));
  border: 0;
}
.ant-refresh .ant-btn.ant-btn.ant-btn-primary.ant-btn-primary.ant-btn-primary:active, .ant-refresh [button-theme=button-primary]:active {
  background-color: hsl(var(--refresh-grey-4));
  border: 0;
}
.ant-refresh .ant-btn.ant-btn.ant-btn-primary.ant-btn-primary.ant-btn-primary:disabled, .ant-refresh [button-theme=button-primary]:disabled {
  background-color: hsl(var(--refresh-grey-4));
  border: 0;
  color: hsl(var(--refresh-grey-0));
}
.ant-refresh .ant-btn.ant-btn.ant-btn-primary.ant-btn-primary.ant-btn-primary.ant-btn-dangerous, .ant-refresh [button-theme=button-primary].ant-btn-dangerous {
  background-color: hsl(var(--destructive)) !important;
  color: hsl(var(--destructive-foreground)) !important;
}
.ant-refresh .ant-btn.ant-btn.ant-btn-primary.ant-btn-primary.ant-btn-primary.ant-btn-dangerous:hover, .ant-refresh .ant-btn.ant-btn.ant-btn-primary.ant-btn-primary.ant-btn-primary.ant-btn-dangerous:focus, .ant-refresh [button-theme=button-primary].ant-btn-dangerous:hover, .ant-refresh [button-theme=button-primary].ant-btn-dangerous:focus {
  background-color: hsl(var(--refresh-red-5)) !important;
  border: 0;
}
.ant-refresh .ant-btn.ant-btn.ant-btn-primary.ant-btn-primary.ant-btn-primary.ant-btn-dangerous:active, .ant-refresh [button-theme=button-primary].ant-btn-dangerous:active {
  background-color: hsl(var(--refresh-red-6)) !important;
  border: 0;
}
.ant-refresh .ant-btn.ant-btn.ant-btn-primary.ant-btn-primary.ant-btn-primary.ant-btn-dangerous:disabled, .ant-refresh [button-theme=button-primary].ant-btn-dangerous:disabled {
  background-color: hsl(var(--refresh-red-2)) !important;
  border: 0;
}
.ant-refresh .ant-btn.ant-btn-ghost, .ant-refresh .ant-btn.ant-btn-default, .ant-refresh .ant-btn.ant-btn-dashed {
  color: hsl(var(--card-foreground));
  border-color: hsl(var(--refresh-grey-3));
}
.ant-refresh .ant-btn.ant-btn-ghost:hover:not(.ant-btn-primary):not(.ant-btn-dangerous):not([disabled]):not(.ant-btn-link):not(.ant-btn-text), .ant-refresh .ant-btn.ant-btn-ghost:focus:not(.ant-btn-primary):not(.ant-btn-dangerous):not([disabled]):not(.ant-btn-link):not(.ant-btn-text), .ant-refresh .ant-btn.ant-btn-default:hover:not(.ant-btn-primary):not(.ant-btn-dangerous):not([disabled]):not(.ant-btn-link):not(.ant-btn-text), .ant-refresh .ant-btn.ant-btn-default:focus:not(.ant-btn-primary):not(.ant-btn-dangerous):not([disabled]):not(.ant-btn-link):not(.ant-btn-text), .ant-refresh .ant-btn.ant-btn-dashed:hover:not(.ant-btn-primary):not(.ant-btn-dangerous):not([disabled]):not(.ant-btn-link):not(.ant-btn-text), .ant-refresh .ant-btn.ant-btn-dashed:focus:not(.ant-btn-primary):not(.ant-btn-dangerous):not([disabled]):not(.ant-btn-link):not(.ant-btn-text) {
  border-color: hsl(var(--refresh-grey-5));
  color: hsl(var(--refresh-grey-5));
}
.ant-refresh .ant-btn.ant-btn-ghost:active:not(.ant-btn-primary):not(.ant-btn-dangerous):not([disabled]):not(.ant-btn-link):not(.ant-btn-text), .ant-refresh .ant-btn.ant-btn-default:active:not(.ant-btn-primary):not(.ant-btn-dangerous):not([disabled]):not(.ant-btn-link):not(.ant-btn-text), .ant-refresh .ant-btn.ant-btn-dashed:active:not(.ant-btn-primary):not(.ant-btn-dangerous):not([disabled]):not(.ant-btn-link):not(.ant-btn-text) {
  color: hsl(var(--refresh-grey-5));
}
.ant-refresh .ant-btn.ant-btn-ghost:disabled:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link):not(.ant-btn-ghost), .ant-refresh .ant-btn.ant-btn-default:disabled:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link):not(.ant-btn-ghost), .ant-refresh .ant-btn.ant-btn-dashed:disabled:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link):not(.ant-btn-ghost) {
  border-color: hsl(var(--refresh-grey-2));
  color: hsl(var(--refresh-grey-2));
}
.ant-refresh .ant-btn.ant-btn-ghost:disabled, .ant-refresh .ant-btn.ant-btn-default:disabled, .ant-refresh .ant-btn.ant-btn-dashed:disabled {
  color: hsl(var(--refresh-grey-4)) !important;
}
.ant-refresh .ant-btn.ant-btn-ghost.ant-btn-dangerous, .ant-refresh .ant-btn.ant-btn-default.ant-btn-dangerous, .ant-refresh .ant-btn.ant-btn-dashed.ant-btn-dangerous {
  color: hsl(var(--destructive));
  border-color: hsl(var(--destructive));
}
.ant-refresh .ant-btn.ant-btn-ghost.ant-btn-dangerous:hover, .ant-refresh .ant-btn.ant-btn-ghost.ant-btn-dangerous:focus, .ant-refresh .ant-btn.ant-btn-default.ant-btn-dangerous:hover, .ant-refresh .ant-btn.ant-btn-default.ant-btn-dangerous:focus, .ant-refresh .ant-btn.ant-btn-dashed.ant-btn-dangerous:hover, .ant-refresh .ant-btn.ant-btn-dashed.ant-btn-dangerous:focus {
  border-color: hsl(var(--refresh-red-5));
  color: hsl(var(--refresh-red-5));
}
.ant-refresh .ant-btn.ant-btn-ghost.ant-btn-dangerous:active, .ant-refresh .ant-btn.ant-btn-default.ant-btn-dangerous:active, .ant-refresh .ant-btn.ant-btn-dashed.ant-btn-dangerous:active {
  border-color: hsl(var(--refresh-red-6));
  color: hsl(var(--refresh-red-6));
}
.ant-refresh .ant-btn.ant-btn-ghost.ant-btn-dangerous:disabled, .ant-refresh .ant-btn.ant-btn-default.ant-btn-dangerous:disabled, .ant-refresh .ant-btn.ant-btn-dashed.ant-btn-dangerous:disabled {
  border: hsl(var(--refresh-grey-1));
}
.ant-refresh .ant-btn.ant-btn-link {
  color: hsl(var(--primary)) !important;
}
.ant-refresh .ant-btn.ant-btn-link:disabled {
  color: hsl(var(--refresh-grey-4)) !important;
}
.ant-refresh .ant-btn.ant-btn-link:hover, .ant-refresh .ant-btn.ant-btn-link:focus {
  color: hsl(var(--refresh-grey-5)) !important;
}
.ant-refresh .ant-alert-error {
  background-color: hsl(var(--refresh-red-2));
  border-color: hsl(var(--refresh-red));
}
.ant-refresh .ant-alert-success {
  background-color: hsl(var(--refresh-green-2));
  border-color: hsl(var(--refresh-green));
}
.ant-refresh .ant-alert-warning {
  background-color: hsl(var(--refresh-yellow-2));
  border-color: hsl(var(--refresh-yellow));
}
.ant-refresh .ant-alert-info {
  background-color: hsl(var(--refresh-blue-2));
  border-color: hsl(var(--refresh-blue));
}
.ant-refresh .ant-modal-mask, .ant-refresh .ant-drawer-mask {
  background-color: hsl(var(--refresh-grey)/0.45);
}
.ant-refresh .ant-checkbox .ant-checkbox-checked:after {
  border-color: hsl(var(--primary));
}
.ant-refresh .ant-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner, .ant-refresh .ant-checkbox-wrapper .ant-checkbox-input:focus + .ant-checkbox-inner, .ant-refresh .ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: hsl(var(--primary));
}
.ant-refresh .ant-checkbox:focus .ant-checkbox-inner, .ant-refresh .ant-checkbox:hover .ant-checkbox-inner, .ant-refresh .ant-checkbox-wrapper:focus .ant-checkbox-inner, .ant-refresh .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-refresh .ant-checkbox-checked:focus .ant-checkbox-inner, .ant-refresh .ant-checkbox-checked:hover .ant-checkbox-inner {
  border-color: hsl(var(--primary));
  border-radius: calc(var(--radius) / 2);
}
.ant-refresh .ant-checkbox-checked .ant-checkbox-inner {
  background-color: hsl(var(--primary));
  border-color: hsl(var(--refresh-grey));
}
.ant-refresh .ant-checkbox-checked:after {
  border-color: hsl(var(--primary));
}
.ant-refresh .ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: hsl(var(--primary));
}
.ant-refresh .ant-input, .ant-refresh .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
  border-color: hsl(var(--refresh-grey));
}
.ant-refresh .ant-input:focus, .ant-refresh .ant-input:hover, .ant-refresh .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus, .ant-refresh .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: hsl(var(--primary));
  box-shadow: none;
}
.ant-refresh .ant-input:focus.ant-input-prefix, .ant-refresh .ant-input:hover.ant-input-prefix, .ant-refresh .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus.ant-input-prefix, .ant-refresh .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover.ant-input-prefix {
  color: hsl(var(--primary));
}
.ant-refresh .ant-select-dropdown, .ant-refresh .ant-dropdown-menu, .ant-refresh .ant-menu {
  padding: 4px;
}
.ant-refresh .ant-dropdown-menu-item.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled), .ant-refresh .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu:not(.ant-dropdown-menu-submenu-diabled) .ant-dropdown-menu-submenu-title {
  color: hsl(var(--primary)) !important;
}
.ant-refresh .ant-dropdown-menu-item.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled):hover, .ant-refresh .ant-dropdown-menu-item.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled):hover span, .ant-refresh .ant-dropdown-menu-item.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled):focus, .ant-refresh .ant-dropdown-menu-item.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled):focus span, .ant-refresh .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu:not(.ant-dropdown-menu-submenu-diabled) .ant-dropdown-menu-submenu-title:hover, .ant-refresh .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu:not(.ant-dropdown-menu-submenu-diabled) .ant-dropdown-menu-submenu-title:hover span, .ant-refresh .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu:not(.ant-dropdown-menu-submenu-diabled) .ant-dropdown-menu-submenu-title:focus, .ant-refresh .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu:not(.ant-dropdown-menu-submenu-diabled) .ant-dropdown-menu-submenu-title:focus span {
  color: hsl(var(--primary)) !important;
}
.ant-refresh .ant-dropdown-menu-item.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled):hover .ant-btn-primary span, .ant-refresh .ant-dropdown-menu-item.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled):focus .ant-btn-primary span, .ant-refresh .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu:not(.ant-dropdown-menu-submenu-diabled) .ant-dropdown-menu-submenu-title:hover .ant-btn-primary span, .ant-refresh .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu:not(.ant-dropdown-menu-submenu-diabled) .ant-dropdown-menu-submenu-title:focus .ant-btn-primary span {
  color: hsl(var(--refresh-grey-0)) !important;
}
.ant-refresh .ant-dropdown-menu-item:hover {
  background-color: hsl(var(--refresh-grey-0)) !important;
}
.ant-refresh .ant-dropdown-menu-item-disabled.ant-dropdown-menu-item-disabled {
  color: hsl(var(--refresh-grey-3));
  background-color: hsl(var(--refresh-grey-0));
}
.ant-refresh .ant-dropdown-menu-item-disabled.ant-dropdown-menu-item-disabled:hover, .ant-refresh .ant-dropdown-menu-item-disabled.ant-dropdown-menu-item-disabled:focus {
  color: hsl(var(--refresh-grey-3)) !important;
  background-color: hsl(var(--refresh-grey-0)) !important;
}
.ant-refresh .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title {
  color: hsl(var(--refresh-grey-3)) !important;
}
.ant-refresh .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title:hover, .ant-refresh .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title:hover span, .ant-refresh .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title:focus, .ant-refresh .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title:focus span {
  color: hsl(var(--primary)) !important;
}
.ant-refresh .ant-dropdown-menu-item-danger {
  color: hasl(var(--refresh-red)) !important;
}
.ant-refresh .ant-dropdown-menu-item-danger:hover, .ant-refresh .ant-dropdown-menu-item-danger:focus {
  background-color: hsl(var(--refresh-grey-1)) !important;
  color: hsl(var(--refresh-red-5)) !important;
}
.ant-refresh .ant-select.ant-select {
  border-color: hsl(var(--refresh-grey-3));
}
.ant-refresh .ant-select.ant-select:hover:not(.ant-select-disabled), .ant-refresh .ant-select.ant-select:hover:not(.ant-select-disabled) .ant-select-selector, .ant-refresh .ant-select.ant-select:focus:not(.ant-select-disabled), .ant-refresh .ant-select.ant-select:focus:not(.ant-select-disabled) .ant-select-selector {
  border-color: hsl(var(--primary));
  outline-color: hsl(var(--primary)) !important;
}
.ant-refresh .ant-select.ant-select:hover:not(.ant-select-disabled) .ant-select-arrow, .ant-refresh .ant-select.ant-select:hover:not(.ant-select-disabled) .ant-select-selector .ant-select-arrow, .ant-refresh .ant-select.ant-select:focus:not(.ant-select-disabled) .ant-select-arrow, .ant-refresh .ant-select.ant-select:focus:not(.ant-select-disabled) .ant-select-selector .ant-select-arrow {
  color: hsl(var(--primary));
}
.ant-refresh .ant-select.ant-select.ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
  box-shadow: none;
  border-color: hsl(var(--primary));
}
.ant-refresh .ant-select-dropdown .ant-select-item-option {
  color: hsl(var(--primary));
}
.ant-refresh .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: hsl(var(--refresh-grey-0));
  font-weight: 600;
}
.ant-refresh .ant-list-items .ant-list-item {
  color: hsl(var(--primary));
}
.ant-refresh .ant-list-items .ant-list-item:hover, .ant-refresh .ant-list-items .ant-list-item:focus {
  background-color: hsl(var(--refresh-grey-1));
  color: hsl(var(--primary));
}
.ant-refresh .ant-modal-content, .ant-refresh .ant-dropdown-menu:not(.ant-dropdown-menu-submenu-popup), .ant-refresh .ant-select-dropdown, .ant-refresh .ant-popover-inner, .ant-refresh .ant-tooltip-inner, .ant-refresh .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper, .ant-refresh .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0px 2px 6px -4px hsla(0, 0%, 0%, 0.12), 0px 4px 8px 0px hsla(209, 11%, 41%, 0.08), 0px 8px 24px 8px hsla(209, 11%, 41%, 0.05);
}
.ant-refresh .ant-tabs-nav-wrap .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: hsl(var(--primary)) !important;
}
.ant-refresh .ant-tabs-nav-wrap .ant-tabs-ink-bar {
  background: hsl(var(--primary)) !important;
}
.ant-refresh .ant-tabs-nav-wrap .ant-tabs-tab:hover {
  color: hsl(var(--refresh-blue-6)) !important;
}
.ant-refresh *[data-check=checkIcon] svg {
  fill: hsl(var(--primary)) !important;
}
.ant-refresh .ant-switch-checked {
  background-color: hsl(var(--primary)) !important;
}
.ant-refresh .ant-radio-checked .ant-radio-inner, .ant-refresh .ant-radio:hover .ant-radio-inner {
  border-color: hsl(var(--primary)) !important;
}
.ant-refresh .ant-radio-inner:after {
  background-color: hsl(var(--primary)) !important;
}
.ant-refresh .ant-typography a, .ant-refresh a.ant-typography {
  color: hsl(var(--primary));
}
.ant-refresh .ant-collapse-header {
  color: hsl(var(--primary)) !important;
}
.ant-refresh .ant-picker:not(.ant-picker-status-error):not(.ant-picker-status-warning):hover {
  border-color: hsl(var(--primary)) !important;
}
.ant-refresh .ant-picker:not(.ant-picker-status-error):not(.ant-picker-status-warning):active {
  border-color: hsl(var(--primary)) !important;
}
.ant-refresh .ant-picker:not(.ant-picker-status-error):not(.ant-picker-status-warning):active {
  border-color: hsl(var(--primary)) !important;
}
.ant-refresh .ant-picker .ant-picker-focused {
  border-color: hsl(var(--primary)) !important;
}
.ant-refresh .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: hsl(var(--primary)) !important;
  color: hsl(var(--refresh-grey-0)) !important;
}
.ant-refresh .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border: 1px solid hsl(var(--primary)) !important;
}
.ant-refresh .DatePicker-module_DatePicker__TxraC.ant-picker:not(.ant-picker-status-error):not(.ant-picker-status-warning).ant-picker-focused {
  border-color: hsl(var(--primary)) !important;
  box-shadow: 0 0 0 2px hsl(var(--refresh-grey-2)) !important;
}
.ant-refresh .ant-picker-now-btn {
  color: hsl(var(--primary)) !important;
}
.ant-refresh .ant-input-group-addon {
  border-radius: 8px 0 0 8px !important;
}

._App_13mow_635 {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fdfdfd;
}
._App_13mow_635 ._children_13mow_641 {
  flex: 1;
  overflow-y: auto;
}
._App_13mow_635 ._loadSpinner_13mow_645 {
  position: absolute;
  top: calc(50% - 1.5625rem);
  left: calc(50% - 1.5625rem);
}

:root {
  --brand-accents-green-1: #113c0b;
}