:root {
  --screen-xs: 480px;
  --screen-sm: 576px;
  --screen-md: 768px;
  --screen-lg: 992px;
  --screen-xl: 1200px;
  --screen-xxl: 1600px;
}

._statistic_115j4_19 {
  align-items: flex-start;
}
._statistic_115j4_19 .ant-statistic-title {
  color: var(--gray-9);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  margin-bottom: var(--spacing-xxs);
  order: 0;
}
._statistic_115j4_19 .ant-statistic-content {
  font-size: var(--font-size-sm);
}

._default_115j4_33 .ant-statistic-content {
  font-size: var(--font-size-base);
}

._large_115j4_37 .ant-statistic-content {
  font-size: var(--font-size-base);
}

._padded_115j4_41 {
  padding-top: var(--spacing-xs);
  border-top: 1px solid var(--gray-5);
}

._bordered_115j4_46 {
  border: 1px solid var(--gray-4);
  border-radius: var(--border-radius-sm);
  padding: 0 var(--spacing-xxs);
}

._strong_115j4_52 .ant-statistic-content {
  font-weight: var(--font-weight-semibold);
}

._green_115j4_56 .ant-statistic-content {
  color: var(--green-7);
}

._red_115j4_60 .ant-statistic-content {
  color: var(--red-6);
}

._gray_115j4_64 .ant-statistic-content {
  color: var(--gray-5);
}

@media only screen and (min-width: 768px) {
  ._large_115j4_37 .ant-statistic-content {
    font-size: var(--font-size-xl);
  }
}