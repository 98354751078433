/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1tycw_157 {
  display: flex;
}

._justify-content-space-between_1tycw_161 {
  justify-content: space-between;
}

._tabular-nums_1tycw_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1tycw_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._RadioGroup_1tycw_178 [class*=ant-radio-button-wrapper] {
  min-width: 5.5rem !important;
  border-color: #dadcde;
  color: #aeaeae;
  text-align: center !important;
}
._RadioGroup_1tycw_178 [class*=ant-radio-button-wrapper]:focus-within {
  box-shadow: none !important;
}
._RadioGroup_1tycw_178 [class*=ant-radio-button-wrapper]:first-child {
  border-top-left-radius: 1.5625rem !important;
  border-bottom-left-radius: 1.5625rem !important;
}
._RadioGroup_1tycw_178 [class*=ant-radio-button-wrapper]:last-child {
  border-top-right-radius: 1.5625rem !important;
  border-bottom-right-radius: 1.5625rem !important;
}