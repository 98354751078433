/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_u2no5_157 {
  display: flex;
}

._justify-content-space-between_u2no5_161 {
  justify-content: space-between;
}

._tabular-nums_u2no5_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_u2no5_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ThankYouPage_u2no5_178 ._content_u2no5_178 > ._title_u2no5_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 48px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._ThankYouPage_u2no5_178 ._followBox_u2no5_188 ._text_u2no5_188 ._subtitle_u2no5_188, ._ThankYouPage_u2no5_178 ._content_u2no5_178 > ._subtitle_u2no5_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._ThankYouPage_u2no5_178 ._followBox_u2no5_188 ._text_u2no5_188 ._title_u2no5_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._ThankYouPage_u2no5_178 {
  position: relative;
  height: 100%;
  min-height: 50rem;
}
@media only screen and (max-width: 62.5rem) {
  ._ThankYouPage_u2no5_178 ._sideBanner_u2no5_214 {
    display: none;
  }
}
@media only screen and (max-width: 37.5rem) {
  ._ThankYouPage_u2no5_178 ._content_u2no5_178 {
    left: 5%;
    width: 90%;
  }
  ._ThankYouPage_u2no5_178 ._logo_u2no5_223 {
    left: 5%;
  }
}
._ThankYouPage_u2no5_178 ._content_u2no5_178 {
  position: relative;
  top: 50%;
  left: 10%;
  width: 80%;
  transform: translateY(-50%);
}
._ThankYouPage_u2no5_178 ._content_u2no5_178 > ._title_u2no5_178 {
  margin-bottom: 2rem;
  color: #2e426d;
}
._ThankYouPage_u2no5_178 ._content_u2no5_178 > ._subtitle_u2no5_188 {
  margin-bottom: 4.0625rem;
  color: #8f8d91;
}
._ThankYouPage_u2no5_178 ._followBox_u2no5_188 {
  display: flex;
  flex-direction: row;
  max-width: 31.25rem;
  padding: 2.125rem;
  border-radius: 0.75rem;
  background: linear-gradient(225.52deg, #8343bb 2.22%, #bd4186 30.98%, #d2525e 52.79%, #e98041 75.72%, #f9db8c 100.47%);
}
._ThankYouPage_u2no5_178 ._followBox_u2no5_188 ._icon_u2no5_250 {
  margin-right: 2.1875rem;
}
._ThankYouPage_u2no5_178 ._followBox_u2no5_188 ._icon_u2no5_250 * {
  fill: #fdfdfd !important;
}
._ThankYouPage_u2no5_178 ._followBox_u2no5_188 ._text_u2no5_188 {
  flex: 1;
}
._ThankYouPage_u2no5_178 ._followBox_u2no5_188 ._text_u2no5_188 ._title_u2no5_178 {
  margin-bottom: 1rem;
  color: #f6c772;
}
._ThankYouPage_u2no5_178 ._followBox_u2no5_188 ._text_u2no5_188 ._subtitle_u2no5_188 {
  color: #fdfdfd;
}
._ThankYouPage_u2no5_178 ._logo_u2no5_223 {
  position: absolute;
  top: 3.125rem;
  left: 3.125rem;
  width: 2.5rem;
  height: 2.5rem;
}
._ThankYouPage_u2no5_178 ._sideBanner_u2no5_214 {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}