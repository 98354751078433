/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1rcqs_157 {
  display: flex;
}

._justify-content-space-between_1rcqs_161 {
  justify-content: space-between;
}

._tabular-nums_1rcqs_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1rcqs_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ThumbnailUpload_1rcqs_178 ._fileSubtitle_1rcqs_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

._ThumbnailUpload_1rcqs_178 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 6.125rem;
  height: 6.125rem;
  text-align: center;
}
._ThumbnailUpload_1rcqs_178 ._fileSelector_1rcqs_196 {
  max-width: 12.5rem;
  max-height: 12.5rem;
}
._ThumbnailUpload_1rcqs_178._error_1rcqs_200 ._fileSelector_1rcqs_196 {
  border-color: #f1515f;
  background-color: #f6e4e5 !important;
}
._ThumbnailUpload_1rcqs_178 ._tmpLogoWrapper_1rcqs_204 ._removeImg_1rcqs_204 {
  position: relative;
  top: 0.9375rem;
  right: -85%;
  width: 1.5rem;
  height: 1.5rem;
  margin-top: -1.5rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 50%;
  background: #fdfdfd;
  color: #dadcde;
  cursor: pointer;
}
._ThumbnailUpload_1rcqs_178 ._tmpLogo_1rcqs_204 {
  object-fit: contain;
  max-width: 6.125rem;
  margin-bottom: 0.625rem;
  border-radius: 0.5rem;
}
._ThumbnailUpload_1rcqs_178 ._fileSubtitle_1rcqs_178 {
  margin-top: 0.625rem;
  color: #8f8d91;
}
._ThumbnailUpload_1rcqs_178 ._fileSubtitle_1rcqs_178._error_1rcqs_200 {
  color: #f1515f;
}