/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_pxd0y_157 {
  display: flex;
}

._justify-content-space-between_pxd0y_161 {
  justify-content: space-between;
}

._tabular-nums_pxd0y_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_pxd0y_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Select_pxd0y_178 {
  height: 2.5rem;
  color: #5F5F5F;
  font-weight: 400;
  font-size: 13px;
}
._Select_pxd0y_178 [class=ant-select-selector] {
  border-color: #dadcde !important;
  border-radius: 6.25rem !important;
  background-color: #eff5f9 !important;
}
._Select_pxd0y_178[class*=ant-select-focused] [class=ant-select-selector] {
  border-color: #3996E0 !important;
  box-shadow: none !important;
}
._Select_pxd0y_178 [class*=ant-select-arrow] {
  top: 60%;
  right: 1rem;
}
._Select_pxd0y_178 [class*=anticon-down] {
  width: 0;
  height: 0;
  border-top: 0.4375rem solid #aeaeae;
  border-right: 0.3125rem solid transparent;
  border-left: 0.3125rem solid transparent;
}
._Select_pxd0y_178 [class*=anticon-down] svg {
  display: none;
}

._SelectPopover_pxd0y_208 {
  border-radius: 0.25rem;
}
._SelectPopover_pxd0y_208 [class*="ant-select-item ant-select-item-option"] {
  padding: 0.25rem 1rem;
}