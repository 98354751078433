/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_6w5rp_157 {
  display: flex;
}

._justify-content-space-between_6w5rp_161 {
  justify-content: space-between;
}

._tabular-nums_6w5rp_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_6w5rp_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._TextItemsOptions_6w5rp_178 {
  display: flex;
  flex-direction: column;
}
._TextItemsOptions_6w5rp_178 ._itemOption_6w5rp_182 {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
._TextItemsOptions_6w5rp_178 ._itemOption_6w5rp_182 ._itemHeader_6w5rp_187 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
}
._TextItemsOptions_6w5rp_178 ._itemOption_6w5rp_182 ._itemHeader_6w5rp_187 ._title_6w5rp_193,
._TextItemsOptions_6w5rp_178 ._itemOption_6w5rp_182 ._itemHeader_6w5rp_187 ._removeItemButton_6w5rp_194 {
  font-weight: normal;
  font-style: normal;
  font-size: 0.8125rem;
  line-height: 1rem;
}
._TextItemsOptions_6w5rp_178 ._itemOption_6w5rp_182 ._itemHeader_6w5rp_187 ._title_6w5rp_193 {
  height: auto;
  margin: 0;
}
._TextItemsOptions_6w5rp_178 ._itemOption_6w5rp_182 ._itemHeader_6w5rp_187 ._removeItemButton_6w5rp_194 {
  height: auto;
  padding: 0;
  color: #f1515f;
}
._TextItemsOptions_6w5rp_178 ._itemOption_6w5rp_182 ._itemInput_6w5rp_209 {
  height: 7.5rem !important;
}
._TextItemsOptions_6w5rp_178 ._addItemButton_6w5rp_212 {
  margin-left: auto;
}