._DueDateItem_1ylmg_1 {
  display: flex;
  flex-direction: row;
}
._DueDateItem_1ylmg_1 ._text_1ylmg_5 {
  line-height: 32px;
  margin-inline-start: 10px;
}
._DueDateItem_1ylmg_1 ._text_1ylmg_5 span {
  vertical-align: middle;
}
._DueDateItem_1ylmg_1 > * + * {
  margin-inline-start: 10px;
}