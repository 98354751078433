:root {
  --spacing-xxxl: 80px;
  --spacing-xxl: 48px;
  --spacing-xl: 32px;
  --spacing-lg: 24px;
  --spacing-md: 16px;
  --spacing-sm: 12px;
  --spacing-xs: 8px;
  --spacing-xxs: 4px;
}

:root {
  --screen-xs: 480px;
  --screen-sm: 576px;
  --screen-md: 768px;
  --screen-lg: 992px;
  --screen-xl: 1200px;
  --screen-xxl: 1600px;
}

:root {
  --primary: #167cf4;
  --primary-40: #3d73d7;
  --primary-10: #e6f7ff;
  --secondary: #f58984;
  --secondary-40: #fcbbae;
  --secondary-10: #fef0ed;
  --bay-grey: #989ea8;
  --grey-10: #fafafa;
  --grey-25: #e9e8ea;
  --grey-50: #aeaeae;
  --grey-75: #8f8f8f;
  --grey-100: #1a1b1c;
  --white: #fff;
  --black: #1a1b1c;
  --green: #006462;
  --sand: #f6f3ef;
  --yellow: #ffcd5b;
  --magenta-base: #ffddff;
  --red-base: #f5b3b4;
  --volcano-base: #ff9c93;
  --orange-base: #fabf95;
  --gold-base: #f1e5ac;
  --lime-base: #ebffd0;
  --sage-base: #c1e1c1;
  --cyan-base: #c9f2f2;
  --blue-base: #70d1f1;
  --geekblue-base: #a2aff1;
  --purple-base: #c5b3e3;
  --mauve-base: #c6b0ca;
  --coral-base: #ff8a8a;
  --apricot-base: #f5a287;
  --marigold-base: #fdaf1c;
  --butter-base: #f9eda1;
  --apple-base: #b7d275;
  --asparagus-base: #9db17c;
  --tiffany-base: #5fcdca;
  --baby-base: #a3ddf9;
  --carolina-base: #7eb3d8;
  --rose-base: #ffb4c7;
  --watermelon-base: #ffa1b6;
  --blue-0: #f4fcff;
  --blue-1: #e6f7ff;
  --blue-3: #91d5ff;
  --blue-5: #499dff;
  --blue-6: #167cf4;
  --blue-7: #2771c9;
  --gray-1: #fff;
  --gray-2: #fafafa;
  --gray-3: #f5f5f5;
  --gray-4: #f0f0f0;
  --gray-5: #d9d9d9;
  --gray-6: #bfbfbf;
  --gray-7: #8c8c8c;
  --gray-8: #505256;
  --gray-9: #1f1f21;
  --red-1: #fff1f0;
  --red-3: #ffa39e;
  --red-4: #ff7875;
  --red-5: #ff4d4f;
  --red-6: #f5222d;
  --gold-1: #fffbe6;
  --gold-3: #ffe58f;
  --gold-5: #ffc53d;
  --gold-6: #faad14;
  --gold-7: #d48806;
  --green-1: #f6ffed;
  --green-3: #b7eb8f;
  --green-5: #73d13d;
  --green-6: #52c41a;
  --green-7: #389e0d;
  --text-color: #505256;
  --text-color-secondary: #8c8c8c;
  --text-color-inverse: #fff;
  --icon-color: inherit;
  --icon-color-hover: rgba(26, 27, 28, 0.75);
  --heading-color: #1f1f21;
  --text-color-dark: rgba(255, 255, 255, 0.85);
  --text-color-secondary-dark: rgba(255, 255, 255, 0.65);
  --text-selection-bg: #167cf4;
  --link-color: #167cf4;
  --modal-mask-bg: rgba(79, 90, 114, 0.45);
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_108ay_396 {
  display: flex;
}

._justify-content-space-between_108ay_400 {
  justify-content: space-between;
}

._tabular-nums_108ay_404 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_108ay_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._TemplatesPage_108ay_417 {
  display: flex;
  flex-direction: column;
}
._TemplatesPage_108ay_417 ._templatesPageHeader_108ay_421 {
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  min-height: 4rem;
  background-color: #fff;
  border-bottom: 0.0625rem solid #f5f5f5;
}
._TemplatesPage_108ay_417 ._templatesPageHeader_108ay_421 ._back_108ay_429 {
  margin-right: 0.75rem;
}
._TemplatesPage_108ay_417 ._templatesPageHeader_108ay_421 ._title_108ay_432 {
  margin: 0;
  font-weight: 600;
  font-size: 1.5rem;
}
._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 2rem 0;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 4rem;
  background-color: #fdfdfd;
  border-radius: 0.375rem;
  border: 1px solid #f0f0f0;
  position: relative;
  color: #1f1f21;
}
@media only screen and (max-width: 992px) {
  ._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 {
    margin: 24px 32px 0;
  }
}
@media only screen and (min-width: 992px) {
  ._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 {
    margin: 24px 48px 0;
  }
}
@media only screen and (min-width: 1600px) {
  ._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 {
    max-width: 1010px;
    margin: 24px auto 0;
  }
}
._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437._loading_108ay_469 {
  opacity: 0.4;
}
@media only screen and (min-width: 480px) {
  ._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 .ant-col-xs-20 {
    max-width: 83.33333333% !important;
  }
}
._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 ._title_108ay_432 {
  margin: 2rem 0 0.5rem;
  font-weight: 600;
  font-size: 1.375rem;
}
._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 ._blurb_108ay_482 {
  text-align: center;
  margin-bottom: 1.5rem;
}
._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 ._templates_108ay_421 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style: none;
}
._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 ._templates_108ay_421 ._template_108ay_421 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 37.5rem;
  margin: 0 auto;
  padding: 1rem;
  border: 0.0625rem solid #f0f0f0;
  background-color: #fafafa;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
}
._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 ._templates_108ay_421 ._template_108ay_421:hover {
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
}
._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 ._templates_108ay_421 ._template_108ay_421:hover ._templateAction_108ay_508 ._templateActionText_108ay_508 {
  color: #167cf4;
  display: inline;
}
._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 ._templates_108ay_421 ._template_108ay_421:hover ._templateAction_108ay_508 ._templateActionIcon_108ay_512 {
  color: #167cf4;
}
._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 ._templates_108ay_421 ._template_108ay_421 ._templateIcon_108ay_515 {
  margin-right: 1rem;
}
._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 ._templates_108ay_421 ._template_108ay_421 ._templateIcon_108ay_515 img {
  width: 3.75rem;
  height: 3.75rem;
}
._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 ._templates_108ay_421 ._template_108ay_421 ._templateText_108ay_522 {
  color: #1a1818;
  width: 25rem;
}
._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 ._templates_108ay_421 ._template_108ay_421 ._templateText_108ay_522 ._templateLabel_108ay_526 {
  font-weight: 600;
}
._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 ._templates_108ay_421 ._template_108ay_421 ._templateText_108ay_522 ._templateDescription_108ay_529 {
  color: #505256;
}
._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 ._templates_108ay_421 ._template_108ay_421 ._templateText_108ay_522 p {
  padding: 0;
  margin: 0;
}
._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 ._templates_108ay_421 ._template_108ay_421 ._templateAction_108ay_508 {
  width: 6.25rem;
  text-align: right;
}
._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 ._templates_108ay_421 ._template_108ay_421 ._templateAction_108ay_508 ._templateActionText_108ay_508 {
  margin-right: 0.125rem;
  display: none;
}
._TemplatesPage_108ay_417 ._templatesPageContent_108ay_437 ._templates_108ay_421 ._template_108ay_421 ._templateAction_108ay_508 ._templateActionIcon_108ay_512 {
  color: #505256;
}