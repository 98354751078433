/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_13hkd_157 {
  display: flex;
}

._justify-content-space-between_13hkd_161 {
  justify-content: space-between;
}

._tabular-nums_13hkd_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_13hkd_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._FormTextInput_13hkd_178 ._messagingContainer_13hkd_178 {
  display: flex;
  justify-content: flex-end;
}
._FormTextInput_13hkd_178 ._messagingContainer_13hkd_178._withError_13hkd_182 {
  justify-content: space-between;
}
._FormTextInput_13hkd_178 ._messagingContainer_13hkd_178 ._errorMessage_13hkd_185 {
  color: #ff4d4e;
}
._FormTextInput_13hkd_178 ._messagingContainer_13hkd_178 ._inputCount_13hkd_188 {
  color: #8c8c8c;
}