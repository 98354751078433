/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1wd2a_157 {
  display: flex;
}

._justify-content-space-between_1wd2a_161 {
  justify-content: space-between;
}

._tabular-nums_1wd2a_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1wd2a_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._LeftPanel_1wd2a_178 {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.125rem 0 0;
}
._LeftPanel_1wd2a_178 ._tabs_1wd2a_184 {
  flex: 1;
  overflow-y: auto;
}
._LeftPanel_1wd2a_178 ._hide_1wd2a_188 {
  display: none;
}
._LeftPanel_1wd2a_178 .ant-tabs-content {
  padding-right: 6px;
}
._LeftPanel_1wd2a_178 .ant-tabs-nav {
  margin-bottom: 0;
  padding-bottom: 1.125rem;
  border-bottom: 0.0625rem solid #dadcde;
  color: #bdbdbd;
}
._LeftPanel_1wd2a_178 .ant-tabs-nav::before {
  border-bottom: 0;
}
._LeftPanel_1wd2a_178 .ant-tabs-nav-wrap {
  justify-content: flex-start !important;
}
._LeftPanel_1wd2a_178 .ant-tabs-ink-bar {
  display: none;
}
._LeftPanel_1wd2a_178 .ant-tabs-content-holder {
  overflow-y: scroll;
  padding-top: 0.5rem;
}
._LeftPanel_1wd2a_178 .ant-tabs-content {
  height: 100%;
}
._LeftPanel_1wd2a_178 .ant-tabs-tab-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
._LeftPanel_1wd2a_178 .ant-tabs-tab {
  padding: 0.25rem 0;
}
._LeftPanel_1wd2a_178 ._btns_1wd2a_224 {
  padding: 1.125rem 1rem;
  border-top: 0.0625rem solid #dadcde;
  text-align: right;
}
._LeftPanel_1wd2a_178 ._btns_1wd2a_224 > *:not(:first-child) {
  margin-left: 0.5rem;
}