._GuestHome_168ms_1 {
  height: 100%;
  display: flex;
  flex-direction: column;
}
._GuestHome_168ms_1 ._nav_168ms_6 {
  flex-shrink: 0;
}
._GuestHome_168ms_1 ._content_168ms_9 {
  overflow-y: auto;
  flex: 1;
}
._GuestHome_168ms_1 .ant-typography {
  word-break: initial;
}