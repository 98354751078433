/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1rjuo_157 {
  display: flex;
}

._justify-content-space-between_1rjuo_161 {
  justify-content: space-between;
}

._tabular-nums_1rjuo_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1rjuo_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MemberActivityItem_1rjuo_178 {
  display: flex;
  gap: 16px;
}
._MemberActivityItem_1rjuo_178 ._appDetails_1rjuo_182 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
._MemberActivityItem_1rjuo_178 ._appDetails_1rjuo_182 ._appIcon_1rjuo_187 {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  color: var(--gray-1);
}
._MemberActivityItem_1rjuo_178 ._appDetails_1rjuo_182 ._gmailIcon_1rjuo_193 {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1.125rem;
  background: url("/etc/aspirex/app/config/esbuild/../../src/app/assets/svgs/gmail.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
._MemberActivityItem_1rjuo_178 ._appDetails_1rjuo_182 ._automationIcon_1rjuo_203 {
  width: 0.75rem;
  height: 0.75rem;
  position: relative;
  left: 0.875rem;
  bottom: 0.875rem;
}
._MemberActivityItem_1rjuo_178 ._appDetails_1rjuo_182::after {
  content: "";
  display: block;
  width: 2px;
  flex: 1;
  background-color: var(--gray-4);
}
._MemberActivityItem_1rjuo_178 ._content_1rjuo_217 {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  gap: 4px;
}
._MemberActivityItem_1rjuo_178 ._content_1rjuo_217 ._label_1rjuo_224 {
  font-size: 14px;
  line-height: 1.6;
  max-width: 400px;
}
._MemberActivityItem_1rjuo_178 ._content_1rjuo_217 ._actions_1rjuo_229 {
  display: flex;
  align-items: center;
  gap: 16px;
}
._MemberActivityItem_1rjuo_178 ._content_1rjuo_217 ._actions_1rjuo_229 .ant-btn {
  padding: 4px 0;
}
._MemberActivityItem_1rjuo_178 ._content_1rjuo_217 ._actions_1rjuo_229 a.ant-btn-link {
  padding: 6px 0 4px;
}