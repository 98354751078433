/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1ov57_157 {
  display: flex;
}

._justify-content-space-between_1ov57_161 {
  justify-content: space-between;
}

._tabular-nums_1ov57_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1ov57_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MemberContractList_1ov57_178 {
  max-width: 38.375rem;
  margin: 0 auto;
  margin-top: 1rem;
}
._MemberContractList_1ov57_178 ._toolbar_1ov57_183 {
  display: flex;
}
._MemberContractList_1ov57_178 ._toolbar_1ov57_183 ._displaying_disclaimer_1ov57_186 {
  flex: 1;
  padding-top: 0.625rem;
  color: #1a1818;
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.375rem;
  letter-spacing: 0.02777775rem;
}
._MemberContractList_1ov57_178 ._toolbar_1ov57_183 ._new_button_1ov57_197 {
  flex: 1;
  justify-content: right;
  text-align: right;
}
._MemberContractList_1ov57_178 ._none_placeholder_1ov57_202 {
  margin-top: 1.5rem;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background: #e6e4e7;
  color: #8f8d91;
  font-weight: 600;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.0625rem;
  letter-spacing: 0.021875rem;
  text-align: center;
}
._MemberContractList_1ov57_178 ._contractList_1ov57_216 {
  padding: 1.5rem 0 0;
}
._MemberContractList_1ov57_178 ._contractItem_1ov57_219 {
  margin: 0;
  margin-bottom: 1.5rem;
  padding: 0;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
  list-style-type: none;
}
._MemberContractList_1ov57_178 ._contractItem_1ov57_219:last-child {
  margin-bottom: 0;
}
._MemberContractList_1ov57_178 ._contractItem_head_1ov57_230 {
  display: flex;
  padding: 1rem;
  border-bottom: 0.0625rem solid #dadcde;
}
._MemberContractList_1ov57_178 ._contractItem_head_1ov57_230 ._text_wrap_1ov57_235 {
  flex: 1;
}
._MemberContractList_1ov57_178 ._contractItem_head_1ov57_230 ._contractName_1ov57_238 {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125rem;
  letter-spacing: 0.00625rem;
}
._MemberContractList_1ov57_178 ._contractItem_head_1ov57_230 ._dateCreated_1ov57_248 {
  display: block;
  font-weight: normal;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
}
._MemberContractList_1ov57_178 ._contractItem_head_1ov57_230 ._button_wrap_1ov57_257 {
  flex: 1;
  justify-content: right;
  text-align: right;
}
._MemberContractList_1ov57_178 ._contractItem_head_1ov57_230 ._download_button_1ov57_262 {
  position: relative;
  display: inline-box;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0.75rem;
  border-radius: 0.5rem;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1);
  box-orient: horizontal;
  box-direction: normal;
  box-align: center;
  box-pack: center;
}
._MemberContractList_1ov57_178 ._contractItem_head_1ov57_230 ._download_button_1ov57_262:hover, ._MemberContractList_1ov57_178 ._contractItem_head_1ov57_230 ._download_button_1ov57_262:focus {
  background-color: #eff5f9;
}
._MemberContractList_1ov57_178 ._requirements_wrap_1ov57_283 {
  padding: 1rem;
}
._MemberContractList_1ov57_178 ._requirements_wrap_1ov57_283 ._requirement_label_1ov57_286 {
  display: block;
  padding-bottom: 1rem;
  font-weight: bold;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125rem;
  letter-spacing: 0.00625rem;
}
._MemberContractList_1ov57_178 ._requirements_wrap_1ov57_283 ._requirement_list_1ov57_296 {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
._MemberContractList_1ov57_178 ._requirements_wrap_1ov57_283 ._requirement_item_1ov57_301 {
  padding: 0.5rem 0 0.5rem 1.625rem;
  background: url("/etc/aspirex/app/config/esbuild/../../src/app/assets/svgs/blue_circlecheckmark.svg");
  background-position: left center;
  background-size: 1.25rem;
  background-repeat: no-repeat;
}
._MemberContractList_1ov57_178 ._signed_status_1ov57_308 {
  margin: 0 1rem 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #eff5f9;
  text-align: center;
}
._MemberContractList_1ov57_178 ._signed_status_1ov57_308 ._signIcon_1ov57_315 {
  margin-right: 0.25rem;
}
._MemberContractList_1ov57_178 ._signed_status_1ov57_308 ._status_txt_1ov57_318 {
  font-weight: bold;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125rem;
  letter-spacing: 0.00625rem;
}