._DueDateList_1l6r4_1 ul {
  list-style: none;
  padding-inline-start: 0px;
  margin-bottom: 0px;
}
._DueDateList_1l6r4_1 ul li {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
}
._DueDateList_1l6r4_1 ul li > * + * {
  margin-block-start: 5px;
}