._PostInstructions_y39qx_1 .ant-typography {
  height: auto;
  padding: 8px;
  margin-bottom: 0px;
}
._PostInstructions_y39qx_1 .ant-typography-edit-content {
  height: auto;
  margin: 0;
  left: 0;
  padding: 0;
}
._PostInstructions_y39qx_1 ._item_y39qx_12 {
  margin-left: 22px !important;
  padding: 0px !important;
  border: none !important;
  margin-bottom: 0px !important;
  min-height: 35px !important;
}
._PostInstructions_y39qx_1 ._PostInstruction_y39qx_1, ._PostInstructions_y39qx_1 ._PostInstructionReadOnly_y39qx_19 {
  display: flex;
  flex-direction: column;
}
._PostInstructions_y39qx_1 ._PostInstruction_y39qx_1 .ant-typography, ._PostInstructions_y39qx_1 ._PostInstructionReadOnly_y39qx_19 .ant-typography {
  font-size: 14px;
}
._PostInstructions_y39qx_1 ._PostInstruction_y39qx_1 .ant-list-items, ._PostInstructions_y39qx_1 ._PostInstructionReadOnly_y39qx_19 .ant-list-items {
  list-style-type: disc;
  margin-left: 20px;
}
._PostInstructions_y39qx_1 ._PostInstruction_y39qx_1 .ant-list-item, ._PostInstructions_y39qx_1 ._PostInstructionReadOnly_y39qx_19 .ant-list-item {
  justify-content: flex-start;
  border: none;
  word-break: break-all;
  display: list-item;
  padding: 8px 4px;
}
._PostInstructions_y39qx_1 ._PostInstruction_y39qx_1 .ant-list-item ul, ._PostInstructions_y39qx_1 ._PostInstructionReadOnly_y39qx_19 .ant-list-item ul {
  list-style-type: none;
  padding: 0px;
}
._PostInstructions_y39qx_1 ._PostInstruction_y39qx_1 .linkify-component, ._PostInstructions_y39qx_1 ._PostInstructionReadOnly_y39qx_19 .linkify-component {
  padding-left: 0;
  padding-right: 0;
  margin-left: 5px;
  margin-right: 5px;
}
._PostInstructions_y39qx_1 ._PostInstructionReadOnly_y39qx_19 {
  margin-left: 30px;
}
._PostInstructions_y39qx_1 ._PostInstructionReadOnly_y39qx_19 .ant-typography {
  padding: 0px;
}
._PostInstructions_y39qx_1 ._PostInstructionReadOnly_y39qx_19 .ant-list-items {
  margin-left: 30px;
}