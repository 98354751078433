/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_52ynv_157 {
  display: flex;
}

._justify-content-space-between_52ynv_161 {
  justify-content: space-between;
}

._tabular-nums_52ynv_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_52ynv_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._detailedProductView_52ynv_178 {
  padding: 1rem 1.25rem;
  margin-bottom: 0.5rem;
}
._detailedProductView_52ynv_178 ._variantRow_52ynv_182 {
  display: flex;
  margin: 0.875rem 0;
  align-items: center;
}
._detailedProductView_52ynv_178 ._variantRow_52ynv_182 ._variantDetails_52ynv_187 {
  flex: 1;
}
._detailedProductView_52ynv_178 ._variantRow_52ynv_182 ._variantDetails_52ynv_187 ._productTitle_52ynv_190 {
  display: flex;
  justify-content: space-between;
}
._detailedProductView_52ynv_178 ._variantRow_52ynv_182 ._variantPrice_52ynv_194 {
  flex: 1;
}
._detailedProductView_52ynv_178 ._variantRow_52ynv_182 ._variantPrice_52ynv_194 ._row_52ynv_197 {
  display: flex;
  align-items: center;
}
._detailedProductView_52ynv_178 ._variantRow_52ynv_182 ._variantPrice_52ynv_194 ._row_52ynv_197 ._priceDetails_52ynv_201 {
  justify-content: flex-end;
  display: flex;
  flex-grow: 1;
}
._detailedProductView_52ynv_178 ._variantRow_52ynv_182 ._variantPrice_52ynv_194 ._row_52ynv_197 ._priceDetails_52ynv_201._quantitySelect_52ynv_206 {
  width: 2.8125rem;
}
._detailedProductView_52ynv_178 ._variantRow_52ynv_182 ._variantPrice_52ynv_194 ._row_52ynv_197 ._priceDetails_52ynv_201._total_52ynv_209 {
  font-weight: bold;
}
._detailedProductView_52ynv_178 ._variantRow_52ynv_182 ._variantPrice_52ynv_194 ._row_52ynv_197 ._grayText_52ynv_212 {
  color: #8f8d91;
}
._detailedProductView_52ynv_178 ._variantRow_52ynv_182 ._variantPrice_52ynv_194 ._row_52ynv_197 ._multiply_52ynv_215 {
  margin: 0 0.875rem;
}
._detailedProductView_52ynv_178 ._select_52ynv_218 {
  width: 100%;
  max-width: 14rem;
}
._detailedProductView_52ynv_178 ._header_52ynv_222 {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fdfdfd;
}
._detailedProductView_52ynv_178 ._header_52ynv_222 ._image_52ynv_228 {
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 0.625rem;
}
._detailedProductView_52ynv_178 ._header_52ynv_222 ._completedIcon_52ynv_233 {
  color: #3996e0;
}
._detailedProductView_52ynv_178 ._header_52ynv_222 ._details_52ynv_236 {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-left: 1rem;
}
._detailedProductView_52ynv_178 ._header_52ynv_222 ._details_52ynv_236 ._title_52ynv_242 {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: -0.02rem;
}
._detailedProductView_52ynv_178 ._header_52ynv_222 ._details_52ynv_236 ._subTitle_52ynv_248 {
  display: flex;
  flex-direction: row;
  color: #8f8d91;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.025rem;
}
._detailedProductView_52ynv_178 ._header_52ynv_222 ._addProductButton_52ynv_257 ._addButton_52ynv_257 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 1.0625rem;
  letter-spacing: -0.025rem;
}
._detailedProductView_52ynv_178 ._header_52ynv_222 ._addProductButton_52ynv_257 ._addButton_52ynv_257 ._completedIcon_52ynv_233 {
  margin-right: 0.5rem;
}
._detailedProductView_52ynv_178 ._labelTitle_52ynv_268 {
  margin-top: 1.25rem;
  color: #1a1818;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: -0.02rem;
}
._detailedProductView_52ynv_178 ._labelDescription_52ynv_276 {
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  color: #8f8d91;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.025rem;
}
._detailedProductView_52ynv_178 ._searchField_52ynv_284 {
  max-width: 14rem;
  border-radius: 6.25rem;
  background-color: #eff5f9;
}
._detailedProductView_52ynv_178 ._searchField_52ynv_284 .ant-input {
  background-color: #eff5f9;
}
._detailedProductView_52ynv_178 ._searchField_52ynv_284 ._prefix_52ynv_292 {
  color: #8f8d91;
}
._detailedProductView_52ynv_178 ._divider_52ynv_295 {
  margin: 1.5rem 0rem;
  border-top: 0.0625rem solid #e9e8ea;
}
._detailedProductView_52ynv_178 ._price_52ynv_201 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
._detailedProductView_52ynv_178 ._quantity_52ynv_206 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: x(-0.4);
}
._detailedProductView_52ynv_178 ._total_52ynv_209 {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: -0.02rem;
  text-align: right;
}