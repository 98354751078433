/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_12c7u_157 {
  display: flex;
}

._justify-content-space-between_12c7u_161 {
  justify-content: space-between;
}

._tabular-nums_12c7u_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_12c7u_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ConfigHeader_12c7u_178 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 0.0625rem solid #D9D9D9;
}
._ConfigHeader_12c7u_178 ._header_12c7u_185 {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
}
._ConfigHeader_12c7u_178 ._header_12c7u_185 ._title_12c7u_190 {
  font-weight: 600;
  font-size: 1.5rem;
}
._ConfigHeader_12c7u_178 ._headerAction_12c7u_194 {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}