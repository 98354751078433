/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_gjyzp_157 {
  display: flex;
}

._justify-content-space-between_gjyzp_161 {
  justify-content: space-between;
}

._tabular-nums_gjyzp_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_gjyzp_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Progressbar_gjyzp_178 {
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
}
._Progressbar_gjyzp_178 ._base_gjyzp_183 {
  width: 61.125rem;
  height: 0.5rem;
  background: #F5F5F5;
  border-radius: 0.5rem;
  align-self: center;
}
._Progressbar_gjyzp_178 ._base_gjyzp_183 ._progress_gjyzp_190 {
  background: #113C0B;
  height: 0.5rem;
  border-radius: 0.5rem;
  transition: width 0.25s ease-in-out;
}
._Progressbar_gjyzp_178 ._status_gjyzp_196 {
  margin-left: 0.3125rem;
  color: #8C8C8C;
}