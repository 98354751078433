/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_m3alw_157 {
  display: flex;
}

._justify-content-space-between_m3alw_161 {
  justify-content: space-between;
}

._tabular-nums_m3alw_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_m3alw_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_m3alw_157 {
  display: flex;
}

._justify-content-space-between_m3alw_161 {
  justify-content: space-between;
}

._tabular-nums_m3alw_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_m3alw_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._WorkletsList_m3alw_355 {
  transition: opacity 0.15s cubic-bezier(0.23, 1, 0.32, 1);
}
._WorkletsList_m3alw_355._loading_m3alw_358 {
  opacity: 0.4;
  pointer-events: none;
}
._WorkletsList_m3alw_355 ._item_m3alw_362 {
  padding: 0 !important;
  background-color: #fdfdfd;
  outline: none;
  cursor: pointer;
}
._WorkletsList_m3alw_355:not(._editing_m3alw_368) ._item_m3alw_362:not(._editing_m3alw_368):hover {
  background-color: #f5f5f5;
}
._WorkletsList_m3alw_355:not(._editing_m3alw_368) ._item_m3alw_362:not(._editing_m3alw_368):hover ._workletWrapper_m3alw_371 {
  background-color: #f5f5f5;
}
._WorkletsList_m3alw_355 ._workletWrapper_m3alw_371 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0.5rem 1rem;
}
._WorkletsList_m3alw_355 ._workletWrapper_m3alw_371 ._specLogo_m3alw_383 {
  width: 1.25rem;
  height: 1.25rem;
}
._WorkletsList_m3alw_355 ._workletWrapper_m3alw_371 ._arrow_m3alw_387 {
  margin-right: 0.625rem;
  font-size: 0.75rem;
}
._WorkletsList_m3alw_355 ._workletWrapper_m3alw_371._disabled_m3alw_391 {
  opacity: 0.5;
}
._WorkletsList_m3alw_355 ._editing_m3alw_368 {
  border-color: #0075ff;
}
._WorkletsList_m3alw_355 ._editSpec_m3alw_397 {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 1rem;
  cursor: default;
}
._WorkletsList_m3alw_355 ._editSpec_m3alw_397 ._label_m3alw_404 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-weight: 500;
  cursor: pointer;
}
._WorkletsList_m3alw_355 ._editSpec_m3alw_397 ._label_m3alw_404 ._arrow_m3alw_387 {
  display: none;
  margin-right: 0.625rem;
  font-size: 0.75rem;
}
._WorkletsList_m3alw_355 ._editSpec_m3alw_397 ._label_m3alw_404._hasArrow_m3alw_417 ._arrow_m3alw_387 {
  display: block;
}
._WorkletsList_m3alw_355 ._editSpec_m3alw_397 ._select_m3alw_420 {
  width: 100%;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}
._WorkletsList_m3alw_355 ._editSpec_m3alw_397 .ant-select-arrow {
  color: inherit;
}
._WorkletsList_m3alw_355 ._editSpec_m3alw_397 .ant-select-disabled .ant-select-arrow {
  color: rgba(0, 0, 0, 0.25);
}
._WorkletsList_m3alw_355 ._editSpec_m3alw_397 ._customNameInput_m3alw_431 {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}
._WorkletsList_m3alw_355 ._editSpec_m3alw_397 ._divider_m3alw_435 {
  width: calc(100% + 2rem);
  margin: 0.75rem -1rem;
}
._WorkletsList_m3alw_355 ._editSpec_m3alw_397 ._buttons_m3alw_439 {
  display: flex;
  justify-content: flex-end;
  margin: 0 -0.75rem;
}
._WorkletsList_m3alw_355 ._editSpec_m3alw_397 ._Tooltip_m3alw_444 {
  display: block;
}
._WorkletsList_m3alw_355 ._editSpec_m3alw_397._sortable_m3alw_447 {
  cursor: move;
}
._WorkletsList_m3alw_355 ._editSpec_m3alw_397._sortable_m3alw_447 ._label_m3alw_404:not(._hasArrow_m3alw_417) {
  cursor: move;
}

._worklet_m3alw_371 {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
  height: 100%;
  font-size: 0.875rem;
}
._worklet_m3alw_371 div {
  min-width: 0;
}
._worklet_m3alw_371 ._specLogo_m3alw_383 {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
  border-radius: 0.25rem;
}
._worklet_m3alw_371 ._memberCount_m3alw_471 {
  color: #aeaeae;
}