._blue_3fcl8_1 .recharts-bar-rectangle path {
  fill: #61799e;
}
._blue_3fcl8_1 .recharts-bar-rectangle path:hover {
  fill: #3a495f;
}

._chartColors-blue_3fcl8_8 .recharts-bar-rectangle path {
  fill: hsl(var(--refresh-blue-5));
}
._chartColors-blue_3fcl8_8 .recharts-bar-rectangle path:hover {
  fill: hsl(var(--refresh-blue-6));
}

._plum_3fcl8_15 .recharts-bar-rectangle path {
  fill: #ac537e;
}
._plum_3fcl8_15 .recharts-bar-rectangle path:hover {
  fill: #67324c;
}

._chartColors-plum_3fcl8_22 .recharts-bar-rectangle path {
  fill: hsl(var(--refresh-pink-5));
}
._chartColors-plum_3fcl8_22 .recharts-bar-rectangle path:hover {
  fill: hsl(var(--refresh-pink-6));
}

._orange_3fcl8_29 .recharts-bar-rectangle path {
  fill: #d5712a;
}
._orange_3fcl8_29 .recharts-bar-rectangle path:hover {
  fill: #804419;
}

._chartColors-orange_3fcl8_36 .recharts-bar-rectangle path {
  fill: hsl(var(--refresh-orange-5));
}
._chartColors-orange_3fcl8_36 .recharts-bar-rectangle path:hover {
  fill: hsl(var(--refresh-orange-6));
}

._green_3fcl8_43 .recharts-bar-rectangle path {
  fill: #619e9e;
}
._green_3fcl8_43 .recharts-bar-rectangle path:hover {
  fill: #3a5f5f;
}

._chartColors-green_3fcl8_50 .recharts-bar-rectangle path {
  fill: hsl(var(--refresh-green-5));
}
._chartColors-green_3fcl8_50 .recharts-bar-rectangle path:hover {
  fill: hsl(var(--refresh-green-6));
}

._tooltipTitle_3fcl8_57 {
  color: var(--gray-1);
  font-weight: var(--font-weight-semibold);
}

._tooltipDescription_3fcl8_62 {
  color: var(--gray-1);
}