/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_bf4c9_157 {
  display: flex;
}

._justify-content-space-between_bf4c9_161 {
  justify-content: space-between;
}

._tabular-nums_bf4c9_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_bf4c9_368 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._EmailMessageItem_bf4c9_178 ._header_bf4c9_178 ._info_bf4c9_178 ._name_bf4c9_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._EmailMessageItem_bf4c9_178 ._header_bf4c9_178 ._info_bf4c9_178 ._text_bf4c9_188, ._EmailMessageItem_bf4c9_178 ._header_bf4c9_178 ._info_bf4c9_178 ._snippet_bf4c9_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._EmailMessageItem_bf4c9_178 ._header_bf4c9_178 ._info_bf4c9_178 ._nameAndDate_bf4c9_198 ._date_bf4c9_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._EmailMessageItem_bf4c9_178 {
  border: 0.0625rem solid rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  background: #fdfdfd;
  box-shadow: 0 0.0625rem 0.375rem rgba(26, 24, 24, 0.08);
  opacity: 1;
  transition: opacity 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  will-change: opacity;
}
._EmailMessageItem_bf4c9_178:hover {
  opacity: 1 !important;
}
._EmailMessageItem_bf4c9_178._collapsed_bf4c9_282 {
  opacity: 0.7;
}
._EmailMessageItem_bf4c9_178 ._header_bf4c9_178 {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  height: 4.625rem;
  padding: 0 1.125rem;
  cursor: pointer;
  user-select: none;
}
._EmailMessageItem_bf4c9_178 ._header_bf4c9_178 ._avatar_bf4c9_296 {
  flex-shrink: 0;
  margin-right: 1rem;
}
._EmailMessageItem_bf4c9_178 ._header_bf4c9_178 ._automationIcon_bf4c9_300 {
  width: 1rem;
  height: 1rem;
  position: absolute;
  left: 2.625rem;
  top: 2.625rem;
}
._EmailMessageItem_bf4c9_178 ._header_bf4c9_178 ._info_bf4c9_178 {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: center;
  overflow: hidden;
  margin-right: 1.25rem;
  white-space: nowrap;
}
._EmailMessageItem_bf4c9_178 ._header_bf4c9_178 ._info_bf4c9_178 ._email_bf4c9_316 {
  color: #8c8c8c;
  font-size: 14px;
  font-weight: normal;
}
._EmailMessageItem_bf4c9_178 ._header_bf4c9_178 ._info_bf4c9_178 ._snippet_bf4c9_188 {
  margin-top: 0.25rem;
  color: #8f8d91;
}
._EmailMessageItem_bf4c9_178 ._header_bf4c9_178 ._info_bf4c9_178 ._nameAndDate_bf4c9_198 {
  display: flex;
  justify-content: space-between;
}
._EmailMessageItem_bf4c9_178 ._header_bf4c9_178 ._info_bf4c9_178 ._nameAndDate_bf4c9_198 ._date_bf4c9_198 {
  align-self: center;
  color: #8f8d91;
}
._EmailMessageItem_bf4c9_178 ._header_bf4c9_178 ._info_bf4c9_178 ._memberLink_bf4c9_333 {
  color: #3996e0;
  cursor: pointer;
  user-select: none;
  transition: color 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: color;
}
._EmailMessageItem_bf4c9_178 ._header_bf4c9_178 ._info_bf4c9_178 ._memberLink_bf4c9_333:hover {
  color: #4fa7ee;
}
._EmailMessageItem_bf4c9_178 ._messageDetail_bf4c9_343 {
  overflow: scroll;
  border-top: 0.0625rem solid #dadcde;
  opacity: 1;
  transition: max-height 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0.2s;
  will-change: max-height, opacity;
  padding-bottom: 0.75rem;
}
._EmailMessageItem_bf4c9_178 ._messageDetail_bf4c9_343._collapsed_bf4c9_282 {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
._EmailMessageItem_bf4c9_178 ._messageDetail_bf4c9_343 ._content_bf4c9_356 {
  padding: 1.5rem 1rem 0;
}
._EmailMessageItem_bf4c9_178 ._messageDetail_bf4c9_343 ._content_bf4c9_356._hideQuote_bf4c9_359 .gmail_quote {
  display: none !important;
}
._EmailMessageItem_bf4c9_178 ._messageDetail_bf4c9_343 ._attachments_bf4c9_362 {
  padding: 0.625rem 1rem;
}
._EmailMessageItem_bf4c9_178 ._messageDetail_bf4c9_343 ._actions_bf4c9_365 {
  padding: 0 1rem 1.5rem;
}
._EmailMessageItem_bf4c9_178 ._messageDetail_bf4c9_343 ._actions_bf4c9_365 ._showQuoteButton_bf4c9_368 {
  font-size: 1.875rem;
}
._EmailMessageItem_bf4c9_178 ._messageDetail_bf4c9_343 ._actions_bf4c9_365 ._showLess_bf4c9_371 {
  margin-top: 1.25rem;
}