/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_5x6b1_157 {
  display: flex;
}

._justify-content-space-between_5x6b1_161 {
  justify-content: space-between;
}

._tabular-nums_5x6b1_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_5x6b1_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._MemberInsightsAuth_5x6b1_209 {
  width: 100%;
  padding-top: 1rem;
  margin-bottom: 2.625rem;
}
._MemberInsightsAuth_5x6b1_209 ._standard_label_5x6b1_214 {
  display: block;
  padding: 2rem 0 0.75rem;
  color: #1a1818;
  font-weight: bold;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125rem;
  letter-spacing: 0.00625rem;
}
._MemberInsightsAuth_5x6b1_209 ._messageForm_5x6b1_225 {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}
._MemberInsightsAuth_5x6b1_209 ._messageForm_5x6b1_225._hide_5x6b1_231 {
  display: none;
}