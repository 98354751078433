/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_16y43_157 {
  display: flex;
}

._justify-content-space-between_16y43_161 {
  justify-content: space-between;
}

._tabular-nums_16y43_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_16y43_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Settings_16y43_178 {
  padding-bottom: 1.5rem;
}
._Settings_16y43_178 ._PageURLContainer_16y43_181 {
  padding: 16px 12px 16px 0;
  border-bottom: 1px solid var(--gray-5);
}
._Settings_16y43_178 ._row_16y43_185 {
  margin-top: 1rem;
  padding-bottom: 16px;
}
._Settings_16y43_178 ._row_16y43_185 ._subrow_16y43_189:not(:first-child) {
  margin-top: 24px;
}
._Settings_16y43_178 ._row_16y43_185:not(:last-child) {
  border-bottom: 1px solid var(--gray-5);
}
._Settings_16y43_178 ._subtitle_16y43_195 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  font-size: 0.875rem;
}
._Settings_16y43_178 ._select_16y43_200 {
  width: 100%;
}