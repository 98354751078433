/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_12ceq_157 {
  display: flex;
}

._justify-content-space-between_12ceq_161 {
  justify-content: space-between;
}

._tabular-nums_12ceq_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_12ceq_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._upload_content_12ceq_178 h1 {
  color: #1a1818;
  font-weight: bold;
  font-style: normal;
  font-size: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.5rem;
  letter-spacing: 0.009375rem;
}
._upload_content_12ceq_178 p {
  color: #1a1818;
  font-weight: normal;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
}
._upload_content_12ceq_178 ._skip_button_12ceq_196 {
  padding: 0.625rem 1.25rem;
  border-radius: 6.25rem;
  background-color: #eff5f9;
  color: #3996e0;
  font-weight: 600;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.0625rem;
  letter-spacing: 0.021875rem;
}