/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1oo80_157 {
  display: flex;
}

._justify-content-space-between_1oo80_161 {
  justify-content: space-between;
}

._tabular-nums_1oo80_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1oo80_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._content_1oo80_178 ._header_1oo80_178 ._title_1oo80_178 h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._AudienceAuthSection_1oo80_188 ._content_1oo80_178 ._more_1oo80_188, ._content_1oo80_178 ._header_1oo80_178 ._title_1oo80_178 h4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1oo80_157 {
  display: flex;
}

._justify-content-space-between_1oo80_161 {
  justify-content: space-between;
}

._tabular-nums_1oo80_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1oo80_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._content_1oo80_178 ._header_1oo80_178 ._title_1oo80_178 h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._AudienceAuthSection_1oo80_188 ._content_1oo80_178 ._more_1oo80_188, ._content_1oo80_178 ._header_1oo80_178 ._title_1oo80_178 h4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._content_1oo80_178 {
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
._content_1oo80_178 ._header_1oo80_178 {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
._content_1oo80_178 ._header_1oo80_178 ._title_1oo80_178 {
  display: flex;
  flex-direction: column;
}
._content_1oo80_178 ._header_1oo80_178 ._title_1oo80_178 h3 {
  margin: 0.5rem 0 0.5rem 0.5rem;
  color: #2e426d;
}
._content_1oo80_178 ._header_1oo80_178 ._title_1oo80_178 h4 {
  margin: 0 0 0.5rem 0.5rem;
  color: #8f8d91;
}
._content_1oo80_178 ._header_1oo80_178 ._select_1oo80_451 {
  margin-left: auto;
}

._AudienceAuthSection_1oo80_188 ._content_1oo80_178 ._header_1oo80_178 {
  position: relative;
}
._AudienceAuthSection_1oo80_188 ._content_1oo80_178 ._main_1oo80_458 {
  margin-bottom: 2.25rem;
  flex: 1;
}
._AudienceAuthSection_1oo80_188 ._content_1oo80_178 ._more_1oo80_188 {
  flex-shrink: 0;
  text-align: center;
}
._AudienceAuthSection_1oo80_188 ._content_1oo80_178 ._more_1oo80_188 a {
  color: #8f8d91;
  text-decoration: none;
  will-change: color;
  transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
._AudienceAuthSection_1oo80_188 ._content_1oo80_178 ._more_1oo80_188 a:hover {
  color: #3996e0;
}