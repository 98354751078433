/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1mgju_157 {
  display: flex;
}

._justify-content-space-between_1mgju_161 {
  justify-content: space-between;
}

._tabular-nums_1mgju_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1mgju_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._TopNavbarDropdown_1mgju_209 {
  animation-duration: 0.1s;
}
._TopNavbarDropdown_1mgju_209 .ant-dropdown-menu {
  transform: translateY(-0.5rem);
}

._TopNavbarSubmenu_1mgju_216 {
  padding: 0 !important;
  overflow: hidden;
}
._TopNavbarSubmenu_1mgju_216 ._userMenuContent_1mgju_220 {
  padding: 0.25rem 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
}
._TopNavbarSubmenu_1mgju_216 ._userMenuContent_1mgju_220 ._userEmail_1mgju_226 {
  color: #8f8d91;
}
._TopNavbarSubmenu_1mgju_216 ._userMenuContent_1mgju_220 ._switchAccounts_1mgju_229 {
  margin-top: 0.25rem;
  pointer-events: auto;
  cursor: pointer;
  color: #167cf4;
}

._TopNavbarSubmenuLong_1mgju_236 {
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

._TopNavbarSubmenuImage_1mgju_242 {
  margin-right: 8px;
  border-radius: 4px;
}

._TopNavbarSubmenuItemCreate_1mgju_247 {
  height: 48px;
  border-top: 1px solid #dadcde;
  color: #167cf4;
}

._TopNavbarSubmenuText_1mgju_253 {
  display: inline-flex;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

._UserClientMenu_1mgju_261 {
  max-height: 400px;
  overflow-y: auto;
}
._UserClientMenu_1mgju_261 ._client_1mgju_265 {
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 0.375rem;
  will-change: background-color;
  transition: background-color 0.1s ease-out;
}
._UserClientMenu_1mgju_261 ._client_1mgju_265._active_1mgju_272 {
  pointer-events: none;
  color: #167cf4;
}
._UserClientMenu_1mgju_261 ._client_1mgju_265:hover {
  background-color: #eff5f9;
}

._TopNavbarSubmenuItem_1mgju_247 {
  padding: 0 1rem !important;
  font-size: 0.875rem;
  line-height: 3.25rem;
}
._TopNavbarSubmenuItem_1mgju_247:hover {
  background-color: #f4fcff;
  color: #1890ff;
}
._TopNavbarSubmenuItem_1mgju_247._selected_1mgju_289 {
  background-color: #e6f7ff;
  color: #002766;
}