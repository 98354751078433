/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1h6mx_157 {
  display: flex;
}

._justify-content-space-between_1h6mx_161 {
  justify-content: space-between;
}

._tabular-nums_1h6mx_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1h6mx_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._AppModalNav_1h6mx_209 {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
  column-gap: 2rem;
}
._AppModalNav_1h6mx_209 ._sideNav_1h6mx_216,
._AppModalNav_1h6mx_209 ._mainContent_1h6mx_217 {
  display: flex;
  overflow-y: auto;
  height: 100%;
}
._AppModalNav_1h6mx_209 ._sideNav_1h6mx_216 {
  flex: 1;
  background-color: #f7f8fa;
}
._AppModalNav_1h6mx_209 ._coreItemList_1h6mx_226 {
  width: 100%;
  margin: 0;
  padding: 1.5rem 0;
  list-style: none;
}
._AppModalNav_1h6mx_209 ._coreItemList_1h6mx_226 ._coreItem_1h6mx_226 {
  display: block;
  padding: 0.75rem 1.5rem;
  background: url("/etc/aspirex/app/config/esbuild/../../src/app/assets/svgs/arrowhead_right.svg");
  background-position: right center;
  background-position: 94% center;
  background-size: contain;
  background-size: 0.75rem;
  background-repeat: no-repeat;
  color: #1a1818;
  font-weight: bold;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.00625rem;
  cursor: pointer;
}
._AppModalNav_1h6mx_209 ._coreItemList_1h6mx_226 ._coreItem_1h6mx_226:hover, ._AppModalNav_1h6mx_209 ._coreItemList_1h6mx_226 ._coreItem_1h6mx_226:focus {
  background-color: #e6edf2;
}
._AppModalNav_1h6mx_209 ._coreItemList_1h6mx_226 ._coreItem_1h6mx_226:active {
  background-color: #f7f8fa;
}
._AppModalNav_1h6mx_209 ._coreItemList_1h6mx_226 ._coreItem_1h6mx_226._active_1h6mx_255 {
  background: #e6edf2 url("/etc/aspirex/app/config/esbuild/../../src/app/assets/svgs/arrowhead_right_blue.svg");
  background-position: 94% center;
  background-size: 0.75rem;
  background-repeat: no-repeat;
  color: #3996e0;
}
._AppModalNav_1h6mx_209 ._active_1h6mx_255 {
  background-color: #e6edf2;
  color: #3996e0 !important;
}
._AppModalNav_1h6mx_209 ._new_label_1h6mx_266 {
  display: inline-block;
  margin-left: 0.5rem;
  padding: 0.1875rem 0.5625rem 0.0625rem;
  border-radius: 0.59375rem;
  background: linear-gradient(180deg, #3996e0 0%, #74baf2 100%);
  color: #eff5f9;
  font-weight: bold;
  font-style: normal;
  font-size: 0.75rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  /* identical to box height, or 133% */
  text-align: center;
}
._AppModalNav_1h6mx_209 ._mainContent_1h6mx_217 {
  flex: 4;
}