/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1kvm6_157 {
  display: flex;
}

._justify-content-space-between_1kvm6_161 {
  justify-content: space-between;
}

._tabular-nums_1kvm6_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1kvm6_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._RequirementAssignmentForm_1kvm6_178 ._Title_1kvm6_178 {
  margin-bottom: 0;
  padding-bottom: 0.75rem;
  color: #1a1818;
  font-weight: bold;
  font-style: normal;
  font-size: 1.25rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.5rem;
  letter-spacing: -0.02rem;
  text-align: center;
}
._RequirementAssignmentForm_1kvm6_178 ._Description_1kvm6_190 {
  margin-bottom: 0;
  padding-bottom: 2.5rem;
  color: #1a1818;
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.375rem;
  letter-spacing: -0.025rem;
  text-align: center;
}
._RequirementAssignmentForm_1kvm6_178 ._StandardLabel_1kvm6_202 {
  display: block;
  padding-bottom: 1rem;
  color: #1a1818;
  font-weight: bold;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125rem;
  letter-spacing: -0.02rem;
}
._RequirementAssignmentForm_1kvm6_178 ._RequirementWrap_1kvm6_213 {
  max-width: 33.4375rem;
  margin: 0 auto;
}
._RequirementAssignmentForm_1kvm6_178 ._ButtonWrap_1kvm6_217 {
  margin-top: 2.5rem;
  text-align: center;
}
._RequirementAssignmentForm_1kvm6_178 ._ButtonWrap_1kvm6_217 ._Submit_1kvm6_221 {
  margin-right: 1rem;
}