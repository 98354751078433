._CreateAppContainer_1sxcy_1 {
  width: 100%;
  height: 99%;
  border: none;
}

._LoadingSpinner_1sxcy_7 {
  position: relative;
  left: 0;
  top: 50%;
  text-align: center;
}