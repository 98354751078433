/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_a3gd6_157 {
  display: flex;
}

._justify-content-space-between_a3gd6_161 {
  justify-content: space-between;
}

._tabular-nums_a3gd6_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_a3gd6_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ShopifyAddNew_a3gd6_178 ._title_a3gd6_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._ShopifyAddNew_a3gd6_178 ._label_a3gd6_188, ._ShopifyAddNew_a3gd6_178 ._permissionsLabel_a3gd6_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._ShopifyAddNew_a3gd6_178 ._description_a3gd6_198, ._ShopifyAddNew_a3gd6_178 ._checkbox_a3gd6_198 ._checkboxContent_a3gd6_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._ShopifyAddNew_a3gd6_178 ._inputGroup_a3gd6_208 {
  margin-bottom: 2rem;
}
._ShopifyAddNew_a3gd6_178 ._inputGroup_a3gd6_208 > :last-child {
  padding-right: 0.625rem;
  padding-left: 0.3125rem;
  border: solid 0.0625rem #eff5f9;
  border-top-right-radius: 624.9375rem;
  border-bottom-right-radius: 624.9375rem;
  background-color: #eff5f9;
}
._ShopifyAddNew_a3gd6_178 ._permissionsLabel_a3gd6_188 {
  margin-bottom: 1.25rem;
}
._ShopifyAddNew_a3gd6_178 ._checkbox_a3gd6_198 {
  margin-bottom: 2.625rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
._ShopifyAddNew_a3gd6_178 ._checkbox_a3gd6_198 ._checkboxContent_a3gd6_198 {
  margin-top: -0.25rem;
  display: flex;
  flex-direction: column;
}
._ShopifyAddNew_a3gd6_178 ._checkbox_a3gd6_198 ._checkboxContent_a3gd6_198 ._header_a3gd6_233 {
  margin-bottom: 0.25rem;
}
._ShopifyAddNew_a3gd6_178 ._checkbox_a3gd6_198 ._checkboxContent_a3gd6_198 ._body_a3gd6_236 {
  color: #8f8d91;
}
._ShopifyAddNew_a3gd6_178 ._errorNotice_a3gd6_239 {
  background-color: #F8EDEE;
  border-radius: 5px;
}
._ShopifyAddNew_a3gd6_178 ._logo_a3gd6_243 {
  display: block;
  width: 5rem;
  height: 5rem;
  margin: auto;
  margin-bottom: 1rem;
}
._ShopifyAddNew_a3gd6_178 ._title_a3gd6_178 {
  margin-bottom: 0.5rem;
  text-align: center;
}
._ShopifyAddNew_a3gd6_178 ._description_a3gd6_198 {
  margin-bottom: 2.25rem;
  color: #8f8d91;
  text-align: center;
}
._ShopifyAddNew_a3gd6_178 ._label_a3gd6_188 {
  margin-bottom: 0.5rem;
}

._error_a3gd6_239 {
  display: flex;
  flex-direction: column;
  margin: 5vh auto;
  align-items: center;
  width: 450px;
}
._error_a3gd6_239 ._errorIcon_a3gd6_270 {
  height: 80px;
  width: 80px;
  margin-bottom: 32px;
}
._error_a3gd6_239 ._errorTitle_a3gd6_275 {
  color: #f5222d;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 16px;
}
._error_a3gd6_239 ._errorDescription_a3gd6_283 {
  color: #8C8C8C;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 24px;
}