/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1hwjk_157 {
  display: flex;
}

._justify-content-space-between_1hwjk_161 {
  justify-content: space-between;
}

._tabular-nums_1hwjk_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1hwjk_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1hwjk_157 {
  display: flex;
}

._justify-content-space-between_1hwjk_161 {
  justify-content: space-between;
}

._tabular-nums_1hwjk_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1hwjk_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._workflowButton_1hwjk_355 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._workflowButton_1hwjk_355 {
  min-width: 2.25rem;
}
._workflowButton_1hwjk_355 > svg:first-of-type:not(:only-child) {
  margin-right: 0.25rem;
}

._workflowButton_1hwjk_355:focus, ._workflowButton_1hwjk_355:hover {
  border-color: #167cf4;
  color: #167cf4;
}

._workflowButton_1hwjk_355:hover, ._workflowButton_1hwjk_355._active_1hwjk_377 {
  background: #fafafa;
}