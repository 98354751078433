/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1yzlp_157 {
  display: flex;
}

._justify-content-space-between_1yzlp_161 {
  justify-content: space-between;
}

._tabular-nums_1yzlp_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1yzlp_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._PlanDetailSettings_1yzlp_178 {
  width: 80%;
}
._PlanDetailSettings_1yzlp_178 ._header_1yzlp_181 {
  display: flex;
  justify-content: space-between;
}
._PlanDetailSettings_1yzlp_178 ._header_1yzlp_181 ._title_1yzlp_185 {
  font-size: 1.5rem;
  font-weight: 600;
  padding-bottom: 2.5rem;
}
._PlanDetailSettings_1yzlp_178 ._planItem_1yzlp_190 {
  margin-bottom: 2.5rem;
}