/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_vrdrg_157 {
  display: flex;
}

._justify-content-space-between_vrdrg_161 {
  justify-content: space-between;
}

._tabular-nums_vrdrg_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_vrdrg_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ConfirmationModal_vrdrg_178 ._content_vrdrg_178 ._title_vrdrg_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._ConfirmationModal_vrdrg_178 ._content_vrdrg_178 ._text_vrdrg_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._ConfirmationModal_vrdrg_178 ._content_vrdrg_178 {
  padding: 2.25rem;
}
._ConfirmationModal_vrdrg_178 ._content_vrdrg_178 ._title_vrdrg_178 {
  margin-bottom: 0.625rem;
  text-align: center;
}
._ConfirmationModal_vrdrg_178 ._content_vrdrg_178 ._text_vrdrg_188 {
  margin-bottom: 2rem;
  text-align: center;
}
._ConfirmationModal_vrdrg_178 ._content_vrdrg_178 ._actions_vrdrg_209 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
._ConfirmationModal_vrdrg_178 ._content_vrdrg_178 ._actions_vrdrg_209 ._button_vrdrg_215 {
  margin-right: 1rem;
}