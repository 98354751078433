/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_pdi7p_157 {
  display: flex;
}

._justify-content-space-between_pdi7p_161 {
  justify-content: space-between;
}

._tabular-nums_pdi7p_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_pdi7p_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Modal_pdi7p_178 .ant-modal-content {
  border-radius: 0.75rem;
}
._Modal_pdi7p_178 .ant-modal-content .ant-modal-header {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
._Modal_pdi7p_178 .ant-modal-content .ant-modal-footer {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}