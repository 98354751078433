/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_bzcl9_157 {
  display: flex;
}

._justify-content-space-between_bzcl9_161 {
  justify-content: space-between;
}

._tabular-nums_bzcl9_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_bzcl9_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._AllSchemas_bzcl9_178 {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 1.5rem;
}
._AllSchemas_bzcl9_178 ._search_bzcl9_184 {
  width: 100%;
  margin: 1rem 0 1.5rem;
}
._AllSchemas_bzcl9_178 ._search_bzcl9_184 .ant-input {
  background-color: transparent;
}
._AllSchemas_bzcl9_178 ._field_bzcl9_191:not(:last-child) {
  margin-bottom: 1rem;
}
._AllSchemas_bzcl9_178 ._header_bzcl9_194 {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
}
._AllSchemas_bzcl9_178 ._schemas_bzcl9_199 {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 1rem;
}
._AllSchemas_bzcl9_178 ._linksContainer_bzcl9_205 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
._AllSchemas_bzcl9_178 ._linksContainer_bzcl9_205 ._link_bzcl9_205 {
  color: #3996e0;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
}

._CreateFieldModal_bzcl9_217 .ant-modal-footer {
  display: none !important;
}