/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1wslx_157 {
  display: flex;
}

._justify-content-space-between_1wslx_161 {
  justify-content: space-between;
}

._tabular-nums_1wslx_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1wslx_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._NewContractFileSelector_1wslx_178 h1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._NewContractFileSelector_1wslx_178 ._label_1wslx_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._NewContractFileSelector_1wslx_178 ._newFileSection_1wslx_198 ._fileSubtitle_1wslx_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

._NewContractFileSelector_1wslx_178 {
  padding-top: 1rem;
}
._NewContractFileSelector_1wslx_178 ._newFileSection_1wslx_198 ._fileUploadField_1wslx_211 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 12.5rem;
  text-align: center;
}
._NewContractFileSelector_1wslx_178 ._newFileSection_1wslx_198 ._fileSelector_1wslx_218 {
  width: 14.375rem;
  height: 7.5rem;
  margin: auto;
}
._NewContractFileSelector_1wslx_178 ._newFileSection_1wslx_198 ._fileSubtitle_1wslx_198 {
  margin-top: 0.625rem;
  color: #8f8d91;
  text-align: center;
}
._NewContractFileSelector_1wslx_178 ._newTemplateSection_1wslx_228 {
  padding-top: 1.25rem;
}
._NewContractFileSelector_1wslx_178 ._newTemplateSection_1wslx_228 ._templateCell_1wslx_231 {
  height: 5rem;
  width: 80%;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
}
._NewContractFileSelector_1wslx_178 ._newTemplateSection_1wslx_228 ._templateCell_1wslx_231 ._nameWrap_1wslx_241 {
  display: inline-block;
  width: 55%;
  margin-right: 1rem;
}
._NewContractFileSelector_1wslx_178 ._newTemplateSection_1wslx_228 ._templateCell_1wslx_231 ._contractName_1wslx_246 {
  display: inline-block;
  vertical-align: -0.9375rem;
  overflow: hidden;
  color: #1a1818;
  font-weight: bold;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125rem;
  letter-spacing: 0.00625rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._NewContractFileSelector_1wslx_178 ._newTemplateSection_1wslx_228 ._templateCell_1wslx_231 ._actionsWrap_1wslx_260 {
  position: absolute;
  right: 8.125rem;
  display: inline-block;
}
._NewContractFileSelector_1wslx_178 ._newTemplateSection_1wslx_228 ._noTemplatesNotice_1wslx_265 {
  width: 80%;
  margin: auto;
}
._NewContractFileSelector_1wslx_178 h1 {
  margin-bottom: 1.875rem;
  text-align: center;
}
._NewContractFileSelector_1wslx_178 ._label_1wslx_188 {
  display: block;
  padding-top: 1.25rem;
  padding-bottom: 0.625rem;
}