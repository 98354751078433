._Header_6psn6_1 {
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-md) var(--spacing-lg);
  border-bottom: 1px solid var(--gray-5);
}
._Header_6psn6_1 ._icon_6psn6_7 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--height-base);
  height: var(--height-base);
  background-color: #004281;
  border-radius: var(--border-radius-sm);
}
._Header_6psn6_1 ._icon_6psn6_7 svg {
  fill: var(--white);
  font-size: 16px;
}