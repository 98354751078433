/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1cglu_157 {
  display: flex;
}

._justify-content-space-between_1cglu_161 {
  justify-content: space-between;
}

._tabular-nums_1cglu_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1cglu_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._BulkContractInvalidMembers_1cglu_178 h1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._BulkContractInvalidMembers_1cglu_178 {
  padding-top: 1.25rem;
}
._BulkContractInvalidMembers_1cglu_178 h1 {
  margin-bottom: 0.375rem;
  text-align: center;
}
._BulkContractInvalidMembers_1cglu_178 ._loadingHeader_1cglu_195 {
  margin-top: 9.375rem;
}
._BulkContractInvalidMembers_1cglu_178 ._spinner_1cglu_198 {
  margin-top: 1.875rem;
}
._BulkContractInvalidMembers_1cglu_178 ._subtext_1cglu_201 {
  text-align: center;
}
._BulkContractInvalidMembers_1cglu_178 ._membersTable_1cglu_204 {
  height: 29.375rem;
  padding: 1.875rem 6.25rem 0;
}