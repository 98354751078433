/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_awh3q_157 {
  display: flex;
}

._justify-content-space-between_awh3q_161 {
  justify-content: space-between;
}

._tabular-nums_awh3q_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_awh3q_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._TermsAppMessageComposer_awh3q_178 ._title_awh3q_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._TermsAppMessageComposer_awh3q_178 ._description_awh3q_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._TermsAppMessageComposer_awh3q_178 {
  margin: 0.75rem 6.25rem 3.75rem;
}
._TermsAppMessageComposer_awh3q_178 ._MessageComposerWrapper_awh3q_232 {
  padding: 1.625rem 2.375rem;
  border: 1px solid #dadcde;
  border-radius: 0.375rem;
}
._TermsAppMessageComposer_awh3q_178 ._description_awh3q_188 {
  margin-bottom: 2.125rem;
}
._TermsAppMessageComposer_awh3q_178 ._actions_awh3q_240 {
  margin-top: 3.75rem;
  display: flex;
  justify-content: center;
}
._TermsAppMessageComposer_awh3q_178 ._EmailPreviewer_awh3q_245 {
  border: 1px solid #dadcde;
  border-radius: 0.5rem;
  padding-top: 2rem;
}
._TermsAppMessageComposer_awh3q_178 ._EmailComposer_awh3q_250 {
  border: 1px solid #dadcde;
  border-radius: 0.375rem;
}