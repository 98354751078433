:root {
  --screen-xs: 480px;
  --screen-sm: 576px;
  --screen-md: 768px;
  --screen-lg: 992px;
  --screen-xl: 1200px;
  --screen-xxl: 1600px;
}

._pagePadding_4jcwm_19 {
  background: var(--gray-1);
  padding: 0 24px;
  padding-bottom: 48px;
}

@media only screen and (min-width: 480px) {
  ._pagePadding_4jcwm_19 {
    padding: 0 24px;
    padding-bottom: 48px;
  }
}
@media only screen and (min-width: 768px) {
  ._pagePadding_4jcwm_19 {
    padding: 0 32px;
    padding-bottom: 48px;
  }
}
@media only screen and (min-width: 1200px) {
  ._pagePadding_4jcwm_19 {
    padding: 0 48px;
    padding-bottom: 48px;
  }
}
@media only screen and (min-width: 1600px) {
  ._pagePadding_4jcwm_19 {
    padding: 0 80px;
    padding-bottom: 48px;
  }
}
._chartLabel_4jcwm_49 {
  height: 59.3px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

._cardLayout_4jcwm_57 .ant-card-body {
  padding: 24px;
}

@media only screen and (min-width: 992px) {
  ._cardLayout_4jcwm_57 .ant-card-body {
    padding: 24px 32px;
  }
}
@media only screen and (min-width: 1600px) {
  ._cardLayout_4jcwm_57 .ant-card-body {
    padding: 24px 48px;
  }
}