/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_192mo_157 {
  display: flex;
}

._justify-content-space-between_192mo_161 {
  justify-content: space-between;
}

._tabular-nums_192mo_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_192mo_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Segment_192mo_178 {
  display: flex;
  align-items: center;
}
._Segment_192mo_178 ._saveBtn_192mo_182 svg {
  vertical-align: inherit;
}

._button_192mo_186 {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  border: 0;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
  outline: none;
}
._button_192mo_186:disabled {
  color: #8f8d91;
  cursor: not-allowed;
}