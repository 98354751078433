/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_y4y8c_157 {
  display: flex;
}

._justify-content-space-between_y4y8c_161 {
  justify-content: space-between;
}

._tabular-nums_y4y8c_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_y4y8c_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._BaseStep_y4y8c_178 {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.875rem;
}
._BaseStep_y4y8c_178 ._label_y4y8c_183 {
  margin-bottom: 0.625rem;
}
._BaseStep_y4y8c_178 ._label_y4y8c_183 h2 {
  margin: 0;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}
._BaseStep_y4y8c_178 ._box_y4y8c_192 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 1.25rem 1.25rem 0.625rem 1.25rem;
  border: 0.0625rem solid #d9d9d9;
  border-radius: 0.375rem;
}
@media (max-width: 37.5rem) {
  ._BaseStep_y4y8c_178 ._box_y4y8c_192 {
    flex-wrap: wrap;
  }
}
._BaseStep_y4y8c_178 ._box_y4y8c_192 ._left_y4y8c_207 ._icon_y4y8c_207 {
  margin-right: 1.875rem;
  margin-bottom: 0.625rem;
}
._BaseStep_y4y8c_178 ._box_y4y8c_192 ._left_y4y8c_207 ._icon_y4y8c_207 img {
  object-fit: cover;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 0.375rem;
}
._BaseStep_y4y8c_178 ._box_y4y8c_192 ._right_y4y8c_217 {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 75rem) {
  ._BaseStep_y4y8c_178 ._box_y4y8c_192 ._right_y4y8c_217 {
    flex-wrap: wrap;
  }
}
._BaseStep_y4y8c_178 ._box_y4y8c_192 ._right_y4y8c_217 ._textContent_y4y8c_228 {
  flex: 1;
  margin-right: 1.25rem;
  margin-bottom: 0.625rem;
  color: #505256;
}
@media (max-width: 75rem) {
  ._BaseStep_y4y8c_178 ._box_y4y8c_192 ._right_y4y8c_217 ._textContent_y4y8c_228 {
    width: 80%;
  }
}
._BaseStep_y4y8c_178 ._box_y4y8c_192 ._right_y4y8c_217 ._textContent_y4y8c_228 p {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.375rem;
}
._BaseStep_y4y8c_178 ._box_y4y8c_192 ._right_y4y8c_217 ._actionContent_y4y8c_244 {
  margin-bottom: 0.625rem;
}
._BaseStep_y4y8c_178 ._box_y4y8c_192 ._right_y4y8c_217 ._actionContent_y4y8c_244 .ant-btn {
  width: 6.25rem;
  border: 0;
  box-shadow: none;
  text-shadow: none;
}