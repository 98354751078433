._InstagramTable_1hxuu_1 .ant-table-footer {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
}
._InstagramTable_1hxuu_1 ._InstagramTableFooter_1hxuu_6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._TableTitle_1hxuu_12 {
  margin-bottom: 16px !important;
}

._EmptyAdAccounts_1hxuu_16 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

._RowTable_1hxuu_23 {
  height: 60px;
}
._RowTable_1hxuu_23 ._RemoveButton_1hxuu_26 {
  display: none;
}
._RowTable_1hxuu_23:hover ._RemoveButton_1hxuu_26 {
  display: block;
}

._marginBottom_1hxuu_33 {
  margin-bottom: 32px;
}