/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_17uk1_157 {
  display: flex;
}

._justify-content-space-between_17uk1_161 {
  justify-content: space-between;
}

._tabular-nums_17uk1_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_17uk1_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._accordionHeader_17uk1_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 0.0625rem solid #e9e8ea;
  background: #fdfdfd;
  color: #8f8d91;
  cursor: pointer;
}
._accordionHeader_17uk1_178:hover {
  background-color: #eff5f9;
}
._accordionHeader_17uk1_178 ._completedIcon_17uk1_191 {
  color: #3996e0;
}
._accordionHeader_17uk1_178 ._upArrow_17uk1_194 {
  transform: rotate(180deg);
}
._accordionHeader_17uk1_178 ._title_17uk1_197 {
  display: flex;
  flex-grow: 1;
  margin-left: 1rem;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: -0.02rem;
}