/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1l5j5_157 {
  display: flex;
}

._justify-content-space-between_1l5j5_161 {
  justify-content: space-between;
}

._tabular-nums_1l5j5_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1l5j5_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MessageTemplateEditor_1l5j5_178 [class^=_editor_] {
  min-height: 18.75rem;
  max-height: 18.75rem;
  overflow-y: auto !important;
  width: 86% !important;
}
._MessageTemplateEditor_1l5j5_178 ._template_1l5j5_184 {
  border: 0.0625rem solid var(--gray-5);
  border-radius: 0.375rem;
}
._MessageTemplateEditor_1l5j5_178 ._template_1l5j5_184 ._templateHeader_1l5j5_188 {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.75rem 0.75rem 0rem 0.75rem;
}
._MessageTemplateEditor_1l5j5_178 ._template_1l5j5_184 ._templateHeader_1l5j5_188 ._templateTitle_1l5j5_195 {
  overflow: hidden;
  padding: 0.125rem;
  width: 17.5rem;
}
._MessageTemplateEditor_1l5j5_178 ._template_1l5j5_184 ._templateHeader_1l5j5_188 ._templateTitle_1l5j5_195 ._titleInput_1l5j5_200 {
  width: 17.25rem;
}
._MessageTemplateEditor_1l5j5_178 ._template_1l5j5_184 ._templateHeader_1l5j5_188 ._templateTitle_1l5j5_195 ._editIcon_1l5j5_203 {
  margin-left: 0.125rem;
  margin-top: 0.125rem;
  cursor: pointer;
}
._MessageTemplateEditor_1l5j5_178 ._template_1l5j5_184 ._templateHeader_1l5j5_188 ._icon_1l5j5_208 {
  padding: 0.25rem 0.375rem 0.125rem 0.375rem;
  border: 0.0625rem solid var(--gray-5);
  border-radius: 0.375rem;
  color: #4f5256;
}
._MessageTemplateEditor_1l5j5_178 ._template_1l5j5_184 ._templateHeader_1l5j5_188 ._icon_1l5j5_208:hover {
  color: #167cf4;
}
._MessageTemplateEditor_1l5j5_178 ._template_1l5j5_184 ._emailComposer_1l5j5_217 ._subject_1l5j5_217 {
  margin-bottom: 0.75rem;
}
._MessageTemplateEditor_1l5j5_178 ._template_1l5j5_184 ._emailComposer_1l5j5_217 ._subject_1l5j5_217 ._label_1l5j5_220 {
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}
._MessageTemplateEditor_1l5j5_178 ._template_1l5j5_184 ._emailComposer_1l5j5_217 ._subject_1l5j5_217 ._text_1l5j5_223 {
  padding: 0 0.75rem;
}
._MessageTemplateEditor_1l5j5_178 ._signature_1l5j5_226 {
  margin: 0.5rem 0;
}
._MessageTemplateEditor_1l5j5_178 ._signature_1l5j5_226 a {
  text-decoration: underline;
}
._MessageTemplateEditor_1l5j5_178 ._signature_1l5j5_226 a ._externalLinkIcon_1l5j5_232 {
  vertical-align: text-bottom;
  margin-left: 0.375rem;
}