._AspireAdvice_bup8e_1 {
  background-color: #FBFAF9;
  border: 1px solid var(--sand);
  border-radius: var(--border-radius-base);
  margin: 0;
  padding: var(--spacing-sm);
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: var(--spacing-md);
}
._AspireAdvice_bup8e_1 ._icon_bup8e_12 .anticon {
  background-color: var(--sand);
  border-radius: var(--border-radius-base);
  padding: var(--spacing-sm);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
._AspireAdvice_bup8e_1 ._icon_bup8e_12 .anticon svg {
  color: #826b4a;
}
._AspireAdvice_bup8e_1 ._title_bup8e_24 {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--gray-9);
}
._AspireAdvice_bup8e_1 ._description_bup8e_29 {
  font-size: var(--font-size-sm);
  color: var(--gray-9);
}
._AspireAdvice_bup8e_1 ._link_bup8e_33 {
  font-size: var(--font-size-sm);
  color: var(--blue-6);
}

._center_bup8e_38 {
  display: flex;
  justify-content: center;
  align-items: center;
}

._AlertAction_bup8e_44 {
  padding: 0 var(--spacing-xs);
}
._AlertAction_bup8e_44 ._counter_bup8e_47 {
  color: var(--gray-8);
}
._AlertAction_bup8e_44 ._button_bup8e_50 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
._AlertAction_bup8e_44 ._button_bup8e_50 .anticon {
  background-color: transparent;
  border-radius: 0;
  padding: 0;
}
._AlertAction_bup8e_44 ._button_bup8e_50 .anticon svg {
  color: var(--gray-8);
}