._ThreadAssignees_1orli_1:hover ._popoverTrigger_1orli_1 ._editIcon_1orli_1 {
  display: flex;
}

._ThreadAssignees_1orli_1 {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}
._ThreadAssignees_1orli_1 ._header_1orli_11 {
  font-weight: 600;
  padding-right: 4px;
}
._ThreadAssignees_1orli_1 ._popoverTrigger_1orli_1 {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
}
._ThreadAssignees_1orli_1 ._popoverTrigger_1orli_1 ._editIcon_1orli_1 {
  display: none;
}
._ThreadAssignees_1orli_1 ._popoverTrigger_1orli_1._sharedEmail_1orli_24 {
  color: var(--ant-primary);
}

._popoverContent_1orli_28 {
  padding: 8px;
  width: 280px;
}
._popoverContent_1orli_28 ._popoverList_1orli_32 {
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 420px;
  overflow: hidden;
  overflow-y: auto;
}
._popoverContent_1orli_28 ._popoverList_1orli_32 ._popoverElementWrapper_1orli_41 {
  width: 100%;
  margin-left: 0;
}
._popoverContent_1orli_28 ._popoverList_1orli_32 ._popoverElementWrapper_1orli_41 ._popoverElement_1orli_41 {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
._popoverContent_1orli_28 ._popoverList_1orli_32 ._popoverElementWrapper_1orli_41 ._popoverElement_1orli_41 .ant-avatar {
  background-color: var(--brand-accents-green-1);
  color: var(--gray-1);
}