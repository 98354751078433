/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1dgq8_157 {
  display: flex;
}

._justify-content-space-between_1dgq8_161 {
  justify-content: space-between;
}

._tabular-nums_1dgq8_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1dgq8_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._AutomationSummaryBody_1dgq8_209 {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

._AutomationSummary_1dgq8_209 {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

._TemplateList_1dgq8_222 {
  display: flex;
  flex-direction: column;
  row-gap: 0.625rem;
}

._AutomationSummaryTitle_1dgq8_228 {
  color: #1f1f21;
  stroke: black;
  display: flex;
  align-items: center;
}
._AutomationSummaryTitle_1dgq8_228 > *:not(:last-child) {
  margin-right: 0.5rem;
}

._AutomationSuggestedSubtitle_1dgq8_238 {
  color: #8c8c8c;
}

._AutomationSummaryFooter_1dgq8_242 {
  justify-content: center;
  margin-top: "auto";
}