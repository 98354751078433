._MessageComposer_sl0tl_1 {
  display: flex;
  flex-direction: column;
}
._MessageComposer_sl0tl_1 > * + * {
  margin-block: 25px;
}
._MessageComposer_sl0tl_1 ._EmailComposer_sl0tl_8 {
  min-height: 500px;
  border: 1px solid #dadcde;
}
._MessageComposer_sl0tl_1 ._EmailComposer_sl0tl_8._hide_sl0tl_12 {
  display: none;
}

._footer_sl0tl_16 {
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
  border-top: 1px solid #dadcde;
  margin-top: 16px;
}
._footer_sl0tl_16 button {
  margin-left: 12px;
}