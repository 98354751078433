._OrderReview_1t1l5_1 {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
}
._OrderReview_1t1l5_1 .ant-spin-spinning {
  display: flex;
  opacity: 1;
  position: static;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
._OrderReview_1t1l5_1 ._avatar_1t1l5_16 {
  width: 40px;
  height: 40px;
}
._OrderReview_1t1l5_1 ._mainContentWrapper_1t1l5_20 {
  flex: 1 1 auto;
  overflow: auto;
}
._OrderReview_1t1l5_1 ._footer_1t1l5_24 {
  flex: 0 0 10%; /* changes here for 15% height*/
  border-top: 1px solid #d9d9d9;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
}
._OrderReview_1t1l5_1 ._footer_1t1l5_24 ._footerButton_1t1l5_32 {
  width: 270px;
}
._OrderReview_1t1l5_1 ._nameCell_1t1l5_35 {
  display: flex;
  align-items: center;
}
._OrderReview_1t1l5_1 ._nameCell_1t1l5_35 ._name_1t1l5_35 {
  padding-left: 8px;
}