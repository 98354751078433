/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_oqxf3_157 {
  display: flex;
}

._justify-content-space-between_oqxf3_161 {
  justify-content: space-between;
}

._tabular-nums_oqxf3_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_oqxf3_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._RequirementCell_oqxf3_178 {
  display: block;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
}
._RequirementCell_oqxf3_178 ._StandardCheckbox_oqxf3_186 {
  display: inline-block;
  vertical-align: 50%;
  margin-right: 1rem;
}
._RequirementCell_oqxf3_178 ._TextWrap_oqxf3_191 {
  display: inline-block;
  width: calc(100% - 3.625rem);
}
._RequirementCell_oqxf3_178 ._Name_oqxf3_195 {
  display: block;
  margin-bottom: 0;
  padding-bottom: 0.5rem;
  color: #1a1818;
  font-weight: bold;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125rem;
  letter-spacing: -0.02rem;
}
._RequirementCell_oqxf3_178 ._DueDate_oqxf3_207 {
  display: block;
  margin-bottom: 0;
  padding-bottom: 0.5rem;
  color: #1a1818;
  font-weight: normal;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.25rem;
  letter-spacing: -0.025rem;
}

._SelectedCell_oqxf3_220 {
  background-color: #eff5f9;
}

._UnselectedCell_oqxf3_224 {
  background-color: #fdfdfd;
}