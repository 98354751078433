/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_9y9wb_157 {
  display: flex;
}

._justify-content-space-between_9y9wb_161 {
  justify-content: space-between;
}

._tabular-nums_9y9wb_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_9y9wb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._IFrame_9y9wb_178 {
  width: 100%;
  height: 100%;
  border: 0;
}
._IFrame_9y9wb_178 body {
  margin: 0;
}
._IFrame_9y9wb_178 html body {
  margin: 0;
}