/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._DeleteMemberMenuItem_1nikd_32 {
  color: #f5232d;
}

._DeleteModal_1nikd_36 {
  width: 420px !important;
}
._DeleteModal_1nikd_36 .ant-modal-content {
  padding: 14px;
  border-radius: px(12);
}
._DeleteModal_1nikd_36 .ant-modal-content .ant-modal-header {
  border-top-left-radius: px(12);
  border-top-right-radius: px(12);
  border: none;
}
._DeleteModal_1nikd_36 .ant-modal-content .ant-modal-footer {
  border-bottom-right-radius: px(12);
  border-bottom-left-radius: px(12);
  border: none;
}
._DeleteModal_1nikd_36 ._content_1nikd_53 {
  color: #8c8c8c;
}

.ant-dropdown-menu-item {
  background-color: #fff !important;
}
.ant-dropdown-menu-item:hover {
  background-color: #e6f6ff !important;
  color: #3c73d7 !important;
}
.ant-dropdown-menu-item-disabled:hover {
  background-color: #fff !important;
  color: #bdbdbd !important;
}