/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1mz8u_157 {
  display: flex;
}

._justify-content-space-between_1mz8u_161 {
  justify-content: space-between;
}

._tabular-nums_1mz8u_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1mz8u_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ReviewPayment_1mz8u_178 {
  padding: 2.625rem 0;
}
._ReviewPayment_1mz8u_178 h1 {
  color: #1a1818;
  font-weight: bold;
  font-style: normal;
  font-size: 1.25rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.5rem;
  letter-spacing: 0.009375rem;
  text-align: center;
}
._ReviewPayment_1mz8u_178 ._BodyWrap_1mz8u_191 {
  max-width: 40.125rem;
  margin: 0 auto;
}
._ReviewPayment_1mz8u_178 ._PaymentList_1mz8u_195 {
  margin: 0;
  padding: 0;
  color: #1a1818;
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.375rem;
}
._ReviewPayment_1mz8u_178 ._PaymentList_1mz8u_195 li {
  padding: 1.5rem;
  border-bottom: 0.0625rem solid #dadcde;
  list-style-type: none;
}
._ReviewPayment_1mz8u_178 ._PaymentList_1mz8u_195 li:last-child {
  border-bottom: 0;
}
._ReviewPayment_1mz8u_178 ._PaymentList_1mz8u_195 ._Amount_1mz8u_213 {
  text-align: right;
}
._ReviewPayment_1mz8u_178 ._PaymentList_1mz8u_195 ._Creator_1mz8u_216 {
  padding-top: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
}
._ReviewPayment_1mz8u_178 ._PaymentList_1mz8u_195 ._Total_1mz8u_222 {
  font-weight: bold;
}
._ReviewPayment_1mz8u_178 ._SubmitWrap_1mz8u_225 {
  margin-top: 2rem;
  text-align: center;
}
._ReviewPayment_1mz8u_178 ._ErrorNotice_1mz8u_229 {
  margin-top: 0.75rem;
}