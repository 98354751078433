/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_rye1e_157 {
  display: flex;
}

._justify-content-space-between_rye1e_161 {
  justify-content: space-between;
}

._tabular-nums_rye1e_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_rye1e_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Wizard_rye1e_178 {
  position: relative;
  height: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr 8.125rem;
  grid-template-columns: auto;
  grid-template-areas: "header" "content" "footer";
}
._Wizard_rye1e_178 ._logoHeader_rye1e_187 {
  position: absolute;
  top: 3.125rem;
  left: 50%;
  transform: translateX(-50%);
  max-width: 63rem;
  width: 100%;
}
._Wizard_rye1e_178 ._logoHeader_rye1e_187 ._logo_rye1e_187 {
  width: 6.25rem;
}
._Wizard_rye1e_178 ._scrollableContent_rye1e_198 {
  position: relative;
  padding: 0;
  max-height: calc(100vh - 8.125rem);
  overflow: scroll;
}
._Wizard_rye1e_178 ._header_rye1e_204 {
  grid-area: header;
}
._Wizard_rye1e_178 ._content_rye1e_207 {
  grid-area: content;
  margin-top: 3rem;
}
._Wizard_rye1e_178 ._footer_rye1e_211 {
  grid-area: footer;
  box-shadow: 0rem -0.25rem 0.5rem rgba(0, 0, 0, 0.08);
  padding-top: 1.5rem;
}