/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_fu5jf_157 {
  display: flex;
}

._justify-content-space-between_fu5jf_161 {
  justify-content: space-between;
}

._tabular-nums_fu5jf_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_fu5jf_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SelectOptions_fu5jf_178 ._choiceLabel_fu5jf_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._SelectOptions_fu5jf_178 {
  margin-top: 1rem;
}
._SelectOptions_fu5jf_178 ._choices_fu5jf_191 {
  margin: 1.5rem 0;
}
._SelectOptions_fu5jf_178 ._button_fu5jf_194 {
  margin-top: 2.25rem;
}
._SelectOptions_fu5jf_178 ._choice_fu5jf_178 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
  margin: 1rem 0;
}
._SelectOptions_fu5jf_178 ._choiceLabel_fu5jf_178 {
  overflow: hidden;
  width: 60%;
  padding-right: 1.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._SelectOptions_fu5jf_178 ._choiceActions_fu5jf_214 {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
._SelectOptions_fu5jf_178 ._choiceActions_fu5jf_214 > *:not(:first-child) {
  margin-left: 0.625rem;
}