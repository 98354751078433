/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1wt88_157 {
  display: flex;
}

._justify-content-space-between_1wt88_161 {
  justify-content: space-between;
}

._tabular-nums_1wt88_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1wt88_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._select_1wt88_178 .ant-select-dropdown {
  z-index: 10000;
  border-radius: 0.25rem;
  background: #fdfdfd;
  box-shadow: 0 0.25rem 0.875rem rgba(26, 24, 24, 0.08);
}
._select_1wt88_178 .ant-select-selector {
  border-radius: 6.25rem !important;
}