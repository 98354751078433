/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_c5n4p_157 {
  display: flex;
}

._justify-content-space-between_c5n4p_161 {
  justify-content: space-between;
}

._tabular-nums_c5n4p_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_c5n4p_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._title_c5n4p_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._title_c5n4p_178 {
  margin-bottom: 2.5rem;
  font-size: 1.5rem;
}