._CSVErrorModal_1go50_1 .ant-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
._CSVErrorModal_1go50_1 .ant-modal .ant-modal-content .warning-icon {
  width: 80px;
  height: 80px;
  fill: red;
}
._CSVErrorModal_1go50_1 .ant-modal .ant-modal-content .ant-modal-body {
  text-align: center;
}
._CSVErrorModal_1go50_1 .ant-modal .ant-modal-content .ant-modal-footer {
  display: flex;
  border-top: 0px;
  justify-content: center;
}