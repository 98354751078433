/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_viejj_157 {
  display: flex;
}

._justify-content-space-between_viejj_161 {
  justify-content: space-between;
}

._tabular-nums_viejj_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_viejj_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Programs_viejj_178 {
  display: flex;
  flex-direction: row;
  margin-top: 1.5625rem;
}
._Programs_viejj_178 ._emptyMsg_viejj_183 {
  padding: 0.75rem 0.75rem 0.75rem 0;
  border-radius: 0.5rem;
  background-color: #e9e8ea;
  color: #8f8d91;
  text-align: center;
}
._Programs_viejj_178 ._list_viejj_190 {
  flex: 1;
  max-width: 20rem;
}
._Programs_viejj_178 ._details_viejj_194 {
  flex: 1;
  margin-left: 1.875rem;
}
._Programs_viejj_178 ._create_viejj_198 {
  color: #3996e0;
}