/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1xla4_157 {
  display: flex;
}

._justify-content-space-between_1xla4_161 {
  justify-content: space-between;
}

._tabular-nums_1xla4_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1xla4_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Perks_1xla4_178 {
  padding-bottom: 1.5rem;
}
._Perks_1xla4_178 .ant-checkbox-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
._Perks_1xla4_178 .ant-checkbox-wrapper {
  font-size: 14px;
  line-height: 1.2;
  color: var(--gray-9);
  margin-top: 16px;
  align-items: flex-start;
}
._Perks_1xla4_178 ._row_1xla4_194 {
  margin-top: 1rem;
  padding-bottom: 16px;
}
._Perks_1xla4_178 ._row_1xla4_194 ._rowDescription_1xla4_198 {
  color: var(--gray-9);
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 16px;
}
._Perks_1xla4_178 ._row_1xla4_194 ._subrow_1xla4_204:not(:first-child) {
  margin-top: 24px;
}
._Perks_1xla4_178 ._row_1xla4_194:not(:last-child) {
  border-bottom: 1px solid var(--gray-5);
}
._Perks_1xla4_178 ._subtitle_1xla4_210 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}