/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1tmjj_157 {
  display: flex;
}

._justify-content-space-between_1tmjj_161 {
  justify-content: space-between;
}

._tabular-nums_1tmjj_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1tmjj_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._EmptyEmailResources_1tmjj_178 ._header_1tmjj_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._EmptyEmailResources_1tmjj_178 ._message_1tmjj_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._EmptyEmailResources_1tmjj_178 {
  overflow: auto;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
._EmptyEmailResources_1tmjj_178 img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}
._EmptyEmailResources_1tmjj_178 ._header_1tmjj_178 {
  font-size: 1.5rem;
  margin: 1rem auto;
  text-align: center;
}
._EmptyEmailResources_1tmjj_178 ._message_1tmjj_188 {
  font-size: 1rem;
  margin: 0 auto 1.5rem;
  text-align: center;
}
._EmptyEmailResources_1tmjj_178 ._actions_1tmjj_254 {
  text-align: center;
}
._EmptyEmailResources_1tmjj_178 ._actions_1tmjj_254 div:last-child {
  margin-left: 0.9375rem;
}