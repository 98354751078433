._tab_b16bi_1 {
  border: 1px solid var(--gray-4);
  cursor: pointer;
  transition: border-color 100ms ease-out;
  min-width: 120px;
}
._tab_b16bi_1 ._content_b16bi_7 {
  padding: var(--spacing-sm);
}
._tab_b16bi_1::before {
  content: "";
  display: block;
  left: 0;
  top: 0;
  height: 9px;
  width: 100%;
  border-bottom: 1px solid var(--gray-4);
  transition: all 100ms ease-out;
}

._green_b16bi_21::before {
  background-color: #f6ffed;
}
._green_b16bi_21:hover:not(._isActive_b16bi_24) {
  border-color: #9ac994;
}
._green_b16bi_21:hover:not(._isActive_b16bi_24)::before {
  background-color: #9ac994;
  border-color: #9ac994;
}
._green_b16bi_21._isActive_b16bi_24 {
  border-color: #113c0b;
}
._green_b16bi_21._isActive_b16bi_24::before {
  background-color: #113c0b;
  border-color: #113c0b;
}

._blue_b16bi_39::before {
  background-color: #eff2f5;
}
._blue_b16bi_39:hover:not(._isActive_b16bi_24) {
  border-color: #a0afc5;
}
._blue_b16bi_39:hover:not(._isActive_b16bi_24)::before {
  background-color: #a0afc5;
  border-color: #a0afc5;
}
._blue_b16bi_39._isActive_b16bi_24 {
  border-color: #61799e;
}
._blue_b16bi_39._isActive_b16bi_24::before {
  background-color: #61799e;
  border-color: #61799e;
}

._plum_b16bi_57::before {
  background-color: #f7eef2;
}
._plum_b16bi_57:hover:not(._isActive_b16bi_24) {
  border-color: #cd98b2;
}
._plum_b16bi_57:hover:not(._isActive_b16bi_24)::before {
  background-color: #cd98b2;
  border-color: #cd98b2;
}
._plum_b16bi_57._isActive_b16bi_24 {
  border-color: #ac537e;
}
._plum_b16bi_57._isActive_b16bi_24::before {
  background-color: #ac537e;
  border-color: #ac537e;
}

._orange_b16bi_75::before {
  background-color: #fbf1ea;
}
._orange_b16bi_75:hover:not(._isActive_b16bi_24) {
  border-color: #e6aa7f;
}
._orange_b16bi_75:hover:not(._isActive_b16bi_24)::before {
  background-color: #e6aa7f;
  border-color: #e6aa7f;
}
._orange_b16bi_75._isActive_b16bi_24 {
  border-color: #d5712a;
}
._orange_b16bi_75._isActive_b16bi_24::before {
  background-color: #d5712a;
  border-color: #d5712a;
}

._teal_b16bi_93::before {
  background-color: #eff5f5;
}
._teal_b16bi_93:hover:not(._isActive_b16bi_24) {
  border-color: #a0c5c5;
}
._teal_b16bi_93:hover:not(._isActive_b16bi_24)::before {
  background-color: #a0c5c5;
  border-color: #a0c5c5;
}
._teal_b16bi_93._isActive_b16bi_24 {
  border-color: #619e9e;
}
._teal_b16bi_93._isActive_b16bi_24::before {
  background-color: #619e9e;
  border-color: #619e9e;
}

._statistic_b16bi_111 {
  margin-bottom: 0;
}
._statistic_b16bi_111 .ant-statistic-content-prefix {
  margin-right: 0;
}
._statistic_b16bi_111 .ant-statistic-content-suffix {
  margin-left: 0;
}

._trend_b16bi_121 {
  display: flex;
}
._trend_b16bi_121 .ant-statistic-content {
  padding: 0 var(--spacing-xxs);
  background-color: var(--white);
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--gray-4);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
}
._trend_b16bi_121 .ant-statistic-content-suffix {
  margin: 0;
}