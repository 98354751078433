/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1c21s_157 {
  display: flex;
}

._justify-content-space-between_1c21s_161 {
  justify-content: space-between;
}

._tabular-nums_1c21s_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1c21s_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._DiscoverCreatorsStep_1c21s_178 ._icon_1c21s_178 {
  background-image: url("/etc/aspirex/app/config/esbuild/../../src/app/assets/images/discover_creators.png");
  background-size: 3.75rem 3.75rem;
}