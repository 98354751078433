._ShopifyShippingLines_zva1q_1 {
  width: 100%;
}
._ShopifyShippingLines_zva1q_1 .ant-space {
  width: 100%;
}
._ShopifyShippingLines_zva1q_1 .ant-space-item {
  width: 100%;
}
._ShopifyShippingLines_zva1q_1 ._grayText_zva1q_10 {
  color: #5d6974;
}
._ShopifyShippingLines_zva1q_1 ._numberInput_zva1q_13 {
  width: 100%;
}