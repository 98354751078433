._statistic_id7l6_1 {
  align-items: flex-start;
}
._statistic_id7l6_1 .ant-statistic-title {
  color: var(--gray-9);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  margin-bottom: 0;
  order: 0;
}
._statistic_id7l6_1 .ant-statistic-content-prefix {
  margin-right: 0;
}
._statistic_id7l6_1 .ant-statistic-content {
  font-size: 30px !important;
  font-weight: var(--font-weight-semibold);
  line-height: 1.2;
}

._trend_id7l6_20 {
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-xxs);
  background-color: var(--white);
  border-radius: var(--border-radius-sm);
}
._trend_id7l6_20 .ant-statistic-content {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
}
._trend_id7l6_20 .ant-statistic-content-suffix {
  margin: 0;
}

._blue_id7l6_35 {
  background-color: #eff2f5;
}
._blue_id7l6_35 .recharts-bar-rectangle path {
  fill: #61799e;
}
._blue_id7l6_35 .recharts-bar-rectangle path:hover {
  fill: #3a495f;
}

._plum_id7l6_45 {
  background-color: #f7eef2;
}
._plum_id7l6_45 .recharts-bar-rectangle path {
  fill: #ac537e;
}
._plum_id7l6_45 .recharts-bar-rectangle path:hover {
  fill: #67324c;
}

._orange_id7l6_55 {
  background-color: #fbf1ea;
}
._orange_id7l6_55 .recharts-bar-rectangle path {
  fill: #d5712a;
}
._orange_id7l6_55 .recharts-bar-rectangle path:hover {
  fill: #804419;
}

._green_id7l6_65 {
  background-color: #eff5f5;
}
._green_id7l6_65 .recharts-bar-rectangle path {
  fill: #619e9e;
}
._green_id7l6_65 .recharts-bar-rectangle path:hover {
  fill: #3a5f5f;
}