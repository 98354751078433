._Details_1jng9_1 {
  height: 100%;
  display: flex;
  flex-direction: column;
}
._Details_1jng9_1 ._navigation_1jng9_6 {
  flex-shrink: 0;
  display: flex;
  width: 100%;
  padding: 3px;
  background-color: var(--gray-3);
  border-radius: var(--border-radius-base);
}
._Details_1jng9_1 ._navigation_1jng9_6 .ant-radio-button-wrapper {
  flex: 1;
  border: none;
  text-align: center;
  border-radius: var(--border-radius-base);
  background-color: var(--gray-3);
}
._Details_1jng9_1 ._navigation_1jng9_6 .ant-radio-button-wrapper::before {
  display: none;
}
._Details_1jng9_1 ._navigation_1jng9_6 .ant-radio-button-wrapper-checked {
  background-color: #09375a !important;
}
._Details_1jng9_1 ._main_1jng9_27 {
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}
._Details_1jng9_1 ._main_1jng9_27 ._content_1jng9_33 {
  padding-right: 12px;
  overflow-y: auto;
  flex: 1;
}
._Details_1jng9_1 ._main_1jng9_27 ._actions_1jng9_38 {
  flex-shrink: 0;
}
._Details_1jng9_1 ._main_1jng9_27 ._actions_1jng9_38 ._spinner_1jng9_41 {
  margin-top: 12px;
}