/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1ssdl_157 {
  display: flex;
}

._justify-content-space-between_1ssdl_161 {
  justify-content: space-between;
}

._tabular-nums_1ssdl_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1ssdl_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ContentDeliverableTemplate_1ssdl_209 {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  border-radius: 4px;
  overflow: hidden;
  align-items: center;
  margin: 5px;
}
._ContentDeliverableTemplate_1ssdl_209:nth-child(odd) {
  background-color: #EFF5F9;
}
._ContentDeliverableTemplate_1ssdl_209:nth-child(even) {
  background-color: white;
}
._ContentDeliverableTemplate_1ssdl_209 ._controls_1ssdl_224 {
  margin-left: auto;
}