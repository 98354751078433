/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1lp3z_157 {
  display: flex;
}

._justify-content-space-between_1lp3z_161 {
  justify-content: space-between;
}

._tabular-nums_1lp3z_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1lp3z_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Header_1lp3z_178 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  text-align: center;
  color: #1F1F21;
  padding-top: 5rem;
}
._Header_1lp3z_178._logoVisible_1lp3z_187 {
  padding-top: 7.5rem;
}
._Header_1lp3z_178 ._title_1lp3z_190 {
  font-size: 1.875rem;
}
._Header_1lp3z_178 ._subTitle_1lp3z_193 {
  width: 40rem;
  font-size: 1rem;
}