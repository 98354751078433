/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_w9oka_157 {
  display: flex;
}

._justify-content-space-between_w9oka_161 {
  justify-content: space-between;
}

._tabular-nums_w9oka_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_w9oka_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ProgramOnboarding_w9oka_178 ._label_w9oka_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._ProgramOnboarding_w9oka_178 ._label_w9oka_178 {
  margin-bottom: 0.5rem;
}
._ProgramOnboarding_w9oka_178 ._label_w9oka_178:not(:first-of-type) {
  margin-top: 1.875rem;
}
._ProgramOnboarding_w9oka_178 ._landing_w9oka_194 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ProgramOnboarding_w9oka_178 ._landing_w9oka_194 span a {
  color: #3996e0;
}
._ProgramOnboarding_w9oka_178 ._landing_w9oka_194 span a:hover {
  color: #4fa7ee;
}
._ProgramOnboarding_w9oka_178 ._landing_w9oka_194 span a ._externalLinkIcon_w9oka_205 {
  vertical-align: text-bottom;
  margin-left: 0.5rem;
}
._ProgramOnboarding_w9oka_178 ._landing_w9oka_194 ._input_w9oka_209 {
  flex: 1;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
._ProgramOnboarding_w9oka_178 ._landing_w9oka_194 ._input_w9oka_209._hidden_w9oka_214 {
  display: none;
}
._ProgramOnboarding_w9oka_178 ._landing_w9oka_194 ._copyLinkButton_w9oka_217 {
  margin-right: 0.5rem;
  margin-left: auto;
}
._ProgramOnboarding_w9oka_178 ._landing_w9oka_194 ._cancelButton_w9oka_221 {
  margin-right: 0.5rem;
}
._ProgramOnboarding_w9oka_178 ._disabled_w9oka_224 {
  opacity: 0.8;
  pointer-events: none;
}
._ProgramOnboarding_w9oka_178 ._applicationFormFields_w9oka_228 {
  margin-bottom: 1rem;
}
._ProgramOnboarding_w9oka_178 ._applicationFormFields_w9oka_228 ._row_w9oka_231 {
  display: grid;
  grid-template-columns: minmax(11.25rem, 1fr) 1.5fr 7.5rem 2.5rem;
  gap: 0.75rem;
  padding: 0.75rem;
  border: 0.0625rem solid #dadcde;
}
._ProgramOnboarding_w9oka_178 ._applicationFormFields_w9oka_228 ._row_w9oka_231:first-of-type {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
._ProgramOnboarding_w9oka_178 ._applicationFormFields_w9oka_228 ._row_w9oka_231:last-of-type {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
._ProgramOnboarding_w9oka_178 ._applicationFormFields_w9oka_228 ._row_w9oka_231:not(:first-of-type) {
  border-top: 0;
}
._ProgramOnboarding_w9oka_178 ._applicationFormFields_w9oka_228 ._row_w9oka_231 ._fieldDropdown_w9oka_249._disabled_w9oka_224 {
  cursor: not-allowed;
}
._ProgramOnboarding_w9oka_178 ._applicationFormFields_w9oka_228 ._row_w9oka_231 ._fieldCheckbox_w9oka_252[class*=disabled] > div {
  opacity: 0.6;
  filter: none;
}
._ProgramOnboarding_w9oka_178 ._applicationFormFields_w9oka_228 ._row_w9oka_231 ._fieldDelete_w9oka_256 {
  width: 2.5rem;
  min-width: unset;
  border: 0;
  background: transparent;
}
._ProgramOnboarding_w9oka_178 ._applicationFormFields_w9oka_228 ._row_w9oka_231 ._fieldDelete_w9oka_256 > [class*=icon] {
  margin: 0;
  color: #8f8d91;
}
._ProgramOnboarding_w9oka_178 ._applicationFormFields_w9oka_228 ._row_w9oka_231 ._fieldDelete_w9oka_256:hover {
  background: #e9e8ea;
}
._ProgramOnboarding_w9oka_178 ._applicationFormFields_w9oka_228 ._row_w9oka_231 ._fieldDelete_w9oka_256:hover > [class*=icon] {
  color: #1a1818;
}

._hidden_w9oka_214 {
  opacity: 0;
}