/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_n824q_188 {
  display: flex;
}

._justify-content-space-between_n824q_192 {
  justify-content: space-between;
}

._tabular-nums_n824q_196 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_n824q_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._DatePicker_n824q_209 {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 0.0625rem solid #bdbdbd;
  border-radius: 0.25rem;
}
._DatePicker_n824q_209:focus, ._DatePicker_n824q_209:hover {
  border-color: #3996e0 !important;
}