/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_g67ti_157 {
  display: flex;
}

._justify-content-space-between_g67ti_161 {
  justify-content: space-between;
}

._tabular-nums_g67ti_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_g67ti_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SocialAccountAuthCell_g67ti_178 {
  height: 5rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}
._SocialAccountAuthCell_g67ti_178 ._hiddenForm_g67ti_187 {
  display: none;
}
._SocialAccountAuthCell_g67ti_178 ._hiddenText_g67ti_190 {
  display: block;
  width: 0.0625rem;
  height: 0.0625rem;
  opacity: 0;
}
._SocialAccountAuthCell_g67ti_178 ._NameWrap_g67ti_196 {
  display: inline-flex;
  width: 26.25rem;
  margin-right: 1rem;
}
._SocialAccountAuthCell_g67ti_178 ._NetworkIcon_g67ti_201 {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
}
._SocialAccountAuthCell_g67ti_178 ._UserName_g67ti_207 {
  display: inline-block;
  vertical-align: -0.9375rem;
  overflow: hidden;
  width: 12.5rem;
  color: #1a1818;
  font-weight: bold;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125rem;
  letter-spacing: 0.00625rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._SocialAccountAuthCell_g67ti_178 ._ActionsWrap_g67ti_222 {
  position: absolute;
  right: 3.125rem;
  display: inline-block;
}
._SocialAccountAuthCell_g67ti_178 ._ActionsWrap_g67ti_222 ._CopyButton_g67ti_227 {
  margin-right: 1rem;
}
._SocialAccountAuthCell_g67ti_178 ._ActionsWrap_g67ti_222 ._Notice_g67ti_230 {
  position: absolute;
  top: 0;
  left: -12.5rem;
  font-weight: 600;
}