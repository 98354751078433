/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1grjr_157 {
  display: flex;
}

._justify-content-space-between_1grjr_161 {
  justify-content: space-between;
}

._tabular-nums_1grjr_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1grjr_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._CreditCardSelector_1grjr_178 {
  padding-top: 1rem;
}
._CreditCardSelector_1grjr_178 ._CardToCharge_1grjr_181 {
  margin-top: 2rem;
  padding: 1.5rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
  text-align: center;
}
._CreditCardSelector_1grjr_178 ._CardToCharge_1grjr_181 ._StandardLabel_1grjr_188 {
  display: block;
  padding-bottom: 1rem;
  color: #1a1818;
  font-weight: bold;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125rem;
  letter-spacing: 0.00625rem;
  text-align: center;
}
._CreditCardSelector_1grjr_178 ._CardToCharge_1grjr_181 ._SelectDropdown_1grjr_199 {
  max-width: 27.125rem;
  margin: 0 auto;
  border-radius: 6.25rem;
}