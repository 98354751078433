/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_j9ock_157 {
  display: flex;
}

._justify-content-space-between_j9ock_161 {
  justify-content: space-between;
}

._tabular-nums_j9ock_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_j9ock_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SettingsModal_j9ock_178 ._modalContent_j9ock_178 ._nav_j9ock_178 ._title_j9ock_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._SettingsModal_j9ock_178 ._modalContent_j9ock_178 ._nav_j9ock_178 ._item_j9ock_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._SettingsModal_j9ock_178 ._modalDialog_j9ock_198 {
  padding: 0;
}
._SettingsModal_j9ock_178 ._modalContent_j9ock_178 {
  display: flex;
  flex-direction: row;
  height: 45rem;
}
._SettingsModal_j9ock_178 ._modalContent_j9ock_178 ._nav_j9ock_178 {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 18.75rem;
  padding: 0.625rem 1.5rem 2rem 1rem;
  background: #eceef2;
  user-select: none;
}
._SettingsModal_j9ock_178 ._modalContent_j9ock_178 ._nav_j9ock_178 ._title_j9ock_178 {
  margin-top: 1.375rem;
  margin-bottom: 0.75rem;
  padding: 0 1rem;
}
._SettingsModal_j9ock_178 ._modalContent_j9ock_178 ._nav_j9ock_178 ._item_j9ock_188 {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  height: 2.625rem;
  padding: 0 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: color, background-color;
}
._SettingsModal_j9ock_178 ._modalContent_j9ock_178 ._nav_j9ock_178 ._item_j9ock_188._disabled_j9ock_232 {
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}
._SettingsModal_j9ock_178 ._modalContent_j9ock_178 ._nav_j9ock_178 ._item_j9ock_188:hover {
  background-color: #eff5f9;
  color: #3996e0;
}
._SettingsModal_j9ock_178 ._modalContent_j9ock_178 ._nav_j9ock_178 ._item_j9ock_188._active_j9ock_240 {
  background-color: #3996e0;
  color: #fdfdfd;
  font-weight: 700;
}
._SettingsModal_j9ock_178 ._modalContent_j9ock_178 ._content_j9ock_245 {
  flex: 1;
  overflow-y: auto;
  padding: 2.625rem 1.25rem 2.625rem 2rem;
}
._SettingsModal_j9ock_178 ._modalContent_j9ock_178 ._content_j9ock_245 ._error_j9ock_250 {
  margin-top: 1.25rem;
}