._Loading_ke5iz_1 {
  background-color: #fff;
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
._Loading_ke5iz_1._visible_ke5iz_14 {
  opacity: 1;
}
._Loading_ke5iz_1 .ant-skeleton-title {
  margin: 0;
}
._Loading_ke5iz_1._largeStat_ke5iz_20 .ant-skeleton-title {
  height: 73px;
}
._Loading_ke5iz_1._smallStat_ke5iz_23 {
  display: block;
}
._Loading_ke5iz_1._smallStat_ke5iz_23 .ant-skeleton-title {
  height: 48px;
}
._Loading_ke5iz_1._link_ke5iz_29 .ant-skeleton-title {
  height: 32px;
  width: 120px;
}