._CollaborationDetails_xjb6q_1 {
  display: flex;
  flex-direction: column;
}
._CollaborationDetails_xjb6q_1 ._briefDetails_xjb6q_5 .ant-space-horizontal {
  width: 100%;
  justify-content: space-between;
}

._alertText_xjb6q_10 {
  margin-bottom: 8px;
}

._compensationContainer_xjb6q_14 {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
._compensationContainer_xjb6q_14 ._rewardContainer_xjb6q_19 {
  width: 100%;
}
._compensationContainer_xjb6q_14 ._projectBudgetInfoContainer_xjb6q_22 {
  width: 50%;
  margin-top: 32px;
}