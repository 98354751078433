/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_ypao7_157 {
  display: flex;
}

._justify-content-space-between_ypao7_161 {
  justify-content: space-between;
}

._tabular-nums_ypao7_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_ypao7_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MemberSocialPostList_ypao7_178 {
  width: 100%;
}
._MemberSocialPostList_ypao7_178 ._postContainer_ypao7_181 {
  display: inline-block;
  width: 20rem;
  margin-right: 3.125rem;
}