/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1iwjb_157 {
  display: flex;
}

._justify-content-space-between_1iwjb_161 {
  justify-content: space-between;
}

._tabular-nums_1iwjb_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1iwjb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._container_1iwjb_178 ._header_1iwjb_178 {
  width: 100%;
  margin-bottom: 2.5rem;
  color: var(--black);
}
._container_1iwjb_178 ._header_1iwjb_178 ._title_1iwjb_183 {
  margin-bottom: 0.75rem;
  font-weight: 700;
  font-size: 1.25rem;
}
._container_1iwjb_178 ._header_1iwjb_178 ._headerContent_1iwjb_188 {
  font-size: 0.875rem;
  line-height: 1.25;
}
._container_1iwjb_178 ._networks_1iwjb_192 {
  overflow: hidden;
  width: 28.75rem;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
}
._container_1iwjb_178 ._eligibilityNotice_1iwjb_198 {
  margin-top: 2.5rem;
  color: #fa541c;
  font-size: 0.75rem;
}