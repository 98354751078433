/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_kmhwz_157 {
  display: flex;
}

._justify-content-space-between_kmhwz_161 {
  justify-content: space-between;
}

._tabular-nums_kmhwz_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_kmhwz_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._NewContentSetup_kmhwz_209 {
  padding-bottom: 2.625rem;
}
._NewContentSetup_kmhwz_209 ._main_title_kmhwz_212 {
  margin-bottom: 0;
  padding-bottom: 1rem;
  color: #1a1818;
  font-weight: bold;
  font-style: normal;
  font-size: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.5rem;
  letter-spacing: 0.009375rem;
}
._NewContentSetup_kmhwz_209 ._main_descrip_kmhwz_223 {
  padding-bottom: 2rem;
  color: #1a1818;
  font-weight: normal;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
}
._NewContentSetup_kmhwz_209 ._standard_label_kmhwz_233 {
  display: block;
  padding-bottom: 0.75rem;
  color: #1a1818;
  font-weight: bold;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125rem;
  letter-spacing: 0.00625rem;
}
._NewContentSetup_kmhwz_209 fieldset {
  padding-bottom: 2rem;
}
._NewContentSetup_kmhwz_209 ._Select_kmhwz_247 {
  border-radius: 6.25rem;
}
._NewContentSetup_kmhwz_209 ._form_wrap_kmhwz_250 {
  max-width: 20.25rem;
}
._NewContentSetup_kmhwz_209 ._Notice_kmhwz_253 {
  margin-top: 1rem;
}