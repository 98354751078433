/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_2y6bu_157 {
  display: flex;
}

._justify-content-space-between_2y6bu_161 {
  justify-content: space-between;
}

._tabular-nums_2y6bu_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_2y6bu_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ApplicationList_2y6bu_209 {
  display: flex;
  flex-direction: row;
  align-items: center;
}