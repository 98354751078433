/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1kg5i_157 {
  display: flex;
}

._justify-content-space-between_1kg5i_161 {
  justify-content: space-between;
}

._tabular-nums_1kg5i_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1kg5i_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._EmptyState_1kg5i_178 ._title_1kg5i_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._EmptyState_1kg5i_178 ._subtitle_1kg5i_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._EmptyState_1kg5i_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
._EmptyState_1kg5i_178 ._messageWrapper_1kg5i_204 {
  text-align: center;
}
._EmptyState_1kg5i_178 ._title_1kg5i_178 {
  margin-top: 2rem;
}
._EmptyState_1kg5i_178 ._subtitle_1kg5i_188 {
  margin-top: 1rem;
  color: #8f8d91;
}