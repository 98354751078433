/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_19zhl_157 {
  display: flex;
}

._justify-content-space-between_19zhl_161 {
  justify-content: space-between;
}

._tabular-nums_19zhl_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_19zhl_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SocialAccountCell_19zhl_178 {
  padding: 1rem 1.5rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
}
._SocialAccountCell_19zhl_178 ._AccountUsername_19zhl_183 {
  color: #1a1818;
  font-weight: bold;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125rem;
  letter-spacing: -0.02rem;
  text-align: center;
}