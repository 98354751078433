._EmailContainer_pmmqj_1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

._MessageComposer_pmmqj_9 {
  display: flex;
  flex-direction: column;
}
._MessageComposer_pmmqj_9 > * + * {
  margin-block: 25px;
}
._MessageComposer_pmmqj_9 ._EmailComposer_pmmqj_16 {
  min-height: 500px;
  border: 1px solid #dadcde;
}
._MessageComposer_pmmqj_9 ._EmailComposer_pmmqj_16._hide_pmmqj_20 {
  display: none;
}