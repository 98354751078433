/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1wgl7_157 {
  display: flex;
}

._justify-content-space-between_1wgl7_161 {
  justify-content: space-between;
}

._tabular-nums_1wgl7_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1wgl7_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1wgl7_157 {
  display: flex;
}

._justify-content-space-between_1wgl7_161 {
  justify-content: space-between;
}

._tabular-nums_1wgl7_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1wgl7_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._button_1wgl7_355 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._button_1wgl7_355 {
  min-width: 2.25rem;
}
._button_1wgl7_355 > svg:first-of-type:not(:only-child) {
  margin-right: 0.25rem;
}

._button_1wgl7_355:focus, ._button_1wgl7_355:hover {
  border-color: #167cf4;
  color: #167cf4;
}

._icon_1wgl7_377 {
  color: #d9534f !important;
}

._button_1wgl7_355._disabled_1wgl7_381 {
  background-color: #fdfdfd !important;
  cursor: no-drop;
  pointer-events: none;
}