/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1qylf_157 {
  display: flex;
}

._justify-content-space-between_1qylf_161 {
  justify-content: space-between;
}

._tabular-nums_1qylf_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1qylf_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._menuItem_1qylf_178 ._menuText_1qylf_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._CsvDropdown_1qylf_188:not(:hover) {
  background-color: transparent !important;
}

._menuItem_1qylf_178 {
  padding: 0.3125rem 0.9375rem !important;
}
._menuItem_1qylf_178._disabled_1qylf_195 {
  opacity: 0.5;
}