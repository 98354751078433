/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1xtsq_157 {
  display: flex;
}

._justify-content-space-between_1xtsq_161 {
  justify-content: space-between;
}

._tabular-nums_1xtsq_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1xtsq_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._StepContent_1xtsq_178 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}
._StepContent_1xtsq_178 ._stepForm_1xtsq_184 {
  width: 30rem;
  margin-right: 3rem;
}
._StepContent_1xtsq_178 ._formItem_1xtsq_188 {
  margin-bottom: 1.875rem;
}
._StepContent_1xtsq_178 ._stepInfo_1xtsq_191 {
  background: #f6f3ef;
  border-radius: 0.5rem 0.5rem 8rem 0.5rem;
  padding: 1.5rem 3rem 2rem 1.75rem;
  width: 30rem;
}
._StepContent_1xtsq_178 ._stepInfo_1xtsq_191 ._title_1xtsq_197 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
._StepContent_1xtsq_178 ._stepInfo_1xtsq_191 ._section_1xtsq_202:not(:last-child) {
  margin-bottom: 1.5rem;
}
._StepContent_1xtsq_178 ._stepInfo_1xtsq_191 ._section_1xtsq_202 h4 {
  display: inline;
}
._StepContent_1xtsq_178 ._stepInfo_1xtsq_191 ._section_1xtsq_202 ul {
  margin-left: 18px;
  list-style: disc;
}
._StepContent_1xtsq_178 ._stepInfo_1xtsq_191 ._section_1xtsq_202 a {
  color: #127a48;
  font-weight: 500;
}
._StepContent_1xtsq_178 ._stepInfo_1xtsq_191 ._section_1xtsq_202 a ._externalLinkIcon_1xtsq_216 {
  vertical-align: text-bottom;
}