/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1pnik_157 {
  display: flex;
}

._justify-content-space-between_1pnik_161 {
  justify-content: space-between;
}

._tabular-nums_1pnik_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1pnik_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ThreadHeader_1pnik_178 ._top_1pnik_178 ._title_1pnik_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

:root {
  --screen-xs: 480px;
  --screen-sm: 576px;
  --screen-md: 768px;
  --screen-lg: 992px;
  --screen-xl: 1200px;
  --screen-xxl: 1600px;
}

._ThreadHeader_1pnik_178 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1.25rem;
  height: 6.3125rem;
  border-bottom: 0.0625rem solid #dadcde;
}
._ThreadHeader_1pnik_178 ._top_1pnik_178 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
}
._ThreadHeader_1pnik_178 ._top_1pnik_178 ._title_1pnik_178 {
  margin-right: 1rem;
}
._ThreadHeader_1pnik_178 ._top_1pnik_178 ._messageAction_1pnik_254 {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
._ThreadHeader_1pnik_178 ._top_1pnik_178 ._backButton_1pnik_259 {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}
@media screen and (max-width: 1200px) {
  ._ThreadHeader_1pnik_178 ._top_1pnik_178 ._backButton_1pnik_259 {
    width: 24px;
    height: 24px;
  }
}
._ThreadHeader_1pnik_178 ._bottom_1pnik_272 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
  margin-bottom: 8px;
}