._GroupContentReviewPage_14038_1 {
  padding: 24px;
}
._GroupContentReviewPage_14038_1 .ant-table-cell {
  padding: 8px !important;
}

._nameColumn_14038_8 {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}