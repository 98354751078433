._TabContent_kn52w_1 {
  width: 100%;
}
._TabContent_kn52w_1 .ant-statistic {
  display: inline-flex;
}
._TabContent_kn52w_1 .ant-statistic-content-prefix {
  margin-right: 0;
}
._TabContent_kn52w_1 .ant-statistic-content-suffix {
  margin-left: 0;
}
._TabContent_kn52w_1 .ant-divider-horizontal {
  margin: var(--spacing-xxs) 0;
}
._TabContent_kn52w_1 .ant-space-horizontal {
  width: 100%;
  justify-content: space-between;
}
._TabContent_kn52w_1 .ant-typography {
  color: var(--gray-9);
}
._TabContent_kn52w_1 ._chartTitle_kn52w_23 {
  color: var(--gray-9);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-base);
}
._TabContent_kn52w_1 ._chartTimeLabel_kn52w_28 {
  color: var(--gray-7);
  font-size: var(--font-size-sm);
}

._Badge_kn52w_33 {
  margin-right: var(--spacing-xs);
}
._Badge_kn52w_33 .ant-badge-status-dot {
  width: var(--spacing-sm);
  height: var(--spacing-sm);
}

._marginRight_kn52w_41 {
  margin-right: var(--spacing-xxs);
}

._blue_kn52w_45 .ant-badge-status-dot {
  background-color: #61799e;
}

._green_kn52w_49 .ant-badge-status-dot {
  background-color: #619e9e;
}

._plum_kn52w_53 .ant-badge-status-dot {
  background-color: #ac537e;
}

._orange_kn52w_57 .ant-badge-status-dot {
  background-color: #d5712a;
}

._teal_kn52w_61 .ant-badge-status-dot {
  background-color: #619e9e;
}

._yellow_kn52w_65 .ant-badge-status-dot {
  background-color: #ffcd5b;
}