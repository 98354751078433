/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1hxfn_157 {
  display: flex;
}

._justify-content-space-between_1hxfn_161 {
  justify-content: space-between;
}

._tabular-nums_1hxfn_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1hxfn_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MemberPage_1hxfn_178 {
  position: relative;
  overflow: hidden;
  height: 100%;
  background-color: #fdfdfd;
}
._MemberPage_1hxfn_178 ._memberList_1hxfn_184 {
  position: relative;
  height: 100%;
}
._MemberPage_1hxfn_178 ._right_1hxfn_188 {
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.08);
  height: 100%;
  padding: 1.25rem;
  padding-left: 2rem;
  background-color: white;
  margin-left: 15rem;
  display: flex;
  flex-direction: column;
  position: relative;
}
._MemberPage_1hxfn_178 ._memberListDivider_1hxfn_199 {
  height: 0.0625rem;
  margin-top: 1.25rem;
  margin-bottom: 0.9375rem;
  background-color: #e9e8ea;
}
._MemberPage_1hxfn_178 ._folders_1hxfn_205 {
  position: absolute;
  display: block;
  width: 15rem;
}