/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1d68x_157 {
  display: flex;
}

._justify-content-space-between_1d68x_161 {
  justify-content: space-between;
}

._tabular-nums_1d68x_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1d68x_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._FieldForm_1d68x_178 ._type_1d68x_178 ._label_1d68x_178, ._FieldForm_1d68x_178 ._name_1d68x_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._FieldForm_1d68x_178 ._notice_1d68x_188 ._title_1d68x_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._FieldForm_1d68x_178 ._notice_1d68x_188 ._subtitle_1d68x_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._FieldForm_1d68x_178 {
  position: relative;
}
._FieldForm_1d68x_178 ._form_1d68x_211 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._FieldForm_1d68x_178 ._name_1d68x_178 {
  flex: 2;
  padding-right: 1.875rem;
}
._FieldForm_1d68x_178 ._type_1d68x_178 {
  flex: 1;
}
._FieldForm_1d68x_178 ._helpIcon_1d68x_223 {
  vertical-align: middle;
  margin-left: 0.625rem;
  color: #ea92af;
}
._FieldForm_1d68x_178 ._label_1d68x_178 {
  display: block;
  margin-bottom: 0.75rem;
}
._FieldForm_1d68x_178 ._notice_1d68x_188 {
  margin-top: 1.25rem;
}
._FieldForm_1d68x_178 ._notice_1d68x_188 ._title_1d68x_188 {
  margin-bottom: 0.375rem;
}
._FieldForm_1d68x_178 ._notice_1d68x_188 ._subtitle_1d68x_198 {
  margin-bottom: 1rem;
}
._FieldForm_1d68x_178 ._buttons_1d68x_241 {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
._FieldForm_1d68x_178 ._buttons_1d68x_241 ._buttonsContainer_1d68x_247 > * {
  min-width: 7.5rem;
  margin-right: 1rem;
}
._FieldForm_1d68x_178 ._dangerBtn_1d68x_251,
._FieldForm_1d68x_178 ._dangerBtn_1d68x_251:hover,
._FieldForm_1d68x_178 ._dangerBtn_1d68x_251:active {
  color: #d9534f;
  background-color: #F8EDEE;
}
._FieldForm_1d68x_178._Vertical_1d68x_257 ._form_1d68x_211 {
  flex-direction: column;
  align-items: flex-start;
}
._FieldForm_1d68x_178._Vertical_1d68x_257 ._name_1d68x_178,
._FieldForm_1d68x_178._Vertical_1d68x_257 ._type_1d68x_178 {
  width: 100%;
  padding-right: 0;
}
._FieldForm_1d68x_178._Vertical_1d68x_257 ._fieldName_1d68x_266 {
  padding-bottom: 1rem;
}