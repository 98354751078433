._automationNodesContainer_e3c0k_1 {
  height: 90vh;
}

._automationNodeContainer_e3c0k_5 {
  width: 400px;
  border: 1px solid #F0F0F0;
  border-radius: 8px;
  background: #FFFFFF;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: inline-flex;
}

._automationNodeOnFocus_e3c0k_16 {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
}