/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_h34f4_157 {
  display: flex;
}

._justify-content-space-between_h34f4_161 {
  justify-content: space-between;
}

._tabular-nums_h34f4_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_h34f4_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MemberNotes_h34f4_178 {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  flex: 1;
}
._MemberNotes_h34f4_178 ._notesContainer_h34f4_186 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
._MemberNotes_h34f4_178 ._notesContainer_h34f4_186 ._note_h34f4_186 {
  width: 100%;
  max-width: 800px;
  display: flex;
  gap: 12px;
}
._MemberNotes_h34f4_178 ._notesContainer_h34f4_186 ._note_h34f4_186 ._noteContent_h34f4_198 {
  background-color: var(--gray-2);
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  color: var(--gray-9);
  line-height: 1.5;
  flex: 1;
}
._MemberNotes_h34f4_178 ._notesContainer_h34f4_186 ._note_h34f4_186 ._noteContent_h34f4_198 ._noteText_h34f4_210 p {
  margin-bottom: 6px;
}
._MemberNotes_h34f4_178 ._notesContainer_h34f4_186 ._note_h34f4_186 ._noteContent_h34f4_198 ._noteText_h34f4_210 p:first-child:last-child {
  margin-bottom: 0;
}
._MemberNotes_h34f4_178 ._list_h34f4_216 {
  width: 100%;
  flex: 1;
  overflow: hidden;
  max-height: 100%;
  padding: 24px;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
}
._MemberNotes_h34f4_178 ._list_h34f4_216 ._loadingContainer_h34f4_227 {
  display: flex;
  justify-content: center;
  align-items: center;
}
._MemberNotes_h34f4_178 ._list_h34f4_216._emptyNotesSection_h34f4_232 {
  align-items: center;
  text-align: center;
}
._MemberNotes_h34f4_178 ._list_h34f4_216 ._notesEmptyAction_h34f4_236 {
  width: 114px;
  height: 32px;
  margin-top: 24px;
}
._MemberNotes_h34f4_178 ._footer_h34f4_241 {
  width: 100%;
  padding: 24px;
}
._MemberNotes_h34f4_178 ._footer_h34f4_241 ._editor_h34f4_245 {
  border: 1px solid var(--gray-5);
  border-radius: 8px;
}
._MemberNotes_h34f4_178 ._footer_h34f4_241 ._editor_h34f4_245 ._textEditor_h34f4_249 {
  border: 0;
}
._MemberNotes_h34f4_178 ._footer_h34f4_241 ._editor_h34f4_245 ._textEditor_h34f4_249 .ql-editor {
  min-height: 60px;
}
._MemberNotes_h34f4_178 ._footer_h34f4_241 ._editor_h34f4_245 ._editorFooter_h34f4_255 {
  display: flex;
  justify-content: flex-end;
  padding: 12px;
  border-top: 1px solid var(--gray-5);
}
._MemberNotes_h34f4_178 ._footer_h34f4_241 ._editor_h34f4_245 ._editorFooter_h34f4_255 ._closeButton_h34f4_261 {
  margin-right: 0.75rem;
}
._MemberNotes_h34f4_178 ._footer_h34f4_241 ._editor_h34f4_245 ._editorFooter_h34f4_255 ._closeButton_h34f4_261 svg {
  width: 0.75rem;
}