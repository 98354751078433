/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_170go_157 {
  display: flex;
}

._justify-content-space-between_170go_161 {
  justify-content: space-between;
}

._tabular-nums_170go_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_170go_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
.ant-select-dropdown {
  z-index: 10000;
  border-radius: 0.25rem;
  background: #fdfdfd;
  box-shadow: 0 0.25rem 0.875rem rgba(26, 24, 24, 0.08);
}

._searchBar_170go_185 {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  margin: 1.25rem 0rem;
}
._searchBar_170go_185 ._shopSelect_170go_191 {
  width: 50%;
  margin-right: 1.5rem;
}
._searchBar_170go_185 ._searchField_170go_195 {
  width: 50%;
  border-radius: 6.25rem;
  background-color: #eff5f9;
}
._searchBar_170go_185 ._searchField_170go_195 .ant-input {
  background-color: #eff5f9;
}
._searchBar_170go_185 ._searchField_170go_195 ._prefix_170go_203 {
  color: #8f8d91;
}