/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_l9b2l_157 {
  display: flex;
}

._justify-content-space-between_l9b2l_161 {
  justify-content: space-between;
}

._tabular-nums_l9b2l_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_l9b2l_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SystemMessageItem_l9b2l_178 ._header_l9b2l_178 ._subject_l9b2l_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._SystemMessageItem_l9b2l_178 ._header_l9b2l_178 ._date_l9b2l_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._SystemMessageItem_l9b2l_178 {
  border: 0.0625rem solid rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  background: #fdfdfd;
  box-shadow: 0 0.0625rem 0.375rem rgba(26, 24, 24, 0.08);
  opacity: 1;
  transition: opacity 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  will-change: opacity;
}
._SystemMessageItem_l9b2l_178:hover {
  opacity: 1 !important;
}
._SystemMessageItem_l9b2l_178._collapsed_l9b2l_241 {
  opacity: 0.7;
}
._SystemMessageItem_l9b2l_178 ._header_l9b2l_178 {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  height: 4.625rem;
  padding: 0 1.125rem;
  cursor: pointer;
  user-select: none;
}
._SystemMessageItem_l9b2l_178 ._header_l9b2l_178 ._avatar_l9b2l_254 {
  flex-shrink: 0;
  margin-right: 1rem;
}
._SystemMessageItem_l9b2l_178 ._header_l9b2l_178 ._subject_l9b2l_178 {
  flex: 1;
  color: #8f8d91;
}
._SystemMessageItem_l9b2l_178 ._header_l9b2l_178 ._date_l9b2l_188 {
  flex-shrink: 0;
  margin-left: auto;
  color: #8f8d91;
}
._SystemMessageItem_l9b2l_178 ._messageDetail_l9b2l_267 {
  border-top: 0.0625rem solid #dadcde;
  opacity: 1;
  transition: max-height 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0.2s;
  will-change: max-height, opacity;
}
._SystemMessageItem_l9b2l_178 ._messageDetail_l9b2l_267._collapsed_l9b2l_241 {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
._SystemMessageItem_l9b2l_178 ._messageDetail_l9b2l_267 ._content_l9b2l_278 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;
}
._SystemMessageItem_l9b2l_178 ._messageDetail_l9b2l_267 ._content_l9b2l_278 ._message_l9b2l_267 {
  margin-bottom: 1.5rem;
  white-space: pre-wrap;
}
._SystemMessageItem_l9b2l_178 ._messageDetail_l9b2l_267 ._content_l9b2l_278 ._notice_l9b2l_288 {
  width: 100%;
  margin-bottom: 1.5rem;
}
._SystemMessageItem_l9b2l_178 ._messageDetail_l9b2l_267 ._content_l9b2l_278 ._image_l9b2l_292 {
  vertical-align: top;
  width: 15.9375rem;
  margin-right: 0.625rem;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
}
._SystemMessageItem_l9b2l_178 ._messageDetail_l9b2l_267 ._content_l9b2l_278 ._action_l9b2l_299 {
  margin-right: 0.625rem;
}
._SystemMessageItem_l9b2l_178 ._messageDetail_l9b2l_267 ._content_l9b2l_278 ._attachements_l9b2l_302 {
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}
._SystemMessageItem_l9b2l_178 ._messageDetail_l9b2l_267 ._content_l9b2l_278 ._attachment_l9b2l_306 {
  display: block;
}