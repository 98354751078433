/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_bpsqv_157 {
  display: flex;
}

._justify-content-space-between_bpsqv_161 {
  justify-content: space-between;
}

._tabular-nums_bpsqv_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_bpsqv_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._productContainer_bpsqv_178 {
  overflow-y: auto;
  flex: 1;
}
._productContainer_bpsqv_178 ._numberOfResults_bpsqv_182 {
  padding-bottom: 1.5rem;
  color: #4f4f4f;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.025rem;
}
._productContainer_bpsqv_178 ._loadMore_bpsqv_190 {
  height: 2.5rem;
  margin: 0.625rem;
}