/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_2jrgm_157 {
  display: flex;
}

._justify-content-space-between_2jrgm_161 {
  justify-content: space-between;
}

._tabular-nums_2jrgm_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_2jrgm_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SocialPostsList_2jrgm_178 {
  width: calc(100% - 2rem);
  margin-top: 1rem;
}
._SocialPostsList_2jrgm_178 ._postListContainer_2jrgm_182 {
  padding-top: 1.25rem;
}
._SocialPostsList_2jrgm_178 ._postContainer_2jrgm_185 {
  display: inline-block;
  width: 20rem;
  margin-right: 1.875rem;
  padding-bottom: 1.875rem;
}