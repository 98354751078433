/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1dwhb_157 {
  display: flex;
}

._justify-content-space-between_1dwhb_161 {
  justify-content: space-between;
}

._tabular-nums_1dwhb_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1dwhb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MemberFilterList_1dwhb_178 ._filters_1dwhb_178 {
  position: relative;
  display: flex;
  align-items: center;
}
._MemberFilterList_1dwhb_178 ._description_1dwhb_183 {
  margin-top: var(--spacing-xs);
  margin-bottom: calc(var(--spacing-xs) * -1);
  color: #8f8d91;
  font-size: 0.75rem;
}
._MemberFilterList_1dwhb_178 ._segment_1dwhb_189 {
  margin-left: var(--spacing-xs);
}