/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_90clf_157 {
  display: flex;
}

._justify-content-space-between_90clf_161 {
  justify-content: space-between;
}

._tabular-nums_90clf_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_90clf_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Item_90clf_178:not(._highlighting_90clf_178) {
  margin-left: 22px;
}
._Item_90clf_178:not(._highlighting_90clf_178) span {
  padding-left: 2px !important;
}
._Item_90clf_178._highlighting_90clf_178:hover {
  background-color: #EFF5F9;
}
._Item_90clf_178._editing_90clf_187:hover {
  background-color: white;
}
._Item_90clf_178 ._link_90clf_190 {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: top;
}