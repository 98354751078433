/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_y83f1_157 {
  display: flex;
}

._justify-content-space-between_y83f1_161 {
  justify-content: space-between;
}

._tabular-nums_y83f1_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_y83f1_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._EditSegmentDropdown_y83f1_178 {
  padding: 0 0 var(--spacing-xxs);
}
._EditSegmentDropdown_y83f1_178 ._header_y83f1_181 {
  align-items: center;
  margin-bottom: var(--spacing-md);
  color: #1a1818;
  font-weight: 500;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
}
._EditSegmentDropdown_y83f1_178 ._button_y83f1_191 {
  margin-top: var(--spacing-sm);
}