/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_8b9bd_157 {
  display: flex;
}

._justify-content-space-between_8b9bd_161 {
  justify-content: space-between;
}

._tabular-nums_8b9bd_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_8b9bd_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
:root {
  --spacing-xxxl: 80px;
  --spacing-xxl: 48px;
  --spacing-xl: 32px;
  --spacing-lg: 24px;
  --spacing-md: 16px;
  --spacing-sm: 12px;
  --spacing-xs: 8px;
  --spacing-xxs: 4px;
}

._contentGuidelinesListHeader_8b9bd_200 {
  margin: 32px 24px 24px;
  row-gap: 24px;
}