._TermsWizard_18f7h_1 ._content_18f7h_1, ._ApplicationDrawer_18f7h_1 ._content_18f7h_1 {
  display: flex;
  flex-direction: column;
  height: 100%;
}
._TermsWizard_18f7h_1 ._content_18f7h_1 ._header_18f7h_6, ._ApplicationDrawer_18f7h_1 ._content_18f7h_1 ._header_18f7h_6 {
  flex: 0 0 64px;
  height: 64px;
  border-bottom: 1px solid #d9d9d9;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-weight: bold;
}
._TermsWizard_18f7h_1 ._content_18f7h_1 ._header_18f7h_6 ._headerContentWrapper_18f7h_18, ._ApplicationDrawer_18f7h_1 ._content_18f7h_1 ._header_18f7h_6 ._headerContentWrapper_18f7h_18 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._TermsWizard_18f7h_1 ._content_18f7h_1 ._header_18f7h_6 ._titleIcon_18f7h_23, ._ApplicationDrawer_18f7h_1 ._content_18f7h_1 ._header_18f7h_6 ._titleIcon_18f7h_23 {
  height: 32px;
  width: 32px;
  margin-right: 8px;
}
._TermsWizard_18f7h_1 ._content_18f7h_1 ._mainContentWrapper_18f7h_28, ._ApplicationDrawer_18f7h_1 ._content_18f7h_1 ._mainContentWrapper_18f7h_28 {
  flex: 1 1 auto;
  min-height: 1px;
  overflow: hidden scroll;
  padding: 32px 75px;
}
._TermsWizard_18f7h_1 ._content_18f7h_1 ._footer_18f7h_34, ._ApplicationDrawer_18f7h_1 ._content_18f7h_1 ._footer_18f7h_34 {
  flex: 0 0 130px;
  height: 130px;
  border-top: 1px solid #d9d9d9;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
._TermsWizard_18f7h_1 ._content_18f7h_1 ._footer_18f7h_34 ._footerButton_18f7h_44, ._ApplicationDrawer_18f7h_1 ._content_18f7h_1 ._footer_18f7h_34 ._footerButton_18f7h_44 {
  width: 270px;
}
._TermsWizard_18f7h_1 ._content_18f7h_1 ._footer_18f7h_34 ._progressWrapper_18f7h_47, ._ApplicationDrawer_18f7h_1 ._content_18f7h_1 ._footer_18f7h_34 ._progressWrapper_18f7h_47 {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  padding: 0 32px;
  width: 100%;
}
._TermsWizard_18f7h_1 ._content_18f7h_1 ._footer_18f7h_34 ._progressWrapper_18f7h_47 ._progress_18f7h_47, ._ApplicationDrawer_18f7h_1 ._content_18f7h_1 ._footer_18f7h_34 ._progressWrapper_18f7h_47 ._progress_18f7h_47 {
  flex: 1 1 auto;
  margin-right: 8px;
}
._TermsWizard_18f7h_1 ._content_18f7h_1 ._footer_18f7h_34 ._progressWrapper_18f7h_47 ._progressStatus_18f7h_58, ._ApplicationDrawer_18f7h_1 ._content_18f7h_1 ._footer_18f7h_34 ._progressWrapper_18f7h_47 ._progressStatus_18f7h_58 {
  flex: 0 0 auto;
  color: #8c8c8c;
  white-space: nowrap;
}

._ApplicationDrawer_18f7h_1 ._content_18f7h_1 ._mainContentWrapper_18f7h_28 {
  padding: 32px 10px;
}