/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_ruhdz_157 {
  display: flex;
}

._justify-content-space-between_ruhdz_161 {
  justify-content: space-between;
}

._tabular-nums_ruhdz_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_ruhdz_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_ruhdz_157 {
  display: flex;
}

._justify-content-space-between_ruhdz_161 {
  justify-content: space-between;
}

._tabular-nums_ruhdz_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_ruhdz_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._primaryCTA_ruhdz_355, ._button_ruhdz_355 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._button_ruhdz_355 {
  min-width: 2.25rem;
}
._button_ruhdz_355 > svg:first-of-type:not(:only-child) {
  margin-right: 0.25rem;
}

._button_ruhdz_355:focus, ._button_ruhdz_355:hover {
  border-color: #167cf4;
  color: #167cf4;
}

._primaryCTA_ruhdz_355 {
  border-color: #167cf4;
  background-color: #167cf4;
}
._primaryCTA_ruhdz_355:focus, ._primaryCTA_ruhdz_355:hover {
  background-color: #2e8af5;
  color: #fff;
}
._primaryCTA_ruhdz_355:disabled {
  color: #fff;
  opacity: 0.8;
}

._TableHeader_ruhdz_390 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -0.5rem;
}
._TableHeader_ruhdz_390 ._count_ruhdz_397 {
  margin: 0.5rem 1.5rem 1rem 0;
}
._TableHeader_ruhdz_390 ._buttonRow_ruhdz_400 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-right: auto;
}
._TableHeader_ruhdz_390 ._right_ruhdz_407 {
  flex-wrap: wrap;
  align-self: flex-end;
  margin-left: 0.5rem;
}

._button_ruhdz_355,
._buttonTooltip_ruhdz_414,
._primaryCTA_ruhdz_355 {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
._button_ruhdz_355 ._icon_ruhdz_419:not(:only-child),
._buttonTooltip_ruhdz_414 ._icon_ruhdz_419:not(:only-child),
._primaryCTA_ruhdz_355 ._icon_ruhdz_419:not(:only-child) {
  margin-right: 0.25rem;
}
._button_ruhdz_355._isPrimaryTheme_ruhdz_424:hover, ._button_ruhdz_355._isPrimaryTheme_ruhdz_424:focus,
._buttonTooltip_ruhdz_414._isPrimaryTheme_ruhdz_424:hover,
._buttonTooltip_ruhdz_414._isPrimaryTheme_ruhdz_424:focus,
._primaryCTA_ruhdz_355._isPrimaryTheme_ruhdz_424:hover,
._primaryCTA_ruhdz_355._isPrimaryTheme_ruhdz_424:focus {
  color: #fff !important;
}

._button_ruhdz_355._disabled_ruhdz_432 {
  background-color: #fdfdfd !important;
  cursor: no-drop;
  pointer-events: none;
}

:root {
  --primary: #167cf4;
  --primary-40: #3d73d7;
  --primary-10: #e6f7ff;
  --secondary: #f58984;
  --secondary-40: #fcbbae;
  --secondary-10: #fef0ed;
  --bay-grey: #989ea8;
  --grey-10: #fafafa;
  --grey-25: #e9e8ea;
  --grey-50: #aeaeae;
  --grey-75: #8f8f8f;
  --grey-100: #1a1b1c;
  --white: #fff;
  --black: #1a1b1c;
  --green: #006462;
  --sand: #f6f3ef;
  --yellow: #ffcd5b;
  --magenta-base: #ffddff;
  --red-base: #f5b3b4;
  --volcano-base: #ff9c93;
  --orange-base: #fabf95;
  --gold-base: #f1e5ac;
  --lime-base: #ebffd0;
  --sage-base: #c1e1c1;
  --cyan-base: #c9f2f2;
  --blue-base: #70d1f1;
  --geekblue-base: #a2aff1;
  --purple-base: #c5b3e3;
  --mauve-base: #c6b0ca;
  --coral-base: #ff8a8a;
  --apricot-base: #f5a287;
  --marigold-base: #fdaf1c;
  --butter-base: #f9eda1;
  --apple-base: #b7d275;
  --asparagus-base: #9db17c;
  --tiffany-base: #5fcdca;
  --baby-base: #a3ddf9;
  --carolina-base: #7eb3d8;
  --rose-base: #ffb4c7;
  --watermelon-base: #ffa1b6;
  --blue-0: #f4fcff;
  --blue-1: #e6f7ff;
  --blue-3: #91d5ff;
  --blue-5: #499dff;
  --blue-6: #167cf4;
  --blue-7: #2771c9;
  --gray-1: #fff;
  --gray-2: #fafafa;
  --gray-3: #f5f5f5;
  --gray-4: #f0f0f0;
  --gray-5: #d9d9d9;
  --gray-6: #bfbfbf;
  --gray-7: #8c8c8c;
  --gray-8: #505256;
  --gray-9: #1f1f21;
  --red-1: #fff1f0;
  --red-3: #ffa39e;
  --red-4: #ff7875;
  --red-5: #ff4d4f;
  --red-6: #f5222d;
  --gold-1: #fffbe6;
  --gold-3: #ffe58f;
  --gold-5: #ffc53d;
  --gold-6: #faad14;
  --gold-7: #d48806;
  --green-1: #f6ffed;
  --green-3: #b7eb8f;
  --green-5: #73d13d;
  --green-6: #52c41a;
  --green-7: #389e0d;
  --text-color: #505256;
  --text-color-secondary: #8c8c8c;
  --text-color-inverse: #fff;
  --icon-color: inherit;
  --icon-color-hover: rgba(26, 27, 28, 0.75);
  --heading-color: #1f1f21;
  --text-color-dark: rgba(255, 255, 255, 0.85);
  --text-color-secondary-dark: rgba(255, 255, 255, 0.65);
  --text-selection-bg: #167cf4;
  --link-color: #167cf4;
  --modal-mask-bg: rgba(79, 90, 114, 0.45);
}

._primaryCTA_ruhdz_355 {
  transition: opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1);
}
._primaryCTA_ruhdz_355._disabled_ruhdz_432 {
  opacity: 0.4;
  pointer-events: none;
}

._alertIcon_ruhdz_614 {
  fill: #f5222d;
}

._menu_ruhdz_618 .ant-dropdown-menu-submenu-title:hover,
._menu_ruhdz_618 .ant-dropdown-menu-item:hover {
  background-color: var(--primary-10) !important;
  color: var(--primary-40) !important;
}
._menu_ruhdz_618 .ant-dropdown-menu-submenu-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.75rem;
}
._menu_ruhdz_618 .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: relative;
  right: 0;
  transform: rotate(-90deg);
}

._menuItem_ruhdz_635:hover {
  background-color: var(--primary-10) !important;
  color: var(--primary-40) !important;
}

.ant-dropdown-menu-submenu-arrow {
  display: none;
}
.ant-dropdown-menu-item > .ant-dropdown-menu-title-content > .stageTitle {
  width: 12.5rem;
}