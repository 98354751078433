/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_gr547_157 {
  display: flex;
}

._justify-content-space-between_gr547_161 {
  justify-content: space-between;
}

._tabular-nums_gr547_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_gr547_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._displayContainer_gr547_178 {
  width: 100%;
}
._displayContainer_gr547_178 ._link_gr547_181 {
  display: block;
  overflow: hidden;
  color: #3996e0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._displayContainer_gr547_178 ._buttonsContainer_gr547_188 {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  transform: translateX(-0.25rem);
}
._displayContainer_gr547_178 ._buttonsContainer_gr547_188 ._editBtn_gr547_194 {
  display: block;
  height: auto;
  margin-right: 1rem;
  padding: 0.25rem;
  color: #3996e0;
  font-weight: 500;
}
._displayContainer_gr547_178 ._buttonsContainer_gr547_188 ._editBtn_gr547_194:hover {
  background-color: #eff5f9;
}

._inputCounter_gr547_206 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 0.25rem;
  color: #aeaeae;
  font-weight: 600;
  font-size: 0.75rem;
}
._inputCounter_gr547_206._invalidInput_gr547_215 {
  color: #d9534f;
}

._inputContainer_gr547_219 ._urlInput_gr547_219 {
  padding: 0 0 0 0.75rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.375rem;
  background-color: #FAFAFA;
}
._inputContainer_gr547_219 ._urlInput_gr547_219 span {
  padding-right: 0.625rem;
  border-right: 0.0625rem solid #dadcde;
}
._inputContainer_gr547_219 ._urlInput_gr547_219 input {
  padding-left: 0.5rem;
  border-radius: 0.0625rem 0.5rem 0.5rem 0.0625rem !important;
}
._inputContainer_gr547_219 ._urlInput_gr547_219 .ant-input-prefix {
  margin-right: 0;
}
._inputContainer_gr547_219 ._warning_gr547_236 {
  display: flex;
  margin-top: 0.5rem;
}
._inputContainer_gr547_219 ._warning_gr547_236 ._warningLabel_gr547_240 {
  padding-left: 0.5rem;
  color: #7c7c7c;
  font-weight: 500;
  font-size: 0.75rem;
}
._inputContainer_gr547_219 ._warning_gr547_236 ._warningIcon_gr547_246 {
  color: #f1515f;
  font-size: 0.875rem;
  transform: translateY(0.125rem);
}
._inputContainer_gr547_219 ._inputBtnsContainer_gr547_251 {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}
._inputContainer_gr547_219 ._inputBtnsContainer_gr547_251 ._inputBtn_gr547_251 {
  font-weight: 500;
}
._inputContainer_gr547_219 ._inputBtnsContainer_gr547_251 ._inputBtn_gr547_251._cancel_gr547_259 {
  color: #f1515f;
}
._inputContainer_gr547_219 ._inputBtnsContainer_gr547_251 ._inputBtn_gr547_251._save_gr547_262 {
  color: #3996e0;
}
._inputContainer_gr547_219 ._inputBtnsContainer_gr547_251 ._inputBtn_gr547_251._disabled_gr547_265 {
  opacity: 0.25;
}