/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1lmw9_157 {
  display: flex;
}

._justify-content-space-between_1lmw9_161 {
  justify-content: space-between;
}

._tabular-nums_1lmw9_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1lmw9_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ArtifactAssignmentForm_1lmw9_178 {
  display: block;
  padding-top: 1rem;
}
._ArtifactAssignmentForm_1lmw9_178._embedded_1lmw9_182 {
  padding: 0;
}
._ArtifactAssignmentForm_1lmw9_178 ._artifact_assign_header_1lmw9_185 {
  padding-bottom: 2rem;
  color: #1a1818;
  font-style: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-align: center;
}
._ArtifactAssignmentForm_1lmw9_178 ._artifact_assign_header_1lmw9_185._embedded_1lmw9_182 {
  text-align: left;
}
._ArtifactAssignmentForm_1lmw9_178 ._artifact_assign_header_1lmw9_185 h1 {
  color: #1a1818;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: 0.009375rem;
  text-align: center;
}
._ArtifactAssignmentForm_1lmw9_178 ._artifact_assign_header_1lmw9_185 p {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: 0.02777775rem;
}
._ArtifactAssignmentForm_1lmw9_178 ._standard_label_1lmw9_209 {
  display: block;
  padding-bottom: 0.75rem;
  color: #1a1818;
  font-weight: bold;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125rem;
  letter-spacing: 0.00625rem;
}
._ArtifactAssignmentForm_1lmw9_178 ._form_wrap_1lmw9_220 {
  max-width: 20.25rem;
  margin: 0 auto;
}
._ArtifactAssignmentForm_1lmw9_178 ._form_wrap_1lmw9_220._embedded_1lmw9_182 {
  margin: initial;
}
._ArtifactAssignmentForm_1lmw9_178 ._form_wrap_1lmw9_220 ._Select_1lmw9_227 {
  border-radius: 6.25rem;
}
._ArtifactAssignmentForm_1lmw9_178 ._standard_fieldset_1lmw9_230 {
  padding-bottom: 2rem;
}
._ArtifactAssignmentForm_1lmw9_178 ._actions_wrap_1lmw9_233 {
  padding-top: 2rem;
  text-align: center;
}
._ArtifactAssignmentForm_1lmw9_178 ._actions_wrap_1lmw9_233 ._submit_button_1lmw9_237 {
  margin-right: 1rem;
}