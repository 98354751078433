/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1k6ea_157 {
  display: flex;
}

._justify-content-space-between_1k6ea_161 {
  justify-content: space-between;
}

._tabular-nums_1k6ea_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1k6ea_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._PaymentSource_1k6ea_178 {
  margin-bottom: var(--spacing-sm);
}
._PaymentSource_1k6ea_178 ._cardIcon_1k6ea_181 {
  width: 1.875rem;
  margin-right: var(--spacing-lg);
}
._PaymentSource_1k6ea_178 ._removeBtn_1k6ea_185 {
  margin-left: auto;
}
._PaymentSource_1k6ea_178 ._removeBtn_1k6ea_185:not(._visible_1k6ea_188) {
  visibility: hidden;
  pointer-events: none;
}
._PaymentSource_1k6ea_178:hover ._removeBtn_1k6ea_185 {
  visibility: unset;
  pointer-events: unset;
}