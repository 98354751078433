/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_vnc9e_157 {
  display: flex;
}

._justify-content-space-between_vnc9e_161 {
  justify-content: space-between;
}

._tabular-nums_vnc9e_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_vnc9e_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._MessagingApp_vnc9e_209 {
  height: 100%;
  position: relative;
  display: flex;
}
._MessagingApp_vnc9e_209 ._rightPanel_vnc9e_214 {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  flex: 1;
  overflow: hidden;
}
._MessagingApp_vnc9e_209 ._rightPanel_vnc9e_214 ._content_vnc9e_222 {
  display: flex;
  height: 100%;
  overflow-y: auto;
}
._MessagingApp_vnc9e_209 ._rightPanel_vnc9e_214 ._content_vnc9e_222 ._threadList_vnc9e_227 {
  flex-shrink: 0;
  width: 18.75rem;
  border-right: 0.0625rem solid #dadcde;
}
._MessagingApp_vnc9e_209 ._rightPanel_vnc9e_214 ._content_vnc9e_222 ._messageList_vnc9e_232 {
  flex: 1;
  overflow: hidden;
  min-width: 18.75rem;
}
._MessagingApp_vnc9e_209._legacy_vnc9e_237 {
  display: block;
  cursor: pointer;
}
._MessagingApp_vnc9e_209._legacy_vnc9e_237 ._rightPanel_vnc9e_214 {
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: #fdfdfd;
  cursor: auto;
  overflow: inherit;
}
._MessagingApp_vnc9e_209._legacy_vnc9e_237 ._rightPanel_vnc9e_214 ._threadList_vnc9e_227 {
  flex-shrink: 0;
  width: 18.75rem;
  border-right: 0.0625rem solid #dadcde;
}
._MessagingApp_vnc9e_209._legacy_vnc9e_237 ._rightPanel_vnc9e_214 ._messageList_vnc9e_232 {
  flex: 1;
  overflow: hidden;
  min-width: 18.75rem;
}
._MessagingApp_vnc9e_209._refreshHeader_vnc9e_259 {
  height: calc(100vh - 80px) !important;
}