/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_zbziz_157 {
  display: flex;
}

._justify-content-space-between_zbziz_161 {
  justify-content: space-between;
}

._tabular-nums_zbziz_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_zbziz_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._selectProductForm_zbziz_178 {
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 1.375rem 0.6875rem;
  background: #EFF5F9;
}
._selectProductForm_zbziz_178 ._empty_zbziz_188 {
  margin-top: 1.25rem;
}
._selectProductForm_zbziz_178 ._scrollable_zbziz_191 {
  padding: 0rem 0.6875rem;
  flex: 1;
  overflow: hidden;
  background: #EFF5F9;
}
._selectProductForm_zbziz_178 ._scrollable_zbziz_191 ._products_zbziz_197 {
  padding: 1.5rem;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #FDFDFD;
}
._selectProductForm_zbziz_178 ._scrollable_zbziz_191 ._products_zbziz_197 ._title_zbziz_205 {
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.3px;
  text-align: left;
  color: #1A1818;
}
._selectProductForm_zbziz_178 ._footer_zbziz_214 {
  position: relative;
}