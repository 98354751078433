/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_11lye_157 {
  display: flex;
}

._justify-content-space-between_11lye_161 {
  justify-content: space-between;
}

._tabular-nums_11lye_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_11lye_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_11lye_157 {
  display: flex;
}

._justify-content-space-between_11lye_161 {
  justify-content: space-between;
}

._tabular-nums_11lye_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_11lye_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._wrapper_11lye_355 ._workflowButton_11lye_355 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._wrapper_11lye_355 ._workflowButton_11lye_355 {
  min-width: 2.25rem;
}
._wrapper_11lye_355 ._workflowButton_11lye_355 > svg:first-of-type:not(:only-child) {
  margin-right: 0.25rem;
}

._wrapper_11lye_355 ._workflowButton_11lye_355:focus, ._wrapper_11lye_355 ._workflowButton_11lye_355:hover {
  border-color: #167cf4;
  color: #167cf4;
}

._BulkAppAction_11lye_377 ._label_11lye_377 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._BulkAppAction_11lye_377 ._label_11lye_377 ._arrow_11lye_382 {
  margin-left: 0.3125rem;
  color: #a2a1a4;
}
._BulkAppAction_11lye_377._active_11lye_386 ._arrow_11lye_382, ._BulkAppAction_11lye_377:hover ._arrow_11lye_382 {
  color: inherit;
}

._menuItem_11lye_390 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.375rem 0.75rem !important;
}
._menuItem_11lye_390 ._menuText_11lye_396 {
  padding-left: 0.625rem;
}
._menuItem_11lye_390._menuItemWorkflowEnabled_11lye_399 ._menuText_11lye_396 {
  padding-left: 0;
}

._menu_11lye_390 .ant-dropdown-menu-submenu-title,
._menu_11lye_390 .ant-dropdown-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}
._menu_11lye_390 .ant-dropdown-menu-submenu-arrow,
._menu_11lye_390 .ant-dropdown-menu-submenu-arrow-icon {
  font-size: 1.125rem !important;
}

._wrapper_11lye_355._disabled_11lye_415 ._workflowButton_11lye_355 {
  background-color: #fdfdfd !important;
  cursor: no-drop;
  pointer-events: none;
}
._wrapper_11lye_355 ._workflowButton_11lye_355:hover, ._wrapper_11lye_355 ._workflowButton_11lye_355._active_11lye_386 {
  background: #fafafa;
}