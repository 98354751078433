/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_8tiza_157 {
  display: flex;
}

._justify-content-space-between_8tiza_161 {
  justify-content: space-between;
}

._tabular-nums_8tiza_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_8tiza_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_8tiza_157 {
  display: flex;
}

._justify-content-space-between_8tiza_161 {
  justify-content: space-between;
}

._tabular-nums_8tiza_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_8tiza_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._dashboard_8tiza_355 {
  padding-top: 1rem;
  padding-bottom: 1.75rem;
}
._dashboard_8tiza_355 ._header_8tiza_359 ._headerCol_8tiza_359 ._count_8tiza_359 {
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5625rem;
  text-align: left;
}
._dashboard_8tiza_355 ._nameCell_8tiza_366 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._dashboard_8tiza_355 ._nameCell_8tiza_366 ._avatar_8tiza_371 {
  margin-right: 0.5rem;
}
._dashboard_8tiza_355 ._searchContainer_8tiza_374 {
  display: flex;
  width: 20.0625rem;
}