/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1vapo_157 {
  display: flex;
}

._justify-content-space-between_1vapo_161 {
  justify-content: space-between;
}

._tabular-nums_1vapo_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1vapo_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._EmailApp_1vapo_178 ._header_1vapo_178 ._title_1vapo_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._EmailApp_1vapo_178 ._header_1vapo_178 ._nav_1vapo_188 ._item_1vapo_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._EmailApp_1vapo_178 {
  height: 100%;
  background: #fdfdfd;
}
._EmailApp_1vapo_178 ._header_1vapo_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4.625rem;
  padding: 0 2.625rem;
  border-bottom: 0.0625rem solid #e5e5e5;
}
._EmailApp_1vapo_178 ._header_1vapo_178 ._icon_1vapo_241 {
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin-right: 0.75rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
._EmailApp_1vapo_178 ._header_1vapo_178 ._icon_1vapo_241._gmail_1vapo_250 {
  background-image: url("/etc/aspirex/app/config/esbuild/../../src/app/assets/svgs/gmail.svg");
}
._EmailApp_1vapo_178 ._header_1vapo_178 ._icon_1vapo_241._outlook_1vapo_253 {
  background-image: url("/etc/aspirex/app/config/esbuild/../../src/app/assets/svgs/outlook.svg");
}
._EmailApp_1vapo_178 ._header_1vapo_178 ._outlook_icon_1vapo_256 {
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin-right: 0.75rem;
  background: url("/etc/aspirex/app/config/esbuild/../../src/app/assets/svgs/outlook.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
._EmailApp_1vapo_178 ._header_1vapo_178 ._title_1vapo_178 {
  margin-right: 3rem;
}
._EmailApp_1vapo_178 ._header_1vapo_178 ._nav_1vapo_188 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._EmailApp_1vapo_178 ._header_1vapo_178 ._nav_1vapo_188 ._item_1vapo_188 {
  position: relative;
  margin-right: 3.125rem;
  color: #8f8d91;
  cursor: pointer;
  transition: color 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: color;
}
._EmailApp_1vapo_178 ._header_1vapo_178 ._nav_1vapo_188 ._item_1vapo_188::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  padding-top: 1.375rem;
  border-bottom: 0.1875rem solid #3996e0; /* This creates the border. Replace black with whatever color you want. */
  transition: transform 0.1s cubic-bezier(0.4, 0, 1, 1);
  transform: scaleX(0);
  transform-origin: center;
}
._EmailApp_1vapo_178 ._header_1vapo_178 ._nav_1vapo_188 ._item_1vapo_188:hover, ._EmailApp_1vapo_178 ._header_1vapo_178 ._nav_1vapo_188 ._item_1vapo_188._active_1vapo_293 {
  color: #3996e0;
}
._EmailApp_1vapo_178 ._header_1vapo_178 ._nav_1vapo_188 ._item_1vapo_188:hover::after, ._EmailApp_1vapo_178 ._header_1vapo_178 ._nav_1vapo_188 ._item_1vapo_188._active_1vapo_293::after {
  transform: scaleX(1);
}
._EmailApp_1vapo_178 ._header_1vapo_178 ._nav_1vapo_188 ._item_1vapo_188 a {
  color: inherit;
}
._EmailApp_1vapo_178 ._children_1vapo_302 {
  padding: 2rem 2.625rem;
}