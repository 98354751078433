/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_tpky4_157 {
  display: flex;
}

._justify-content-space-between_tpky4_161 {
  justify-content: space-between;
}

._tabular-nums_tpky4_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_tpky4_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._DeliverableList_tpky4_209 .ant-space-item:nth-of-type(1) {
  width: 27%;
}
._DeliverableList_tpky4_209 .ant-space-item:nth-of-type(2) {
  width: 15%;
}
._DeliverableList_tpky4_209 ._input_tpky4_215._error_tpky4_215 {
  border-color: #f5222d;
}