/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_m780s_157 {
  display: flex;
}

._justify-content-space-between_m780s_161 {
  justify-content: space-between;
}

._tabular-nums_m780s_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_m780s_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._PaymentTable_m780s_178 {
  padding-top: 1.5rem;
}
._PaymentTable_m780s_178 ._mainTitle_m780s_181 {
  position: absolute;
  top: -1.125rem;
  padding-top: 1.75rem;
  color: #1a1818;
  font-weight: bold;
  font-style: normal;
  font-size: 1.25rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.5rem;
  letter-spacing: 0.009375rem;
  display: flex;
  gap: 0.5rem;
}
._PaymentTable_m780s_178 ._exportButton_m780s_195 {
  margin-left: 1rem;
}

._drawerHeader_m780s_199 {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}
._drawerHeader_m780s_199 ._icon_m780s_205 {
  background-color: #723814;
  display: flex;
  align-items: center;
  justify-content: center;
}

._drawerFooter_m780s_212 {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

._drawerBodyContainer_m780s_219 {
  width: 100%;
  display: flex;
  justify-content: center;
}
._drawerBodyContainer_m780s_219 ._drawerBodyMain_m780s_224 {
  width: 50%;
  height: 100px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
._drawerBodyContainer_m780s_219 ._drawerBodyMain_m780s_224 ._desription_m780s_231 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}