/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_mbrpm_157 {
  display: flex;
}

._justify-content-space-between_mbrpm_161 {
  justify-content: space-between;
}

._tabular-nums_mbrpm_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_mbrpm_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SegmentFolderList_mbrpm_178 ._segment_mbrpm_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._SegmentFolderList_mbrpm_178 {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  padding: 0.5rem;
}
._SegmentFolderList_mbrpm_178::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
._SegmentFolderList_mbrpm_178 ._btnWrapper_mbrpm_198 {
  margin-bottom: 1.25rem;
  padding: 0 1.875rem 0 0.9375rem;
}
._SegmentFolderList_mbrpm_178 ._count_mbrpm_202 {
  margin-left: auto;
  color: #8f8d91;
}
._SegmentFolderList_mbrpm_178 ._folder_mbrpm_206 {
  margin-bottom: 0.25rem;
}
._SegmentFolderList_mbrpm_178 ._segment_mbrpm_178 {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-weight: 500;
  letter-spacing: 0;
  cursor: pointer;
}
._SegmentFolderList_mbrpm_178 ._segment_mbrpm_178:hover {
  background-color: #eff5f9;
}
._SegmentFolderList_mbrpm_178 ._segment_mbrpm_178._selected_mbrpm_222 {
  background-color: #eff5f9;
}
._SegmentFolderList_mbrpm_178 ._segment_mbrpm_178._selected_mbrpm_222, ._SegmentFolderList_mbrpm_178 ._segment_mbrpm_178:hover {
  color: #0075FF;
}
._SegmentFolderList_mbrpm_178 ._segment_mbrpm_178._selected_mbrpm_222 ._count_mbrpm_202, ._SegmentFolderList_mbrpm_178 ._segment_mbrpm_178:hover ._count_mbrpm_202 {
  color: #0075FF;
}
._SegmentFolderList_mbrpm_178 ._segment_mbrpm_178 ._icon_mbrpm_231 {
  margin-right: 0.625rem;
}
._SegmentFolderList_mbrpm_178 ._divider_mbrpm_234 {
  height: 0.0625rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
  background-color: #e9e8ea;
}
._SegmentFolderList_mbrpm_178 ._icon_mbrpm_231 {
  color: #0075FF;
}
._SegmentFolderList_mbrpm_178 ._tagIcon_mbrpm_245 {
  color: #da3737;
}
._SegmentFolderList_mbrpm_178 ._starIcon_mbrpm_248 {
  color: #eacd60;
}
._SegmentFolderList_mbrpm_178 ._widgetsIcon_mbrpm_251 {
  margin-left: -0.125rem;
}
._SegmentFolderList_mbrpm_178 ._bookmarkIcon_mbrpm_254 {
  color: #A77EE9;
}