/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_db56w_157 {
  display: flex;
}

._justify-content-space-between_db56w_161 {
  justify-content: space-between;
}

._tabular-nums_db56w_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_db56w_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_db56w_157 {
  display: flex;
}

._justify-content-space-between_db56w_161 {
  justify-content: space-between;
}

._tabular-nums_db56w_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_db56w_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ListDrawer_db56w_355 ._title_db56w_355 ._controls_db56w_355 ._settingsIcon_db56w_355, ._ListDrawer_db56w_355 ._title_db56w_355 ._controls_db56w_355 ._settingsIcon_db56w_355 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._ListDrawer_db56w_355 ._title_db56w_355 ._controls_db56w_355 ._settingsIcon_db56w_355 {
  min-width: 2.25rem;
}
._ListDrawer_db56w_355 ._title_db56w_355 ._controls_db56w_355 ._settingsIcon_db56w_355 > svg:first-of-type:not(:only-child) {
  margin-right: 0.25rem;
}

._ListDrawer_db56w_355 {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--white);
  pointer-events: none;
}
._ListDrawer_db56w_355 ._divider_db56w_380 {
  margin: 0.875rem 0;
}
._ListDrawer_db56w_355._loading_db56w_383 {
  pointer-events: none;
}
._ListDrawer_db56w_355 ._drawer_db56w_386 {
  position: inherit;
  z-index: 1;
  height: 100%;
  margin-left: 0 !important;
  background-color: #fff;
  pointer-events: all;
}
._ListDrawer_db56w_355 ._title_db56w_355 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem 2rem 0;
}
._ListDrawer_db56w_355 ._title_db56w_355 h1 {
  margin: 0;
  font-size: 1.375rem;
}
._ListDrawer_db56w_355 ._title_db56w_355 ._controls_db56w_355 {
  display: flex;
  flex-direction: row;
}
._ListDrawer_db56w_355 ._tableWrapper_db56w_408 {
  position: relative;
  flex: 1;
}
._ListDrawer_db56w_355 ._tableWrapper_db56w_408 ._loadSpinner_db56w_412 {
  margin-top: 15%;
}
._ListDrawer_db56w_355 ._empty_db56w_415 {
  margin: 25% auto 0;
  color: #8f8d91;
  text-align: center;
}

._filterList_db56w_421 {
  margin-top: 0.5rem;
  padding: 0 2rem;
}

._membersTable_db56w_426 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: unset !important;
  transition: opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1);
}
._membersTable_db56w_426._loading_db56w_383 {
  opacity: 0.4;
}
._membersTable_db56w_426._loading_db56w_383 * {
  pointer-events: none;
}
._membersTable_db56w_426 ._tableHeader_db56w_441 {
  margin-bottom: 2rem;
  transition: opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1);
}
._membersTable_db56w_426 ._tableHeader_db56w_441._loading_db56w_383 {
  opacity: 0.4;
}
._membersTable_db56w_426 ._tableHeader_db56w_441._loading_db56w_383 * {
  pointer-events: none;
}
._membersTable_db56w_426 ._bodyRow_db56w_451 {
  background-color: transparent;
}
._membersTable_db56w_426 ._checkboxCell_db56w_454 > div {
  width: 100%;
  height: 100%;
  padding: 0;
}
._membersTable_db56w_426 ._checkboxCell_db56w_454 > div > label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
._membersTable_db56w_426 ._nameHeaderCell_db56w_466 > div {
  padding-left: 4rem;
}
._membersTable_db56w_426 ._singleCTAButton_db56w_469 {
  padding-right: 0;
  padding-left: 0;
}

._headerCell_db56w_474 {
  font-weight: unset;
  font-size: 0.875rem;
  letter-spacing: 0.015625rem;
  text-transform: unset;
  cursor: default;
}

:root {
  --spacing-xxxl: 80px;
  --spacing-xxl: 48px;
  --spacing-xl: 32px;
  --spacing-lg: 24px;
  --spacing-md: 16px;
  --spacing-sm: 12px;
  --spacing-xs: 8px;
  --spacing-xxs: 4px;
}

._empty_db56w_415 {
  max-width: 28.75rem;
  margin-top: 15% !important;
  font-size: 1rem;
  line-height: 1.5rem;
}

._title_db56w_355 {
  padding: 32px 32px 0 !important;
  display: flex !important;
  flex-direction: column !important;
}

._memberDetailsDrawer_db56w_517 {
  width: 100%;
}

._content_db56w_521 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow: hidden;
}

._tableHeader_db56w_441 {
  padding-left: 1.5rem;
}