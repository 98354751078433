/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_n183v_157 {
  display: flex;
}

._justify-content-space-between_n183v_161 {
  justify-content: space-between;
}

._tabular-nums_n183v_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_n183v_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._container_n183v_178 {
  margin-bottom: 2.5rem;
}
._container_n183v_178 ._radioGroupContainer_n183v_181 {
  display: flex;
  flex-direction: column;
}
._container_n183v_178 ._radioGroupContainer_n183v_181 .ant-checkbox-group-item {
  margin-bottom: 0.75rem;
}
._container_n183v_178 ._hasError_n183v_188 .ant-input {
  border-color: #d9534f;
  box-shadow: 0 0 0 0.125rem rgba(217, 83, 79, 0.2);
}