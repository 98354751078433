._container_11qkq_1 {
  width: 100%;
  padding: var(--spacing-lg);
  justify-content: center;
}

._title_11qkq_7 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

._tooltip_11qkq_14 {
  margin-left: 2px;
}

._value_11qkq_18 .ant-statistic-title {
  color: var(--gray-9);
  font-size: var(--font-size-paragraph);
  font-weight: var(--font-weight-semibold);
  order: 0;
  margin-bottom: var(--spacing-md);
}
._value_11qkq_18 .ant-statistic-title .anticon {
  color: var(--gray-7);
  margin-left: var(--spacing-xxs);
}
._value_11qkq_18 .ant-statistic-content {
  font-size: 86px;
  line-height: 1;
}

._statistic_11qkq_34 .ant-statistic-title {
  color: var(--gray-9);
  font-weight: var(--font-weight-semibold);
  order: 0;
  margin-bottom: 0;
}
._statistic_11qkq_34 .ant-statistic-content {
  font-size: var(--font-size-xl);
}
._statistic_11qkq_34 .ant-statistic-content-prefix {
  margin-right: 0;
}

._trend_11qkq_47 {
  padding: calc(var(--spacing-xxs) / 2) var(--spacing-xs);
}
._trend_11qkq_47 .ant-statistic-content {
  font-size: var(--font-size-paragraph);
  font-weight: var(--font-weight-regular);
}
._trend_11qkq_47 .ant-statistic-content-suffix {
  margin: 0;
}