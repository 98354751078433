/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1qh8e_157 {
  display: flex;
}

._justify-content-space-between_1qh8e_161 {
  justify-content: space-between;
}

._tabular-nums_1qh8e_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1qh8e_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SegmentFolder_1qh8e_178 ._header_1qh8e_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._SegmentFolder_1qh8e_178 {
  user-select: none;
}
._SegmentFolder_1qh8e_178._editable_1qh8e_191 ._header_1qh8e_178:hover ._editIcon_1qh8e_191 {
  display: block;
}
._SegmentFolder_1qh8e_178 ._editIcon_1qh8e_191 {
  position: absolute;
  top: 50%;
  right: 1.5625rem;
  display: none;
  color: #0075FF;
  transform: translateY(-50%);
}
._SegmentFolder_1qh8e_178 ._editIcon_1qh8e_191 > * {
  vertical-align: middle;
}
._SegmentFolder_1qh8e_178 ._title_1qh8e_205 {
  flex: auto;
  overflow: hidden;
}
._SegmentFolder_1qh8e_178 ._header_1qh8e_178 {
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
}
._SegmentFolder_1qh8e_178 ._header_1qh8e_178 ._icon_1qh8e_218 {
  flex: none;
  margin-right: 0.625rem;
  display: flex;
  align-items: center;
}
._SegmentFolder_1qh8e_178 ._header_1qh8e_178 ._arrow_1qh8e_224 {
  flex: auto;
  justify-self: flex-end;
  font-weight: lighter;
  text-align: right;
  font-size: 0.625rem;
}
._SegmentFolder_1qh8e_178 ._header_1qh8e_178:hover {
  background-color: #eff5f9;
  color: #0075FF;
}
._SegmentFolder_1qh8e_178 ._segmentList_1qh8e_235 {
  display: none;
}
._SegmentFolder_1qh8e_178._expanded_1qh8e_238 ._segmentList_1qh8e_235 {
  display: block;
}
._SegmentFolder_1qh8e_178 ._segment_1qh8e_235 {
  margin-top: 0.125rem;
}
._SegmentFolder_1qh8e_178 ._segment_1qh8e_235:last-child {
  margin-bottom: 0.625rem;
}