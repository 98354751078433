:root {
  --primary: #167cf4;
  --primary-40: #3d73d7;
  --primary-10: #e6f7ff;
  --secondary: #f58984;
  --secondary-40: #fcbbae;
  --secondary-10: #fef0ed;
  --bay-grey: #989ea8;
  --grey-10: #fafafa;
  --grey-25: #e9e8ea;
  --grey-50: #aeaeae;
  --grey-75: #8f8f8f;
  --grey-100: #1a1b1c;
  --white: #fff;
  --black: #1a1b1c;
  --green: #006462;
  --sand: #f6f3ef;
  --yellow: #ffcd5b;
  --magenta-base: #ffddff;
  --red-base: #f5b3b4;
  --volcano-base: #ff9c93;
  --orange-base: #fabf95;
  --gold-base: #f1e5ac;
  --lime-base: #ebffd0;
  --sage-base: #c1e1c1;
  --cyan-base: #c9f2f2;
  --blue-base: #70d1f1;
  --geekblue-base: #a2aff1;
  --purple-base: #c5b3e3;
  --mauve-base: #c6b0ca;
  --coral-base: #ff8a8a;
  --apricot-base: #f5a287;
  --marigold-base: #fdaf1c;
  --butter-base: #f9eda1;
  --apple-base: #b7d275;
  --asparagus-base: #9db17c;
  --tiffany-base: #5fcdca;
  --baby-base: #a3ddf9;
  --carolina-base: #7eb3d8;
  --rose-base: #ffb4c7;
  --watermelon-base: #ffa1b6;
  --blue-0: #f4fcff;
  --blue-1: #e6f7ff;
  --blue-3: #91d5ff;
  --blue-5: #499dff;
  --blue-6: #167cf4;
  --blue-7: #2771c9;
  --gray-1: #fff;
  --gray-2: #fafafa;
  --gray-3: #f5f5f5;
  --gray-4: #f0f0f0;
  --gray-5: #d9d9d9;
  --gray-6: #bfbfbf;
  --gray-7: #8c8c8c;
  --gray-8: #505256;
  --gray-9: #1f1f21;
  --red-1: #fff1f0;
  --red-3: #ffa39e;
  --red-4: #ff7875;
  --red-5: #ff4d4f;
  --red-6: #f5222d;
  --gold-1: #fffbe6;
  --gold-3: #ffe58f;
  --gold-5: #ffc53d;
  --gold-6: #faad14;
  --gold-7: #d48806;
  --green-1: #f6ffed;
  --green-3: #b7eb8f;
  --green-5: #73d13d;
  --green-6: #52c41a;
  --green-7: #389e0d;
  --text-color: #505256;
  --text-color-secondary: #8c8c8c;
  --text-color-inverse: #fff;
  --icon-color: inherit;
  --icon-color-hover: rgba(26, 27, 28, 0.75);
  --heading-color: #1f1f21;
  --text-color-dark: rgba(255, 255, 255, 0.85);
  --text-color-secondary-dark: rgba(255, 255, 255, 0.65);
  --text-selection-bg: #167cf4;
  --link-color: #167cf4;
  --modal-mask-bg: rgba(79, 90, 114, 0.45);
}

:root {
  --screen-xs: 480px;
  --screen-sm: 576px;
  --screen-md: 768px;
  --screen-lg: 992px;
  --screen-xl: 1200px;
  --screen-xxl: 1600px;
}

:root {
  --spacing-xxxl: 80px;
  --spacing-xxl: 48px;
  --spacing-xl: 32px;
  --spacing-lg: 24px;
  --spacing-md: 16px;
  --spacing-sm: 12px;
  --spacing-xs: 8px;
  --spacing-xxs: 4px;
}

:root {
  --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --code-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  --font-size-paragraph: 16px;
  --font-size-base: 14px;
  --font-size-xl: 20px;
  --font-size-lg: 16px;
  --font-size-sm: 12px;
  --font-weight-regular: 400;
  --font-weight-semibold: 600;
}

:root {
  --border-radius-max: 9999px;
  --border-radius-lg: 12px;
  --border-radius-base: 6px;
  --border-radius-sm: 4px;
  --border-radius-xs: 2px;
  --height-lg: 40px;
  --height-base: 32px;
  --height-sm: 24px;
}

._StatisticsCard_basl6_255 {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #fff;
  padding: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
._StatisticsCard_basl6_255 > * + * {
  margin-block-start: 24px;
}
._StatisticsCard_basl6_255 ._headerRow_basl6_269 {
  flex: 0 0 auto;
  min-height: 1px;
}
._StatisticsCard_basl6_255 ._headerRow_basl6_269 ._header_basl6_269 {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
._StatisticsCard_basl6_255 ._headerRow_basl6_269 ._header_basl6_269 h5 {
  margin-bottom: 0;
}
._StatisticsCard_basl6_255 ._headerRow_basl6_269 ._header_basl6_269 ._subtitle_basl6_285 {
  color: #8c8c8c;
  font-weight: 400;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-inline-start: 8px;
}
._StatisticsCard_basl6_255 ._headerRow_basl6_269 ._header_basl6_269 > * + * {
  margin-inline-start: 8px;
}
._StatisticsCard_basl6_255 ._headerRow_basl6_269 ._header_basl6_269 .anticon {
  color: #1f1f21;
  font-size: 16px;
}
._StatisticsCard_basl6_255 ._headerRow_basl6_269 ._header_basl6_269 .ant-skeleton-title {
  margin: 0;
}
._StatisticsCard_basl6_255 ._headerRow_basl6_269 ._infoWrapper_basl6_304 {
  align-items: baseline;
  color: #8c8c8c;
  flex: 0 0 auto;
  font-size: 16px;
  transition: opacity 0.5s ease-in-out;
}
._StatisticsCard_basl6_255 ._headerRow_basl6_269 ._infoWrapper_basl6_304._loading_basl6_311 {
  opacity: 0;
}
._StatisticsCard_basl6_255 ._headerRow_basl6_269 .ant-skeleton {
  max-width: 200px;
}
._StatisticsCard_basl6_255 ._statsRow_basl6_317 {
  flex: 1;
}
._StatisticsCard_basl6_255 ._statsRow_basl6_317._loading_basl6_311._largeStats_basl6_320 {
  height: 122px;
}
._StatisticsCard_basl6_255 ._statsRow_basl6_317._loading_basl6_311._smallStats_basl6_323 {
  height: 95px;
}
._StatisticsCard_basl6_255 ._statsRow_basl6_317 ._statsCol_basl6_326 {
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;
}
._StatisticsCard_basl6_255 ._statsRow_basl6_317 ._errorWrapper_basl6_331 {
  color: #f5222d;
}
._StatisticsCard_basl6_255 ._statsRow_basl6_317 ._errorWrapper_basl6_331._large_basl6_320 {
  font-size: 30px;
  height: 48px;
}
._StatisticsCard_basl6_255 ._linksRow_basl6_338 {
  justify-content: center;
}
._StatisticsCard_basl6_255 ._linksRow_basl6_338 ._linkColumn_basl6_341 {
  flex: 0 0 auto;
  text-align: center;
  width: 100%;
  padding: 0 8px;
}
._StatisticsCard_basl6_255 ._linksRow_basl6_338 ._linkColumn_basl6_341._twoColumn_basl6_347 {
  width: 50%;
}
._StatisticsCard_basl6_255 ._linksRow_basl6_338 ._linkColumn_basl6_341._twoColumn_basl6_347:first-child {
  text-align: right;
}
._StatisticsCard_basl6_255 ._linksRow_basl6_338 ._linkColumn_basl6_341._twoColumn_basl6_347:last-child {
  text-align: left;
}
._StatisticsCard_basl6_255 ._linksRow_basl6_338 ._linkColumn_basl6_341._twoColumn_basl6_347:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}
._StatisticsCard_basl6_255 ._linksRow_basl6_338 ._linkColumn_basl6_341._loading_basl6_311 {
  height: 32px;
}
._StatisticsCard_basl6_255 ._customContentWrapper_basl6_362 {
  flex: 1 1 auto;
  overflow: auto;
  animation: _visible_basl6_1 0.5s linear;
}
@media only screen and (min-width: 992px) {
  ._StatisticsCard_basl6_255 ._customContentWrapper_basl6_362 {
    display: flex;
    height: 322px;
  }
}

._infoWrapper_basl6_304 {
  align-items: baseline;
  color: #8c8c8c;
  flex: 0 0 auto;
  font-size: 16px;
  transition: opacity 0.5s ease-in-out;
}
._infoWrapper_basl6_304._loading_basl6_311 {
  opacity: 0;
}

@keyframes _visible_basl6_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._refreshOverride_basl6_393 {
  border: 1px solid hsl(var(--border));
  border-radius: 1rem;
  padding: 0;
}