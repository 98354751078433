/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1dgfa_157 {
  display: flex;
}

._justify-content-space-between_1dgfa_161 {
  justify-content: space-between;
}

._tabular-nums_1dgfa_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1dgfa_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1dgfa_157 {
  display: flex;
}

._justify-content-space-between_1dgfa_161 {
  justify-content: space-between;
}

._tabular-nums_1dgfa_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1dgfa_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._button_1dgfa_355 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._button_1dgfa_355 {
  min-width: 2.25rem;
}
._button_1dgfa_355 > svg:first-of-type:not(:only-child) {
  margin-right: 0.25rem;
}

._button_1dgfa_355:focus, ._button_1dgfa_355:hover {
  border-color: #167cf4;
  color: #167cf4;
}

._TableHeader_1dgfa_377 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -0.5rem;
}
._TableHeader_1dgfa_377 ._count_1dgfa_384 {
  margin: 0.5rem 1.5rem 1rem 0;
}
._TableHeader_1dgfa_377 ._buttonRow_1dgfa_387 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-right: auto;
}
._TableHeader_1dgfa_377 ._right_1dgfa_394 {
  flex-wrap: wrap;
  align-self: flex-end;
  margin-left: 0.5rem;
}

._button_1dgfa_355,
._buttonTooltip_1dgfa_401,
._primaryCTA_1dgfa_402 {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
._button_1dgfa_355 ._icon_1dgfa_406:not(:only-child),
._buttonTooltip_1dgfa_401 ._icon_1dgfa_406:not(:only-child),
._primaryCTA_1dgfa_402 ._icon_1dgfa_406:not(:only-child) {
  margin-right: 0.25rem;
}
._button_1dgfa_355._isPrimaryTheme_1dgfa_411:hover, ._button_1dgfa_355._isPrimaryTheme_1dgfa_411:focus,
._buttonTooltip_1dgfa_401._isPrimaryTheme_1dgfa_411:hover,
._buttonTooltip_1dgfa_401._isPrimaryTheme_1dgfa_411:focus,
._primaryCTA_1dgfa_402._isPrimaryTheme_1dgfa_411:hover,
._primaryCTA_1dgfa_402._isPrimaryTheme_1dgfa_411:focus {
  color: #fff !important;
}

._button_1dgfa_355._disabled_1dgfa_419 {
  background-color: #fdfdfd !important;
  cursor: no-drop;
  pointer-events: none;
}