/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1dsky_157 {
  display: flex;
}

._justify-content-space-between_1dsky_161 {
  justify-content: space-between;
}

._tabular-nums_1dsky_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1dsky_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ContextPicker_1dsky_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-md);
  cursor: pointer;
  transition: background-color var(--animation-duration-base) var(--ease-in-out-quint);
}
._ContextPicker_1dsky_178 > div {
  display: flex;
  align-items: center;
  min-width: 0;
}
._ContextPicker_1dsky_178:hover, ._ContextPicker_1dsky_178._active_1dsky_191 {
  background-color: var(--grey-25) !important;
}
._ContextPicker_1dsky_178._disabled_1dsky_194 {
  opacity: 0.6;
  pointer-events: none;
}
._ContextPicker_1dsky_178 ._selectIcon_1dsky_198 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48;
  height: 48;
  margin-right: var(--spacing-sm);
  border-radius: var(--border-radius-base);
}
._ContextPicker_1dsky_178 ._selectLabel_1dsky_207 {
  flex: 1;
  min-width: 0;
  margin: 0;
  word-break: break-word;
}
._ContextPicker_1dsky_178 ._downIcon_1dsky_213 {
  margin: 0 0 0 var(--spacing-xs);
}
._ContextPicker_1dsky_178._active_1dsky_191 ._downIcon_1dsky_213 {
  transform: rotateZ(180deg);
}

._overlay_1dsky_220 {
  width: 18.75rem;
  background-color: var(--white);
}
._overlay_1dsky_220 ._menu_1dsky_224 {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(3rem * 8 + 3.75rem);
  padding-bottom: 0;
}
._overlay_1dsky_220 ._menu_1dsky_224 ._menuFooter_1dsky_230 {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3.75rem;
  background-color: var(--white);
}
._overlay_1dsky_220 ._menu_1dsky_224 ._menuFooter_1dsky_230 ._footerDivider_1dsky_243 {
  width: 100%;
  margin-right: 0 !important;
  margin-left: 0 !important;
}
._overlay_1dsky_220 ._menu_1dsky_224 ._item_1dsky_248 > .ant-dropdown-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
._overlay_1dsky_220 ._menu_1dsky_224 ._item_1dsky_248 > .ant-dropdown-menu-title-content {
  overflow: hidden;
}
._overlay_1dsky_220 ._menu_1dsky_224 ._item_1dsky_248 > .ant-dropdown-menu-title-content > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
._overlay_1dsky_220 ._menu_1dsky_224 ._item_1dsky_248._selected_1dsky_263, ._overlay_1dsky_220 ._menu_1dsky_224 ._item_1dsky_248:hover {
  background-color: var(--primary-10);
}
._overlay_1dsky_220 ._menu_1dsky_224 ._item_1dsky_248 ._icon_1dsky_266 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32;
  min-width: 32;
  height: 32;
  margin-right: var(--spacing-xs);
}
._overlay_1dsky_220 ._menu_1dsky_224 ._item_1dsky_248 ._icon_1dsky_266 > svg {
  display: block;
}
._overlay_1dsky_220 ._menu_1dsky_224 ._item_1dsky_248 ._itemLabel_1dsky_278 {
  display: block;
  flex: 1;
  overflow: hidden;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}