._Comments_3exlk_1 {
  height: 100%;
  display: flex;
  flex-direction: column;
}
._Comments_3exlk_1 ._divider_3exlk_6 {
  flex-shrink: 0;
}
._Comments_3exlk_1 ._comments_3exlk_9 {
  padding-right: 12px;
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
._Comments_3exlk_1 ._comments_3exlk_9 ._comment_3exlk_9 {
  border-radius: 6px;
}
._Comments_3exlk_1 ._comments_3exlk_9 ._comment_3exlk_9._default_3exlk_20 {
  background: #FAFAFA;
  border: 1px solid #F0F0F0;
}
._Comments_3exlk_1 ._comments_3exlk_9 ._comment_3exlk_9 p {
  margin: 0;
}
._Comments_3exlk_1 ._comments_3exlk_9 ._comment_3exlk_9 ul {
  padding: revert;
  list-style-type: disc;
}
._Comments_3exlk_1 ._comments_3exlk_9 ._comment_3exlk_9 ol {
  padding: revert;
  list-style-type: decimal;
}
._Comments_3exlk_1 ._guidelines_3exlk_35 {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
._Comments_3exlk_1 ._reply_3exlk_41 {
  flex-shrink: 0;
}
._Comments_3exlk_1 ._actionButton_3exlk_44 {
  position: absolute;
  right: 18px;
  bottom: 9px;
  width: 118px;
  display: flex;
  gap: 8px;
}

._commentEditor_3exlk_53 .ql-editing {
  left: 0 !important;
}
._commentEditor_3exlk_53 .quill {
  display: flex;
  flex-direction: column-reverse;
}
._commentEditor_3exlk_53 .ql-toolbar {
  border-bottom: none;
  border-top: 1px solid #d9d9d9;
  padding: 12px;
}

._instructionDesc_3exlk_66 .ant-typography-secondary {
  font-size: 14px;
}

._feedbackFormCollapsePanel_3exlk_70 {
  border: none;
}