/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1ce5w_157 {
  display: flex;
}

._justify-content-space-between_1ce5w_161 {
  justify-content: space-between;
}

._tabular-nums_1ce5w_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1ce5w_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._BrandSocialLinks_1ce5w_178 {
  width: 95%;
}
._BrandSocialLinks_1ce5w_178 ._description_1ce5w_181 {
  margin-top: 0.8125rem;
  margin-bottom: 1.375rem;
  color: #8c8c8c;
}
._BrandSocialLinks_1ce5w_178 ._links_input_1ce5w_186 ._link_1ce5w_186 {
  margin-bottom: 0.625rem;
}
._BrandSocialLinks_1ce5w_178 ._links_input_1ce5w_186 ._link_1ce5w_186 input {
  text-overflow: ellipsis;
}
._BrandSocialLinks_1ce5w_178 ._links_input_1ce5w_186 ._link_1ce5w_186 svg {
  vertical-align: middle;
}