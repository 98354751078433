/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_npg69_157 {
  display: flex;
}

._justify-content-space-between_npg69_161 {
  justify-content: space-between;
}

._tabular-nums_npg69_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_npg69_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._CustomFieldsList_npg69_178 ._title_npg69_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._CustomFieldsList_npg69_178 ._description_npg69_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._CustomFieldsList_npg69_178 ._label_npg69_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._CustomFieldsList_npg69_178 ._title_npg69_178 {
  margin-bottom: 0.625rem;
}
._CustomFieldsList_npg69_178 ._description_npg69_188 {
  margin-top: 0.625rem;
}
._CustomFieldsList_npg69_178 ._label_npg69_198 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2.5rem;
  margin-bottom: 1.5625rem;
}
._CustomFieldsList_npg69_178 ._customField_npg69_221 {
  margin-bottom: 0.9375rem;
}
._CustomFieldsList_npg69_178 ._newBtn_npg69_224 {
  margin-left: auto;
}

._name_npg69_228 {
  white-space: normal !important;
}