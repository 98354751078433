/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_cjfr2_157 {
  display: flex;
}

._justify-content-space-between_cjfr2_161 {
  justify-content: space-between;
}

._tabular-nums_cjfr2_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_cjfr2_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._emptyContent_cjfr2_178 ._title_cjfr2_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._emptyContent_cjfr2_178 ._text_cjfr2_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._emptyContent_cjfr2_178 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 5.5rem;
}
._emptyContent_cjfr2_178 ._title_cjfr2_178 {
  margin-bottom: 1rem;
}
._emptyContent_cjfr2_178 ._text_cjfr2_188 {
  margin-bottom: 1.5rem;
  color: #8c8c8c;
}
._emptyContent_cjfr2_178 ._mailIcon_cjfr2_243 {
  font-size: 5rem;
  margin-bottom: 2.25rem;
  color: #f0f0f0;
}