/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_yog3i_157 {
  display: flex;
}

._justify-content-space-between_yog3i_161 {
  justify-content: space-between;
}

._tabular-nums_yog3i_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_yog3i_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_yog3i_157 {
  display: flex;
}

._justify-content-space-between_yog3i_161 {
  justify-content: space-between;
}

._tabular-nums_yog3i_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_yog3i_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._LandingPages_yog3i_355 {
  height: 100%;
}
._LandingPages_yog3i_355 ._legacyLandingPage_yog3i_358 {
  position: relative;
  max-width: 50rem;
  margin: 2rem auto;
}
._LandingPages_yog3i_355 ._legacyLandingPage_yog3i_358 ._saveButton_yog3i_363 {
  position: absolute;
  right: 0;
  bottom: 0;
}