:root {
  --primary: #167cf4;
  --primary-40: #3d73d7;
  --primary-10: #e6f7ff;
  --secondary: #f58984;
  --secondary-40: #fcbbae;
  --secondary-10: #fef0ed;
  --bay-grey: #989ea8;
  --grey-10: #fafafa;
  --grey-25: #e9e8ea;
  --grey-50: #aeaeae;
  --grey-75: #8f8f8f;
  --grey-100: #1a1b1c;
  --white: #fff;
  --black: #1a1b1c;
  --green: #006462;
  --sand: #f6f3ef;
  --yellow: #ffcd5b;
  --magenta-base: #ffddff;
  --red-base: #f5b3b4;
  --volcano-base: #ff9c93;
  --orange-base: #fabf95;
  --gold-base: #f1e5ac;
  --lime-base: #ebffd0;
  --sage-base: #c1e1c1;
  --cyan-base: #c9f2f2;
  --blue-base: #70d1f1;
  --geekblue-base: #a2aff1;
  --purple-base: #c5b3e3;
  --mauve-base: #c6b0ca;
  --coral-base: #ff8a8a;
  --apricot-base: #f5a287;
  --marigold-base: #fdaf1c;
  --butter-base: #f9eda1;
  --apple-base: #b7d275;
  --asparagus-base: #9db17c;
  --tiffany-base: #5fcdca;
  --baby-base: #a3ddf9;
  --carolina-base: #7eb3d8;
  --rose-base: #ffb4c7;
  --watermelon-base: #ffa1b6;
  --blue-0: #f4fcff;
  --blue-1: #e6f7ff;
  --blue-3: #91d5ff;
  --blue-5: #499dff;
  --blue-6: #167cf4;
  --blue-7: #2771c9;
  --gray-1: #fff;
  --gray-2: #fafafa;
  --gray-3: #f5f5f5;
  --gray-4: #f0f0f0;
  --gray-5: #d9d9d9;
  --gray-6: #bfbfbf;
  --gray-7: #8c8c8c;
  --gray-8: #505256;
  --gray-9: #1f1f21;
  --red-1: #fff1f0;
  --red-3: #ffa39e;
  --red-4: #ff7875;
  --red-5: #ff4d4f;
  --red-6: #f5222d;
  --gold-1: #fffbe6;
  --gold-3: #ffe58f;
  --gold-5: #ffc53d;
  --gold-6: #faad14;
  --gold-7: #d48806;
  --green-1: #f6ffed;
  --green-3: #b7eb8f;
  --green-5: #73d13d;
  --green-6: #52c41a;
  --green-7: #389e0d;
  --text-color: #505256;
  --text-color-secondary: #8c8c8c;
  --text-color-inverse: #fff;
  --icon-color: inherit;
  --icon-color-hover: rgba(26, 27, 28, 0.75);
  --heading-color: #1f1f21;
  --text-color-dark: rgba(255, 255, 255, 0.85);
  --text-color-secondary-dark: rgba(255, 255, 255, 0.65);
  --text-selection-bg: #167cf4;
  --link-color: #167cf4;
  --modal-mask-bg: rgba(79, 90, 114, 0.45);
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1qk02_325 {
  display: flex;
}

._justify-content-space-between_1qk02_329 {
  justify-content: space-between;
}

._tabular-nums_1qk02_333 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1qk02_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1qk02_325 {
  display: flex;
}

._justify-content-space-between_1qk02_329 {
  justify-content: space-between;
}

._tabular-nums_1qk02_333 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1qk02_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MembersTable_1qk02_523 {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}
._MembersTable_1qk02_523 .ant-table-cell-fix-left {
  background-color: #fff;
}
._MembersTable_1qk02_523 .ant-pagination {
  display: none;
}
._MembersTable_1qk02_523 ._tagsCell_1qk02_535 {
  display: flex;
}
._MembersTable_1qk02_523 ._tagsCell_1qk02_535 ._addTagIcon_1qk02_538 {
  visibility: hidden;
}
._MembersTable_1qk02_523 ._tagsCell_1qk02_535:hover ._addTagIcon_1qk02_538 {
  visibility: visible;
}
._MembersTable_1qk02_523 ._clickableTag_1qk02_544 {
  cursor: pointer;
}
._MembersTable_1qk02_523 ._membersTableContainer_1qk02_547 {
  padding-right: 0.5rem;
}
._MembersTable_1qk02_523 .ant-table-thead > tr > th {
  white-space: normal;
}
._MembersTable_1qk02_523 .ant-table-tbody > tr:not(:first-child) > td {
  padding-top: 4.5px !important;
  padding-bottom: 4.5px !important;
}
._MembersTable_1qk02_523 ._loadSpinner_1qk02_557 {
  margin-top: 10%;
}
._MembersTable_1qk02_523 ._table_1qk02_560 {
  flex: 1;
  max-height: unset !important;
}
._MembersTable_1qk02_523 ._table_1qk02_560 > div > div > [class^=BodyRow_] {
  background: transparent;
}
._MembersTable_1qk02_523 ._tableHeader_1qk02_567 {
  padding: 0 1.5rem 0 2rem;
}

._checkboxHeaderCell_1qk02_571 {
  background-color: unset !important;
}

._checkboxCell_1qk02_575,
._checkboxHeaderCell_1qk02_571 {
  pointer-events: none;
}
._checkboxCell_1qk02_575 > div,
._checkboxHeaderCell_1qk02_571 > div {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  pointer-events: none;
}
._checkboxCell_1qk02_575 > div > label,
._checkboxHeaderCell_1qk02_571 > div > label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: initial;
}

._tag_1qk02_535 {
  cursor: pointer;
}

._defaultTag_1qk02_600 {
  background-color: #e5f7ff !important;
}

._fixedCell_1qk02_604 {
  overflow: hidden;
}

._leftAlignedButton_1qk02_608 {
  padding-left: 0;
}

._loadingOverlaySpinner_1qk02_612 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background: white;
  opacity: 0.7;
}

._openButton_1qk02_623 {
  display: flex;
  justify-content: center;
  align-items: center;
}
._openButton_1qk02_623:focus {
  color: unset !important;
  background-color: transparent !important;
  border: 0.0625rem solid #d9d9d9 !important;
}
._openButton_1qk02_623:hover {
  color: #479cfd !important;
  background-color: #fafafa !important;
  border: 0.0625rem solid #479cfd !important;
}