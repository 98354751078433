._TableTitle_1alkx_1 {
  margin-bottom: 16px !important;
}

._RowTable_1alkx_5 {
  height: 60px;
}
._RowTable_1alkx_5 ._RemoveButton_1alkx_8 {
  display: none;
}
._RowTable_1alkx_5:hover ._RemoveButton_1alkx_8 {
  display: block;
}
._RowTable_1alkx_5 ._loading_1alkx_14 {
  display: block;
}