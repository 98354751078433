/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1f211_157 {
  display: flex;
}

._justify-content-space-between_1f211_161 {
  justify-content: space-between;
}

._tabular-nums_1f211_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1f211_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SearchFilter_1f211_178 ._title_1f211_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._SearchFilter_1f211_178 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #dadcde;
}
._SearchFilter_1f211_178 ._filterInput_1f211_195 {
  display: flex;
  align-items: center;
}
._SearchFilter_1f211_178 ._searchFilterInput_1f211_199 {
  float: right;
  width: 462px;
}
._SearchFilter_1f211_178 ._searchFilterInput_1f211_199 .ant-input {
  width: 400px;
}
._SearchFilter_1f211_178 ._searchFilterInput_1f211_199 .ant-input-suffix {
  position: relative;
  right: 17px;
  top: 1px;
  padding-left: 16px;
}
._SearchFilter_1f211_178 ._filterDropdownIcon_1f211_212 {
  cursor: pointer;
  position: relative;
  right: 25px;
  z-index: 1;
}

._searchFilterDropdown_1f211_219 {
  position: relative;
  top: 8px;
  left: 10px;
  width: 460px;
  border-width: 0px 0px 1px 0px;
  border-radius: 6px;
  padding: 16px;
  background-color: white;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
}
._searchFilterDropdown_1f211_219 ._searchFilterGrid_1f211_230 {
  display: grid;
  grid-template-columns: 100px 300px;
  grid-template-rows: auto;
  justify-items: start;
  align-items: center;
  row-gap: 20px;
  column-gap: 20px;
}
._searchFilterDropdown_1f211_219 ._actionButtons_1f211_239 {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 10px;
}

._suggestionItem_1f211_246 {
  display: flex;
  column-gap: 10px;
  align-items: center;
}