/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1o2mp_157 {
  display: flex;
}

._justify-content-space-between_1o2mp_161 {
  justify-content: space-between;
}

._tabular-nums_1o2mp_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1o2mp_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._LandingPages_1o2mp_178 {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 0 3rem;
}
._LandingPages_1o2mp_178 ._leftPanel_1o2mp_184 {
  width: 22.3125rem;
  height: 100%;
  border-right: 0.0625rem solid #dadcde;
}
._LandingPages_1o2mp_178 ._rightPanel_1o2mp_189 {
  flex: 1;
  height: 100%;
}