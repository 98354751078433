/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_ohkzd_157 {
  display: flex;
}

._justify-content-space-between_ohkzd_161 {
  justify-content: space-between;
}

._tabular-nums_ohkzd_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_ohkzd_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._footer_ohkzd_178 {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 4.6875rem;
  padding: 1.25rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 0 0 0.4375rem 0.4375rem;
  background: rgba(253, 253, 253, 0.95);
  opacity: 0.9;
}
._footer_ohkzd_178 ._selection_ohkzd_194 {
  display: flex;
  flex-grow: 1;
}
._footer_ohkzd_178 ._selection_ohkzd_194 ._badge_ohkzd_198 {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  padding: 0.0625rem 0.5rem;
  border-radius: 0.59375rem;
  background-color: #3996e0;
  color: #eff5f9;
  line-height: 1rem;
  letter-spacing: -0.025rem;
  text-align: center;
}
._footer_ohkzd_178 ._primaryButton_ohkzd_210 {
  margin-right: 0.75rem;
}
._footer_ohkzd_178 ._secondaryButton_ohkzd_213 {
  margin-right: 0.75rem;
}