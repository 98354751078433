/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1vurb_157 {
  display: flex;
}

._justify-content-space-between_1vurb_161 {
  justify-content: space-between;
}

._tabular-nums_1vurb_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1vurb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._AddOrEditCommunity_1vurb_178 ._header_1vurb_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 34px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._AddOrEditCommunity_1vurb_178 {
  position: relative;
  min-width: 56.25rem;
  min-height: 100%;
  overflow-y: scroll;
  margin-left: 0.125rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background-color: #fdfdfd;
}
._AddOrEditCommunity_1vurb_178 ._backBtn_1vurb_198 {
  position: absolute;
  top: 4.0625rem;
  left: 2.5rem;
  padding: 0.3125rem;
  border: solid 0.0625rem #ececec;
  background-color: #fdfdfd;
  transform: translateX(-50%);
}
._AddOrEditCommunity_1vurb_178 ._backBtn_1vurb_198:active {
  background-color: #fdfdfd;
}
._AddOrEditCommunity_1vurb_178 ._splashImage_1vurb_210 {
  width: 5rem;
  height: 5rem;
  margin-right: 1.25rem;
  border-radius: 0.625rem;
  object-fit: cover;
}
._AddOrEditCommunity_1vurb_178 ._header_1vurb_178 {
  display: flex;
  align-items: center;
  margin-bottom: 0.9375rem;
}
._AddOrEditCommunity_1vurb_178 ._content_1vurb_222 {
  margin-right: 5rem;
  margin-left: 5rem;
}
._AddOrEditCommunity_1vurb_178 ._basics_1vurb_226 {
  max-width: 37.5rem;
}