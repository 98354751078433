._ChartLoading_1v7ac_1 {
  padding: 16px;
  gap: 16px;
  width: 100%;
  height: 150px;
  background: var(--gray-2);
  border-radius: 6px;
}

._TrendLoading_1v7ac_10 {
  margin-top: var(--spacing-xs);
  padding-top: var(--spacing-xs);
  border-top: 1px solid var(--gray-5);
}

._AlertLoading_1v7ac_16 {
  background: var(--gray-2);
  border-radius: 6px;
  width: 100%;
  padding: 12px;
  gap: 12px;
}

._MetricTab_1v7ac_24 {
  flex: 1;
  display: flex;
  height: 68px;
}
._MetricTab_1v7ac_24 .ant-skeleton-button {
  flex: 1;
  display: flex;
  height: 100%;
}

._Divider_1v7ac_35 {
  border: 1px solid var(--gray-5);
  width: 100%;
}