._ContentReviewPanel_r8g0s_1 ._title_r8g0s_1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
._ContentReviewPanel_r8g0s_1 ._title_r8g0s_1 ._icon_r8g0s_8 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
  background-color: #A89EE4;
  border-radius: 6px;
}
._ContentReviewPanel_r8g0s_1 ._title_r8g0s_1 ._icon_r8g0s_8 ._svg_r8g0s_21 {
  margin-right: 0;
}