/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1nk04_157 {
  display: flex;
}

._justify-content-space-between_1nk04_161 {
  justify-content: space-between;
}

._tabular-nums_1nk04_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1nk04_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ThreadList_1nk04_178 ._header_1nk04_178 ._titleAndSearch_1nk04_178 ._titleAndCount_1nk04_178 ._count_1nk04_178, ._ThreadList_1nk04_178 ._header_1nk04_178 ._titleAndSearch_1nk04_178 ._titleAndCount_1nk04_178 ._title_1nk04_178, ._ThreadList_1nk04_178 ._header_1nk04_178 ._titleIGDM_1nk04_178 ._count_1nk04_178, ._ThreadList_1nk04_178 ._header_1nk04_178 ._titleIGDM_1nk04_178 ._title_1nk04_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188 ._info_1nk04_188 ._title_1nk04_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188 ._snippetAndAttachmentIcon_1nk04_198 ._snippet_1nk04_198, ._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188 ._subjectAndCount_1nk04_198 ._subject_1nk04_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188 ._info_1nk04_188 ._time_1nk04_208 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._overflowMenu_1nk04_249 {
  padding: 0.625rem;
  border-radius: 0.5rem;
  background: #fdfdfd;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0.0625rem 0.25rem;
}

._ThreadList_1nk04_178 {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #FDFDFD;
}
._ThreadList_1nk04_178 ._notice_1nk04_262 {
  margin: 1rem 1.25rem;
}
._ThreadList_1nk04_178 ._header_1nk04_178 {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  height: 3.75rem;
  padding: 0 0.75rem;
  border-bottom: solid 0.0625rem #dadcde;
}
._ThreadList_1nk04_178 ._header_1nk04_178._legacy_1nk04_274 {
  height: 6.3125rem;
}
._ThreadList_1nk04_178 ._header_1nk04_178 ._titleIGDM_1nk04_178 {
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
  height: 6.25rem;
  align-items: center;
  justify-content: flex-end;
}
._ThreadList_1nk04_178 ._header_1nk04_178 ._titleIGDM_1nk04_178 ._title_1nk04_178 {
  margin-right: 0.5rem;
  margin-top: -1rem;
}
._ThreadList_1nk04_178 ._header_1nk04_178 ._titleIGDM_1nk04_178 ._count_1nk04_178 {
  color: #8f8d91;
  font-weight: 300;
  margin-top: -1rem;
}
._ThreadList_1nk04_178 ._header_1nk04_178 ._titleIGDM_1nk04_178 ._iconButton_1nk04_294 {
  margin-left: auto;
  font-size: 1rem;
  margin-top: -1rem;
}
._ThreadList_1nk04_178 ._header_1nk04_178 ._titleAndSearch_1nk04_178 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
._ThreadList_1nk04_178 ._header_1nk04_178 ._titleAndSearch_1nk04_178 ._titleAndCount_1nk04_178 {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
}
._ThreadList_1nk04_178 ._header_1nk04_178 ._titleAndSearch_1nk04_178 ._titleAndCount_1nk04_178 ._title_1nk04_178 {
  margin-right: 0.5rem;
}
._ThreadList_1nk04_178 ._header_1nk04_178 ._titleAndSearch_1nk04_178 ._titleAndCount_1nk04_178 ._count_1nk04_178 {
  color: #8f8d91;
  font-weight: 300;
}
._ThreadList_1nk04_178 ._header_1nk04_178 ._actions_1nk04_317 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
._ThreadList_1nk04_178 ._header_1nk04_178 ._actions_1nk04_317 ._bulkActions_1nk04_323 {
  position: relative;
  display: flex;
  column-gap: 10px;
}
._ThreadList_1nk04_178 ._header_1nk04_178 ._actions_1nk04_317 .ant-select-selector {
  border-radius: 0.25rem;
}
._ThreadList_1nk04_178 ._header_1nk04_178 ._actions_1nk04_317 ._actionButtons_1nk04_331 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}
._ThreadList_1nk04_178 ._header_1nk04_178 ._actions_1nk04_317 ._actionButtons_1nk04_331 ._popOver_1nk04_337 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ThreadList_1nk04_178 ._header_1nk04_178 ._actions_1nk04_317 ._actionButtons_1nk04_331 ._refreshButton_1nk04_342 {
  font-size: 1.25rem;
}
._ThreadList_1nk04_178 ._header_1nk04_178 ._actions_1nk04_317 ._actionButtons_1nk04_331 ._refreshButton_1nk04_342._shiftedButton_1nk04_345 {
  margin-right: -0.625rem;
}
._ThreadList_1nk04_178 ._header_1nk04_178 ._actions_1nk04_317 ._closeArea_1nk04_348 {
  margin-top: 0.125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.625rem;
  border-radius: 6.25rem;
  cursor: pointer;
}
._ThreadList_1nk04_178 ._header_1nk04_178 ._actions_1nk04_317 ._closeArea_1nk04_348:hover {
  background-color: #eff5f9;
}
._ThreadList_1nk04_178 ._header_1nk04_178 ._actions_1nk04_317 ._closeArea_1nk04_348 ._closeText_1nk04_360 {
  margin-left: 0.5rem;
  font-size: 0.875rem;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 {
  flex: 1;
  overflow-y: auto;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188 {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  height: 5.625rem;
  padding: 0.6875rem 0.9375rem 0 2rem;
  border-bottom: 0.0625rem solid #dadcde;
  background-color: #eff5f9;
  cursor: pointer;
  transition: background-color 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: background-color;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188:hover, ._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188._selected_1nk04_380 {
  background-color: #3996e0;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188:hover ._info_1nk04_188 ._title_1nk04_178, ._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188._selected_1nk04_380 ._info_1nk04_188 ._title_1nk04_178 {
  color: #fdfdfd;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188:hover ._info_1nk04_188 ._time_1nk04_208, ._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188._selected_1nk04_380 ._info_1nk04_188 ._time_1nk04_208 {
  color: #7fd4f4;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188:hover ._info_1nk04_188 ._type_1nk04_389, ._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188._selected_1nk04_380 ._info_1nk04_188 ._type_1nk04_389 {
  color: #7fd4f4;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188:hover ._subject_1nk04_198, ._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188._selected_1nk04_380 ._subject_1nk04_198 {
  color: #fdfdfd;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188:hover ._snippetAndAttachmentIcon_1nk04_198 ._snippet_1nk04_198, ._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188._selected_1nk04_380 ._snippetAndAttachmentIcon_1nk04_198 ._snippet_1nk04_198 {
  color: #7fd4f4;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188 ._info_1nk04_188 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.25rem;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188 ._info_1nk04_188 ._title_1nk04_178 {
  margin-right: 0.625rem;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188 ._info_1nk04_188 ._time_1nk04_208 {
  flex-shrink: 0;
  margin-left: auto;
  color: #8f8d91;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188 ._info_1nk04_188 ._appIcon_1nk04_412 {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188 ._info_1nk04_188 ._gmailIcon_1nk04_417 {
  background: url("/etc/aspirex/app/config/esbuild/../../src/app/assets/svgs/gmail.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188 ._subjectAndCount_1nk04_198 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.25rem;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188 ._subjectAndCount_1nk04_198 ._subject_1nk04_198 {
  flex: 1;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188 ._subjectAndCount_1nk04_198 ._count_1nk04_178 {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  border-radius: 6.25rem;
  background: #e9e8ea;
  color: #8f8d91;
  font-weight: bold;
  font-size: 0.625rem;
  line-height: 0.625rem;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188 ._snippetAndAttachmentIcon_1nk04_198 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.25rem;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188 ._snippetAndAttachmentIcon_1nk04_198 ._snippet_1nk04_198 {
  flex: 1;
  color: #8f8d91;
}
._ThreadList_1nk04_178 ._threadList_1nk04_188 ._threadItem_1nk04_188 ._snippetAndAttachmentIcon_1nk04_198 ._attachmentIcon_1nk04_458 {
  color: #8f8d91;
}
._ThreadList_1nk04_178 ._loadSpinner_1nk04_461 {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem 0;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
}
._ThreadList_1nk04_178 ._loadSpinner_1nk04_461._initialLoad_1nk04_469 {
  top: 6.875rem;
  bottom: unset;
}
._ThreadList_1nk04_178 .ant-popover-arrow {
  visibility: hidden;
}
._ThreadList_1nk04_178 .ant-popover-inner-content {
  padding: 1.5rem 1.25rem;
}