/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_jijpp_157 {
  display: flex;
}

._justify-content-space-between_jijpp_161 {
  justify-content: space-between;
}

._tabular-nums_jijpp_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_jijpp_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._AppIcon_jijpp_178 {
  display: inline;
  width: 1.5rem;
}