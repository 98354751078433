/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_f9zoh_157 {
  display: flex;
}

._justify-content-space-between_f9zoh_161 {
  justify-content: space-between;
}

._tabular-nums_f9zoh_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_f9zoh_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._contract_setup_f9zoh_178 {
  padding-top: 1rem;
}
._contract_setup_f9zoh_178 h1 {
  padding-bottom: 2rem;
  font-weight: bold;
  font-style: normal;
  font-size: 1.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.8125rem;
  text-align: center;
}
._contract_setup_f9zoh_178 label {
  display: block;
  padding-bottom: 0.5rem;
  font-weight: bold;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125rem;
  letter-spacing: 0.00625rem;
}
._contract_setup_f9zoh_178 ._standard_input_f9zoh_200 {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 0;
  border-radius: 6.25rem;
  background-color: #eff5f9;
  font-weight: normal;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
}
._contract_setup_f9zoh_178 ._standard_input_f9zoh_200:hover {
  background-color: #e6edf2;
}
._contract_setup_f9zoh_178 ._standard_input_f9zoh_200:focus {
  background-color: #dce4e9;
  outline: none;
}
._contract_setup_f9zoh_178 ._form_wrap_f9zoh_220 {
  max-width: 40.625rem;
  margin: 0 auto;
}
._contract_setup_f9zoh_178 ._notice_f9zoh_224 {
  margin-bottom: 2rem;
}
._contract_setup_f9zoh_178 ._message_wrap_f9zoh_227 {
  margin-bottom: 2rem;
}
._contract_setup_f9zoh_178 ._message_wrap_f9zoh_227 textarea {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 0;
  border-radius: 0.5rem;
  background: #eff5f9;
  font-weight: normal;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
}
._contract_setup_f9zoh_178 ._message_wrap_f9zoh_227 textarea:hover {
  background-color: #e6edf2;
}
._contract_setup_f9zoh_178 ._message_wrap_f9zoh_227 textarea:focus {
  background-color: #dce4e9;
  outline: none;
}
._contract_setup_f9zoh_178 ._signers_wrap_f9zoh_250 {
  margin-bottom: 1.75rem;
}
._contract_setup_f9zoh_178 ._signers_wrap_f9zoh_250 ._add_signer_button_f9zoh_253 {
  padding: 0.5rem 1rem 0.5rem 2.25rem;
  border: 0;
  background: url("/etc/aspirex/app/config/esbuild/../../src/app/assets/svgs/circleadd.svg");
  background-position: 0.75rem center;
  background-size: 1.25rem;
  background-repeat: no-repeat;
  color: #3996e0;
  cursor: pointer;
}
._contract_setup_f9zoh_178 ._signers_wrap_f9zoh_250 ._add_signer_button_f9zoh_253:hover, ._contract_setup_f9zoh_178 ._signers_wrap_f9zoh_250 ._add_signer_button_f9zoh_253:focus {
  background-color: #eff5f9;
}
._contract_setup_f9zoh_178 ._actions_wrap_f9zoh_266 {
  text-align: center;
}
._contract_setup_f9zoh_178 ._completeCheckbox_f9zoh_269 {
  display: inline-block;
}
._contract_setup_f9zoh_178 ._completeLabel_f9zoh_272 {
  display: inline-block;
  vertical-align: 1rem;
}
._contract_setup_f9zoh_178 ._error_f9zoh_276 {
  margin-bottom: 1rem;
}