/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_lh9mt_157 {
  display: flex;
}

._justify-content-space-between_lh9mt_161 {
  justify-content: space-between;
}

._tabular-nums_lh9mt_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_lh9mt_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SettingsLayout_lh9mt_178 {
  display: flex;
  padding-top: 2rem;
  padding-bottom: 0.0625rem;
}
._SettingsLayout_lh9mt_178 ._setting_nav_lh9mt_183 {
  flex: 2;
}
._SettingsLayout_lh9mt_178 ._setting_nav_lh9mt_183 ul {
  margin: 0;
  padding: 0;
}
._SettingsLayout_lh9mt_178 ._setting_nav_lh9mt_183 li {
  margin-bottom: 0.5rem;
  list-style-type: none;
}
._SettingsLayout_lh9mt_178 ._setting_nav_lh9mt_183 li:last-child {
  margin-bottom: none;
}
._SettingsLayout_lh9mt_178 ._setting_nav_lh9mt_183 a {
  display: block;
  padding: 0.75rem 1.25rem;
  border-radius: 0.5rem;
  background: url("/etc/aspirex/app/config/esbuild/../../src/app/assets/svgs/arrowhead_right.svg");
  background-position: 95% center;
  background-size: 1rem;
  background-repeat: no-repeat;
  color: #8f8d91;
  font-weight: bold;
  font-style: normal;
  font-size: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.5rem;
  letter-spacing: 0.009375rem;
  cursor: pointer;
}
._SettingsLayout_lh9mt_178 ._setting_nav_lh9mt_183 a:hover, ._SettingsLayout_lh9mt_178 ._setting_nav_lh9mt_183 a:focus {
  background: #eff5f9 url("/etc/aspirex/app/config/esbuild/../../src/app/assets/svgs/arrowhead_right_blue.svg");
  background-position: 95% center;
  background-size: 1rem;
  background-repeat: no-repeat;
}
._SettingsLayout_lh9mt_178 ._setting_nav_lh9mt_183 a:active {
  background: url("/etc/aspirex/app/config/esbuild/../../src/app/assets/svgs/arrowhead_right.svg");
  background-position: 95% center;
  background-size: 1rem;
  background-repeat: no-repeat;
}
._SettingsLayout_lh9mt_178 ._setting_nav_lh9mt_183 ._settings_nav_active_lh9mt_226 {
  background: #eff5f9 url("/etc/aspirex/app/config/esbuild/../../src/app/assets/svgs/arrowhead_right_blue.svg");
  background-position: 95% center;
  background-size: 1rem;
  background-repeat: no-repeat;
  color: #3996e0;
}
._SettingsLayout_lh9mt_178 ._setting_nav_lh9mt_183 ._settings_nav_active_lh9mt_226:hover, ._SettingsLayout_lh9mt_178 ._setting_nav_lh9mt_183 ._settings_nav_active_lh9mt_226:focus {
  background: #eff5f9 url("/etc/aspirex/app/config/esbuild/../../src/app/assets/svgs/arrowhead_right_blue.svg");
  background-position: 95% center;
  background-size: 1rem;
  background-repeat: no-repeat;
}
._SettingsLayout_lh9mt_178 ._setting_detail_lh9mt_239 {
  flex: 6;
  margin-left: 2.5rem;
}
._SettingsLayout_lh9mt_178 ._setting_detail_lh9mt_239 h1 {
  font-weight: bold;
  font-style: normal;
  font-size: 1.25rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.5rem;
  letter-spacing: 0.009375rem;
}
._SettingsLayout_lh9mt_178 ._setting_detail_lh9mt_239 p {
  color: #1a1818;
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.375rem;
  letter-spacing: 0.02777775rem;
}
._SettingsLayout_lh9mt_178 ._setting_detail_lh9mt_239 b {
  color: #1a1818;
  font-weight: bold;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125rem;
  letter-spacing: 0.00625rem;
}
._SettingsLayout_lh9mt_178 ._setting_detail_lh9mt_239 ._none_placeholder_lh9mt_269 {
  margin-bottom: 1.5rem;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background: #e6e4e7;
  color: #8f8d91;
  font-weight: 600;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.0625rem;
  letter-spacing: 0.021875rem;
  text-align: center;
}
._SettingsLayout_lh9mt_178 ._setting_detail_lh9mt_239 ._AddSignerButton_lh9mt_283 {
  margin: 1rem 0;
}