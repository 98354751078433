/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_3hl7d_157 {
  display: flex;
}

._justify-content-space-between_3hl7d_161 {
  justify-content: space-between;
}

._tabular-nums_3hl7d_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_3hl7d_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._TemplateItem_3hl7d_209 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #F0F0F0;
  border-radius: 6px;
}
._TemplateItem_3hl7d_209:hover ._nav_3hl7d_216 {
  color: #167cf4;
}
._TemplateItem_3hl7d_209:hover ._navTextNonPersistent_3hl7d_219 {
  display: flex;
}
._TemplateItem_3hl7d_209 ._contentContainer_3hl7d_222 {
  display: flex;
  align-items: center;
}
._TemplateItem_3hl7d_209 ._contentContainer_3hl7d_222 ._templateIcon_3hl7d_226 {
  padding: 14px 5px;
  background: #FAFAFA;
}
._TemplateItem_3hl7d_209 ._contentContainer_3hl7d_222 ._templateContent_3hl7d_230 {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-left: 8px;
}
._TemplateItem_3hl7d_209 ._contentContainer_3hl7d_222 ._templateContent_3hl7d_230 ._title_3hl7d_236 {
  font-weight: 600;
}
._TemplateItem_3hl7d_209 ._contentContainer_3hl7d_222 ._templateContent_3hl7d_230 ._description_3hl7d_239 {
  color: #8C8C8C;
}
._TemplateItem_3hl7d_209 ._nav_3hl7d_216 {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
._TemplateItem_3hl7d_209 ._nav_3hl7d_216 ._navText_3hl7d_219 {
  font-weight: 600;
}
._TemplateItem_3hl7d_209 ._navTextNonPersistent_3hl7d_219 {
  display: none;
}

._actionable_3hl7d_254 {
  cursor: pointer;
}
._actionable_3hl7d_254:hover {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
}