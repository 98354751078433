/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1pqbs_157 {
  display: flex;
}

._justify-content-space-between_1pqbs_161 {
  justify-content: space-between;
}

._tabular-nums_1pqbs_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1pqbs_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._formElement_1pqbs_178 {
  width: 20rem;
}
._formElement_1pqbs_178 ._thumbnailUpload_1pqbs_181 {
  flex-direction: column;
  max-width: 11.25rem;
}
._formElement_1pqbs_178 ._formElementHead_1pqbs_185 {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}
._formElement_1pqbs_178 ._formElementHead_1pqbs_185 ._formElementLabel_1pqbs_192 {
  margin-right: 0.375rem;
  color: var(--black);
}
._formElement_1pqbs_178 ._formElementHead_1pqbs_185 ._formElementHelp_1pqbs_196,
._formElement_1pqbs_178 ._formElementHead_1pqbs_185 ._formElementRequired_1pqbs_197 {
  margin-right: 0.375rem;
  color: var(--grey-75);
}
._formElement_1pqbs_178 ._requiredLabel_1pqbs_201 {
  margin-top: 0.125rem;
  color: #d9534f;
  font-size: 0.875rem;
}
._formElement_1pqbs_178 ._requiredIndicator_1pqbs_206 {
  margin-right: 0.25rem;
  color: #d9534f;
  font-weight: 600;
}
._formElement_1pqbs_178 ._requiredInput_1pqbs_211 {
  border-color: #d9534f;
}
._formElement_1pqbs_178 ._formElementFooter_1pqbs_214 {
  margin-top: 0.375rem;
  color: var(--grey-75);
  font-size: 0.75rem;
  line-height: 1.6;
}
._formElement_1pqbs_178 ._formElementFooter_1pqbs_214._alignRight_1pqbs_220 {
  text-align: right;
}
._formElement_1pqbs_178 ._inputContainer_1pqbs_223 {
  position: relative;
  width: 100%;
}
._formElement_1pqbs_178 ._inputContainer_1pqbs_223 ._input_1pqbs_223 {
  width: 100%;
}
._formElement_1pqbs_178 ._inputContainer_1pqbs_223 ._inputSpinner_1pqbs_230 {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-50%);
}
._formElement_1pqbs_178 ._inputContainer_1pqbs_223 ._inputSpinner_1pqbs_230 .anticon {
  width: 0.75rem;
  height: 0.75rem;
  font-size: 0.75rem;
}