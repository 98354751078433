/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_bl5dd_157 {
  display: flex;
}

._justify-content-space-between_bl5dd_161 {
  justify-content: space-between;
}

._tabular-nums_bl5dd_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_bl5dd_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Persona_bl5dd_178 {
  padding-bottom: 1.5rem;
}
._Persona_bl5dd_178 ._row_bl5dd_181 {
  margin-top: 1rem;
}
._Persona_bl5dd_178 ._row_bl5dd_181 ._rowDescription_bl5dd_184 {
  color: var(--gray-9);
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 16px;
}
._Persona_bl5dd_178 ._subtitle_bl5dd_190 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  font-size: 0.875rem;
}