/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_10l9v_157 {
  display: flex;
}

._justify-content-space-between_10l9v_161 {
  justify-content: space-between;
}

._tabular-nums_10l9v_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_10l9v_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_10l9v_157 {
  display: flex;
}

._justify-content-space-between_10l9v_161 {
  justify-content: space-between;
}

._tabular-nums_10l9v_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_10l9v_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ListDrawer_10l9v_355 ._title_10l9v_355 ._controls_10l9v_355 ._settingsIcon_10l9v_355, ._ListDrawer_10l9v_355 ._title_10l9v_355 ._controls_10l9v_355 ._settingsIcon_10l9v_355 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._ListDrawer_10l9v_355 ._title_10l9v_355 ._controls_10l9v_355 ._settingsIcon_10l9v_355 {
  min-width: 2.25rem;
}
._ListDrawer_10l9v_355 ._title_10l9v_355 ._controls_10l9v_355 ._settingsIcon_10l9v_355 > svg:first-of-type:not(:only-child) {
  margin-right: 0.25rem;
}

._ListDrawer_10l9v_355 {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--white);
  pointer-events: none;
}
._ListDrawer_10l9v_355 ._divider_10l9v_380 {
  margin: 0.875rem 0;
}
._ListDrawer_10l9v_355._loading_10l9v_383 {
  pointer-events: none;
}
._ListDrawer_10l9v_355 ._drawer_10l9v_386 {
  position: inherit;
  z-index: 1;
  height: 100%;
  margin-left: 0 !important;
  background-color: #fff;
  pointer-events: all;
}
._ListDrawer_10l9v_355 ._title_10l9v_355 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem 2rem 0;
}
._ListDrawer_10l9v_355 ._title_10l9v_355 h1 {
  margin: 0;
  font-size: 1.375rem;
}
._ListDrawer_10l9v_355 ._title_10l9v_355 ._controls_10l9v_355 {
  display: flex;
  flex-direction: row;
}
._ListDrawer_10l9v_355 ._tableWrapper_10l9v_408 {
  position: relative;
  flex: 1;
}
._ListDrawer_10l9v_355 ._tableWrapper_10l9v_408 ._loadSpinner_10l9v_412 {
  margin-top: 15%;
}
._ListDrawer_10l9v_355 ._empty_10l9v_415 {
  margin: 25% auto 0;
  color: #8f8d91;
  text-align: center;
}

._filterList_10l9v_421 {
  margin-top: 0.5rem;
  padding: 0 2rem;
}

._membersTable_10l9v_426 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: unset !important;
  transition: opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1);
}
._membersTable_10l9v_426._loading_10l9v_383 {
  opacity: 0.4;
}
._membersTable_10l9v_426._loading_10l9v_383 * {
  pointer-events: none;
}
._membersTable_10l9v_426 ._tableHeader_10l9v_441 {
  margin-bottom: 2rem;
  transition: opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1);
}
._membersTable_10l9v_426 ._tableHeader_10l9v_441._loading_10l9v_383 {
  opacity: 0.4;
}
._membersTable_10l9v_426 ._tableHeader_10l9v_441._loading_10l9v_383 * {
  pointer-events: none;
}
._membersTable_10l9v_426 ._bodyRow_10l9v_451 {
  background-color: transparent;
}
._membersTable_10l9v_426 ._checkboxCell_10l9v_454 > div {
  width: 100%;
  height: 100%;
  padding: 0;
}
._membersTable_10l9v_426 ._checkboxCell_10l9v_454 > div > label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
._membersTable_10l9v_426 ._nameHeaderCell_10l9v_466 > div {
  padding-left: 4rem;
}
._membersTable_10l9v_426 ._singleCTAButton_10l9v_469 {
  padding-right: 0;
  padding-left: 0;
}

._headerCell_10l9v_474 {
  font-weight: unset;
  font-size: 0.875rem;
  letter-spacing: 0.015625rem;
  text-transform: unset;
  cursor: default;
}

:root {
  --spacing-xxxl: 80px;
  --spacing-xxl: 48px;
  --spacing-xl: 32px;
  --spacing-lg: 24px;
  --spacing-md: 16px;
  --spacing-sm: 12px;
  --spacing-xs: 8px;
  --spacing-xxs: 4px;
}

:root {
  --screen-xs: 480px;
  --screen-sm: 576px;
  --screen-md: 768px;
  --screen-lg: 992px;
  --screen-xl: 1200px;
  --screen-xxl: 1600px;
}

._newWorkItem_10l9v_522 {
  background: #fffef4 !important;
}

._customTaskNotice_10l9v_526 {
  padding: 0 2rem;
}

._loadSpinner_10l9v_412 {
  margin-top: 10%;
}

._listHeader_10l9v_534 {
  height: 3.375rem;
}

._workItemBoxContainer_10l9v_538 {
  display: flex;
  width: 100%;
  gap: 0.3125rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
._workItemBoxContainer_10l9v_538 .ant-card-body {
  padding: 0.5rem;
}
@media only screen and (max-width: 992px) {
  ._workItemBoxContainer_10l9v_538 .ant-statistic-content {
    font-size: var(--font-size-md);
  }
}
@media only screen and (max-width: 992px) {
  ._workItemBoxContainer_10l9v_538 .ant-statistic-title {
    font-size: var(--font-size-sm);
  }
}

._content_10l9v_561 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow: hidden;
}

._lastMessageColumn_10l9v_571 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
._lastMessageColumn_10l9v_571:hover {
  color: #4fa7ee;
}

._title_10l9v_355 {
  word-break: break-word;
  display: flex !important;
  flex-direction: row !important;
}
._title_10l9v_355 ._spacer_10l9v_586 {
  width: 100%;
}

._memberDetailsDrawer_10l9v_590 {
  width: 100%;
}

._ListDrawer_10l9v_355 ._empty_10l9v_415 {
  margin: auto;
  width: 31.25rem;
  position: absolute;
  top: 50%;
  left: calc(50% - 15.625rem);
  transform: translateY(-50%);
}
._ListDrawer_10l9v_355 ._empty_10l9v_415 ._searchIcon_10l9v_602 {
  margin: 0 auto;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  color: #e9e8ea;
}