/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_8p3ii_157 {
  display: flex;
}

._justify-content-space-between_8p3ii_161 {
  justify-content: space-between;
}

._tabular-nums_8p3ii_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_8p3ii_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._left_8p3ii_178 ._header_8p3ii_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._right_8p3ii_188 ._content_8p3ii_188 ._markAsRead_8p3ii_188 ._title_8p3ii_188, ._right_8p3ii_188 ._content_8p3ii_188 ._displayName_8p3ii_188 ._title_8p3ii_188, ._right_8p3ii_188 ._content_8p3ii_188 ._privacy_8p3ii_188 ._title_8p3ii_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._right_8p3ii_188 ._content_8p3ii_188 ._markAsRead_8p3ii_188 ._toggleWrapper_8p3ii_198 ._text_8p3ii_198, ._right_8p3ii_188 ._content_8p3ii_188 ._displayName_8p3ii_188 ._text_8p3ii_198, ._right_8p3ii_188 ._content_8p3ii_188 ._privacy_8p3ii_188 ._text_8p3ii_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._right_8p3ii_188 ._content_8p3ii_188 ._markAsRead_8p3ii_188 ._note_8p3ii_208, ._right_8p3ii_188 ._content_8p3ii_188 ._displayName_8p3ii_188 ._note_8p3ii_208 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._left_8p3ii_178 {
  flex-shrink: 0;
  margin-right: 2.8125rem;
}
._left_8p3ii_178 ._header_8p3ii_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}
._left_8p3ii_178 ._header_8p3ii_178 ._addIcon_8p3ii_259 {
  margin-left: auto;
}
._left_8p3ii_178 ._alertIcon_8p3ii_262 {
  margin-right: 1.5625rem;
  color: #f1515f;
}
._left_8p3ii_178 ._sharedIcon_8p3ii_266 {
  margin-right: 0.375rem;
}
._left_8p3ii_178 ._emailsList_8p3ii_269 {
  width: 15rem;
}
._left_8p3ii_178 ._emailsList_8p3ii_269 > div {
  overflow: hidden;
}
._left_8p3ii_178 ._emailsList_8p3ii_269 > div ._emailText_8p3ii_275 {
  overflow: hidden;
  text-overflow: ellipsis;
}

._right_8p3ii_188 {
  flex: 1;
}
._right_8p3ii_188 ._content_8p3ii_188 {
  padding: 1.5rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
}
._right_8p3ii_188 ._content_8p3ii_188 ._privacy_8p3ii_188 {
  margin-bottom: 2.5rem;
}
._right_8p3ii_188 ._content_8p3ii_188 ._privacy_8p3ii_188 ._title_8p3ii_188 {
  margin-bottom: 1rem;
}
._right_8p3ii_188 ._content_8p3ii_188 ._privacy_8p3ii_188 ._text_8p3ii_198 {
  color: #8f8d91;
}
._right_8p3ii_188 ._content_8p3ii_188 ._displayName_8p3ii_188 {
  margin-bottom: 2.5rem;
}
._right_8p3ii_188 ._content_8p3ii_188 ._displayName_8p3ii_188 ._title_8p3ii_188 {
  margin-bottom: 1rem;
}
._right_8p3ii_188 ._content_8p3ii_188 ._displayName_8p3ii_188 ._text_8p3ii_198 {
  color: #8f8d91;
}
._right_8p3ii_188 ._content_8p3ii_188 ._displayName_8p3ii_188 ._input_8p3ii_306 {
  max-width: 18.75rem;
  margin-bottom: 1rem;
}
._right_8p3ii_188 ._content_8p3ii_188 ._displayName_8p3ii_188 ._note_8p3ii_208 {
  color: #8f8d91;
}
._right_8p3ii_188 ._content_8p3ii_188 ._markAsRead_8p3ii_188 {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
._right_8p3ii_188 ._content_8p3ii_188 ._markAsRead_8p3ii_188 ._title_8p3ii_188 {
  margin-bottom: 0.75rem;
}
._right_8p3ii_188 ._content_8p3ii_188 ._markAsRead_8p3ii_188 ._toggleWrapper_8p3ii_198 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}
._right_8p3ii_188 ._content_8p3ii_188 ._markAsRead_8p3ii_188 ._toggleWrapper_8p3ii_198 ._switch_8p3ii_327 {
  flex-shrink: 0;
  margin-right: 0.75rem;
}
._right_8p3ii_188 ._content_8p3ii_188 ._markAsRead_8p3ii_188 ._note_8p3ii_208 {
  color: #8f8d91;
}
._right_8p3ii_188 ._content_8p3ii_188 ._markAsRead_8p3ii_188 ._note_8p3ii_208 ._date_8p3ii_334 {
  margin: 0 0.3125rem;
  font-weight: bold;
}
._right_8p3ii_188 ._content_8p3ii_188 ._errorNotice_8p3ii_338 {
  display: flex;
  margin-bottom: 1.5rem;
}
._right_8p3ii_188 ._content_8p3ii_188 ._errorNotice_8p3ii_338 .ant-alert {
  width: 100%;
}
._right_8p3ii_188 ._content_8p3ii_188 ._errorNotice_8p3ii_338 .anticon {
  margin-bottom: 4px;
}
._right_8p3ii_188 ._content_8p3ii_188 ._errorNotice_8p3ii_338 .ant-alert-action {
  align-self: center;
}
._right_8p3ii_188 ._content_8p3ii_188 ._actions_8p3ii_351 {
  margin-top: 1.25rem;
}
._right_8p3ii_188 ._content_8p3ii_188 ._actions_8p3ii_351 .ant-btn {
  margin-right: 1rem;
}
._right_8p3ii_188 ._content_8p3ii_188 ._spacer_8p3ii_357 {
  height: 1.5rem;
}