/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_13ghl_157 {
  display: flex;
}

._justify-content-space-between_13ghl_161 {
  justify-content: space-between;
}

._tabular-nums_13ghl_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_13ghl_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._container_13ghl_178 {
  display: flex;
}
._container_13ghl_178 ._col_13ghl_181._left_13ghl_181 {
  flex: 0 auto;
  width: 11.25rem;
  margin-right: 4.375rem;
}
._container_13ghl_178 ._col_13ghl_181._left_13ghl_181 ._formElement_13ghl_186 {
  width: 100%;
}
._container_13ghl_178 ._col_13ghl_181._right_13ghl_189 {
  flex: 1 auto;
}
._container_13ghl_178 ._col_13ghl_181._right_13ghl_189 ._fields_13ghl_192 {
  width: 100%;
}