/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1jlxq_157 {
  display: flex;
}

._justify-content-space-between_1jlxq_161 {
  justify-content: space-between;
}

._tabular-nums_1jlxq_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1jlxq_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._PublishedProjectsList_1jlxq_178 ._navigate_1jlxq_178 {
  display: flex;
  align-items: center;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  margin-left: 2.1875rem;
}
._PublishedProjectsList_1jlxq_178 ._navigate_1jlxq_178 ._backButton_1jlxq_185 {
  height: 3rem;
  margin-right: 0.75rem;
  padding: 0.75rem;
  border-radius: 0.5rem;
}
._PublishedProjectsList_1jlxq_178 ._navigate_1jlxq_178 ._backButton_1jlxq_185 ._leftArrowIcon_1jlxq_191 svg {
  color: #1a1818;
  font-size: 1.5rem;
}
._PublishedProjectsList_1jlxq_178 ._navigate_1jlxq_178 ._title_1jlxq_195 {
  margin: 0 3rem 0 1rem;
  font-weight: 600;
  font-size: 1.5rem;
}
._PublishedProjectsList_1jlxq_178 ._navigate_1jlxq_178 ._splashImage_1jlxq_200 {
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
}
._PublishedProjectsList_1jlxq_178 ._content_1jlxq_205 {
  display: flex;
  flex-direction: column;
  width: 95%;
  min-height: calc(100vh - 9.375rem);
  margin: auto;
  background: #f7f8fa;
}
._PublishedProjectsList_1jlxq_178 ._content_1jlxq_205 ._newPageBtn_1jlxq_213 {
  align-self: end;
  margin-top: 1.875rem;
  margin-right: 1.25rem;
}
._PublishedProjectsList_1jlxq_178 ._content_1jlxq_205 ._title_1jlxq_195 {
  margin-top: 1.25rem;
  margin-bottom: 3.125rem;
  font-weight: 500;
  font-size: 1.25rem;
  text-align: center;
}
._PublishedProjectsList_1jlxq_178 ._content_1jlxq_205 ._listGridView_1jlxq_225 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(19.375rem, max-content));
  row-gap: 3.125rem;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 2.5rem;
  column-gap: 2.5rem;
}
._PublishedProjectsList_1jlxq_178 ._content_1jlxq_205 ._listGridView_1jlxq_225 ._listItem_1jlxq_235 {
  position: relative;
  width: 19.375rem;
  height: 15rem;
}
._PublishedProjectsList_1jlxq_178 ._content_1jlxq_205 ._listGridView_1jlxq_225 ._listItem_1jlxq_235 ._image_1jlxq_240 {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}
._PublishedProjectsList_1jlxq_178 ._content_1jlxq_205 ._listGridView_1jlxq_225 ._listItem_1jlxq_235 ._ctaBtns_1jlxq_245 {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: none;
  flex-direction: column;
  transform: translate(-50%, -50%);
}
._PublishedProjectsList_1jlxq_178 ._content_1jlxq_205 ._listGridView_1jlxq_225 ._listItem_1jlxq_235 ._ctaBtns_1jlxq_245 button {
  width: 13rem;
  height: 2.5rem;
  margin-bottom: 0.625rem;
}
._PublishedProjectsList_1jlxq_178 ._content_1jlxq_205 ._listGridView_1jlxq_225 ._listItem_1jlxq_235 ._ctaBtns_1jlxq_245 button img {
  padding-bottom: 0.25rem;
}
._PublishedProjectsList_1jlxq_178 ._content_1jlxq_205 ._listGridView_1jlxq_225 ._listItem_1jlxq_235 ._listItemTitle_1jlxq_262 {
  margin-top: 0.3125rem;
  text-align: center;
}
._PublishedProjectsList_1jlxq_178 ._content_1jlxq_205 ._listGridView_1jlxq_225 ._listItem_1jlxq_235:hover ._image_1jlxq_240 {
  filter: brightness(50%);
}
._PublishedProjectsList_1jlxq_178 ._content_1jlxq_205 ._listGridView_1jlxq_225 ._listItem_1jlxq_235:hover ._ctaBtns_1jlxq_245 {
  display: flex;
}