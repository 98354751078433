/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1e3mb_157 {
  display: flex;
}

._justify-content-space-between_1e3mb_161 {
  justify-content: space-between;
}

._tabular-nums_1e3mb_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1e3mb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Form_1e3mb_178 {
  padding-bottom: 1.5rem;
}
._Form_1e3mb_178 ._row_1e3mb_181 {
  margin-top: 1rem;
  padding-bottom: 16px;
}
._Form_1e3mb_178 ._row_1e3mb_181 ._rowDescription_1e3mb_185 {
  color: var(--gray-9);
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 16px;
}
._Form_1e3mb_178 ._row_1e3mb_181 ._subrow_1e3mb_191:not(:first-child) {
  margin-top: 24px;
}
._Form_1e3mb_178 ._row_1e3mb_181:not(:last-child) {
  border-bottom: 1px solid var(--gray-5);
}
._Form_1e3mb_178 ._subtitle_1e3mb_197 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
._Form_1e3mb_178 ._title_1e3mb_205 {
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1rem;
}
._Form_1e3mb_178 ._fields_1e3mb_210 {
  padding-bottom: 1.5rem;
  border-bottom: 0.0625rem solid #dadcde;
}
._Form_1e3mb_178 ._unpaidAlert_1e3mb_214 {
  color: var(--gray-9);
}
._Form_1e3mb_178 ._unpaidAlert_1e3mb_214 p:last-child {
  margin-bottom: 0;
}
._Form_1e3mb_178 .ant-input-textarea-show-count::after {
  padding-right: 0.6875rem;
}