/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1w9wd_157 {
  display: flex;
}

._justify-content-space-between_1w9wd_161 {
  justify-content: space-between;
}

._tabular-nums_1w9wd_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1w9wd_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._EmptyAccessTable_1w9wd_178 p {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._EmptyAccessTable_1w9wd_178 {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
._EmptyAccessTable_1w9wd_178 ._Button_1w9wd_195 {
  height: 2.5rem;
  width: 215px;
  background-color: #167cf4;
  border-radius: 0.5rem;
}