/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_lkfeo_157 {
  display: flex;
}

._justify-content-space-between_lkfeo_161 {
  justify-content: space-between;
}

._tabular-nums_lkfeo_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_lkfeo_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._PaymentSetup_lkfeo_178 ._title_lkfeo_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._PaymentSetup_lkfeo_178 ._subtitle_lkfeo_188, ._PaymentSetup_lkfeo_178 ._tableTitle_lkfeo_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._PaymentSetup_lkfeo_178 ._label_lkfeo_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._PaymentSetup_lkfeo_178 {
  padding: 2.625rem;
}
._PaymentSetup_lkfeo_178 ._title_lkfeo_178 {
  margin-bottom: 1.5rem;
  text-align: center;
}
._PaymentSetup_lkfeo_178 ._tableTitle_lkfeo_188 {
  margin-bottom: 1rem;
  text-align: center;
}
._PaymentSetup_lkfeo_178 ._TermsTable_lkfeo_219 {
  justify-items: center;
  padding-bottom: 1.875rem;
  max-width: 47.5rem;
  margin: 0 auto;
}
._PaymentSetup_lkfeo_178 ._subtitle_lkfeo_188 {
  margin-bottom: 1.5rem;
  text-align: center;
}
._PaymentSetup_lkfeo_178 ._form_lkfeo_229 {
  max-width: 18.75rem;
  margin: auto;
  text-align: center;
}
._PaymentSetup_lkfeo_178 ._buttons_lkfeo_234 {
  margin-top: 1.5rem;
  text-align: center;
}
._PaymentSetup_lkfeo_178 ._buttons_lkfeo_234 > * {
  margin-right: 0.9375rem;
}
._PaymentSetup_lkfeo_178 ._label_lkfeo_198 {
  margin-top: 1.25rem;
  margin-bottom: 0.3125rem;
}