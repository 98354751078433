/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1qf9x_157 {
  display: flex;
}

._justify-content-space-between_1qf9x_161 {
  justify-content: space-between;
}

._tabular-nums_1qf9x_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1qf9x_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._creatorSec_1qf9x_178 ._row_1qf9x_178 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.9375rem;
}
._creatorSec_1qf9x_178 ._creatorCard_1qf9x_183 {
  padding: 0 0.75rem;
  width: 25%;
  margin-bottom: 1.5rem;
}
._creatorSec_1qf9x_178 ._creatorList_1qf9x_188 {
  padding: 0 1.875rem;
}
._creatorSec_1qf9x_178 ._head_1qf9x_191 {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.25rem;
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 1.25rem;
}
._creatorSec_1qf9x_178 ._head_1qf9x_191 button svg {
  font-size: 12px;
}
._creatorSec_1qf9x_178 ._head_1qf9x_191 > div {
  display: flex;
  align-items: center;
  padding-left: 0.3125rem;
}
._creatorSec_1qf9x_178 ._head_1qf9x_191 > div ._groupImage_1qf9x_206 {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 6px;
  margin: 0 1.5625rem;
}
._creatorSec_1qf9x_178 ._head_1qf9x_191 h4 {
  margin: 0;
  color: #1F1F21;
}
._creatorSec_1qf9x_178 ._tableWrapper_1qf9x_217 {
  max-width: 99%;
  margin: 0 auto;
  border: 1px solid #D9D9D9;
  border-radius: 0.375rem;
  margin-bottom: 1.25rem;
  min-height: 80vh;
}
._creatorSec_1qf9x_178 ._tableWrapper_1qf9x_217 table tr th {
  font-size: 0.75rem;
  color: #8C8C8C;
  font-weight: 400;
  border-bottom: 1px solid #D9D9D9;
  white-space: nowrap;
}
._creatorSec_1qf9x_178 ._tableWrapper_1qf9x_217 table tr th:first-child {
  padding-left: 6.25rem;
}
._creatorSec_1qf9x_178 ._tableWrapper_1qf9x_217 table tr th:nth-child(2) {
  text-align: center;
}
._creatorSec_1qf9x_178 ._tableWrapper_1qf9x_217 table tr td {
  color: #505256;
  border-bottom: 1px solid #D9D9D9;
  white-space: nowrap;
}
._creatorSec_1qf9x_178 ._tableWrapper_1qf9x_217 table tr td ._brandNote_1qf9x_243 {
  display: block;
  max-width: 200px;
  white-space: initial;
}
._creatorSec_1qf9x_178 ._tableWrapper_1qf9x_217 table tr:last-child td {
  border: none;
}
._creatorSec_1qf9x_178 ._tableWrapper_1qf9x_217 ._nameProfile_1qf9x_251 {
  padding-left: 1.875rem;
  display: flex;
  align-items: center;
}
._creatorSec_1qf9x_178 ._tableWrapper_1qf9x_217 ._nameProfile_1qf9x_251 ._profile_1qf9x_256 {
  max-width: 220px;
  width: 100%;
}
._creatorSec_1qf9x_178 ._tableWrapper_1qf9x_217 ._nameProfile_1qf9x_251 ._profile_1qf9x_256 > a {
  cursor: pointer;
  display: flex;
  align-items: center;
}
._creatorSec_1qf9x_178 ._tableWrapper_1qf9x_217 ._nameProfile_1qf9x_251 ._profile_1qf9x_256 > a ._name_1qf9x_251 {
  display: inline-block;
  margin-left: 0.5rem;
  width: 150px;
  white-space: initial;
}
._creatorSec_1qf9x_178 ._tableWrapper_1qf9x_217 ._nameProfile_1qf9x_251 ._action_1qf9x_271 {
  display: flex;
  align-items: center;
  padding-left: 1.25rem;
}
._creatorSec_1qf9x_178 ._tableWrapper_1qf9x_217 ._nameProfile_1qf9x_251 ._action_1qf9x_271 button {
  background: none;
  border: none;
  font-size: 1.375rem;
  margin: 0 0.625rem;
  padding: 0;
  cursor: pointer;
}
._creatorSec_1qf9x_178 ._tableWrapper_1qf9x_217 ._nameProfile_1qf9x_251 ._action_1qf9x_271 button:disabled {
  opacity: 0.5;
  filter: grayscale(1);
  cursor: default;
}
._creatorSec_1qf9x_178 ._tableWrapper_1qf9x_217 ._nameProfile_1qf9x_251 ._action_1qf9x_271 ._accept_1qf9x_289 {
  color: #389E0D;
}
._creatorSec_1qf9x_178 ._tableWrapper_1qf9x_217 ._nameProfile_1qf9x_251 ._action_1qf9x_271 ._reject_1qf9x_292 {
  color: #F5222D;
}
._creatorSec_1qf9x_178 ._tableWrapper_1qf9x_217 ._nameHandle_1qf9x_295 {
  text-align: center;
}
._creatorSec_1qf9x_178 ._tableWrapper_1qf9x_217 ._nameHandle_1qf9x_295 a {
  font-size: 1.125rem;
  color: #1F1F21;
  margin: 0 0.3125rem;
  vertical-align: middle;
}