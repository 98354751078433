/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_olqf6_157 {
  display: flex;
}

._justify-content-space-between_olqf6_161 {
  justify-content: space-between;
}

._tabular-nums_olqf6_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_olqf6_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._WooAccountList_olqf6_178 ._listItem_olqf6_178 ._noticeContent_olqf6_178 ._title_olqf6_178, ._WooAccountList_olqf6_178 ._listItem_olqf6_178 ._store_olqf6_178 ._title_olqf6_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._WooAccountList_olqf6_178 ._listItem_olqf6_178 ._noticeContent_olqf6_178 ._text_olqf6_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._WooAccountList_olqf6_178 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2.5rem;
}
._WooAccountList_olqf6_178 ._menu_olqf6_204 {
  width: 20rem;
  margin-right: 2rem;
}
._WooAccountList_olqf6_178 ._content_olqf6_208 {
  flex: 1;
  max-width: 50rem;
}
._WooAccountList_olqf6_178 ._listItem_olqf6_178 {
  margin-bottom: 1rem;
  padding: 1rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
}
._WooAccountList_olqf6_178 ._listItem_olqf6_178._disabled_olqf6_218 {
  opacity: 0.8;
  pointer-events: none;
}
._WooAccountList_olqf6_178 ._listItem_olqf6_178 ._store_olqf6_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._WooAccountList_olqf6_178 ._listItem_olqf6_178 ._store_olqf6_178._error_olqf6_227 ._title_olqf6_178 {
  color: #f1515f;
}
._WooAccountList_olqf6_178 ._listItem_olqf6_178 ._store_olqf6_178 ._icon_olqf6_230 {
  position: relative;
  margin-right: 1rem;
}
._WooAccountList_olqf6_178 ._listItem_olqf6_178 ._store_olqf6_178 ._icon_olqf6_230 img {
  width: 1.125rem;
  height: 1.25rem;
}
._WooAccountList_olqf6_178 ._listItem_olqf6_178 ._store_olqf6_178 ._icon_olqf6_230 ._checkmark_olqf6_238 {
  position: absolute;
  top: -0.3125rem;
  right: -0.3125rem;
  border: 0;
  border-radius: 50%;
  background: #fdfdfd;
  color: #3996e0;
}
._WooAccountList_olqf6_178 ._listItem_olqf6_178 ._store_olqf6_178 ._remove_olqf6_247 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}
._WooAccountList_olqf6_178 ._listItem_olqf6_178 ._errorNotice_olqf6_253 {
  margin-top: 0.625rem;
}
._WooAccountList_olqf6_178 ._listItem_olqf6_178 ._noticeContent_olqf6_178 {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
._WooAccountList_olqf6_178 ._listItem_olqf6_178 ._noticeContent_olqf6_178 ._title_olqf6_178 {
  margin-bottom: 0.3125rem;
  color: #fdfdfd;
}
._WooAccountList_olqf6_178 ._listItem_olqf6_178 ._noticeContent_olqf6_178 ._text_olqf6_188 {
  margin-bottom: 0.75rem;
  color: #fdfdfd;
}
._WooAccountList_olqf6_178 ._listItem_olqf6_178 ._noticeContent_olqf6_178 ._reconnect_olqf6_269 {
  width: 6.875rem;
  border: 0;
  background: #f96672;
  color: #fdfdfd;
}
._WooAccountList_olqf6_178 ._addBtn_olqf6_275 {
  margin-top: 0.625rem;
}

._addNew_olqf6_279 {
  max-width: 31.25rem;
  margin: auto;
}