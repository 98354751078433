/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1a8o2_157 {
  display: flex;
}

._justify-content-space-between_1a8o2_161 {
  justify-content: space-between;
}

._tabular-nums_1a8o2_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1a8o2_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._PercentagePanel_1a8o2_178 ._header_1a8o2_178 {
  display: flex;
  justify-content: space-between;
}
._PercentagePanel_1a8o2_178 ._header_1a8o2_178 ._title_1a8o2_182 {
  font-size: 1rem;
  font-weight: 600;
}