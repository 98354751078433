/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_w0541_157 {
  display: flex;
}

._justify-content-space-between_w0541_161 {
  justify-content: space-between;
}

._tabular-nums_w0541_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_w0541_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._CreateProjectApplicationStep_w0541_178 ._icon_w0541_178 {
  background-image: url("/etc/aspirex/app/config/esbuild/../../src/app/assets/images/create_application.png");
  background-size: 3.75rem 3.75rem;
}