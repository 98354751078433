/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1azg6_157 {
  display: flex;
}

._justify-content-space-between_1azg6_161 {
  justify-content: space-between;
}

._tabular-nums_1azg6_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1azg6_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._BaseMethod_1azg6_178 ._title_1azg6_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._BaseMethod_1azg6_178 {
  display: flex;
  flex: 0 1 calc(33.33% - 0.5rem);
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: 1.25rem;
  border: 0.0625rem solid #d9d9d9;
  border-radius: 0.375rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
  text-align: center;
  cursor: pointer;
}
._BaseMethod_1azg6_178 ._iconBox_1azg6_233 {
  display: flex;
  align-items: center;
  justify-items: center;
  width: 2.25rem;
  height: 2.25rem;
  margin-bottom: 0.625rem;
  border-radius: 0.375rem;
}
._BaseMethod_1azg6_178 ._icon_1azg6_233 {
  max-width: 1.5625rem;
  max-height: 1.5625rem;
  margin: 0 auto;
  filter: brightness(0) invert(1);
}
._BaseMethod_1azg6_178 ._title_1azg6_178 {
  margin: 0;
}
._BaseMethod_1azg6_178 ._description_1azg6_251 {
  margin: 0;
  color: #8c8c8c;
}