._Sidebar_99mzm_1 {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}
._Sidebar_99mzm_1 ._projectPicker_99mzm_7,
._Sidebar_99mzm_1 ._footerMenu_99mzm_8 {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: var(--grey-10);
}
._Sidebar_99mzm_1 ._projectPicker_99mzm_7 {
  top: 0;
}
._Sidebar_99mzm_1 ._footerMenu_99mzm_8 {
  bottom: 0;
  margin-top: auto;
  border: 0;
}
._Sidebar_99mzm_1 ._divider_99mzm_22 {
  margin: 0;
}

._content_99mzm_26 {
  display: flex;
  flex-direction: column;
}