:root {
  --primary: #167cf4;
  --primary-40: #3d73d7;
  --primary-10: #e6f7ff;
  --secondary: #f58984;
  --secondary-40: #fcbbae;
  --secondary-10: #fef0ed;
  --bay-grey: #989ea8;
  --grey-10: #fafafa;
  --grey-25: #e9e8ea;
  --grey-50: #aeaeae;
  --grey-75: #8f8f8f;
  --grey-100: #1a1b1c;
  --white: #fff;
  --black: #1a1b1c;
  --green: #006462;
  --sand: #f6f3ef;
  --yellow: #ffcd5b;
  --magenta-base: #ffddff;
  --red-base: #f5b3b4;
  --volcano-base: #ff9c93;
  --orange-base: #fabf95;
  --gold-base: #f1e5ac;
  --lime-base: #ebffd0;
  --sage-base: #c1e1c1;
  --cyan-base: #c9f2f2;
  --blue-base: #70d1f1;
  --geekblue-base: #a2aff1;
  --purple-base: #c5b3e3;
  --mauve-base: #c6b0ca;
  --coral-base: #ff8a8a;
  --apricot-base: #f5a287;
  --marigold-base: #fdaf1c;
  --butter-base: #f9eda1;
  --apple-base: #b7d275;
  --asparagus-base: #9db17c;
  --tiffany-base: #5fcdca;
  --baby-base: #a3ddf9;
  --carolina-base: #7eb3d8;
  --rose-base: #ffb4c7;
  --watermelon-base: #ffa1b6;
  --blue-0: #f4fcff;
  --blue-1: #e6f7ff;
  --blue-3: #91d5ff;
  --blue-5: #499dff;
  --blue-6: #167cf4;
  --blue-7: #2771c9;
  --gray-1: #fff;
  --gray-2: #fafafa;
  --gray-3: #f5f5f5;
  --gray-4: #f0f0f0;
  --gray-5: #d9d9d9;
  --gray-6: #bfbfbf;
  --gray-7: #8c8c8c;
  --gray-8: #505256;
  --gray-9: #1f1f21;
  --red-1: #fff1f0;
  --red-3: #ffa39e;
  --red-4: #ff7875;
  --red-5: #ff4d4f;
  --red-6: #f5222d;
  --gold-1: #fffbe6;
  --gold-3: #ffe58f;
  --gold-5: #ffc53d;
  --gold-6: #faad14;
  --gold-7: #d48806;
  --green-1: #f6ffed;
  --green-3: #b7eb8f;
  --green-5: #73d13d;
  --green-6: #52c41a;
  --green-7: #389e0d;
  --text-color: #505256;
  --text-color-secondary: #8c8c8c;
  --text-color-inverse: #fff;
  --icon-color: inherit;
  --icon-color-hover: rgba(26, 27, 28, 0.75);
  --heading-color: #1f1f21;
  --text-color-dark: rgba(255, 255, 255, 0.85);
  --text-color-secondary-dark: rgba(255, 255, 255, 0.65);
  --text-selection-bg: #167cf4;
  --link-color: #167cf4;
  --modal-mask-bg: rgba(79, 90, 114, 0.45);
}

:root {
  --screen-xs: 480px;
  --screen-sm: 576px;
  --screen-md: 768px;
  --screen-lg: 992px;
  --screen-xl: 1200px;
  --screen-xxl: 1600px;
}

:root {
  --spacing-xxxl: 80px;
  --spacing-xxl: 48px;
  --spacing-xl: 32px;
  --spacing-lg: 24px;
  --spacing-md: 16px;
  --spacing-sm: 12px;
  --spacing-xs: 8px;
  --spacing-xxs: 4px;
}

:root {
  --border-radius-max: 9999px;
  --border-radius-lg: 12px;
  --border-radius-base: 6px;
  --border-radius-sm: 4px;
  --border-radius-xs: 2px;
  --height-lg: 40px;
  --height-base: 32px;
  --height-sm: 24px;
}

._ContentCard_1f6r3_231 {
  border-radius: 4px;
}
._ContentCard_1f6r3_231 ._coverImage_1f6r3_234 {
  background-color: #f6f3ef;
  object-fit: cover;
  width: 100%;
}
._ContentCard_1f6r3_231 ._archivedImage_1f6r3_239 {
  opacity: 0.5;
}
._ContentCard_1f6r3_231._loading_1f6r3_242 .ant-skeleton-content .ant-skeleton-title {
  margin: 4px 0 12px;
}
._ContentCard_1f6r3_231:not(._loading_1f6r3_242) {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}
._ContentCard_1f6r3_231:not(._loading_1f6r3_242).fade-enter-active, ._ContentCard_1f6r3_231:not(._loading_1f6r3_242).fade-enter-done {
  opacity: 1;
}
._ContentCard_1f6r3_231 ._loadingImageWrapper_1f6r3_252 {
  background-color: #f6f3ef;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
._ContentCard_1f6r3_231 .ant-card-body {
  padding: 12px;
  height: 78px;
  overflow: hidden;
}
._ContentCard_1f6r3_231 ._notification_1f6r3_264 {
  background-color: #f5222d;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 0 10px;
  height: 20px;
  width: auto;
  min-width: 20px;
  line-height: 18px;
  text-align: center;
  position: absolute;
  z-index: 100;
  top: 12px;
  right: 12px;
}
._ContentCard_1f6r3_231 ._cardBody_1f6r3_280 ._title_1f6r3_280,
._ContentCard_1f6r3_231 ._cardBody_1f6r3_280 ._description_1f6r3_281 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
._ContentCard_1f6r3_231 ._cardBody_1f6r3_280 ._archivedTitle_1f6r3_286 {
  color: #8c8c8c;
}
._ContentCard_1f6r3_231 ._cardBody_1f6r3_280 ._description_1f6r3_281 {
  color: #8c8c8c;
}
._ContentCard_1f6r3_231 ._cardBody_1f6r3_280 ._settingsLink_1f6r3_292 {
  color: #8c8c8c;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
}
._ContentCard_1f6r3_231:hover, ._ContentCard_1f6r3_231:focus, ._ContentCard_1f6r3_231:focus-within {
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.05), 0 3px 6px 0 rgba(0, 0, 0, 0.06), 0 5px 12px 4px rgba(0, 0, 0, 0.06);
}
._ContentCard_1f6r3_231:hover ._cardBody_1f6r3_280 ._settingsLink_1f6r3_292, ._ContentCard_1f6r3_231:focus ._cardBody_1f6r3_280 ._settingsLink_1f6r3_292, ._ContentCard_1f6r3_231:focus-within ._cardBody_1f6r3_280 ._settingsLink_1f6r3_292 {
  opacity: 1;
  pointer-events: all;
}
._ContentCard_1f6r3_231._smallContentCard_1f6r3_305 ._coverImage_1f6r3_234,
._ContentCard_1f6r3_231._smallContentCard_1f6r3_305 ._loadingImageWrapper_1f6r3_252 {
  height: 170px;
}
._ContentCard_1f6r3_231._largeContentCard_1f6r3_309 ._coverImage_1f6r3_234,
._ContentCard_1f6r3_231._largeContentCard_1f6r3_309 ._loadingImageWrapper_1f6r3_252 {
  height: 350px;
}
@media only screen and (min-width: 576px) {
  ._ContentCard_1f6r3_231._largeContentCard_1f6r3_309 ._coverImage_1f6r3_234,
  ._ContentCard_1f6r3_231._largeContentCard_1f6r3_309 ._loadingImageWrapper_1f6r3_252 {
    height: 350px;
  }
}
@media only screen and (min-width: 768px) {
  ._ContentCard_1f6r3_231._largeContentCard_1f6r3_309 ._coverImage_1f6r3_234,
  ._ContentCard_1f6r3_231._largeContentCard_1f6r3_309 ._loadingImageWrapper_1f6r3_252 {
    height: 256px;
  }
}
@media only screen and (min-width: 1600px) {
  ._ContentCard_1f6r3_231._largeContentCard_1f6r3_309 ._coverImage_1f6r3_234,
  ._ContentCard_1f6r3_231._largeContentCard_1f6r3_309 ._loadingImageWrapper_1f6r3_252 {
    height: 350px;
  }
}