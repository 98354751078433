.offer-landing-page-preview {
  /* Product title */
  /* Star rating container */
  /* Star rating */
  /* Review count */
  /* Price container */
  /* Sale price */
  /* Original price */
  /* Discount badge */
  /* Shop Now button */
  /* Medium screens - 2 columns */
  /* Large screens - 3 columns */
  /* Card styles */
  /* Card image loading with aspect ratio */
  /* Card title */
  /* Card description */
  /* The loading Class */
  /* Loading Animation */
  /* Position text in the middle of the page/image */
  /* Container styles */
  /* Inner container styles */
  /* Gradient overlay for top and bottom shadows */
  /* Top gradient shadow */
  /* Bottom gradient shadow */
  /* Override Bootstrap's default transitions */
  /* Disable Bootstrap's fade transition */
  /* Image styles */
  /* Navigation button styles */
  /* Navigation icons */
  /* Hide caption and channel name */
  /* General responsive text adjustments */
  /* Add padding utility class */
  /* Tablet and desktop layout */
  /* Ensure images within frame-30 are responsive */
  /* Mobile first approach - 1 column */
  /* Medium screens - 2 columns */
  /* Large screens - 3 columns */
  /* Make sure the parent container has relative positioning */
  /* Hero featured product name and price specific styles */
  /* Rating stars and review count styles */
  /* Ensure proper alignment of stars and review count */
}
.offer-landing-page-preview ol, .offer-landing-page-preview ul, .offer-landing-page-preview menu {
  list-style: auto;
  padding: revert;
}
.offer-landing-page-preview .customer-s-landing .header-background {
  background: #a2ada2;
  width: 100%;
}
.offer-landing-page-preview .customer-s-landing .frame-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 32px 0px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .frame-4 {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
}
.offer-landing-page-preview .featured-product-grid {
  margin: 20px auto;
  text-align: center;
}
.offer-landing-page-preview .featured-product {
  margin-bottom: 40px;
}
.offer-landing-page-preview .product-grid {
  display: grid;
  gap: 20px;
}
.offer-landing-page-preview .product-card, .offer-landing-page-preview .featured-product-card {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}
.offer-landing-page-preview .product-title {
  font-size: 1.2em;
  margin-top: 10px;
}
.offer-landing-page-preview .product-pricing .price {
  font-size: 1.1em;
  font-weight: bold;
}
.offer-landing-page-preview .product-pricing .original-price {
  font-size: 0.9em;
  text-decoration: line-through;
  color: #888;
}
.offer-landing-page-preview .cta-btn {
  display: inline-block;
  margin-top: 10px;
  background-color: #b56d40;
  color: white;
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 5px;
}
.offer-landing-page-preview .loved-by {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.offer-landing-page-preview .customer-s-landing {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  background-color: #fbfbf9;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  height: 100vh;
  overflow-y: scroll;
}
.offer-landing-page-preview .customer-s-landing .div {
  display: flex;
  align-items: center;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .frame-3 {
  display: flex;
  flex-direction: column;
  width: 18px;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  border-radius: 43px;
  padding: 0px 32px;
}
.offer-landing-page-preview .customer-s-landing .div-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  padding: 40px 20px;
  position: relative;
  margin: 0 auto;
}
.offer-landing-page-preview .customer-s-landing .frame-5 {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}
.offer-landing-page-preview .customer-s-landing .frame-6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .frame-7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  padding: 8px;
}
.offer-landing-page-preview .customer-s-landing .frame-8 {
  gap: 8px;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}
.offer-landing-page-preview .customer-s-landing .frame-9 {
  display: flex;
  gap: 8px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  flex-direction: column;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper {
  position: relative;
  width: 100%;
  font-weight: 400;
  color: #000000;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 48px;
}
.offer-landing-page-preview .customer-s-landing .p {
  position: relative;
  width: 100%;
  max-width: 467px;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: #5d6974;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22.4px;
}
.offer-landing-page-preview .customer-s-landing .frame-10 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  position: relative;
  flex: 0 0 auto;
  margin-bottom: -12px;
  background-color: #fbfbf9;
  border-radius: 16px;
  border: 1px solid;
  border-color: #e5e8eb;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.1215686275);
}
.offer-landing-page-preview .customer-s-landing .frame-11 {
  display: flex;
  gap: 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .img {
  position: relative;
  align-self: stretch;
  width: 112px;
  height: 112px;
  object-fit: cover;
  border-radius: 16px;
}
.offer-landing-page-preview .customer-s-landing .frame-12 {
  align-items: center;
  gap: 24px;
  flex: 1;
  flex-grow: 1;
  display: flex;
  position: relative;
  align-self: stretch;
}
.offer-landing-page-preview .customer-s-landing .frame-13 {
  gap: 12px;
  flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}
.offer-landing-page-preview .customer-s-landing .frame-14 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-2 {
  position: relative;
  flex: 1;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
}
.offer-landing-page-preview .customer-s-landing .frame-15 {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-3 {
  font-family: "Font Awesome 6 Pro-Solid", Helvetica;
  color: transparent;
  text-shadow: 0 0 0 #9c6e4b;
  font-size: 12px;
  line-height: 12px;
  position: relative;
  width: fit-content;
  font-weight: 400;
  letter-spacing: 0;
  white-space: nowrap;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-4 {
  position: relative;
  width: fit-content;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
  display: flex;
}
.offer-landing-page-preview .customer-s-landing .frame-16 {
  display: inline-flex;
  align-items: flex-end;
  gap: 6px;
  position: relative;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-5 {
  position: relative;
  width: fit-content;
  font-size: 18px;
  line-height: 28px;
  white-space: nowrap;
  font-weight: 500;
  letter-spacing: 0;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-6 {
  position: relative;
  width: 36px;
  height: 28px;
  margin-top: -1px;
  color: rgba(40, 28, 28, 0.5019607843);
  font-size: 14px;
  line-height: 19.6px;
  text-decoration: line-through;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  letter-spacing: 0;
}
.offer-landing-page-preview .customer-s-landing .span {
  padding: 12px;
  align-self: stretch;
  flex: 0 0 auto;
  background-color: #884f39;
  border-radius: 12px;
  box-shadow: var(--elevation-re-fresh);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-7 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Adelle Sans Devanagari-Bold", Helvetica;
  font-weight: 700;
  color: #f2eaea;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0;
  line-height: 24.3px;
  white-space: nowrap;
  text-decoration: none;
}
.offer-landing-page-preview .customer-s-landing .frame-17 {
  display: inline-flex;
  height: 24px;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 2px 8px;
  position: relative;
  top: auto;
  left: auto;
  background-color: rgba(136, 79, 57, 0.1882352941);
  border-radius: 56px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1019607843);
  margin: 16px 0;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-8 {
  position: relative;
  width: fit-content;
  margin-top: -0.5px;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #884f39;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 19.2px;
  white-space: nowrap;
}
.offer-landing-page-preview .customer-s-landing .group-wrapper {
  position: relative;
  top: auto;
  left: auto;
  width: 100%;
  max-width: 145px;
  margin: 20px auto;
}
.offer-landing-page-preview .customer-s-landing .group {
  position: relative;
  flex: 1;
  flex-grow: 1;
  height: 145px;
  background-color: rgba(251, 251, 249, 0.4);
  border-radius: 72.5px;
}
.offer-landing-page-preview .customer-s-landing .overlap {
  position: relative;
  width: 154px;
  height: 104px;
  top: 9px;
  left: 8px;
}
.offer-landing-page-preview .customer-s-landing .image-2 {
  position: absolute;
  width: 71px;
  height: 80px;
  top: 22px;
  left: 29px;
}
.offer-landing-page-preview .customer-s-landing .frame-18 {
  display: flex;
  flex-direction: column;
  width: 20px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 4px 3px 2px;
  position: absolute;
  top: 84px;
  left: 78px;
  background-color: #ffffff;
  border-radius: 31px;
  overflow: hidden;
}
.offer-landing-page-preview .customer-s-landing .div-2 {
  /* margin-top: -1px; */
  color: #000000;
  font-size: 14px;
  line-height: 14px;
  position: relative;
  width: fit-content;
  font-weight: 400;
  letter-spacing: 0;
  white-space: nowrap;
  margin: unset;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-9 {
  font-family: "Irish Grover-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-10 {
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
}
.offer-landing-page-preview .customer-s-landing .group-2 {
  position: absolute;
  width: 154px;
  height: 89px;
  top: 0;
  left: 0;
}
.offer-landing-page-preview .customer-s-landing .overlap-group {
  position: absolute;
  width: 42px;
  height: 82px;
  top: 8px;
  left: 0;
}
.offer-landing-page-preview .customer-s-landing .overlap-group-2 {
  position: absolute;
  width: 35px;
  height: 75px;
  top: 6px;
  left: 0;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-11 {
  position: absolute;
  width: 8px;
  top: 60px;
  left: 8px;
  transform: rotate(-110.98deg);
  color: #16282d;
  font-size: 14px;
  line-height: normal;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  letter-spacing: 0;
}
.offer-landing-page-preview .customer-s-landing .o {
  position: absolute;
  width: 11px;
  top: 45px;
  left: 3px;
  transform: rotate(-94.72deg);
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #16282d;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}
.offer-landing-page-preview .customer-s-landing .v {
  position: absolute;
  width: 10px;
  top: 29px;
  left: 5px;
  transform: rotate(-77.35deg);
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #16282d;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}
.offer-landing-page-preview .customer-s-landing .e {
  position: absolute;
  width: 9px;
  top: 14px;
  left: 11px;
  transform: rotate(-61.09deg);
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #16282d;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}
.offer-landing-page-preview .customer-s-landing .d {
  position: absolute;
  width: 11px;
  top: 1px;
  left: 20px;
  transform: rotate(-44.28deg);
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #16282d;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-12 {
  position: absolute;
  width: 4px;
  top: 0;
  left: 34px;
  transform: rotate(-30.27deg);
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #16282d;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}
.offer-landing-page-preview .customer-s-landing .b {
  position: absolute;
  width: 10px;
  top: 2px;
  left: 42px;
  transform: rotate(-16.82deg);
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #16282d;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}
.offer-landing-page-preview .customer-s-landing .y {
  position: absolute;
  width: 10px;
  top: 0;
  left: 58px;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #16282d;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-13 {
  position: absolute;
  width: 4px;
  top: 1px;
  left: 74px;
  transform: rotate(13.45deg);
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #16282d;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}
.offer-landing-page-preview .customer-s-landing .overlap-2 {
  position: absolute;
  width: 47px;
  height: 67px;
  top: 5px;
  left: 80px;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-14 {
  position: absolute;
  width: 8px;
  top: 1px;
  left: 3px;
  transform: rotate(25.78deg);
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #16282d;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}
.offer-landing-page-preview .customer-s-landing .overlap-3 {
  position: absolute;
  width: 36px;
  height: 59px;
  top: 8px;
  left: 10px;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-15 {
  position: absolute;
  width: 10px;
  top: 1px;
  left: 4px;
  transform: rotate(42.04deg);
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #16282d;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}
.offer-landing-page-preview .customer-s-landing .overlap-4 {
  position: absolute;
  width: 26px;
  height: 44px;
  top: 14px;
  left: 10px;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-16 {
  position: absolute;
  width: 10px;
  top: 0;
  left: 5px;
  transform: rotate(59.41deg);
  color: #16282d;
  font-size: 14px;
  line-height: normal;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  letter-spacing: 0;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-17 {
  position: absolute;
  width: 11px;
  top: 15px;
  left: 10px;
  transform: rotate(75.67deg);
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #16282d;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-18 {
  position: absolute;
  width: 11px;
  top: 30px;
  left: 12px;
  transform: rotate(91.92deg);
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #16282d;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}
.offer-landing-page-preview .customer-s-landing .frame-19 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}
.offer-landing-page-preview .customer-s-landing .frame-20 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 0px 32px 0px 0px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .frame-21 {
  display: flex;
  flex-direction: column;
  width: 410px;
  height: 688px;
  align-items: flex-start;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}
.offer-landing-page-preview .customer-s-landing .frame-22 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 10px 12px;
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
  border-radius: 16px;
  background-image: url("https://img.freepik.com/free-psd/realistic-spring-sale-banner-template-design_23-2149395205.jpg");
  background-size: cover;
  background-position: 50% 50%;
}
.offer-landing-page-preview .customer-s-landing .tag-basic {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
  position: relative;
  flex: 0 0 auto;
  background-color: rgba(229, 232, 235, 0.7098039216);
  border-radius: 8px;
}
.offer-landing-page-preview .customer-s-landing .icon {
  position: relative;
  width: 16px;
  height: 16px;
}
.offer-landing-page-preview .customer-s-landing .smile {
  position: absolute;
  height: 12px;
  top: 1px;
  left: 1px;
  font-family: "Font Awesome 6 Pro-Solid", Helvetica;
  font-weight: 400;
  color: var(--neutralgrey-6);
  font-size: 12px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}
.offer-landing-page-preview .customer-s-landing .tag {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--regular-75-caption-font-family);
  font-weight: var(--regular-75-caption-font-weight);
  color: var(--neutralgrey-6);
  font-size: var(--regular-75-caption-font-size);
  letter-spacing: var(--regular-75-caption-letter-spacing);
  line-height: var(--regular-75-caption-line-height);
  white-space: nowrap;
  font-style: var(--regular-75-caption-font-style);
}
.offer-landing-page-preview .customer-s-landing .fontawesome-icon {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  top: 10px;
  left: 143px;
  border-radius: 8px;
  overflow: hidden;
}
.offer-landing-page-preview .customer-s-landing .v-icon {
  position: relative;
  width: 24px;
  height: 24px;
  background-color: rgba(255, 255, 255, 0.6);
}
.offer-landing-page-preview .customer-s-landing .smile-2 {
  position: absolute;
  height: 16px;
  top: 2px;
  left: 5px;
  font-family: "Font Awesome 6 Pro-Regular", Helvetica;
  font-weight: 400;
  color: var(--redred-5);
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}
.offer-landing-page-preview .customer-s-landing .frame-23 {
  top: 690px;
  left: 120px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  position: absolute;
}
.offer-landing-page-preview .customer-s-landing .lokesh-s-views {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: var(--neutralgrey-0);
  font-size: 14px;
  letter-spacing: 4px;
  line-height: 22.4px;
  white-space: break-spaces;
}
.offer-landing-page-preview .customer-s-landing .image-wrapper {
  display: flex;
  flex-direction: column;
  width: 410px;
  height: 100%;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9019607843);
}
.offer-landing-page-preview .customer-s-landing .image-3 {
  position: relative;
  width: 100px;
  height: 8px;
}
.offer-landing-page-preview .customer-s-landing .frame-24 {
  display: flex;
  flex-direction: column;
  width: 105px;
  align-items: flex-start;
  position: relative;
  align-self: stretch;
  border-radius: 16px;
  overflow: hidden;
  opacity: 0.3;
}
.offer-landing-page-preview .customer-s-landing .image-4 {
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
  object-fit: cover;
}
.offer-landing-page-preview .customer-s-landing .frame-25 {
  top: 733px;
  left: -151px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  position: absolute;
}
.offer-landing-page-preview .customer-s-landing .img-wrapper {
  display: flex;
  flex-direction: column;
  width: 304px;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  top: -44px;
  left: -238px;
  background-color: rgba(0, 0, 0, 0.2980392157);
}
.offer-landing-page-preview .customer-s-landing .image-5 {
  position: relative;
  width: 100px;
  height: 4px;
  margin-top: -5999px;
  margin-left: -8392.45px;
}
.offer-landing-page-preview .customer-s-landing .frame-26 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 9px 16px;
  position: absolute;
  top: 341px;
  left: 396px;
  background-color: rgba(255, 255, 255, 0.9019607843);
  border-radius: 50px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1019607843);
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-19 {
  position: relative;
  align-self: stretch;
  margin-top: -1px;
  font-family: "Font Awesome 6 Pro-Regular", Helvetica;
  font-weight: 400;
  color: var(--black);
  font-size: 24px;
  text-align: center;
  letter-spacing: 1px;
  line-height: 28.8px;
}
.offer-landing-page-preview .customer-s-landing .frame-27 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 36px;
  width: 100%;
  padding: 40px 20px;
  position: relative;
  align-self: stretch;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-20 {
  position: relative;
  width: fit-content;
  text-align: left;
  letter-spacing: 1px;
  white-space: nowrap;
  white-space: break-spaces;
}
.offer-landing-page-preview .customer-s-landing .frame-28 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}
.offer-landing-page-preview .customer-s-landing .frame-29 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  width: 100%;
}
@media (min-width: 768px) {
  .offer-landing-page-preview .customer-s-landing .frame-29 {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 400px;
  }
  .offer-landing-page-preview .customer-s-landing .frame-32 {
    flex: 1;
    max-width: 50%;
    padding-left: 40px;
  }
}
.offer-landing-page-preview .customer-s-landing .frame-30 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}
.offer-landing-page-preview .customer-s-landing .image-6 {
  position: relative;
  align-self: stretch;
  width: 100%;
  height: auto;
  object-fit: cover;
}
.offer-landing-page-preview .customer-s-landing .frame-31 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}
.offer-landing-page-preview .customer-s-landing .frame-32 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  position: relative;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-21 {
  font-size: 24px;
  line-height: 28px;
  font-family: "Abhaya Libre-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  margin: 0;
}
.offer-landing-page-preview .customer-s-landing .frame-33 {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-direction: column;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-24 {
  color: #b56d40;
  font-size: 14px;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-25 {
  color: #666;
  font-size: 12px;
}
.offer-landing-page-preview .customer-s-landing .frame-34 {
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin: 4px 0;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-23 {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-22 {
  font-size: 18px;
  color: #666;
  text-decoration: line-through;
}
.offer-landing-page-preview .customer-s-landing .frame-17 {
  display: inline-flex;
  padding: 2px 8px;
  background-color: #f5e6e0;
  border-radius: 4px;
  margin: 0;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-8 {
  color: #b56d40;
  font-size: 14px;
  font-weight: 500;
}
.offer-landing-page-preview .customer-s-landing .span-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 14px;
  background-color: #8b4513;
  border-radius: 8px;
  margin-top: 16px;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-33 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
@media (min-width: 768px) {
  .offer-landing-page-preview .customer-s-landing .frame-32 {
    padding-left: 40px;
  }
}
.offer-landing-page-preview .customer-s-landing .frame-35 {
  display: inline-flex;
  align-items: center;
  gap: 2px;
  position: relative;
  flex: 0 0 auto;
  font-size: 12px;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-26 {
  font-weight: 500;
  color: #a2adb9;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-27 {
  font-family: "Inter-Bold", Helvetica;
  font-weight: 700;
  color: #5d6974;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-28 {
  font-weight: 500;
  color: #5d6974;
  text-decoration: underline;
}
.offer-landing-page-preview .customer-s-landing .rectangle-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 16px 0px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .rectangle-2 {
  height: 1px;
  background-color: #cbd1d7;
  position: relative;
  align-self: stretch;
  width: 100%;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-29 {
  position: relative;
  width: fit-content;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: var(--neutralgrey-5);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 12px;
  white-space: nowrap;
}
.offer-landing-page-preview .customer-s-landing .frame-36 {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 4px;
  position: relative;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .frame-37 {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 31px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1019607843);
  background-image: url("https://img.freepik.com/free-psd/realistic-spring-sale-banner-template-design_23-2149395205.jpg");
  background-size: cover;
  background-position: 50% 50%;
}
.offer-landing-page-preview .customer-s-landing .frame-38 {
  position: relative;
  width: 18px;
  height: 18px;
  background-color: #f6e5dd;
  border-radius: 31px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1019607843);
}
.offer-landing-page-preview .customer-s-landing .frame-39 {
  position: relative;
  width: 18px;
  height: 18px;
  background-color: #aaa9a5;
  border-radius: 31px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1019607843);
}
.offer-landing-page-preview .customer-s-landing .frame-40 {
  position: relative;
  width: 18px;
  height: 18px;
  background-color: #02025e;
  border-radius: 31px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1019607843);
}
.offer-landing-page-preview .customer-s-landing .frame-41 {
  position: relative;
  width: 18px;
  height: 18px;
  background-color: #d7d5cc;
  border-radius: 31px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1019607843);
}
.offer-landing-page-preview .customer-s-landing .frame-42 {
  position: relative;
  width: 18px;
  height: 18px;
  background-color: #c9bdaf;
  border-radius: 31px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1019607843);
}
.offer-landing-page-preview .customer-s-landing .frame-43 {
  position: relative;
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border-radius: 31px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1019607843);
}
.offer-landing-page-preview .customer-s-landing .frame-44 {
  display: inline-flex;
  gap: 4px;
  padding: 0px 30px;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .frame-45 {
  position: relative;
  width: 18px;
  height: 18px;
  background-color: #434343;
  border-radius: 31px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1019607843);
}
.offer-landing-page-preview .customer-s-landing .frame-46 {
  position: relative;
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border-radius: 31px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1019607843);
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-30 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: var(--neutralgrey-5);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  white-space: nowrap;
}
.offer-landing-page-preview .customer-s-landing .frame-47 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .element-set-PIECES-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 18px 12px;
  position: relative;
  flex: 1;
  flex-grow: 1;
  background-color: var(--neutralgrey-0);
  border-radius: 12px;
  border: 1px solid;
  border-color: #d28f75;
}
.offer-landing-page-preview .customer-s-landing .element-set-PIECES {
  position: relative;
  flex: 1;
  margin-top: -1px;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: #d28f75;
  font-size: 16px;
  text-align: center;
  letter-spacing: 1px;
  line-height: 24px;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-31 {
  letter-spacing: 0.16px;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-32 {
  font-size: 12px;
  letter-spacing: 0.12px;
  line-height: 18px;
}
.offer-landing-page-preview .customer-s-landing .frame-48 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 18px 12px;
  position: relative;
  flex: 1;
  flex-grow: 1;
  background-color: var(--neutralgrey-0);
  border-radius: 12px;
  border: 1px solid;
  border-color: var(--neutralgrey-2);
}
.offer-landing-page-preview .customer-s-landing .element-set-PIECES-2 {
  position: relative;
  flex: 1;
  margin-top: -1px;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: var(--black);
  font-size: 16px;
  text-align: center;
  letter-spacing: 1px;
  line-height: 24px;
}
.offer-landing-page-preview .customer-s-landing .frame-49 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .span-2 {
  height: 40px;
  padding: 4px 12px;
  align-self: stretch;
  background-color: #884f39;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-33 {
  position: relative;
  width: fit-content;
  font-family: "Adelle Sans Devanagari-Semibold", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 23.4px;
  white-space: nowrap;
  text-decoration: none;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-34 {
  position: relative;
  width: fit-content;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: var(--neutralgrey-4);
  font-size: 10px;
  letter-spacing: 0;
  line-height: 10px;
  white-space: nowrap;
}
.offer-landing-page-preview .customer-s-landing .frame-50 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  padding: 40px 20px;
  position: relative;
  align-self: stretch;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .frame-51 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  padding: 0;
}
.offer-landing-page-preview .customer-s-landing .frame-52 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  width: 100%;
}
.offer-landing-page-preview .customer-s-landing .frame-53 {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .offer-landing-page-preview .customer-s-landing .frame-53 {
    width: calc(50% - 12px);
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .offer-landing-page-preview .page-width {
    max-width: 1400px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .offer-landing-page-preview .customer-s-landing .frame-52 {
    justify-content: flex-start;
    margin: 0 -12px;
  }
  .offer-landing-page-preview .customer-s-landing .frame-53 {
    width: calc(33.333% - 24px);
    max-width: none;
    margin: 0 12px;
  }
  .offer-landing-page-preview .customer-s-landing .frame-4 {
    flex-direction: row;
  }
}
.offer-landing-page-preview .customer-s-landing .image-7 {
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
  height: auto;
  object-fit: cover;
  border-radius: 12px;
  min-height: unset;
  max-height: unset;
}
.offer-landing-page-preview .customer-s-landing .frame-54 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .frame-55 {
  display: flex;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
  align-items: center;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-35 {
  margin-top: -1px;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  flex: 1;
  font-weight: 400;
  color: #101010;
  letter-spacing: 0;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-36 {
  position: relative;
  width: fit-content;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: var(--neutralgrey-4);
  font-size: 14px;
  line-height: 19.6px;
  text-decoration: line-through;
  white-space: nowrap;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-37 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  color: #5D6974;
  font-size: 16px;
  line-height: 28px;
  white-space: break-spaces;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  letter-spacing: 0;
}
.offer-landing-page-preview .customer-s-landing .frame-56 {
  display: inline-flex;
  align-items: flex-start;
  gap: 4px;
  padding: 8px 0px 0px;
  position: relative;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .frame-57 {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 31px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1019607843);
  background-image: url("https://img.freepik.com/free-psd/realistic-spring-sale-banner-template-design_23-2149395205.jpg");
  background-size: cover;
  background-position: 50% 50%;
}
.offer-landing-page-preview .customer-s-landing .frame-58 {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 31px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1019607843);
  background-image: url("https://img.freepik.com/free-psd/realistic-spring-sale-banner-template-design_23-2149395205.jpg");
  background-size: cover;
  background-position: 50% 50%;
}
.offer-landing-page-preview .customer-s-landing .frame-59 {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 31px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1019607843);
  background-image: url("https://img.freepik.com/free-psd/realistic-spring-sale-banner-template-design_23-2149395205.jpg");
  background-size: cover;
  background-position: 50% 50%;
}
.offer-landing-page-preview .customer-s-landing .frame-60 {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 31px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1019607843);
  background-image: url("https://img.freepik.com/free-psd/realistic-spring-sale-banner-template-design_23-2149395205.jpg");
  background-size: cover;
  background-position: 50% 50%;
}
.offer-landing-page-preview .customer-s-landing .frame-61 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 16px 0px 72px 0px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-38 {
  position: relative;
  align-self: stretch;
  margin-top: -1px;
  font-family: "Adelle Sans Devanagari-Regular", Helvetica;
  font-weight: 400;
  color: var(--black-low-emphasis);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.14px;
  line-height: 21px;
}
.offer-landing-page-preview .customer-s-landing .span-3 {
  width: 197px;
  padding: 4px 12px;
  flex: 0 0 auto;
  border-radius: 8px;
  border: 1px solid;
  border-color: var(--neutralgrey-3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-39 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Adelle Sans Devanagari-Semibold", Helvetica;
  font-weight: 400;
  color: var(--neutralgrey-6);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 23.4px;
  white-space: nowrap;
}
.offer-landing-page-preview .customer-s-landing .frame-62 {
  flex-direction: column;
  padding: 20px;
}
@media (min-width: 768px) {
  .offer-landing-page-preview .customer-s-landing .frame-62 {
    flex-direction: row;
  }
}
.offer-landing-page-preview .customer-s-landing .frame-63 {
  flex-direction: column;
  padding: 20px;
}
@media (min-width: 768px) {
  .offer-landing-page-preview .customer-s-landing .frame-63 {
    flex-direction: row;
    padding: 32px;
  }
}
.offer-landing-page-preview .customer-s-landing .frame-64 {
  gap: 6px;
  flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-40 {
  position: relative;
  align-self: stretch;
  margin-top: -1px;
  font-family: "Abhaya Libre ExtraBold-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 24px;
}
.offer-landing-page-preview .customer-s-landing .add-more-sections-in-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
.offer-landing-page-preview .customer-s-landing .add-more-sections-in {
  position: relative;
  flex: 1;
  margin-top: -1px;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: var(--neutralgrey-4);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22.4px;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-41 {
  position: relative;
  width: fit-content;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: var(--neutralgrey-6);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22.4px;
  text-decoration: underline;
  white-space: nowrap;
}
.offer-landing-page-preview .customer-s-landing .span-4 {
  padding: 8px 50px;
  background-color: var(--neutralgrey-6);
  border-radius: 8px;
  border: 1px solid;
  border-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #16282d;
  border-radius: 8px;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-42 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Adelle Sans Devanagari-Semibold", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 23.4px;
  white-space: nowrap;
}
.offer-landing-page-preview .frame {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 8px 12px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 56px;
  border: 1px solid;
  border-color: transparent;
  margin-bottom: 16px;
}
.offer-landing-page-preview .frame .div {
  display: flex;
  width: 36px;
  height: 48px;
  align-items: center;
  position: relative;
}
.offer-landing-page-preview .frame .ellipse {
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
  object-fit: cover;
  border-radius: 50px;
  background: #884f39;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.offer-landing-page-preview .frame .loved-by-lokesh {
  position: relative;
  width: fit-content;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 1.25px;
  white-space: break-spaces;
  color: #101010;
}
.offer-landing-page-preview .frame .div-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 4px 3px 2px;
  position: absolute;
  top: 45px;
  left: 49px;
  background-color: #ffffff;
  border-radius: 31px;
  overflow: hidden;
}
.offer-landing-page-preview .frame .p {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Irish Grover-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  white-space: nowrap;
}
.offer-landing-page-preview .frame .text-wrapper {
  font-family: "Irish Grover-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
}
.offer-landing-page-preview .frame .span {
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
}
.offer-landing-page-preview .carousel-inner > .item > img, .offer-landing-page-preview .carousel-inner > .item > a > img {
  width: 70%;
  margin: auto;
}
.offer-landing-page-preview .carousel {
  width: 100% !important;
  max-width: 400px !important;
  height: auto !important;
  aspect-ratio: 2/3;
  margin: 0 auto;
}
.offer-landing-page-preview #contentCarousel {
  overflow: visible;
}
.offer-landing-page-preview .card {
  background-color: #fff;
  width: 100%;
  /* Take full width of the container */
  max-width: 800px;
  /* Optional: limit the maximum width */
  margin: 12px auto;
  /* Center horizontally */
  border-radius: 5px;
  box-shadow: 9px 17px 45px -29px rgba(0, 0, 0, 0.44);
  overflow: hidden;
}
.offer-landing-page-preview .card__image.loading {
  width: 100%;
  /* Full width */
  aspect-ratio: 16/9;
  /* Define the aspect ratio */
  background-color: #e2e2e2;
  position: relative;
  color: #000000;
}
.offer-landing-page-preview .card__title {
  padding: 8px;
  font-size: 22px;
  font-weight: 700;
}
.offer-landing-page-preview .card__title.loading {
  height: 1rem;
  width: 50%;
  margin: 1rem;
  border-radius: 3px;
  background-color: #e2e2e2;
  position: relative;
}
.offer-landing-page-preview .card__description {
  padding: 8px;
  font-size: 16px;
}
.offer-landing-page-preview .card__description.loading {
  height: 3rem;
  margin: 1rem;
  border-radius: 3px;
  background-color: #e2e2e2;
  position: relative;
}
.offer-landing-page-preview .loading::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  /* Adding animation */
  animation: loading 0.8s infinite;
}
@keyframes _loading_1mmxo_1785 {
  100% {
    transform: translateX(100%);
  }
}
.offer-landing-page-preview .bg-image {
  /* The image used */
  background-image: url("https://img.freepik.com/free-psd/realistic-spring-sale-banner-template-design_23-2149395205.jpg");
  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.offer-landing-page-preview .bg-text {
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
.offer-landing-page-preview .content-loading {
  filter: blur(10px);
  transition: filter 0.3s ease;
  pointer-events: none;
}
.offer-landing-page-preview .loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  flex-direction: column;
  gap: 20px;
  background: rgba(255, 255, 255, 0.5);
  /* Semi-transparent background */
}
.offer-landing-page-preview .loading-wrapper.active {
  display: flex;
}
.offer-landing-page-preview .loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #e0e0e0;
  /* Lighter color for the circle */
  border-top: 5px solid #333;
  /* Darker color for the spinning part */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  display: block !important;
}
@keyframes _spin_1mmxo_1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.offer-landing-page-preview .hero-section {
  background: linear-gradient(90deg, #f7ede2, #ffddd2);
  padding: 2rem;
  text-align: center;
}
.offer-landing-page-preview .hero-section h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}
.offer-landing-page-preview .hero-section p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
}
.offer-landing-page-preview .hero-section .cta-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #d77a61;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
}
.offer-landing-page-preview .product-section {
  padding: 2rem;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}
.offer-landing-page-preview .product-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  width: 300px;
  text-align: center;
}
.offer-landing-page-preview .product-card img {
  width: 100%;
  height: auto;
}
.offer-landing-page-preview .product-card h3 {
  font-size: 1.2rem;
  color: #333;
  padding: 0.5rem 1rem;
}
.offer-landing-page-preview .product-card p {
  font-size: 1rem;
  color: #555;
  padding: 0 1rem;
}
.offer-landing-page-preview .product-card .price {
  font-weight: bold;
  color: #d77a61;
  font-size: 1.2rem;
  margin: 1rem 0;
}
.offer-landing-page-preview .carousel-control-prev, .offer-landing-page-preview .carousel-control-next {
  width: 7%;
}
.offer-landing-page-preview .carousel {
  position: relative;
  overflow: hidden;
  height: 600px !important;
  border-radius: 16px;
}
.offer-landing-page-preview .carousel-inner {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 16px;
}
.offer-landing-page-preview .carousel-inner::before, .offer-landing-page-preview .carousel-inner::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 150px;
  z-index: 2;
  pointer-events: none;
}
.offer-landing-page-preview .carousel-inner::before {
  top: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
}
.offer-landing-page-preview .carousel-inner::after {
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
}
.offer-landing-page-preview .carousel-item {
  transition: transform 0.6s ease-in-out !important;
  -webkit-transition: transform 0.6s ease-in-out !important;
  backface-visibility: hidden;
  perspective: 1000px;
  width: 400px !important;
  height: 600px !important;
}
.offer-landing-page-preview .carousel-item.active, .offer-landing-page-preview .carousel-item-next, .offer-landing-page-preview .carousel-item-prev {
  display: block !important;
  width: 400px !important;
  height: 600px !important;
}
.offer-landing-page-preview .carousel-item img {
  width: 400px !important;
  height: 600px !important;
  object-fit: cover;
  object-position: center;
  border-radius: 16px;
}
.offer-landing-page-preview .carousel-control-prev, .offer-landing-page-preview .carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px !important;
  height: 40px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  opacity: 0.8;
  z-index: 3;
}
.offer-landing-page-preview .carousel-control-prev {
  left: 10px;
}
.offer-landing-page-preview .carousel-control-next {
  right: 10px;
}
.offer-landing-page-preview .carousel-control-prev-icon, .offer-landing-page-preview .carousel-control-next-icon {
  width: 20px;
  height: 20px;
}
.offer-landing-page-preview .instagram-media {
  width: 100% !important;
  max-width: 540px !important;
  min-width: auto !important;
}
.offer-landing-page-preview .instagram-media a {
  display: none !important;
}
.offer-landing-page-preview .instagram-media .e1e1d {
  display: none !important;
  /* Hides the username */
}
.offer-landing-page-preview #products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
}
@media (max-width: 767px) {
  .offer-landing-page-preview .customer-s-landing .text-wrapper {
    font-size: 32px;
    line-height: 38px;
  }
  .offer-landing-page-preview .customer-s-landing .div-wrapper {
    padding: 20px;
  }
}
.offer-landing-page-preview .page-width {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}
@media (min-width: 768px) {
  .offer-landing-page-preview .page-width {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 768px) {
  .offer-landing-page-preview {
    /* .customer-s-landing .text-wrapper-20 {
      padding-top: 56px;
    } */
  }
  .offer-landing-page-preview .customer-s-landing .frame-28 {
    flex-direction: row;
    align-items: flex-start;
  }
  .offer-landing-page-preview .customer-s-landing .frame-30 {
    flex: 1;
    max-width: 50%;
  }
  .offer-landing-page-preview .customer-s-landing .frame-32 {
    flex: 1;
    max-width: 50%;
  }
}
.offer-landing-page-preview .customer-s-landing .frame-30 img {
  width: 100%;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: 12px;
}
.offer-landing-page-preview .customer-s-landing .frame-52 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  width: 100%;
}
.offer-landing-page-preview .customer-s-landing .frame-53 {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .offer-landing-page-preview .customer-s-landing .frame-53 {
    width: calc(50% - 12px);
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .offer-landing-page-preview .page-width {
    max-width: 1400px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .offer-landing-page-preview .customer-s-landing .frame-52 {
    justify-content: flex-start;
    margin: 0 -12px;
  }
  .offer-landing-page-preview .customer-s-landing .frame-53 {
    width: calc(33.333% - 51px);
    max-width: none;
    margin: 0 12px;
  }
  .offer-landing-page-preview .customer-s-landing .frame-4 {
    flex-direction: row;
  }
}
.offer-landing-page-preview .customer-s-landing .bestseller-badge {
  position: absolute;
  top: 20px;
  right: 15px;
  background-color: #fff;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  display: none;
  /* Hidden by default on mobile */
}
.offer-landing-page-preview .customer-s-landing .frame-10 {
  position: relative;
}
@media (min-width: 1200px) {
  .offer-landing-page-preview .page-width {
    max-width: 1300px;
  }
}
.offer-landing-page-preview #contentCarousel > div {
  height: 100%;
}
.offer-landing-page-preview .carouselWrapper .ant-carousel {
  height: 100%;
}
.offer-landing-page-preview .carouselWrapper .ant-carousel .slick-slider {
  height: 100%;
}
.offer-landing-page-preview .carouselWrapper .ant-carousel .slick-list {
  height: 100%;
}
.offer-landing-page-preview .carouselWrapper .ant-carousel .slick-slide > div {
  height: 100%;
}
.offer-landing-page-preview .carouselWrapper .ant-carousel .slick-slide > div > div {
  height: 100%;
}
.offer-landing-page-preview .carouselWrapper .ant-carousel .slick-track {
  height: 100%;
}
.offer-landing-page-preview .carouselWrapper .ant-carousel .slick-slide {
  height: 100%;
}
.offer-landing-page-preview .carouselWrapper .ant-carousel .slick-prev {
  left: 9px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: white;
  z-index: 99;
}
.offer-landing-page-preview .carouselWrapper .ant-carousel .slick-prev .anticon {
  font-size: 22px;
  fill: #1F1F21;
}
.offer-landing-page-preview .carouselWrapper .ant-carousel .slick-next {
  right: 9px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: white;
}
.offer-landing-page-preview .carouselWrapper .ant-carousel .slick-next .anticon {
  font-size: 22px;
  fill: #1F1F21;
}
@media (min-width: 576px) {
  .offer-landing-page-preview .text-wrapper-2 {
    font-size: 14px;
  }
}
.offer-landing-page-preview .customer-s-landing #hero-featured-product-name, .offer-landing-page-preview .customer-s-landing #hero-featured-product-price {
  font-size: 14px;
  line-height: 1.2;
}
@media (min-width: 576px) {
  .offer-landing-page-preview .customer-s-landing #hero-featured-product-name, .offer-landing-page-preview .customer-s-landing #hero-featured-product-price {
    font-size: 18px;
  }
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-24, .offer-landing-page-preview .customer-s-landing .frame-36 .text-wrapper-24 {
  font-size: 12px;
  line-height: 1;
  margin-right: 2px;
}
.offer-landing-page-preview .customer-s-landing .text-wrapper-25, .offer-landing-page-preview .customer-s-landing #hero-feature-product-reviews-count, .offer-landing-page-preview .customer-s-landing #favorite-featured-product-reviews-count {
  font-size: 12px;
  line-height: 1;
  color: inherit;
}
.offer-landing-page-preview .customer-s-landing .frame-35, .offer-landing-page-preview .customer-s-landing .frame-36 {
  display: flex;
  align-items: center;
  gap: 4px;
}
.offer-landing-page-preview .carousel-wrapper {
  position: relative;
  width: 400px;
  height: 600px;
  margin: 0 auto;
  border-radius: 16px;
  overflow: hidden;
  background: #fff;
}
.offer-landing-page-preview .carousel-wrapper iframe {
  width: 100% !important;
  height: 600px !important;
  min-width: unset !important;
  max-width: unset !important;
  border: none !important;
}
.offer-landing-page-preview .carousel-wrapper .social-embed {
  width: 100% !important;
  height: 600px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.offer-landing-page-preview .carousel-wrapper .rsme-embed > div {
  width: 100%;
}
.mobile-view .customer-s-landing {
  width: 60%;
  max-width: 390px;
}
@media (max-width: 760px) {
  .mobile-view .customer-s-landing {
    width: 100%;
    max-width: 100%;
  }
}
.mobile-view .customer-s-landing .frame-4 {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
}
.mobile-view .customer-s-landing .frame-29 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  width: 100%;
}
.mobile-view .customer-s-landing .frame-32, .mobile-view .customer-s-landing .frame-30 {
  max-width: 100%;
}
.mobile-view .customer-s-landing .frame-52 {
  justify-content: center;
}
.mobile-view .customer-s-landing .frame-53 {
  width: 100%;
}
.mobile-view .customer-s-landing .div-wrapper {
  padding: 0;
  margin-bottom: 32px;
}
.mobile-view .customer-s-landing .frame-wrapper {
  padding: 0;
}
.mobile-view .customer-s-landing .carousel-wrapper {
  width: 100%;
  height: 100%;
}
.mobile-view .ant-card-body {
  display: flex;
  justify-content: center;
}
.product-discounted-price {
  font-size: 12px;
  color: rgba(40, 28, 28, 0.5);
  font-weight: 500;
  text-decoration: line-through;
}