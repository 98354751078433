/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1b2hn_157 {
  display: flex;
}

._justify-content-space-between_1b2hn_161 {
  justify-content: space-between;
}

._tabular-nums_1b2hn_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1b2hn_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._active_1b2hn_178 {
  color: #4eb468;
}

._error_1b2hn_182, ._overLimit_1b2hn_182 {
  color: #F1515F;
}

._pending_1b2hn_186 {
  color: #1a1818;
}

._disabled_1b2hn_190 {
  color: #8f8d91;
}