/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_179ss_157 {
  display: flex;
}

._justify-content-space-between_179ss_161 {
  justify-content: space-between;
}

._tabular-nums_179ss_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_179ss_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ConfigDrawer_179ss_178 {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 21.875rem;
}
._ConfigDrawer_179ss_178 ._configHeader_179ss_185 {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}
._ConfigDrawer_179ss_178 ._configHeader_179ss_185 ._titleContainer_179ss_190 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
._ConfigDrawer_179ss_178 ._configHeader_179ss_185 ._titleContainer_179ss_190 ._title_179ss_190 {
  display: flex;
  align-items: center;
  column-gap: 0.625rem;
}
._ConfigDrawer_179ss_178 ._configHeader_179ss_185 ._titleContainer_179ss_190 ._title_179ss_190 ._titleFont_179ss_200 {
  font-size: 1.5rem;
  font-weight: 600;
}
._ConfigDrawer_179ss_178 ._configHeader_179ss_185 ._description_179ss_204 {
  font-size: 0.875rem;
  font-weight: 400;
  color: #505256;
}
._ConfigDrawer_179ss_178 ._loadingSpinner_179ss_209 {
  margin-top: 3rem;
}
._ConfigDrawer_179ss_178 ._configBody_179ss_212 {
  display: flex;
  flex-direction: column;
  row-gap: 0.9375rem;
  margin-top: 1.25rem;
}
._ConfigDrawer_179ss_178 ._configBody_179ss_212 ._delayConfigContainer_179ss_218 {
  display: flex;
  column-gap: 0.3125rem;
  align-items: center;
}
._ConfigDrawer_179ss_178 ._configBody_179ss_212 ._conditionHeader_179ss_223 {
  font-size: 1rem;
  font-weight: 600;
}
._ConfigDrawer_179ss_178 ._configBody_179ss_212 ._conditionConfigContainer_179ss_227 {
  display: flex;
  flex-direction: column;
  row-gap: 0.625rem;
  border-radius: 0.375rem;
  background: #FAFAFA;
  padding: 0.5rem;
  width: 95%;
  align-self: flex-end;
}
._ConfigDrawer_179ss_178 ._configBody_179ss_212 ._conditionConfigContainer_179ss_227 ._validationError_179ss_237 {
  color: #da3737;
  font-size: 1rem;
}
._ConfigDrawer_179ss_178 ._configBody_179ss_212 ._actionConfigItem_179ss_241 {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
._ConfigDrawer_179ss_178 ._configBody_179ss_212 ._actionConfigItem_179ss_241 ._editTemplateActions_179ss_246 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
._ConfigDrawer_179ss_178 ._configBody_179ss_212 ._actionConfigItem_179ss_241 ._editTemplateActions_179ss_246 ._selectTemplate_179ss_251 {
  width: 18.75rem;
}
._ConfigDrawer_179ss_178 ._configBody_179ss_212 ._actionConfigItem_179ss_241 ._editTemplateActions_179ss_246 ._createNewTemplate_179ss_254 {
  margin-left: 0.5rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  border-radius: 0.375rem;
  color: #505256;
  background-color: #fff;
}
._ConfigDrawer_179ss_178 ._configBody_179ss_212 ._actionConfigItem_179ss_241 ._editTemplateActions_179ss_246 ._createNewTemplate_179ss_254:hover {
  color: #499DFF;
  background-color: #fff;
  cursor: pointer;
}
._ConfigDrawer_179ss_178 ._configBody_179ss_212 ._actionConfigItem_179ss_241 ._editTemplateActions_179ss_246 ._createNewTemplate_179ss_254:disabled {
  border: none;
}
._ConfigDrawer_179ss_178 ._configBody_179ss_212 ._validationError_179ss_237 {
  color: #da3737;
  font-size: 1rem;
}
._ConfigDrawer_179ss_178 ._configFooter_179ss_274 {
  display: flex;
  column-gap: 0.625rem;
  padding-bottom: 0.75rem;
}