/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1lno5_157 {
  display: flex;
}

._justify-content-space-between_1lno5_161 {
  justify-content: space-between;
}

._tabular-nums_1lno5_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1lno5_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
:root {
  --screen-xs: 480px;
  --screen-sm: 576px;
  --screen-md: 768px;
  --screen-lg: 992px;
  --screen-xl: 1200px;
  --screen-xxl: 1600px;
}

._tHead_1lno5_196 {
  padding: 1.25rem 2.1875rem;
  border-bottom: 1px solid #D9D9D9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
._tHead_1lno5_196 ._allstatus_1lno5_203 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 576px) {
  ._tHead_1lno5_196 ._allstatus_1lno5_203 {
    gap: 1rem;
  }
}
._tHead_1lno5_196 ._allstatus_1lno5_203 button {
  display: flex;
  margin-right: 0.3125rem;
  align-items: center;
}
._tHead_1lno5_196 ._allstatus_1lno5_203 button small {
  background: #167CF4;
  color: #fff;
  font-size: 0.625rem;
  padding: 0.0625rem 0.375rem;
  border-radius: 10px;
  line-height: 14px;
  margin-left: 0.625rem;
}
._tHead_1lno5_196 ._search_1lno5_227 {
  width: 13.0625rem;
}
@media only screen and (max-width: 576px) {
  ._tHead_1lno5_196 ._search_1lno5_227 {
    width: 100%;
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 576px) {
  ._tHead_1lno5_196 {
    flex-direction: column;
  }
}