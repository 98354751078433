._ShopifyReauthenticateModal_fsyx0_1 .ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
._ShopifyReauthenticateModal_fsyx0_1 ._errorIcon_fsyx0_7 {
  color: red;
}
._ShopifyReauthenticateModal_fsyx0_1 ._actionButton_fsyx0_10 {
  margin-left: auto;
}