/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1s7j5_157 {
  display: flex;
}

._justify-content-space-between_1s7j5_161 {
  justify-content: space-between;
}

._tabular-nums_1s7j5_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1s7j5_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._TrayIntegration_1s7j5_178 ._title_1s7j5_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._TrayIntegration_1s7j5_178 ._text_1s7j5_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._TrayIntegration_1s7j5_178 ._nav_1s7j5_198 ._item_1s7j5_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.214;
  color: #1a1818;
}

._TrayIntegration_1s7j5_178 ._title_1s7j5_178 {
  margin-bottom: 8px;
}
._TrayIntegration_1s7j5_178 ._text_1s7j5_188 {
  margin-bottom: 24px;
}
._TrayIntegration_1s7j5_178 ._nav_1s7j5_198 {
  margin-bottom: 24px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #DADCDE;
}
._TrayIntegration_1s7j5_178 ._nav_1s7j5_198 ._item_1s7j5_198 {
  margin-right: 21px;
  position: relative;
  cursor: pointer;
  user-select: none;
  width: 80px;
  text-align: center;
  color: #8f8d91;
  font-weight: bold;
  will-change: color;
  transition: color 0.1s ease-out;
}
._TrayIntegration_1s7j5_178 ._nav_1s7j5_198 ._item_1s7j5_198:after {
  position: absolute;
  padding-top: 18px;
  content: "";
  display: block;
  width: 100%;
  border-bottom: 3px solid #3996e0; /* This creates the border. Replace black with whatever color you want. */
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.1s ease-out;
}
._TrayIntegration_1s7j5_178 ._nav_1s7j5_198 ._item_1s7j5_198:hover, ._TrayIntegration_1s7j5_178 ._nav_1s7j5_198 ._item_1s7j5_198._active_1s7j5_245 {
  color: #3996e0;
}
._TrayIntegration_1s7j5_178 ._nav_1s7j5_198 ._item_1s7j5_198._active_1s7j5_245:after {
  transform: scaleX(1);
}