:root {
  --screen-xs: 480px;
  --screen-sm: 576px;
  --screen-md: 768px;
  --screen-lg: 992px;
  --screen-xl: 1200px;
  --screen-xxl: 1600px;
}

._card_1ryan_19 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 24px;
}
._card_1ryan_19 .ant-card-head-title {
  padding: 0;
}
._card_1ryan_19 .ant-card-head {
  min-height: auto;
  border: none;
  padding: 0;
}
._card_1ryan_19 .ant-card-body {
  padding: 0;
}

@media only screen and (min-width: 480px) {
  ._card_1ryan_19 {
    padding: 32px 24px;
  }
}
@media only screen and (min-width: 768px) {
  ._card_1ryan_19 {
    padding: 32px 32px;
  }
}
@media only screen and (min-width: 1200px) {
  ._card_1ryan_19 {
    padding: 32px 48px;
  }
}
@media only screen and (min-width: 1600px) {
  ._card_1ryan_19 {
    padding: 32px 80px;
  }
}
._dark_1ryan_57 {
  background-color: var(--gray-2);
  border-radius: 0;
  border-bottom: 1px solid var(--gray-4);
}

._icon_1ryan_63 svg {
  fill: var(--gray-9);
  font-size: 24px;
}

._title_1ryan_68 .ant-typography {
  margin: 0;
}