/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1ihr5_157 {
  display: flex;
}

._justify-content-space-between_1ihr5_161 {
  justify-content: space-between;
}

._tabular-nums_1ihr5_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1ihr5_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._CustomMenu_1ihr5_209 .ant-menu {
  border-right: none;
  background-color: #fdfdfd;
}
._CustomMenu_1ihr5_209 .ant-menu .ant-menu-item-group-title {
  text-transform: uppercase;
  padding: 0 0 0 0.3125rem;
  font-size: 0.75rem;
  color: #8F8F8F;
  letter-spacing: 0.05rem;
}
._CustomMenu_1ihr5_209 .ant-menu .ant-menu-item {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0.3125rem;
  height: 2.1875rem;
  line-height: 2.1875rem;
}
._CustomMenu_1ihr5_209 .ant-menu .ant-menu-item:hover {
  color: #1890ff;
  background-color: #f4fcff;
}
._CustomMenu_1ihr5_209 .ant-menu .ant-menu-item-selected a {
  color: black;
}
._CustomMenu_1ihr5_209 .ant-menu .ant-menu-item-group:not(:last-child) {
  margin-bottom: 1.875rem;
}