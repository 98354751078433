/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1hhuf_157 {
  display: flex;
}

._justify-content-space-between_1hhuf_161 {
  justify-content: space-between;
}

._tabular-nums_1hhuf_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1hhuf_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._networkRestriction_1hhuf_178 {
  width: 100%;
  margin-bottom: 1.25rem;
  border: 0.0625rem solid var(--grey-25);
  border-radius: 0.375rem;
  background-color: var(--white);
  color: var(--grey-100);
}
._networkRestriction_1hhuf_178 ._header_1hhuf_186 {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.625rem 1rem;
  border-bottom: 0.0625rem solid var(--grey-25);
}
._networkRestriction_1hhuf_178 ._header_1hhuf_186 ._networkIcon_1hhuf_193 {
  margin-right: 0.5rem;
  transform: translateY(0.125rem);
}
._networkRestriction_1hhuf_178 ._header_1hhuf_186 ._networkIcon_1hhuf_193 svg {
  width: 1rem !important;
  height: 1rem !important;
}
._networkRestriction_1hhuf_178 ._header_1hhuf_186 ._networkName_1hhuf_201 {
  font-size: 1rem;
}
._networkRestriction_1hhuf_178 ._content_1hhuf_204 {
  padding: 0.625rem 1rem;
  background-color: var(--grey-10);
}
._networkRestriction_1hhuf_178 ._content_1hhuf_204 ._maxBudget_1hhuf_208 {
  display: flex;
  align-items: center;
}
._networkRestriction_1hhuf_178 ._content_1hhuf_204 ._maxBudget_1hhuf_208 ._maxBudgetLegend_1hhuf_212 {
  flex: 1;
  font-size: 0.875rem;
}
._networkRestriction_1hhuf_178 ._content_1hhuf_204 ._maxBudget_1hhuf_208 ._maxBudgetSelect_1hhuf_216 {
  width: 8.75rem;
  margin-left: 0.5rem;
}
._networkRestriction_1hhuf_178 ._content_1hhuf_204 ._maxBudget_1hhuf_208 ._maxBudgetSelect_1hhuf_216 .ant-select {
  width: 100%;
}
._networkRestriction_1hhuf_178 ._content_1hhuf_204 ._sliders_1hhuf_223 {
  width: 80%;
  margin-bottom: 0.875rem;
}
._networkRestriction_1hhuf_178 ._content_1hhuf_204 ._sliders_1hhuf_223 ._slider_1hhuf_223 {
  width: 100%;
  margin-top: 0.875rem;
}
._networkRestriction_1hhuf_178 ._content_1hhuf_204 ._sliders_1hhuf_223 ._slider_1hhuf_223 ._sliderTitle_1hhuf_231 {
  font-size: 0.875rem;
}
._networkRestriction_1hhuf_178 ._content_1hhuf_204 ._sliders_1hhuf_223 ._slider_1hhuf_223 ._sliderInput_1hhuf_234 {
  margin-top: 0.25rem;
}
._networkRestriction_1hhuf_178 ._content_1hhuf_204 ._sliders_1hhuf_223 ._slider_1hhuf_223 ._sliderInput_1hhuf_234 .ant-slider-track {
  background-color: var(--ant-primary);
}
._networkRestriction_1hhuf_178 ._content_1hhuf_204 ._sliders_1hhuf_223 ._slider_1hhuf_223 ._sliderInput_1hhuf_234 .ant-slider-handle {
  border-color: var(--ant-primary);
}
._networkRestriction_1hhuf_178 ._content_1hhuf_204 ._sliders_1hhuf_223 ._slider_1hhuf_223 ._sliderInput_1hhuf_234 .ant-slider-rail {
  background-color: #d9d9d9;
}
._networkRestriction_1hhuf_178 ._content_1hhuf_204 ._sliders_1hhuf_223 ._slider_1hhuf_223 ._sliderFooter_1hhuf_246 {
  margin-top: 0.25rem;
  font-size: 0.625rem;
}