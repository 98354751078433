._TableTitle_av1d5_1 {
  margin-bottom: 16px !important;
}

._RowTable_av1d5_5 {
  height: 60px;
}
._RowTable_av1d5_5 ._RemoveButton_av1d5_8 {
  display: none;
}
._RowTable_av1d5_5:hover ._RemoveButton_av1d5_8 {
  display: block;
}
._RowTable_av1d5_5 ._loading_av1d5_14 {
  display: block;
}