/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1w8na_157 {
  display: flex;
}

._justify-content-space-between_1w8na_161 {
  justify-content: space-between;
}

._tabular-nums_1w8na_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1w8na_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._TermsGuidelinesSettings_1w8na_178 {
  display: flex;
  flex-direction: row;
  padding: 2rem 2.625rem;
  height: 100%;
}