/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1srmm_157 {
  display: flex;
}

._justify-content-space-between_1srmm_161 {
  justify-content: space-between;
}

._tabular-nums_1srmm_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1srmm_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Footer_1srmm_178 ._actions_1srmm_178 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  gap: 1.5rem;
}
._Footer_1srmm_178 ._actions_1srmm_178 ._spinner_1srmm_186 {
  margin-right: 0.625rem;
  margin-bottom: -0.1875rem;
}
._Footer_1srmm_178 ._actions_1srmm_178 ._arrow_1srmm_190 {
  margin-right: 0.5rem;
  margin-left: 0;
}
._Footer_1srmm_178 ._actions_1srmm_178 ._stepButton_1srmm_194 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  height: 2.5rem;
}
._Footer_1srmm_178 ._actions_1srmm_178 ._stepButton_1srmm_194 span + ._arrow_1srmm_190 {
  margin-left: 0.5rem;
  margin-right: 0;
}
._Footer_1srmm_178 ._actions_1srmm_178 ._prev_1srmm_203 {
  color: #1F1F21;
  background: #FFFFFF;
  border: 0.0625rem solid #D9D9D9;
}
._Footer_1srmm_178 ._actions_1srmm_178 ._prev_1srmm_203:hover:enabled {
  border: 0.0625rem solid #13450D;
}
._Footer_1srmm_178 ._actions_1srmm_178 ._prev_1srmm_203:focus-visible:enabled {
  border: 0.125rem solid #13450D;
}
._Footer_1srmm_178 ._actions_1srmm_178 ._next_1srmm_214 {
  color: #FFFFFF;
  background: #117A48;
  border: none;
}
._Footer_1srmm_178 ._actions_1srmm_178 ._next_1srmm_214:hover:enabled {
  background: #13450D;
}
._Footer_1srmm_178 ._actions_1srmm_178 ._next_1srmm_214:focus-visible:enabled {
  border: 0.125rem solid #13450D;
}
._Footer_1srmm_178 ._actions_1srmm_178 ._next_1srmm_214:disabled {
  opacity: 0.5;
}
._Footer_1srmm_178 ._actions_1srmm_178 ._skipBtn_1srmm_228 {
  color: #117A48 !important;
}
._Footer_1srmm_178 ._actions_1srmm_178 ._skipBtn_1srmm_228:hover {
  background: none;
}
._Footer_1srmm_178 ._actions_1srmm_178 ._skipBtn_1srmm_228:disabled {
  opacity: 0.5;
}