/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_d24un_157 {
  display: flex;
}

._justify-content-space-between_d24un_161 {
  justify-content: space-between;
}

._tabular-nums_d24un_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_d24un_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
:root {
  --spacing-xxxl: 80px;
  --spacing-xxl: 48px;
  --spacing-xl: 32px;
  --spacing-lg: 24px;
  --spacing-md: 16px;
  --spacing-sm: 12px;
  --spacing-xs: 8px;
  --spacing-xxs: 4px;
}

._TopPerformingWidgetCard_d24un_200 {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
._TopPerformingWidgetCard_d24un_200 ._actionButton_d24un_206 {
  margin-top: 16px;
}
._TopPerformingWidgetCard_d24un_200 ._loadingImageBox_d24un_209 .ant-skeleton-title {
  height: 130px;
}
._TopPerformingWidgetCard_d24un_200 .ant-card-head-title {
  padding: 0 !important;
}
._TopPerformingWidgetCard_d24un_200 .ant-card-head {
  min-height: 0;
}
._TopPerformingWidgetCard_d24un_200 .ant-card-cover img {
  border-radius: 6px;
  width: 100%;
  object-fit: cover;
  height: 220px;
}
@media only screen and (max-width: 1599px) {
  ._TopPerformingWidgetCard_d24un_200 .ant-card-cover img {
    height: 10vw;
  }
}
@media only screen and (max-width: 1199px) {
  ._TopPerformingWidgetCard_d24un_200 .ant-card-cover img {
    height: calc((100vw - 336px) / 3);
  }
}
._TopPerformingWidgetCard_d24un_200 .ant-card-cover img:hover {
  transform: translateY(-0.125rem);
  box-shadow: rgba(0, 0, 0, 0.16) 0rem 0.1875rem 0.625rem, rgba(0, 0, 0, 0.23) 0rem 0.1875rem 0.625rem;
}
._TopPerformingWidgetCard_d24un_200 .ant-btn-text {
  font-size: 12px;
}
._TopPerformingWidgetCard_d24un_200 .ant-card-body {
  flex-grow: 1;
}
._TopPerformingWidgetCard_d24un_200 .ant-card-actions {
  border-top: 0;
}