/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_152vl_157 {
  display: flex;
}

._justify-content-space-between_152vl_161 {
  justify-content: space-between;
}

._tabular-nums_152vl_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_152vl_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Field_152vl_178 {
  padding: 0.75rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
}
._Field_152vl_178._isEditing_152vl_183 {
  border-color: #3996e0;
}
._Field_152vl_178 ._header_152vl_186 {
  display: flex;
  align-items: center;
}
._Field_152vl_178 ._labelContainer_152vl_190 {
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;
  margin-right: 0.625rem;
}
._Field_152vl_178 ._labelContainer_152vl_190 ._label_152vl_190 {
  display: flex;
  flex: 1;
  color: #42474b;
  font-weight: 500;
  font-size: 0.875rem;
}
._Field_152vl_178 ._labelContainer_152vl_190 ._label_152vl_190._isRequired_152vl_204::after {
  content: "*";
  margin-left: 0.125rem;
  color: #da3737;
}
._Field_152vl_178 ._labelContainer_152vl_190 ._editIcon_152vl_209 {
  color: #42474b;
  opacity: 0;
}
._Field_152vl_178 ._editable_152vl_213 {
  cursor: pointer;
}
._Field_152vl_178 ._editable_152vl_213:hover {
  opacity: 0.5;
}
._Field_152vl_178 ._editable_152vl_213:hover ._editIcon_152vl_209 {
  opacity: 1;
}
._Field_152vl_178 ._editBlock_152vl_222 ._inputContainer_152vl_222 ._inputLabel_152vl_222 {
  margin-bottom: 0.5rem;
  color: #42474b;
  font-weight: 500;
  font-size: 0.75rem;
}
._Field_152vl_178 ._editBlock_152vl_222 ._inputContainer_152vl_222 ._error_152vl_228 {
  border-color: #d9534f;
}
._Field_152vl_178 ._editBlock_152vl_222 ._checkboxContainer_152vl_231 {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}
._Field_152vl_178 ._editBlock_152vl_222 ._checkboxContainer_152vl_231 ._checkboxLabel_152vl_236 {
  margin-left: 0.125rem;
  color: #42474b;
  font-size: 0.875rem;
}
._Field_152vl_178 ._editBlock_152vl_222 ._editBtns_152vl_241 {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.75rem;
}
._Field_152vl_178 ._editBlock_152vl_222 ._editBtns_152vl_241 ._cancelBtn_152vl_246 {
  color: #aeaeae;
}
._Field_152vl_178 ._right_152vl_249 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 3.125rem;
}
._Field_152vl_178 ._right_152vl_249 ._type_152vl_255 {
  margin-right: 0.625rem;
  color: #5f5f5f;
  font-weight: 500;
  font-size: 0.875rem;
}
._Field_152vl_178 ._choices_152vl_261 {
  margin-top: 0.625rem;
  color: #bdbdbd;
  font-weight: 400;
  font-size: 0.75rem;
}