/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_12z9p_157 {
  display: flex;
}

._justify-content-space-between_12z9p_161 {
  justify-content: space-between;
}

._tabular-nums_12z9p_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_12z9p_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._TransparentColorPicker_12z9p_178 ._transparent_12z9p_178 {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  padding: 0.75rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
}
._TransparentColorPicker_12z9p_178 ._transparent_12z9p_178 > :first-child {
  flex: 1;
}