/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1j7kt_157 {
  display: flex;
}

._justify-content-space-between_1j7kt_161 {
  justify-content: space-between;
}

._tabular-nums_1j7kt_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1j7kt_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._AccessLimitHeader_1j7kt_178 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
._AccessLimitHeader_1j7kt_178 ._infoIcon_1j7kt_183 {
  margin-left: 0.25rem;
}