/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1qgkh_157 {
  display: flex;
}

._justify-content-space-between_1qgkh_161 {
  justify-content: space-between;
}

._tabular-nums_1qgkh_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1qgkh_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._DefaultFieldsList_1qgkh_178 ._title_1qgkh_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._DefaultFieldsList_1qgkh_178 ._description_1qgkh_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._DefaultFieldsList_1qgkh_178 ._label_1qgkh_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._DefaultFieldsList_1qgkh_178 ._title_1qgkh_178 {
  margin-bottom: 0.625rem;
}
._DefaultFieldsList_1qgkh_178 ._description_1qgkh_188 {
  margin: 0.625rem 0;
}
._DefaultFieldsList_1qgkh_178 ._label_1qgkh_198 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2.5rem;
  margin-bottom: 1.5625rem;
}
._DefaultFieldsList_1qgkh_178 ._customField_1qgkh_221 {
  margin-bottom: 0.9375rem;
}