/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_uv80d_157 {
  display: flex;
}

._justify-content-space-between_uv80d_161 {
  justify-content: space-between;
}

._tabular-nums_uv80d_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_uv80d_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._drawer_uv80d_209 .ant-drawer-body {
  padding-top: 0;
}

._unpaddedDrawer_uv80d_213 .ant-drawer-body {
  padding: 0;
}

._paymentAppPadding_uv80d_217 .ant-drawer-body {
  padding: 0;
}
._paymentAppPadding_uv80d_217 .ant-drawer-header {
  padding: 0.5rem 0.25rem;
}

._ApplicationDrawer_uv80d_224 {
  height: 100%;
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    ._ApplicationDrawer_uv80d_224 {
      height: 100vh;
    }
  }
}
._ApplicationDrawer_uv80d_224 ._content_uv80d_234 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-y: auto;
  height: 100%;
}