/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1xtu0_157 {
  display: flex;
}

._justify-content-space-between_1xtu0_161 {
  justify-content: space-between;
}

._tabular-nums_1xtu0_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1xtu0_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MemberThreads_1xtu0_178 {
  width: 100%;
  min-height: 100px;
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
._MemberThreads_1xtu0_178 ._spinContainer_1xtu0_187 {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  padding: 24px;
  flex: 1;
  background-color: rgba(255, 255, 255, 0.1);
}
._MemberThreads_1xtu0_178 ._content_1xtu0_199 {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  flex: 1;
}
._MemberThreads_1xtu0_178 ._content_1xtu0_199 ._messages_1xtu0_206 {
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  overflow: hidden;
  padding: 24px 24px 0;
  overflow-y: auto;
}
._MemberThreads_1xtu0_178 ._content_1xtu0_199 ._messages_1xtu0_206._emptyMessagesSection_1xtu0_215 {
  align-items: center;
  text-align: center;
}
._MemberThreads_1xtu0_178 ._content_1xtu0_199 ._messages_1xtu0_206 ._messagesEmptyAction_1xtu0_219 {
  width: 114px;
  height: 32px;
  margin-top: 24px;
}
._MemberThreads_1xtu0_178 ._footer_1xtu0_224 {
  padding: 1.5rem 4.25rem;
}