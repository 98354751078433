/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1n9w6_157 {
  display: flex;
}

._justify-content-space-between_1n9w6_161 {
  justify-content: space-between;
}

._tabular-nums_1n9w6_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1n9w6_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._BulkUpdateRequest_1n9w6_178 ._label_1n9w6_178, ._BulkUpdateRequest_1n9w6_178 ._successHeader_1n9w6_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._BulkUpdateRequest_1n9w6_178 ._subheader_1n9w6_188, ._BulkUpdateRequest_1n9w6_178 ._noResourceWarning_1n9w6_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._BulkUpdateRequest_1n9w6_178 ._stepTitle_1n9w6_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._BulkUpdateRequest_1n9w6_178 {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}
._BulkUpdateRequest_1n9w6_178 ._noResourceWarning_1n9w6_188 {
  margin: 3.125rem auto;
  text-align: center;
}
._BulkUpdateRequest_1n9w6_178 ._footer_1n9w6_218 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1.25rem;
  background-color: #fdfdfd;
  border: 0.0625rem solid #dadcde;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding-right: 5.625rem;
}
._BulkUpdateRequest_1n9w6_178 ._subheader_1n9w6_188 {
  color: #8f8d91;
  text-align: center;
}
._BulkUpdateRequest_1n9w6_178 ._successHeader_1n9w6_178 {
  text-align: center;
}
._BulkUpdateRequest_1n9w6_178 ._messageForm_1n9w6_236 {
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}
._BulkUpdateRequest_1n9w6_178 ._messageForm_1n9w6_236._hide_1n9w6_242 {
  height: 0;
}
._BulkUpdateRequest_1n9w6_178 ._previewContainer_1n9w6_245 {
  padding-top: 1.5rem;
}
._BulkUpdateRequest_1n9w6_178 ._summary_1n9w6_248 {
  margin-top: 1.25rem;
  border: 1px solid #dadcde;
  border-radius: 0.5rem;
}
._BulkUpdateRequest_1n9w6_178 ._summary_1n9w6_248 ._summarySection_1n9w6_253 {
  padding: 1.5rem;
}
._BulkUpdateRequest_1n9w6_178 ._summary_1n9w6_248 ._summarySection_1n9w6_253:not(:first-child) {
  border-top: 1px solid #dadcde;
}
._BulkUpdateRequest_1n9w6_178 ._summary_1n9w6_248 ._summarySection_1n9w6_253:last-child {
  text-align: center;
}
._BulkUpdateRequest_1n9w6_178 ._container_1n9w6_262 {
  margin-top: 1.25rem;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  background-color: #eff5f9;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  min-height: 600px;
}
._BulkUpdateRequest_1n9w6_178 ._container_1n9w6_262 ._card_1n9w6_273 {
  margin: 1.5rem;
  padding: 1.5rem;
  background-color: #fdfdfd;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 1px 1px;
}
._BulkUpdateRequest_1n9w6_178 ._finishWithoutAssignment_1n9w6_280 {
  margin-right: 0.5rem;
}
._BulkUpdateRequest_1n9w6_178 ._indentField_1n9w6_283 {
  margin-left: 2.5rem;
}
._BulkUpdateRequest_1n9w6_178 ._radioOption_1n9w6_286 {
  margin-top: 1rem;
}
._BulkUpdateRequest_1n9w6_178 ._label_1n9w6_178 {
  margin-bottom: 0.5rem;
}
._BulkUpdateRequest_1n9w6_178 ._label_1n9w6_178:not(:first-of-type) {
  margin-top: 1.875rem;
}
._BulkUpdateRequest_1n9w6_178 ._landing_1n9w6_295 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._BulkUpdateRequest_1n9w6_178 ._landing_1n9w6_295 span a {
  color: #3996e0;
}
._BulkUpdateRequest_1n9w6_178 ._landing_1n9w6_295 span a:hover {
  color: #4fa7ee;
}
._BulkUpdateRequest_1n9w6_178 ._landing_1n9w6_295 span a ._externalLinkIcon_1n9w6_306 {
  vertical-align: text-bottom;
  margin-left: 0.5rem;
}
._BulkUpdateRequest_1n9w6_178 ._landing_1n9w6_295 ._input_1n9w6_310 {
  flex: 1;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
._BulkUpdateRequest_1n9w6_178 ._landing_1n9w6_295 ._input_1n9w6_310._hidden_1n9w6_315 {
  display: none;
}
._BulkUpdateRequest_1n9w6_178 ._landing_1n9w6_295 ._copyLinkButton_1n9w6_318 {
  margin-right: 0.5rem;
  margin-left: auto;
}
._BulkUpdateRequest_1n9w6_178 ._landing_1n9w6_295 ._cancelButton_1n9w6_322 {
  margin-right: 0.5rem;
}
._BulkUpdateRequest_1n9w6_178 ._disabled_1n9w6_325 {
  opacity: 0.8;
  pointer-events: none;
}
._BulkUpdateRequest_1n9w6_178 ._requestFields_1n9w6_329 ._row_1n9w6_329 {
  display: grid;
  grid-template-columns: minmax(11.25rem, 1fr) 1.5fr 7.5rem 2.5rem;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
}
._BulkUpdateRequest_1n9w6_178 ._requestFields_1n9w6_329 ._row_1n9w6_329:not(:first-of-type) {
  border-top: 0;
}
._BulkUpdateRequest_1n9w6_178 ._requestFields_1n9w6_329 ._row_1n9w6_329 ._fieldDropdown_1n9w6_338._disabled_1n9w6_325 {
  cursor: not-allowed;
}
._BulkUpdateRequest_1n9w6_178 ._requestFields_1n9w6_329 ._row_1n9w6_329 ._fieldCheckbox_1n9w6_341[class*=disabled] > div {
  opacity: 0.6;
  filter: none;
}
._BulkUpdateRequest_1n9w6_178 ._requestFields_1n9w6_329 ._row_1n9w6_329 ._fieldDelete_1n9w6_345 {
  width: 2.5rem;
  min-width: unset;
  border: 0;
  background: transparent;
}
._BulkUpdateRequest_1n9w6_178 ._requestFields_1n9w6_329 ._row_1n9w6_329 ._fieldDelete_1n9w6_345 > [class*=icon] {
  margin: 0;
  color: #8f8d91;
}
._BulkUpdateRequest_1n9w6_178 ._requestFields_1n9w6_329 ._row_1n9w6_329 ._fieldDelete_1n9w6_345:hover {
  background: #e9e8ea;
}
._BulkUpdateRequest_1n9w6_178 ._requestFields_1n9w6_329 ._row_1n9w6_329 ._fieldDelete_1n9w6_345:hover > [class*=icon] {
  color: #1a1818;
}

._hidden_1n9w6_315 {
  opacity: 0;
}