/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_10k5u_157 {
  display: flex;
}

._justify-content-space-between_10k5u_161 {
  justify-content: space-between;
}

._tabular-nums_10k5u_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_10k5u_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._EmailMessageList_10k5u_209 {
  display: flex;
  flex-direction: column;
  height: 100%;
}
._EmailMessageList_10k5u_209 ._threadHeader_10k5u_214 {
  flex-shrink: 0;
}
._EmailMessageList_10k5u_209 ._lastRead_10k5u_217 {
  float: right;
}
._EmailMessageList_10k5u_209 ._lastRead_10k5u_217 span {
  font-weight: bold;
}
._EmailMessageList_10k5u_209 ._list_10k5u_223 {
  flex: 1;
  overflow-y: auto;
  padding: 1rem 1.375rem;
}
._EmailMessageList_10k5u_209 ._list_10k5u_223 ._item_10k5u_228 {
  margin-bottom: 1.25rem;
}
._EmailMessageList_10k5u_209 ._list_10k5u_223 ._replyButton_10k5u_231 {
  margin-right: 0.625rem;
}
._EmailMessageList_10k5u_209 ._list_10k5u_223 ._createMessageForm_10k5u_234 {
  border: 0.0625rem solid var(--gray-5);
  border-radius: 0.5rem;
  background: #fdfdfd;
  box-shadow: 0 0.0625rem 0.375rem rgba(26, 24, 24, 0.08);
  margin-bottom: 0.9375rem;
}
._EmailMessageList_10k5u_209 ._list_10k5u_223 ._createMessageForm_10k5u_234._hide_10k5u_241 {
  display: none;
}
._EmailMessageList_10k5u_209 ._list_10k5u_223 ._infoNotice_10k5u_244 {
  margin-bottom: 2rem;
}
._EmailMessageList_10k5u_209 ._list_10k5u_223 ._infoNotice_10k5u_244 ._title_10k5u_247 {
  margin-bottom: 0.375rem;
}
._EmailMessageList_10k5u_209 ._list_10k5u_223 ._infoNotice_10k5u_244 ._description_10k5u_250 {
  margin-bottom: 0.625rem;
}
._EmailMessageList_10k5u_209 ._list_10k5u_223 ._errorNotice_10k5u_253 {
  margin-bottom: 2rem;
}
._EmailMessageList_10k5u_209 ._list_10k5u_223 ._errorNotice_10k5u_253 ._noticeContent_10k5u_256 {
  display: flex;
  flex-direction: column;
}
._EmailMessageList_10k5u_209 ._list_10k5u_223 ._errorNotice_10k5u_253 ._title_10k5u_247 {
  margin-bottom: 0.375rem;
  color: var(--gray-1);
}
._EmailMessageList_10k5u_209 ._list_10k5u_223 ._errorNotice_10k5u_253 ._text_10k5u_264 {
  margin-bottom: 0.75rem;
  color: var(--gray-1);
}
._EmailMessageList_10k5u_209 ._list_10k5u_223 ._errorNotice_10k5u_253 ._actions_10k5u_268 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._EmailMessageList_10k5u_209 ._list_10k5u_223 ._errorNotice_10k5u_253 ._button_10k5u_273 {
  border: 0;
  background: #f96672;
  color: var(--gray-1);
}
._EmailMessageList_10k5u_209 ._list_10k5u_223 ._errorNotice_10k5u_253 ._button_10k5u_273._reconnect_10k5u_278 {
  margin-right: 0.375rem;
}
._EmailMessageList_10k5u_209 ._applicationList_10k5u_281 {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  height: 3.875rem;
  padding: 0 1.5rem;
  border-top: 0.0625rem solid var(--gray-8);
  background: #f7f8fa;
}
._EmailMessageList_10k5u_209 ._messageForm_10k5u_291 {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}
._EmailMessageList_10k5u_209 ._messageForm_10k5u_291._hide_10k5u_241 {
  display: none;
}

._followUp_10k5u_301 {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  height: 3.875rem;
  margin-top: 2.25rem;
  padding: 0 1.5rem 2rem;
}
._followUp_10k5u_301 ._title_10k5u_247 {
  /* identical to box height, or 121% */
  display: flex;
  align-items: center;
  padding-bottom: 1.125rem;
  color: #8f8d91;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
}
._followUp_10k5u_301 ._replyOptions_10k5u_321 {
  display: flex;
  flex-direction: row;
  padding-bottom: 2.25rem;
}
._followUp_10k5u_301 ._replyOptions_10k5u_321 ._otherApps_10k5u_326 {
  margin-left: 1rem;
}