/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1iifz_157 {
  display: flex;
}

._justify-content-space-between_1iifz_161 {
  justify-content: space-between;
}

._tabular-nums_1iifz_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1iifz_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
:root {
  --screen-xs: 480px;
  --screen-sm: 576px;
  --screen-md: 768px;
  --screen-lg: 992px;
  --screen-xl: 1200px;
  --screen-xxl: 1600px;
}

._creatorProfile_1iifz_196 {
  background-color: #16282D;
  color: #fff;
  padding: 1.75rem;
  border-radius: 0.375rem;
  margin-bottom: 1.5rem;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 {
  display: flex;
  align-items: center;
  width: 85%;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 ._profileImage_1iifz_213 {
  height: 250px;
  width: 250px;
  border-radius: 50%;
  flex-shrink: 0;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 ._profileImage_1iifz_213 figure {
  border-radius: 50%;
  display: block;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 ._profileImage_1iifz_213 figure span {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 6.25rem;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 ._profileImage_1iifz_213 figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 992px) {
  ._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 ._profileImage_1iifz_213 {
    height: 200px;
    width: 200px;
  }
}
@media only screen and (max-width: 768px) {
  ._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 ._profileImage_1iifz_213 {
    height: 100px;
    width: 100px;
  }
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 ._info_1iifz_252 {
  padding-left: 2.4375rem;
  width: 80%;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 ._info_1iifz_252 > span {
  color: #FFFFFF;
  line-height: 22px;
  padding: 14px 0 0 0;
  display: block;
  max-width: 80%;
}
@media only screen and (max-width: 768px) {
  ._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 ._info_1iifz_252 {
    width: 100%;
    padding: 0;
    margin: 1rem 0 1.5rem 0;
  }
}
@media only screen and (max-width: 576px) {
  ._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  ._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 {
    flex-direction: column;
    align-items: start;
  }
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 {
  font-size: 12px;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 h3 {
  color: #fff;
  font-size: 30px;
  margin: 0;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 h3 i {
  font-size: 12px;
  vertical-align: middle;
  font-weight: 400;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 ._tags_1iifz_294 {
  padding-top: 16px;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 ._tags_1iifz_294 span {
  padding: 1px 8px;
  color: #1F1F21;
  margin-top: 0.5rem;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 ._socialHandle_1iifz_302 {
  margin-top: 0.5rem;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 ._socialHandle_1iifz_302 a {
  font-size: 14px;
  font-weight: 600;
  color: #79A595;
  display: inline-block;
  margin-right: 10px;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._profileInfo_1iifz_208 ._socialHandle_1iifz_302 a svg {
  color: #fff;
  vertical-align: middle;
  font-size: 16px;
  margin-right: 3px;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._statusSocialAnalytics_1iifz_318 {
  display: flex;
  justify-content: flex-end;
  width: 30%;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._statusSocialAnalytics_1iifz_318 ._statusButtons_1iifz_323 {
  margin-bottom: 1.375rem;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._statusSocialAnalytics_1iifz_318 ._statusButtons_1iifz_323 button {
  padding: 0.3125rem 0.9375rem;
  border: 1px solid #D9D9D9;
  margin-bottom: 0.4375rem;
  display: flex;
  align-items: center;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._statusSocialAnalytics_1iifz_318 ._statusButtons_1iifz_323 button:hover {
  border: 1px solid #D9D9D9;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._statusSocialAnalytics_1iifz_318 ._statusButtons_1iifz_323 button span {
  margin: 0;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._statusSocialAnalytics_1iifz_318 ._statusButtons_1iifz_323 button span svg {
  margin-right: 0.5rem;
  font-size: 12px;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._statusSocialAnalytics_1iifz_318 ._statusButtons_1iifz_323 ._accept_1iifz_343 {
  color: #389E0D;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._statusSocialAnalytics_1iifz_318 ._statusButtons_1iifz_323 ._maybe_1iifz_346 {
  color: #723814;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._statusSocialAnalytics_1iifz_318 ._statusButtons_1iifz_323 ._reject_1iifz_349 {
  color: #F5222D;
}
._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._statusSocialAnalytics_1iifz_318 ._socialDemographics_1iifz_352 span {
  background: #F5F5F5;
  border-radius: 4px;
  padding: 1px 8px;
  color: #1F1F21;
  font-weight: 600;
  display: block;
  width: 90%;
  margin: 0 auto 15px auto;
}
@media only screen and (max-width: 768px) {
  ._creatorProfile_1iifz_196 ._profileRow_1iifz_203 ._statusSocialAnalytics_1iifz_318 {
    justify-content: flex-start;
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  ._creatorProfile_1iifz_196 ._profileRow_1iifz_203 {
    flex-direction: column;
    align-items: start;
  }
}