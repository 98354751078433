/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1ar54_157 {
  display: flex;
}

._justify-content-space-between_1ar54_161 {
  justify-content: space-between;
}

._tabular-nums_1ar54_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1ar54_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._left_1ar54_178 ._header_1ar54_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._left_1ar54_178 {
  flex-shrink: 0;
  width: 25rem;
  margin-right: 2.8125rem;
}
._left_1ar54_178 ._header_1ar54_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}
._left_1ar54_178 ._header_1ar54_178 ._addIcon_1ar54_199 {
  margin-left: auto;
}

._right_1ar54_203 {
  flex: 1;
  height: 100%;
}
._right_1ar54_203 ._contentContainer_1ar54_207 {
  padding: 1.5rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
}

._programList_1ar54_215 {
  max-height: 90%;
  overflow-y: scroll;
}