/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_vy86r_157 {
  display: flex;
}

._justify-content-space-between_vy86r_161 {
  justify-content: space-between;
}

._tabular-nums_vy86r_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_vy86r_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ShopifyAccountList_vy86r_178 ._left_vy86r_178 ._header_vy86r_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._ShopifyAccountList_vy86r_178 ._listItem_vy86r_188 ._noticeContent_vy86r_188 ._title_vy86r_188, ._ShopifyAccountList_vy86r_178 ._listItem_vy86r_188 ._store_vy86r_188 ._title_vy86r_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._ShopifyAccountList_vy86r_178 ._listItem_vy86r_188 ._noticeContent_vy86r_188 ._text_vy86r_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._ShopifyAccountList_vy86r_178 {
  padding: 2rem 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
._ShopifyAccountList_vy86r_178 ._left_vy86r_178 {
  width: 25rem;
  margin-right: 2.8125rem;
  display: flex;
  flex-direction: column;
}
._ShopifyAccountList_vy86r_178 ._left_vy86r_178 ._header_vy86r_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}
._ShopifyAccountList_vy86r_178 ._left_vy86r_178 ._header_vy86r_178 ._addIcon_vy86r_226 {
  margin-left: auto;
}
._ShopifyAccountList_vy86r_178 ._accounts_vy86r_229 {
  flex: 1;
  max-width: 50rem;
}
._ShopifyAccountList_vy86r_178 ._accounts_vy86r_229 ._alert_vy86r_233 {
  width: 100%;
}
._ShopifyAccountList_vy86r_178 ._accounts_vy86r_229 ._ctaButtonsContainer_vy86r_236 {
  gap: 0.5rem;
  display: flex;
}
._ShopifyAccountList_vy86r_178 ._accounts_vy86r_229 ._connectionContainer_vy86r_240 {
  padding: 1rem;
  border: 1px solid #D9D9D9;
  border-radius: 0.375rem;
}
._ShopifyAccountList_vy86r_178 ._accounts_vy86r_229 ._connectionContainerSecondary_vy86r_245 {
  background: #FAFAFA;
}
._ShopifyAccountList_vy86r_178 ._accounts_vy86r_229 ._connectionInnerContainer_vy86r_248 {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}
._ShopifyAccountList_vy86r_178 ._accounts_vy86r_229 ._sectionHeaderContainer_vy86r_253 {
  margin-top: 1rem;
}
._ShopifyAccountList_vy86r_178 ._accounts_vy86r_229 ._removeAccountMessage_vy86r_256 {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  color: #505256;
}
._ShopifyAccountList_vy86r_178 ._sectionGap_vy86r_262 {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
._ShopifyAccountList_vy86r_178 ._sectionHeader_vy86r_253 {
  font-size: 1rem;
  font-weight: 600;
}
._ShopifyAccountList_vy86r_178 ._listItem_vy86r_188 {
  margin-bottom: 1rem;
  padding: 1rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
}
._ShopifyAccountList_vy86r_178 ._listItem_vy86r_188._disabled_vy86r_277 {
  opacity: 0.8;
  pointer-events: none;
}
._ShopifyAccountList_vy86r_178 ._listItem_vy86r_188 ._store_vy86r_188 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ShopifyAccountList_vy86r_178 ._listItem_vy86r_188 ._store_vy86r_188._error_vy86r_286 ._title_vy86r_188 {
  color: #f1515f;
}
._ShopifyAccountList_vy86r_178 ._listItem_vy86r_188 ._store_vy86r_188 ._icon_vy86r_289 {
  position: relative;
  margin-right: 1rem;
}
._ShopifyAccountList_vy86r_178 ._listItem_vy86r_188 ._store_vy86r_188 ._icon_vy86r_289 span {
  color: #96BF48;
}
._ShopifyAccountList_vy86r_178 ._listItem_vy86r_188 ._store_vy86r_188 ._icon_vy86r_289 ._checkmark_vy86r_296 {
  position: absolute;
  top: -0.3125rem;
  right: -0.3125rem;
  border: 0;
  border-radius: 50%;
  background: #fdfdfd;
  color: #3996e0;
}
._ShopifyAccountList_vy86r_178 ._listItem_vy86r_188 ._store_vy86r_188 ._remove_vy86r_256 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}
._ShopifyAccountList_vy86r_178 ._listItem_vy86r_188 ._store_vy86r_188 ._remove_vy86r_256 ._enablePromoCodesLink_vy86r_311 {
  font-weight: bold;
  margin-right: 1rem;
}
._ShopifyAccountList_vy86r_178 ._listItem_vy86r_188 ._errorNotice_vy86r_315 {
  margin-top: 0.625rem;
}
._ShopifyAccountList_vy86r_178 ._listItem_vy86r_188 ._noticeContent_vy86r_188 {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
._ShopifyAccountList_vy86r_178 ._listItem_vy86r_188 ._noticeContent_vy86r_188 ._title_vy86r_188 {
  margin-bottom: 0.3125rem;
  color: #fdfdfd;
}
._ShopifyAccountList_vy86r_178 ._listItem_vy86r_188 ._noticeContent_vy86r_188 ._text_vy86r_198 {
  margin-bottom: 0.75rem;
  color: #fdfdfd;
}
._ShopifyAccountList_vy86r_178 ._listItem_vy86r_188 ._noticeContent_vy86r_188 ._reconnect_vy86r_331 {
  width: 6.875rem;
  border: 0;
  background: #f96672;
  color: #fdfdfd;
}
._ShopifyAccountList_vy86r_178 ._addBtn_vy86r_337 {
  margin-top: 0.625rem;
}
._ShopifyAccountList_vy86r_178 ._alertMessage_vy86r_340 {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 8px;
}

._addNew_vy86r_347 {
  max-width: 31.25rem;
  margin: auto;
}