/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_5ry3m_158 {
  display: flex;
}

._justify-content-space-between_5ry3m_162 {
  justify-content: space-between;
}

._tabular-nums_5ry3m_166 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_5ry3m_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ClientSelectView_5ry3m_179 ._title_5ry3m_179 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._ClientSelectView_5ry3m_179 ._text_5ry3m_189 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

@font-face {
  font-family: "PPAgrandir";
  src: url("https://storage.googleapis.com/aspirex-dev-static.aspireiq-dev.com/app/public/css/PPAgrandir-WideMedium.ttf") format("truetype");
}
._ClientSelectView_5ry3m_179 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
._ClientSelectView_5ry3m_179 ._card_5ry3m_244 {
  padding: 48px;
  width: 496px;
  min-height: 464px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 2px solid #000000;
  border-radius: 8px;
}
._ClientSelectView_5ry3m_179 ._logo_5ry3m_257 {
  flex-shrink: 0;
  width: 112px;
  height: 38px;
  margin-bottom: 48px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
._ClientSelectView_5ry3m_179 ._title_5ry3m_179 {
  margin-bottom: 16px;
  flex-shrink: 0;
}
._ClientSelectView_5ry3m_179 ._error_5ry3m_270 {
  margin-top: 30px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
._ClientSelectView_5ry3m_179 ._error_5ry3m_270 ._link_5ry3m_277 {
  color: #3996e0;
}
._ClientSelectView_5ry3m_179 ._text_5ry3m_189 {
  margin-bottom: 32px;
  width: 400px;
  flex-shrink: 0;
}
._ClientSelectView_5ry3m_179 ._list_5ry3m_285 {
  padding-right: 8px;
  overflow-y: auto;
  flex: 1;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
._ClientSelectView_5ry3m_179 ._list_5ry3m_285 ._button_5ry3m_294 {
  overflow: hidden;
  width: 100%;
  flex-shrink: 0;
}

._clientViewPage_5ry3m_300 {
  display: flex;
  height: 100vh;
  align-items: center;
  padding: 1.875rem;
  justify-content: space-between;
  background-color: #F9FAFB;
}
._clientViewPage_5ry3m_300 ._header_5ry3m_308 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
._clientViewPage_5ry3m_300 ._brandLogo_5ry3m_314 img {
  width: 5.625rem;
}
._clientViewPage_5ry3m_300 ._clientContent_5ry3m_317 h2 {
  font-size: 2rem;
  font-family: "PPAgrandir";
  font-weight: 500;
  color: #16282D;
  margin: 0;
}
@media screen and (max-width: 560px) {
  ._clientViewPage_5ry3m_300 ._clientContent_5ry3m_317 h2 {
    font-size: 1.5rem;
  }
}
._clientViewPage_5ry3m_300 ._clientContent_5ry3m_317 p {
  color: #16282D;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin: 1.25rem 0 1.75rem 0;
}
@media screen and (max-width: 560px) {
  ._clientViewPage_5ry3m_300 ._clientContent_5ry3m_317 p {
    font-size: 0.75rem;
  }
}
._clientViewPage_5ry3m_300 ._clientContent_5ry3m_317 .ant-select {
  width: 20rem;
  text-align: left;
  font-family: "Inter", sans-serif;
}
._clientViewPage_5ry3m_300 ._clientContent_5ry3m_317 .ant-select-selector {
  height: 3rem !important;
  align-items: center;
}
._clientViewPage_5ry3m_300 ._clientContent_5ry3m_317 ._continueBtn_5ry3m_350 {
  background-color: #16282D;
  color: #fff;
  width: 20rem;
  height: 3rem;
  border: none;
}
._clientViewPage_5ry3m_300 ._clientContent_5ry3m_317 ._logOutBtn_5ry3m_357 {
  color: #16282D;
}
._clientViewPage_5ry3m_300 ._leftColumn_5ry3m_360,
._clientViewPage_5ry3m_300 ._rightColumn_5ry3m_361 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 0.625rem;
  height: 100%;
}
._clientViewPage_5ry3m_300 ._leftColumn_5ry3m_360 {
  width: 68%;
}
._clientViewPage_5ry3m_300 ._leftColumn_5ry3m_360 ._clientContent_5ry3m_317 {
  margin-top: 9.375rem;
}
@media screen and (max-width: 560px) {
  ._clientViewPage_5ry3m_300 ._leftColumn_5ry3m_360 ._clientContent_5ry3m_317 {
    margin-top: 3.125rem;
  }
}
@media screen and (max-width: 960px) {
  ._clientViewPage_5ry3m_300 ._leftColumn_5ry3m_360 {
    width: 100%;
  }
}
._clientViewPage_5ry3m_300 ._rightColumn_5ry3m_361 {
  background-color: #16282D;
  color: #fff;
  justify-content: center;
  width: 30%;
  padding: 0.625rem;
}
._clientViewPage_5ry3m_300 ._rightColumn_5ry3m_361 img {
  width: 25rem;
  max-width: 100%;
  height: auto;
}
._clientViewPage_5ry3m_300 ._rightColumn_5ry3m_361 h3 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  font-family: "PPAgrandir";
  color: #fff;
  line-height: 1.1;
  font-weight: 500;
}
._clientViewPage_5ry3m_300 ._rightColumn_5ry3m_361 ._brands_5ry3m_405 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  max-width: 28.125rem;
}
._clientViewPage_5ry3m_300 ._rightColumn_5ry3m_361 ._brands_5ry3m_405 ._brandImg_5ry3m_412 {
  width: 100px;
}
._clientViewPage_5ry3m_300 ._rightColumn_5ry3m_361 ._brands_5ry3m_405 ._brandImg_5ry3m_412 img {
  width: 100%;
  height: 1.5rem;
  object-fit: contain;
}
@media screen and (max-width: 960px) {
  ._clientViewPage_5ry3m_300 ._rightColumn_5ry3m_361 {
    display: none;
  }
}
._clientViewPage_5ry3m_300 ._defaultClientIcon_5ry3m_425 {
  color: #5D6974;
}