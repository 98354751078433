._MessageComposer_1kv2h_1 {
  display: flex;
  flex-direction: column;
}
._MessageComposer_1kv2h_1 > * + * {
  margin-block: 25px;
}
._MessageComposer_1kv2h_1 ._EmailComposer_1kv2h_8 {
  border: 1px solid #DADCDE;
}
._MessageComposer_1kv2h_1 ._EmailComposer_1kv2h_8._hide_1kv2h_11 {
  display: none;
}