/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1rpsm_157 {
  display: flex;
}

._justify-content-space-between_1rpsm_161 {
  justify-content: space-between;
}

._tabular-nums_1rpsm_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1rpsm_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._mainBody_1rpsm_178 {
  overflow-y: scroll;
  width: 18.75rem;
  max-height: 19.375rem;
  padding: 0.5rem;
}
._mainBody_1rpsm_178 ._header_1rpsm_184 {
  display: flex;
  align-items: center;
  margin-bottom: 1.125rem;
  color: #1a1818;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.0125rem;
}
._mainBody_1rpsm_178 ._row_1rpsm_194 {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
._mainBody_1rpsm_178 ._row_1rpsm_194 ._icon_1rpsm_199 {
  width: 1rem;
  height: 1rem;
  margin-right: 0.375rem;
}
._mainBody_1rpsm_178 ._row_1rpsm_194 ._label_1rpsm_204 {
  margin-left: 0.75rem;
  flex-grow: 1;
}
._mainBody_1rpsm_178 ._row_1rpsm_194 ._ant-switch_1rpsm_208 {
  background-color: #3996e0;
}
._mainBody_1rpsm_178 ._row_1rpsm_194 ._switch_1rpsm_211 {
  margin-left: 0.375rem;
}
._mainBody_1rpsm_178 ._row_1rpsm_194 ._switch_1rpsm_211._checked_1rpsm_214 {
  background-color: #3996e0;
}