/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_g7ul3_157 {
  display: flex;
}

._justify-content-space-between_g7ul3_161 {
  justify-content: space-between;
}

._tabular-nums_g7ul3_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_g7ul3_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._emptyNotice_g7ul3_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._emptyNotice_g7ul3_178 {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #E6E4E7;
  color: #8f8d91;
}
._emptyNotice_g7ul3_178 ._addNew_g7ul3_198 {
  margin-left: 3px;
  cursor: pointer;
  user-select: none;
  color: #3996e0;
  will-change: color;
  transition: color 0.1s ease-out;
}
._emptyNotice_g7ul3_178 ._addNew_g7ul3_198:hover {
  color: #4fa7ee;
}