/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_nd7dj_157 {
  display: flex;
}

._justify-content-space-between_nd7dj_161 {
  justify-content: space-between;
}

._tabular-nums_nd7dj_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_nd7dj_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ContractCell_nd7dj_178 {
  margin: 0;
  margin-bottom: 1.5rem;
  padding: 0;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
  list-style-type: none;
}
._ContractCell_nd7dj_178:last-child {
  margin-bottom: 0;
}
._ContractCell_nd7dj_178 ._contractItemHead_nd7dj_189 {
  display: flex;
  padding: 1rem;
  border-bottom: 0.0625rem solid #dadcde;
}
._ContractCell_nd7dj_178 ._contractItemHead_nd7dj_189 ._textWrap_nd7dj_194 {
  position: relative;
  flex: 1;
}
._ContractCell_nd7dj_178 ._contractItemHead_nd7dj_189 ._contractName_nd7dj_198 {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125rem;
  letter-spacing: 0.00625rem;
}
._ContractCell_nd7dj_178 ._contractItemHead_nd7dj_189 ._dateCreated_nd7dj_208 {
  display: block;
  font-weight: normal;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
}
._ContractCell_nd7dj_178 ._contractItemHead_nd7dj_189 ._button_wrap_nd7dj_217 {
  flex: 1;
  justify-content: right;
  text-align: right;
}
._ContractCell_nd7dj_178 ._contractItemHead_nd7dj_189 ._download_button_nd7dj_222 {
  position: relative;
  display: inline-box;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0.75rem;
  border-radius: 0.5rem;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1);
  box-orient: horizontal;
  box-direction: normal;
  box-align: center;
  box-pack: center;
}
._ContractCell_nd7dj_178 ._contractItemHead_nd7dj_189 ._download_button_nd7dj_222:hover, ._ContractCell_nd7dj_178 ._contractItemHead_nd7dj_189 ._download_button_nd7dj_222:focus {
  background-color: #eff5f9;
}
._ContractCell_nd7dj_178 ._requirements_wrap_nd7dj_243 {
  padding: 1rem;
}
._ContractCell_nd7dj_178 ._requirements_wrap_nd7dj_243 ._requirement_label_nd7dj_246 {
  display: block;
  padding-bottom: 1rem;
  font-weight: bold;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125rem;
  letter-spacing: 0.00625rem;
}
._ContractCell_nd7dj_178 ._requirements_wrap_nd7dj_243 ._requirement_list_nd7dj_256 {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
._ContractCell_nd7dj_178 ._requirements_wrap_nd7dj_243 ._requirement_item_nd7dj_261 {
  padding: 0.5rem 0 0.5rem 1.625rem;
  background: url("/etc/aspirex/app/config/esbuild/../../src/app/assets/svgs/blue_circlecheckmark.svg");
  background-position: left center;
  background-size: 1.25rem;
  background-repeat: no-repeat;
}
._ContractCell_nd7dj_178 ._signed_status_nd7dj_268 {
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #eff5f9;
  text-align: center;
}
._ContractCell_nd7dj_178 ._signed_status_nd7dj_268 ._signIcon_nd7dj_275 {
  margin-right: 0.25rem;
}
._ContractCell_nd7dj_178 ._signed_status_nd7dj_268 ._status_txt_nd7dj_278 {
  font-weight: bold;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125rem;
  letter-spacing: 0.00625rem;
}
._ContractCell_nd7dj_178 ._actionsContainer_nd7dj_286 {
  padding: 0.625rem;
  text-align: center;
}
._ContractCell_nd7dj_178 ._actionsContainer_nd7dj_286 ._actionButton_nd7dj_290 {
  display: inline-block;
  margin: 0 0.625rem;
}