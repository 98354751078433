/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_9mzsk_157 {
  display: flex;
}

._justify-content-space-between_9mzsk_161 {
  justify-content: space-between;
}

._tabular-nums_9mzsk_165 {
  font-variant-numeric: tabular-nums;
}

._AdvertiserAccessPage_9mzsk_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_9mzsk_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._AdvertiserAccessPage_9mzsk_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._AdvertiserAccessPage_9mzsk_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._AdvertiserAccessPage_9mzsk_169 ::-webkit-scrollbar-track, ._AdvertiserAccessPage_9mzsk_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._AdvertiserAccessPage_9mzsk_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._AdvertiserAccessPage_9mzsk_169 ._notice_9mzsk_204, ._AdvertiserAccessPage_9mzsk_169 p {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._AdvertiserAccessPage_9mzsk_169 {
  padding: 2rem 0;
}
._AdvertiserAccessPage_9mzsk_169 ._content_9mzsk_217 {
  margin-top: 0.5rem;
}
._AdvertiserAccessPage_9mzsk_169 ._notice_9mzsk_204 {
  font-weight: 600;
  text-align: left;
}
._AdvertiserAccessPage_9mzsk_169 ._notice_9mzsk_204 ._message_9mzsk_224 {
  color: #1a1818;
}
._AdvertiserAccessPage_9mzsk_169 ._contactSupport_9mzsk_227 {
  margin-right: 1rem;
}
._AdvertiserAccessPage_9mzsk_169 ._contactSupport_9mzsk_227 button {
  background: transparent;
  padding: 0.0625rem 0.5rem;
  font-weight: 600;
}

._headerAction_9mzsk_236 {
  min-width: 28.75rem;
}