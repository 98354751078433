/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1nvhr_157 {
  display: flex;
}

._justify-content-space-between_1nvhr_161 {
  justify-content: space-between;
}

._tabular-nums_1nvhr_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1nvhr_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._header_1nvhr_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._label_1nvhr_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._intentContent_1nvhr_198 ul {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._label_1nvhr_188 {
  display: block !important;
  padding-top: 1.25rem;
  padding-bottom: 0.625rem;
}

._header_1nvhr_178 {
  margin-bottom: 1.25rem;
  text-align: center;
}

._DeleteContainer_1nvhr_219 {
  margin-top: 2.5rem;
  border-top: 0.0625rem solid #dadcde;
  padding-bottom: 1.75rem;
}
._DeleteContainer_1nvhr_219 p {
  margin-bottom: 1.25rem;
}

._intentContent_1nvhr_198 {
  padding: 1.875rem;
}
._intentContent_1nvhr_198 ul {
  margin-bottom: 2.625rem;
}
._intentContent_1nvhr_198 ._intentActions_1nvhr_234 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
._intentContent_1nvhr_198 ._intentActions_1nvhr_234 ._btn_1nvhr_240:first-child {
  margin-right: 1rem;
}