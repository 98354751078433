._LineChart_9h011_1 {
  width: 100%;
  height: 100%;
  max-height: 450px;
}
._LineChart_9h011_1 ._text_9h011_6 {
  fill: #505256;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
._LineChart_9h011_1 ._subtext_9h011_14 {
  fill: #8c8c8c;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}