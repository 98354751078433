._GroupContentReviewPage_cfwjo_1 {
  padding: 24px;
}
._GroupContentReviewPage_cfwjo_1 .ant-table-cell {
  padding: 8px !important;
}

._nameColumn_cfwjo_8 {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}