/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_seu06_157 {
  display: flex;
}

._justify-content-space-between_seu06_161 {
  justify-content: space-between;
}

._tabular-nums_seu06_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_seu06_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._icon_seu06_178 {
  margin-left: 0.25rem;
}