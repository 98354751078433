._ProjectDropdown_o83ud_1 {
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #fff;
}
.ant-refresh ._ProjectDropdown_o83ud_1 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
._ProjectDropdown_o83ud_1 .ant-dropdown-menu-item-only-child:first-of-type {
  padding-top: 12px;
}

._Checkbox_o83ud_15 {
  width: 100%;
}

._searchError_o83ud_19 {
  color: var(--error-color);
  font-size: 12px;
  padding: px(12) px(16);
  margin: 0;
  width: 100%;
}