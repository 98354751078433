/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_3o57m_157 {
  display: flex;
}

._justify-content-space-between_3o57m_161 {
  justify-content: space-between;
}

._tabular-nums_3o57m_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_3o57m_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ContentTypeList_3o57m_209 ._space_3o57m_209 {
  width: 100%;
}
._ContentTypeList_3o57m_209 ._subtitle_3o57m_212 {
  color: #8c8c8c;
  font-weight: 400;
}
._ContentTypeList_3o57m_209 ._tag_3o57m_216 {
  cursor: pointer;
  border-radius: 0.75rem;
}
._ContentTypeList_3o57m_209 ._tag_3o57m_216._selected_3o57m_220 {
  background-color: #09375a;
  color: #fdfdfd;
}