/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_154yo_157 {
  display: flex;
}

._justify-content-space-between_154yo_161 {
  justify-content: space-between;
}

._tabular-nums_154yo_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_154yo_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MemberRestrictionSettings_154yo_178 ._title_154yo_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._MemberRestrictionSettings_154yo_178 ._emailSection_154yo_188 ._header_154yo_188, ._MemberRestrictionSettings_154yo_178 ._toggleSection_154yo_188 ._left_154yo_188 ._header_154yo_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._MemberRestrictionSettings_154yo_178 ._description_154yo_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._MemberRestrictionSettings_154yo_178 ._emailSection_154yo_188 ._emptyNotice_154yo_208, ._MemberRestrictionSettings_154yo_178 ._toggleSection_154yo_188 ._left_154yo_188 ._text_154yo_208 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._MemberRestrictionSettings_154yo_178 {
  display: flex;
  flex-direction: column;
}
._MemberRestrictionSettings_154yo_178 ._title_154yo_178 {
  margin-bottom: 0.75rem;
}
._MemberRestrictionSettings_154yo_178 ._description_154yo_198 {
  margin-bottom: 2.5rem;
}
._MemberRestrictionSettings_154yo_178 ._toggleSection_154yo_188 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2.5rem;
}
._MemberRestrictionSettings_154yo_178 ._toggleSection_154yo_188 ._left_154yo_188 {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 1.25rem;
}
._MemberRestrictionSettings_154yo_178 ._toggleSection_154yo_188 ._left_154yo_188 ._header_154yo_188 {
  margin-bottom: 0.625rem;
}
._MemberRestrictionSettings_154yo_178 ._toggleSection_154yo_188 ._left_154yo_188 ._text_154yo_208 ._domains_154yo_243 {
  margin: 0.625rem 0;
  font-weight: bold;
}
._MemberRestrictionSettings_154yo_178 ._toggleSection_154yo_188 ._toggle_154yo_188 {
  flex-shrink: 0;
  margin-left: auto;
}
._MemberRestrictionSettings_154yo_178 ._emailSection_154yo_188 {
  overflow: hidden;
  padding-top: 2.5rem;
  border-top: 0.0625rem solid #dadcde;
}
._MemberRestrictionSettings_154yo_178 ._emailSection_154yo_188 ._header_154yo_188 {
  margin-bottom: 2.5rem;
}
._MemberRestrictionSettings_154yo_178 ._emailSection_154yo_188 ._addRule_154yo_259 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2.5rem;
}
._MemberRestrictionSettings_154yo_178 ._emailSection_154yo_188 ._addRule_154yo_259 ._select_154yo_265 {
  flex-shrink: 0;
  width: 13.75rem;
  margin-right: 0.9375rem;
}
._MemberRestrictionSettings_154yo_178 ._emailSection_154yo_188 ._addRule_154yo_259 ._input_154yo_270 {
  flex: 1;
  margin-right: 0.9375rem;
}
._MemberRestrictionSettings_154yo_178 ._emailSection_154yo_188 ._addRule_154yo_259 ._button_154yo_274 {
  flex-shrink: 0;
  width: 8.75rem;
}
._MemberRestrictionSettings_154yo_178 ._emailSection_154yo_188 ._notice_154yo_278 {
  margin-bottom: 2.5rem;
}
._MemberRestrictionSettings_154yo_178 ._emailSection_154yo_188 ._emptyNotice_154yo_208 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  border-radius: 0.25rem;
  background: #e6e4e7;
  color: #8f8d91;
}
._MemberRestrictionSettings_154yo_178 ._emailSection_154yo_188 ._table_154yo_291 ._removeCell_154yo_291 {
  color: #3996e0;
  font-weight: bold;
  font-size: 0.875rem;
  cursor: pointer;
  user-select: none;
  transition: color 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: color;
}
._MemberRestrictionSettings_154yo_178 ._emailSection_154yo_188 ._table_154yo_291 ._removeCell_154yo_291:hover {
  color: #4fa7ee;
}

._ConfirmationModal_154yo_304 {
  z-index: 9401;
}