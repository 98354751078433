/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1a2cx_157 {
  display: flex;
}

._justify-content-space-between_1a2cx_161 {
  justify-content: space-between;
}

._tabular-nums_1a2cx_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1a2cx_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._DashboardStatisticsCard_1a2cx_178 {
  width: 100%;
  margin-top: 1.5rem;
}
._DashboardStatisticsCard_1a2cx_178 ._title_1a2cx_182 {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
._DashboardStatisticsCard_1a2cx_178 ._title_1a2cx_182 ._summary_1a2cx_188 {
  display: block;
  font-weight: bold;
  font-size: 1.25rem;
}
._DashboardStatisticsCard_1a2cx_178 ._title_1a2cx_182 .ant-picker-range {
  height: 2.375rem;
  border-radius: 0.5rem;
}
._DashboardStatisticsCard_1a2cx_178 ._statsCard_1a2cx_197 {
  height: 6.25rem;
}