/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_btita_157 {
  display: flex;
}

._justify-content-space-between_btita_161 {
  justify-content: space-between;
}

._tabular-nums_btita_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_btita_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._FormMultiSelect_btita_178 {
  width: 100%;
}