/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_ia6vh_157 {
  display: flex;
}

._justify-content-space-between_ia6vh_161 {
  justify-content: space-between;
}

._tabular-nums_ia6vh_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_ia6vh_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ConfirmationModal_ia6vh_178 ._title_ia6vh_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._ConfirmationModal_ia6vh_178 ._description_ia6vh_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._ConfirmationModal_ia6vh_178 ._memberList_ia6vh_198 ._memberItem_ia6vh_198 ._name_ia6vh_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._ConfirmationModal_ia6vh_178 ._memberList_ia6vh_198 ._memberItem_ia6vh_198 ._email_ia6vh_208 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ConfirmationModal_ia6vh_178 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.25rem;
}
._ConfirmationModal_ia6vh_178 ._title_ia6vh_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2.5rem;
  margin-bottom: 0.625rem;
}
._ConfirmationModal_ia6vh_178 ._description_ia6vh_188 {
  margin-bottom: 2rem;
}
._ConfirmationModal_ia6vh_178 ._description_ia6vh_188 ._ruleInfo_ia6vh_265 {
  margin: 0.625rem 0 1.125rem;
  font-weight: bold;
}
._ConfirmationModal_ia6vh_178 ._listContainer_ia6vh_269 {
  position: relative;
}
._ConfirmationModal_ia6vh_178 ._listContainer_ia6vh_269 ._mask_ia6vh_272 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
._ConfirmationModal_ia6vh_178 ._listContainer_ia6vh_269 ._mask_ia6vh_272 ._topFade_ia6vh_280,
._ConfirmationModal_ia6vh_178 ._listContainer_ia6vh_269 ._mask_ia6vh_272 ._bottomFade_ia6vh_281 {
  position: absolute;
  width: 100%;
  height: 2.5rem;
  opacity: 0;
}
._ConfirmationModal_ia6vh_178 ._listContainer_ia6vh_269 ._mask_ia6vh_272 ._topFade_ia6vh_280._active_ia6vh_287,
._ConfirmationModal_ia6vh_178 ._listContainer_ia6vh_269 ._mask_ia6vh_272 ._bottomFade_ia6vh_281._active_ia6vh_287 {
  opacity: 1;
}
._ConfirmationModal_ia6vh_178 ._listContainer_ia6vh_269 ._mask_ia6vh_272 ._topFade_ia6vh_280 {
  top: 0;
  background: linear-gradient(180deg, #fdfdfd 0%, transparent 100%);
}
._ConfirmationModal_ia6vh_178 ._listContainer_ia6vh_269 ._mask_ia6vh_272 ._bottomFade_ia6vh_281 {
  bottom: 0;
  background: linear-gradient(0deg, #fdfdfd 0%, transparent 100%);
}
._ConfirmationModal_ia6vh_178 ._memberList_ia6vh_198 {
  flex-shrink: 0;
  overflow-y: auto;
  width: 100%;
  max-height: 9.375rem;
}
._ConfirmationModal_ia6vh_178 ._memberList_ia6vh_198 ._memberItem_ia6vh_198 {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  height: 1.75rem;
  margin-bottom: 0.625rem;
}
._ConfirmationModal_ia6vh_178 ._memberList_ia6vh_198 ._memberItem_ia6vh_198 ._avatar_ia6vh_313 {
  flex-shrink: 0;
  margin-right: 1rem;
}
._ConfirmationModal_ia6vh_178 ._memberList_ia6vh_198 ._memberItem_ia6vh_198 ._name_ia6vh_198 {
  flex: 1;
  margin-right: 0.625rem;
}
._ConfirmationModal_ia6vh_178 ._memberList_ia6vh_198 ._memberItem_ia6vh_198 ._email_ia6vh_208 {
  flex-shrink: 0;
  margin-right: 0.875rem;
  margin-left: auto;
  color: #f1515f;
}
._ConfirmationModal_ia6vh_178 ._actions_ia6vh_327 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2.5rem;
}
._ConfirmationModal_ia6vh_178 ._actions_ia6vh_327 ._button_ia6vh_333 {
  margin-right: 1.5rem;
}