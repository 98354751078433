/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1ig3g_157 {
  display: flex;
}

._justify-content-space-between_1ig3g_161 {
  justify-content: space-between;
}

._tabular-nums_1ig3g_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1ig3g_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Input_1ig3g_209 {
  box-sizing: border-box;
  height: 2.5rem;
  border: 0.0625rem solid #bdbdbd;
  border-radius: 0.25rem;
}
._Input_1ig3g_209:focus, ._Input_1ig3g_209:hover {
  border-color: #3996e0 !important;
}

._TextArea_1ig3g_219 {
  box-sizing: border-box;
  border: 0.0625rem solid #bdbdbd;
  border-radius: 0.25rem;
}
._TextArea_1ig3g_219:focus, ._TextArea_1ig3g_219:hover {
  border-color: #3996e0 !important;
}