/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_d4544_157 {
  display: flex;
}

._justify-content-space-between_d4544_161 {
  justify-content: space-between;
}

._tabular-nums_d4544_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_d4544_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Intro_d4544_178 {
  padding-bottom: 1.5rem;
}
._Intro_d4544_178 ._row_d4544_181 {
  margin-top: 1rem;
  padding-bottom: 16px;
}
._Intro_d4544_178 ._row_d4544_181 ._subrow_d4544_185:not(:first-child) {
  margin-top: 24px;
}
._Intro_d4544_178 ._row_d4544_181:not(:last-child) {
  border-bottom: 1px solid var(--gray-5);
}
._Intro_d4544_178 ._subtitle_d4544_191 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
._Intro_d4544_178 ._rowDescription_d4544_199 {
  margin-bottom: 8px;
  color: var(--gray-7);
  font-size: 14px;
}