/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1rvvl_157 {
  display: flex;
}

._justify-content-space-between_1rvvl_161 {
  justify-content: space-between;
}

._tabular-nums_1rvvl_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1rvvl_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Select_1rvvl_209 {
  border-width: thin;
  border-color: #bdbdbd;
  border-radius: 0.25rem !important;
}
._Select_1rvvl_209.ant-select .ant-select-selector {
  border-color: #bdbdbd;
  border-radius: 0.25rem;
  color: #000;
  font-size: 0.875rem;
  letter-spacing: -0.025rem;
}
._Select_1rvvl_209.ant-select .ant-select-selector:hover, ._Select_1rvvl_209.ant-select .ant-select-selector:focus {
  border-color: #3996e0;
}