/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_13gni_157 {
  display: flex;
}

._justify-content-space-between_13gni_161 {
  justify-content: space-between;
}

._tabular-nums_13gni_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_13gni_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._AlloyIntegrations_13gni_178 ._list_13gni_178 ._content_13gni_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
._AlloyIntegrations_13gni_178 ._list_13gni_178 ._content_13gni_178 ._warningIcon_13gni_184 {
  color: #d9534f;
}

._loading_13gni_188 {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}