/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1jf5m_157 {
  display: flex;
}

._justify-content-space-between_1jf5m_161 {
  justify-content: space-between;
}

._tabular-nums_1jf5m_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1jf5m_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._status_1jf5m_178 > span {
  display: inline-flex;
  align-items: center;
  border: none;
  padding: 0.375rem 0.5rem;
}
._status_1jf5m_178 > span:hover {
  border: none;
}
._status_1jf5m_178 > span svg {
  font-size: 0.9375rem;
  margin-right: 0.25rem;
}
._status_1jf5m_178 ._pending_1jf5m_191 {
  background: #FFE58F;
  color: #1F1F21;
}
._status_1jf5m_178 ._approved_1jf5m_195 {
  background: #B7EB8F;
  color: #389E0D;
}
._status_1jf5m_178 ._rejected_1jf5m_199 {
  background: #FFA39E;
  color: #F5222D;
}
._status_1jf5m_178 ._maybe_1jf5m_203 {
  background: #F0DACD;
  color: #723814;
}