/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1whhb_157 {
  display: flex;
}

._justify-content-space-between_1whhb_161 {
  justify-content: space-between;
}

._tabular-nums_1whhb_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1whhb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1whhb_157 {
  display: flex;
}

._justify-content-space-between_1whhb_161 {
  justify-content: space-between;
}

._tabular-nums_1whhb_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1whhb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ctaModal_1whhb_355 {
  width: 50% !important;
  max-width: 52.5rem;
  height: 100%;
}
._ctaModal_1whhb_355 .ant-modal-content {
  padding: 2.25rem 3.125rem;
  border-radius: 0.5rem;
}
._ctaModal_1whhb_355 ._formActions_1whhb_364 {
  height: 100%;
}
._ctaModal_1whhb_355 ._formActions_1whhb_364 ._selectList_1whhb_367 {
  overflow-y: auto;
  height: 100%;
}
._ctaModal_1whhb_355 ._title_1whhb_371 {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.875rem;
  text-align: center;
}
._ctaModal_1whhb_355 ._subTitle_1whhb_377 {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  /* identical to box height */
  text-align: center;
}

._label_1whhb_385 {
  display: flex;
  align-items: center;
  width: 100%;
}
._label_1whhb_385 ._labelImage_1whhb_390 {
  object-fit: contain;
  width: 2.1875rem;
  height: 2.1875rem;
  margin-right: 0.75rem;
  border-radius: 0.25rem;
}
._label_1whhb_385 ._labelTitle_1whhb_397 {
  min-width: 0;
  margin-left: 0.5rem;
}

._content_1whhb_402,
._image_1whhb_403 {
  display: block;
}