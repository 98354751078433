/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_l58u1_157 {
  display: flex;
}

._justify-content-space-between_l58u1_161 {
  justify-content: space-between;
}

._tabular-nums_l58u1_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_l58u1_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SettingsPage_l58u1_178 {
  height: 100%;
  margin: 0;
  background: #fdfdfd;
}
._SettingsPage_l58u1_178 .ant-layout {
  overflow-y: scroll;
  overflow-x: scroll;
  background-color: #fdfdfd;
  padding: 3.125rem 3.125rem 0 3.125rem;
}
._SettingsPage_l58u1_178 .ant-layout-sider {
  padding-top: 3.125rem;
  padding-left: 3.125rem;
}
._SettingsPage_l58u1_178 .ant-layout-sider-light {
  background-color: #fdfdfd;
}
._SettingsPage_l58u1_178 ._OauthNotice_l58u1_196 {
  padding: 0px;
}
._SettingsPage_l58u1_178 ._setting_detail_l58u1_199 {
  margin-left: 0px !important;
}
._SettingsPage_l58u1_178 ._SettingsLayout_l58u1_202, ._SettingsPage_l58u1_178 ._ShopifyAccountList_l58u1_202 {
  padding-top: 0px;
}
._SettingsPage_l58u1_178 ._TermsGuidelinesSettings_l58u1_205, ._SettingsPage_l58u1_178 ._MessageTemplatesPage_l58u1_205 {
  padding: 0px;
}
._SettingsPage_l58u1_178 ._header_l58u1_208 {
  margin-top: 0px !important;
}