/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_11wwq_157 {
  display: flex;
}

._justify-content-space-between_11wwq_161 {
  justify-content: space-between;
}

._tabular-nums_11wwq_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_11wwq_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._InstalledIntegrationItem_11wwq_178 ._header_11wwq_178 ._nameAndDate_11wwq_178 ._name_11wwq_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._InstalledIntegrationItem_11wwq_178 ._body_11wwq_188 ._content_11wwq_188 ._actions_11wwq_188 ._remove_11wwq_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._InstalledIntegrationItem_11wwq_178 ._header_11wwq_178 ._nameAndDate_11wwq_178 ._date_11wwq_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

._ConfirmOverlay_11wwq_208 ._title_11wwq_208 {
  margin-left: -22px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ConfirmOverlay_11wwq_208 ._title_11wwq_208 ._alertIcon_11wwq_214 {
  margin-right: 4px;
  display: inline;
  color: #f1515f;
}

._InstalledIntegrationItem_11wwq_178 {
  margin-bottom: 16px;
  overflow: hidden;
  backface-visibility: hidden;
  border-radius: 6px;
  border: 1px solid #DADCDE;
  transform: translateZ(0);
}
._InstalledIntegrationItem_11wwq_178._disabled_11wwq_228 {
  opacity: 0.6;
  pointer-events: none;
}
._InstalledIntegrationItem_11wwq_178 ._header_11wwq_178 {
  padding-left: 16px;
  padding-right: 32px;
  cursor: pointer;
  user-select: none;
  height: 74px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._InstalledIntegrationItem_11wwq_178 ._header_11wwq_178 ._image_11wwq_242 {
  margin-right: 22px;
  width: 20px;
  object-fit: contain;
  object-position: center center;
}
._InstalledIntegrationItem_11wwq_178 ._header_11wwq_178 ._nameAndDate_11wwq_178 ._name_11wwq_178 {
  margin-bottom: 6px;
}
._InstalledIntegrationItem_11wwq_178 ._header_11wwq_178 ._nameAndDate_11wwq_178 ._date_11wwq_198 {
  color: #8f8d91;
}
._InstalledIntegrationItem_11wwq_178 ._header_11wwq_178 ._switch_11wwq_254 {
  margin-left: auto;
  margin-right: 32px;
}
._InstalledIntegrationItem_11wwq_178 ._header_11wwq_178 ._icon_11wwq_258 {
  color: #8f8d91;
  transform: rotate(0);
  -webkit-transform: rotate(0);
  will-change: transform;
  transition: transform 0.25s ease-out;
}
._InstalledIntegrationItem_11wwq_178 ._header_11wwq_178 ._icon_11wwq_258._collapsed_11wwq_265 {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}
._InstalledIntegrationItem_11wwq_178 ._body_11wwq_188 {
  overflow: hidden;
  overflow-y: auto;
  max-height: 200px;
  background: #eff5f9;
  will-change: max-height, opacity;
  transition: max-height 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0ms, opacity 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s;
}
._InstalledIntegrationItem_11wwq_178 ._body_11wwq_188._collapsed_11wwq_265 {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0ms, opacity 0ms cubic-bezier(0.215, 0.61, 0.355, 1) 0ms;
}
._InstalledIntegrationItem_11wwq_178 ._body_11wwq_188 ._content_11wwq_188 {
  padding: 28px 24px 36px;
}
._InstalledIntegrationItem_11wwq_178 ._body_11wwq_188 ._content_11wwq_188 ._description_11wwq_285 {
  margin-bottom: 24px;
}
._InstalledIntegrationItem_11wwq_178 ._body_11wwq_188 ._content_11wwq_188 ._actions_11wwq_188 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._InstalledIntegrationItem_11wwq_178 ._body_11wwq_188 ._content_11wwq_188 ._actions_11wwq_188 ._run_11wwq_293 {
  margin-right: 32px;
  width: 120px;
}
._InstalledIntegrationItem_11wwq_178 ._body_11wwq_188 ._content_11wwq_188 ._actions_11wwq_188 ._remove_11wwq_188 {
  cursor: pointer;
  user-select: none;
  color: #f1515f;
}