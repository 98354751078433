/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_bhk54_157 {
  display: flex;
}

._justify-content-space-between_bhk54_161 {
  justify-content: space-between;
}

._tabular-nums_bhk54_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_bhk54_245 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ReviewTableWrapper_bhk54_240 {
  flex: 1 1 auto;
  overflow: hidden;
  min-height: 1px;
}
._ReviewTableWrapper_bhk54_240 ._reviewTable_bhk54_245 tr._disabled_bhk54_245 td._showDisabled_bhk54_245 {
  opacity: 0.5;
}
._ReviewTableWrapper_bhk54_240 ._reviewTable_bhk54_245 td._comment_bhk54_248 {
  color: #d48806;
}
._ReviewTableWrapper_bhk54_240 ._reviewTable_bhk54_245 table thead.ant-table-thead tr th {
  background-color: transparent;
  color: #8c8c8c;
  padding: 0;
}
._ReviewTableWrapper_bhk54_240 ._reviewTable_bhk54_245 table thead.ant-table-thead tr th .ant-table-column-sorters {
  padding: 8px;
}
._ReviewTableWrapper_bhk54_240 ._reviewTable_bhk54_245 table thead.ant-table-thead tr th .anticon {
  margin-right: 4px;
}
._ReviewTableWrapper_bhk54_240 ._reviewTable_bhk54_245 table thead.ant-table-thead tr th.ant-table-selection-column {
  padding: 8px;
  text-overflow: initial;
  white-space: normal;
  border-right: 1px solid #f0f0f0;
}
._ReviewTableWrapper_bhk54_240 ._reviewTable_bhk54_245 table tbody.ant-table-tbody tr.ant-table-row-selected:hover {
  background: #fafafa;
}
._ReviewTableWrapper_bhk54_240 ._reviewTable_bhk54_245 table tbody.ant-table-tbody tr.ant-table-row-selected td {
  background: unset;
}
._ReviewTableWrapper_bhk54_240 ._reviewTable_bhk54_245 table tbody.ant-table-tbody tr.ant-table-row-selected td.ant-table-column-sort {
  background: #fafafa;
}
._ReviewTableWrapper_bhk54_240 ._reviewTable_bhk54_245 table tbody.ant-table-tbody tr td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 4px 8px;
  border-bottom: 0;
}
._ReviewTableWrapper_bhk54_240 ._reviewTable_bhk54_245 table tbody.ant-table-tbody tr td .ant-avatar {
  margin-right: 4px;
}
._ReviewTableWrapper_bhk54_240 ._reviewTable_bhk54_245 table tbody.ant-table-tbody tr td.ant-table-selection-column {
  padding: 8px;
  text-overflow: initial;
  white-space: normal;
  border-right: 1px solid #f0f0f0;
}