/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1i8do_157 {
  display: flex;
}

._justify-content-space-between_1i8do_161 {
  justify-content: space-between;
}

._tabular-nums_1i8do_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1i8do_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._input_1i8do_178 {
  padding: 0;
  border: 0;
  outline: none;
  appearance: none;
}

._readOnlyField_1i8do_185 {
  max-width: 100%;
}
._readOnlyField_1i8do_185 ._field_1i8do_188 {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
}
._readOnlyField_1i8do_185 ._field_1i8do_188 ._ellipsisLabel_1i8do_194 {
  max-width: 100%;
}
._readOnlyField_1i8do_185 ._field_1i8do_188:hover ._editIcon_1i8do_197 {
  visibility: visible;
}
._readOnlyField_1i8do_185 ._field_1i8do_188 ._editIcon_1i8do_197 {
  margin-left: 0.6875rem;
  color: #3996e0;
  visibility: hidden;
  cursor: pointer;
}