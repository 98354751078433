._green_fyh0y_1 {
  color: var(--green-7);
}

._red_fyh0y_5 {
  color: var(--red-6);
}

._gray_fyh0y_9 {
  color: var(--gray-5);
}

._white_fyh0y_13 {
  color: var(--text-primary-foreground);
}