/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_e1cvv_157 {
  display: flex;
}

._justify-content-space-between_e1cvv_161 {
  justify-content: space-between;
}

._tabular-nums_e1cvv_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_e1cvv_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._InfluencerSearchPage_e1cvv_178 {
  padding-top: 4.625rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}
._InfluencerSearchPage_e1cvv_178 ._header_e1cvv_188 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0.625rem;
  left: 2.5rem;
  right: 2.5rem;
}
._InfluencerSearchPage_e1cvv_178 ._header_e1cvv_188 ._square_e1cvv_196 {
  align-items: center;
  background-color: #70397C;
  border-radius: 0.25rem;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin-right: 0.625rem;
  width: 2rem;
}
._InfluencerSearchPage_e1cvv_178 ._header_e1cvv_188 ._square_e1cvv_196 ._sectionIcon_e1cvv_206 {
  width: 28px;
}
._InfluencerSearchPage_e1cvv_178 ._header_e1cvv_188 ._title_e1cvv_209 {
  margin-top: 0.625rem;
}
._InfluencerSearchPage_e1cvv_178 div[class^="SearchPage_results"] {
  max-height: calc(100vh - 4.375rem);
}
._InfluencerSearchPage_e1cvv_178 div[class*="CreatorList_notice"] {
  position: absolute;
  top: 7.5rem;
  left: 50%;
  transform: translateX(-50%);
}
._InfluencerSearchPage_e1cvv_178 ._searchPageComponent_e1cvv_221 {
  overflow: visible;
}
._InfluencerSearchPage_e1cvv_178 ._searchPageResults_e1cvv_224 {
  margin-top: -4.875rem;
}
._InfluencerSearchPage_e1cvv_178 ._searchPageResults_e1cvv_224 > div {
  max-height: 100%;
}

._spinner_e1cvv_231 {
  position: absolute;
  z-index: 10000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}