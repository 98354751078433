:root {
  --spacing-xxxl: 80px;
  --spacing-xxl: 48px;
  --spacing-xl: 32px;
  --spacing-lg: 24px;
  --spacing-md: 16px;
  --spacing-sm: 12px;
  --spacing-xs: 8px;
  --spacing-xxs: 4px;
}

._loadingContentContainer_u7fk8_23 {
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
}

._loadingContentRow_u7fk8_30 {
  column-gap: 8px;
}

._loadingContentWrapper_u7fk8_34 {
  flex: 1;
  flex-direction: column;
  padding: 24px;
  row-gap: 34px !important;
}

._contentGuidelinesLoadingHeader_u7fk8_41 {
  margin: 32px 24px 24px;
  row-gap: 24px;
  column-gap: 16px !important;
}

._loadingLineXXS_u7fk8_47 {
  width: 24px;
  display: flex;
  align-items: center;
  margin-left: auto;
}

._loadingButton_u7fk8_54 {
  display: flex;
  align-items: center;
  flex: 1;
}
._loadingButton_u7fk8_54:last-child {
  margin-left: auto;
}

.ant-skeleton-title {
  margin-bottom: 0px !important;
}