/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_cwg8a_157 {
  display: flex;
}

._justify-content-space-between_cwg8a_161 {
  justify-content: space-between;
}

._tabular-nums_cwg8a_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_cwg8a_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SegmentItem_cwg8a_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._SegmentItem_cwg8a_178 {
  position: relative;
  display: flex;
  width: 100%;
  padding: 0.375rem 0.5rem 0.375rem 1.875rem;
  border-radius: 0.25rem;
  color: #8f8d91;
  letter-spacing: 0;
  cursor: pointer;
  user-select: none;
}
._SegmentItem_cwg8a_178 ._title_cwg8a_199 {
  margin-right: 0.25rem;
  overflow: auto;
}
._SegmentItem_cwg8a_178 ._editIcon_cwg8a_203 {
  position: absolute;
  top: 50%;
  right: 0.625rem;
  display: none;
  color: #0075FF;
  transform: translateY(-50%);
}
._SegmentItem_cwg8a_178 ._editIcon_cwg8a_203 > * {
  vertical-align: middle;
}
._SegmentItem_cwg8a_178:hover {
  background-color: #eff5f9;
  color: #0075FF;
}
._SegmentItem_cwg8a_178:hover._editable_cwg8a_218 ._count_cwg8a_218 {
  opacity: 0;
  visibility: hidden;
}
._SegmentItem_cwg8a_178:hover._editable_cwg8a_218 ._editIcon_cwg8a_203 {
  display: block;
}
._SegmentItem_cwg8a_178 ._count_cwg8a_218 {
  margin-left: auto;
}
._SegmentItem_cwg8a_178._selected_cwg8a_228 {
  background-color: #eff5f9;
  color: #0075FF;
}