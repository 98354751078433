._MessageComposer_1l1en_1 {
  display: flex;
  flex-direction: column;
}
._MessageComposer_1l1en_1 > * + * {
  margin-block: 25px;
}
._MessageComposer_1l1en_1 ._EmailComposer_1l1en_8 {
  min-height: 500px;
  border: 1px solid #DADCDE;
}
._MessageComposer_1l1en_1 ._EmailComposer_1l1en_8._hide_1l1en_12 {
  display: none;
}