/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_11aif_157 {
  display: flex;
}

._justify-content-space-between_11aif_161 {
  justify-content: space-between;
}

._tabular-nums_11aif_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_11aif_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ShowSection_11aif_178 {
  display: flex;
  flex-direction: row;
  padding: 0.75rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
}
._ShowSection_11aif_178 > :first-child {
  flex: 1;
}