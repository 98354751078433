._searchResultsCard_174sv_1 ._stickyFooter_174sv_1 {
  position: sticky;
  bottom: 0;
  background: white;
  padding: 10px 0;
  z-index: 1000;
}
._searchResultsCard_174sv_1 ._searchIcon_174sv_8 {
  margin-right: var(--spacing-xs);
}
._searchResultsCard_174sv_1 ._divider_174sv_11 {
  margin: var(--spacing-sm) 0 var(--spacing-sm) 0;
}
._searchResultsCard_174sv_1 ._mainSection_174sv_14 {
  height: 320px;
  margin-top: var(--spacing-md);
  overflow-y: scroll;
}
._searchResultsCard_174sv_1 ._mainSection_174sv_14 ._emptyText_174sv_19 {
  align-self: center;
}
._searchResultsCard_174sv_1 ._mainSection_174sv_14 ._listContainer_174sv_22 {
  padding-left: 56px;
}
._searchResultsCard_174sv_1 ._mainSection_174sv_14 ._listContainer_174sv_22 ._firstColumn_174sv_25 {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}