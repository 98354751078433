/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1hcj0_157 {
  display: flex;
}

._justify-content-space-between_1hcj0_161 {
  justify-content: space-between;
}

._tabular-nums_1hcj0_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1hcj0_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
:root {
  --spacing-xxxl: 80px;
  --spacing-xxl: 48px;
  --spacing-xl: 32px;
  --spacing-lg: 24px;
  --spacing-md: 16px;
  --spacing-sm: 12px;
  --spacing-xs: 8px;
  --spacing-xxs: 4px;
}

._TikTokSparkAdsRecommendationsWidgetCard_1hcj0_200 {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* Hide page numbers */
  /* Fix pagination arrow position */
  /* Optional: Hide dots (if any) */
}
._TikTokSparkAdsRecommendationsWidgetCard_1hcj0_200 .ant-pagination {
  position: absolute;
  top: -42px;
  z-index: 99;
  right: 30px;
}
._TikTokSparkAdsRecommendationsWidgetCard_1hcj0_200 .ant-pagination-item {
  display: none;
}
._TikTokSparkAdsRecommendationsWidgetCard_1hcj0_200 .ant-pagination-next {
  margin-top: -8px;
}
._TikTokSparkAdsRecommendationsWidgetCard_1hcj0_200 .ant-pagination-prev {
  margin-top: -8px;
}
._TikTokSparkAdsRecommendationsWidgetCard_1hcj0_200 .ant-pagination-item-ellipsis {
  display: none;
}
._TikTokSparkAdsRecommendationsWidgetCard_1hcj0_200 ._ArrowButton_1hcj0_227 {
  border: none;
  background: transparent;
}
._TikTokSparkAdsRecommendationsWidgetCard_1hcj0_200 ._loadingImageBox_1hcj0_231 .ant-skeleton-title {
  height: 130px;
}
._TikTokSparkAdsRecommendationsWidgetCard_1hcj0_200 ._nineSixteen_1hcj0_234 {
  width: 100%;
  padding-bottom: 177.78%;
  position: relative;
}
._TikTokSparkAdsRecommendationsWidgetCard_1hcj0_200 ._nineSixteen_1hcj0_234 ._nineSixteenFill_1hcj0_239 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
._TikTokSparkAdsRecommendationsWidgetCard_1hcj0_200 .ant-card-head-title {
  padding: 0 !important;
}
._TikTokSparkAdsRecommendationsWidgetCard_1hcj0_200 .ant-card-head {
  min-height: 0;
}
._TikTokSparkAdsRecommendationsWidgetCard_1hcj0_200 .ant-card-cover img {
  border-radius: 6px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
._TikTokSparkAdsRecommendationsWidgetCard_1hcj0_200 .ant-card-cover img:hover {
  transform: translateY(-0.125rem);
  box-shadow: rgba(0, 0, 0, 0.16) 0rem 0.1875rem 0.625rem, rgba(0, 0, 0, 0.23) 0rem 0.1875rem 0.625rem;
}
._TikTokSparkAdsRecommendationsWidgetCard_1hcj0_200 .ant-btn-text {
  font-size: 12px;
}
._TikTokSparkAdsRecommendationsWidgetCard_1hcj0_200 .ant-card-body {
  flex-grow: 1;
  padding-top: 0;
}
._TikTokSparkAdsRecommendationsWidgetCard_1hcj0_200 .ant-card-actions {
  border-top: 0;
}