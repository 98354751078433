/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1606b_157 {
  display: flex;
}

._justify-content-space-between_1606b_161 {
  justify-content: space-between;
}

._tabular-nums_1606b_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1606b_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._BaseEmptyState_1606b_178 ._title_1606b_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._BaseEmptyState_1606b_178 ._subtitle_1606b_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._BaseEmptyState_1606b_178 {
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
._BaseEmptyState_1606b_178 ._title_1606b_178 {
  margin-bottom: 0.75rem;
}
._BaseEmptyState_1606b_178 ._subtitle_1606b_188 {
  color: #8f8d91;
  max-width: 18.75rem;
}