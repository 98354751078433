/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_115mi_157 {
  display: flex;
}

._justify-content-space-between_115mi_161 {
  justify-content: space-between;
}

._tabular-nums_115mi_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_115mi_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ProgramDetails_115mi_178 ._tabs_115mi_178 ._tab_115mi_165 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._ProgramDetails_115mi_178 {
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
}
._ProgramDetails_115mi_178 ._tabs_115mi_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 0.0625rem solid #dadcde;
}
._ProgramDetails_115mi_178 ._tabs_115mi_178 ._tab_115mi_165 {
  flex: 1;
  padding: 1.25rem;
  color: #8f8d91;
  text-align: center;
  cursor: pointer;
}
._ProgramDetails_115mi_178 ._tabs_115mi_178 ._tab_115mi_165:first-child {
  border-top-left-radius: 0.375rem;
}
._ProgramDetails_115mi_178 ._tabs_115mi_178 ._tab_115mi_165:last-child {
  border-top-right-radius: 0.375rem;
}
._ProgramDetails_115mi_178 ._tabs_115mi_178 ._tab_115mi_165._selected_115mi_211 {
  background-color: #e6edf2;
  color: #3996e0;
}
._ProgramDetails_115mi_178 ._content_115mi_215 {
  padding: 1.875rem;
}
._ProgramDetails_115mi_178 ._content_115mi_215 > * {
  display: none;
}
._ProgramDetails_115mi_178 ._content_115mi_215 > ._selected_115mi_211 {
  display: block;
}
._ProgramDetails_115mi_178 ._divider_115mi_224 {
  width: 100%;
  height: 0.0625rem;
  background-color: #dadcde;
}
._ProgramDetails_115mi_178 ._save_115mi_229 {
  padding: 1.875rem;
}