/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._AccessTable_1h4k2_32 ._notice_1h4k2_32._center_1h4k2_32 {
  margin: auto;
}
._AccessTable_1h4k2_32 ._table_1h4k2_35 {
  margin-bottom: 40px;
}
._AccessTable_1h4k2_32 ._helpNotice_1h4k2_38 {
  color: #1a1818;
}
._AccessTable_1h4k2_32 ._errorNotice_1h4k2_41 {
  color: #fdfdfd;
}
._AccessTable_1h4k2_32 ._contactSupport_1h4k2_44 {
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
}