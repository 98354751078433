/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1x8ow_157 {
  display: flex;
}

._justify-content-space-between_1x8ow_161 {
  justify-content: space-between;
}

._tabular-nums_1x8ow_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1x8ow_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._AssignOwnersMenuItem_1x8ow_178 {
  z-index: 9600 !important;
}
._AssignOwnersMenuItem_1x8ow_178 ._label_1x8ow_181 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._AssignOwnersMenuItem_1x8ow_178 ._img_1x8ow_186 {
  width: 1.875rem;
  height: 1.875rem;
  margin-right: 1.25rem;
  border-radius: 0.5rem;
}

._ownerAvatar_1x8ow_193 {
  margin-left: 2px;
  margin-right: 12px;
}