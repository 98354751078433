/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_96foa_157 {
  display: flex;
}

._justify-content-space-between_96foa_161 {
  justify-content: space-between;
}

._tabular-nums_96foa_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_96foa_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._stepForm_96foa_178 {
  width: 42.5rem !important;
  border: 0.0625rem solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 0.5rem;
}
._stepForm_96foa_178 ._previewFrame_96foa_184 {
  height: 50rem;
}
._stepForm_96foa_178 ._urlPanel_96foa_187 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: #E9E8EA;
}
._stepForm_96foa_178 ._urlPanel_96foa_187 ._url_96foa_187 {
  width: 70%;
  height: 1rem !important;
  background-color: #fdfdfd;
}

._stepInfo_96foa_201 {
  width: 23.125rem !important;
}