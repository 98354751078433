/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1o7tx_157 {
  display: flex;
}

._justify-content-space-between_1o7tx_161 {
  justify-content: space-between;
}

._tabular-nums_1o7tx_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1o7tx_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SuccessPage_1o7tx_178 ._header_1o7tx_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._SuccessPage_1o7tx_178 ._subheader_1o7tx_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._SuccessPage_1o7tx_178 ._summary_1o7tx_198 ._content_1o7tx_198 ._noticeTitle_1o7tx_198, ._SuccessPage_1o7tx_178 ._summary_1o7tx_198 ._stepTitle_1o7tx_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._SuccessPage_1o7tx_178 ._summary_1o7tx_198 ._content_1o7tx_198 ._noticeText_1o7tx_208 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._SuccessPage_1o7tx_178 {
  padding: 2rem;
}
._SuccessPage_1o7tx_178 ._header_1o7tx_178 {
  margin-bottom: 0.75rem;
  text-align: center;
}
._SuccessPage_1o7tx_178 ._subheader_1o7tx_188 {
  margin-bottom: 2.5rem;
  color: #8f8d91;
  text-align: center;
}
._SuccessPage_1o7tx_178 ._summary_1o7tx_198 {
  border: 1px solid #dadcde;
  border-radius: 0.5rem;
}
._SuccessPage_1o7tx_178 ._summary_1o7tx_198 ._summarySection_1o7tx_234 {
  padding: 1.5rem;
}
._SuccessPage_1o7tx_178 ._summary_1o7tx_198 ._stepTitle_1o7tx_198 {
  text-align: left;
}
._SuccessPage_1o7tx_178 ._summary_1o7tx_198 ._summarySection_1o7tx_234:not(:first-child) {
  border-top: 1px solid #dadcde;
}
._SuccessPage_1o7tx_178 ._summary_1o7tx_198 ._summarySection_1o7tx_234:last-child {
  text-align: center;
}
._SuccessPage_1o7tx_178 ._summary_1o7tx_198 ._errorNotice_1o7tx_246 {
  margin-bottom: 1.25rem;
}
._SuccessPage_1o7tx_178 ._summary_1o7tx_198 ._content_1o7tx_198 {
  display: flex;
  flex-direction: column;
  max-height: 100px;
}
._SuccessPage_1o7tx_178 ._summary_1o7tx_198 ._content_1o7tx_198 ._noticeTitle_1o7tx_198 {
  margin-bottom: 0.75rem;
  flex-shrink: 0;
  color: #fdfdfd;
}
._SuccessPage_1o7tx_178 ._summary_1o7tx_198 ._content_1o7tx_198 ._noticeText_1o7tx_208 {
  flex: 1;
  overflow-y: auto;
  color: #fdfdfd;
}