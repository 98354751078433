/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_cewf4_157 {
  display: flex;
}

._justify-content-space-between_cewf4_161 {
  justify-content: space-between;
}

._tabular-nums_cewf4_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_cewf4_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._InputNumber_cewf4_209 {
  box-sizing: border-box;
  height: 2.5rem;
  border: 0.0625rem solid #bdbdbd;
  border-radius: 0.25rem;
  width: 100%;
}
._InputNumber_cewf4_209:focus, ._InputNumber_cewf4_209:hover {
  border-color: #3996e0 !important;
}

._InputNumber_cewf4_209 .ant-input-number-input-wrap {
  height: 100%;
}
._InputNumber_cewf4_209 .ant-input-number-input {
  height: 100%;
  padding-left: 0.6875rem;
  padding-right: 0.6875rem;
}