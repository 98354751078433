/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1wadj_157 {
  display: flex;
}

._justify-content-space-between_1wadj_161 {
  justify-content: space-between;
}

._tabular-nums_1wadj_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1wadj_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SocialAppDashboardPosts_1wadj_178 ._headerSection_1wadj_178 ._header_1wadj_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._SocialAppDashboardPosts_1wadj_178 ._headerSection_1wadj_178 ._subHeader_1wadj_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._SocialAppDashboardPosts_1wadj_178 {
  position: relative;
}
._SocialAppDashboardPosts_1wadj_178 ._headerSection_1wadj_178 {
  z-index: 1000;
  width: calc(100% - 8.75rem);
  padding-left: var(--spacing-lg);
  padding-right: var(--spacing-lg);
  padding-bottom: 2rem;
  background: #fdfdfd;
}
._SocialAppDashboardPosts_1wadj_178 ._headerSection_1wadj_178 ._header_1wadj_178 {
  padding-top: 2.625rem;
}
._SocialAppDashboardPosts_1wadj_178 ._headerSection_1wadj_178 ._subHeader_1wadj_188 {
  padding-top: 0.5rem;
}
._SocialAppDashboardPosts_1wadj_178 ._headerSection_1wadj_178 ._actionSection_1wadj_215 {
  padding-top: 2rem;
}
._SocialAppDashboardPosts_1wadj_178 ._headerSection_1wadj_178 ._checkbox_1wadj_218 {
  display: inline-block;
  vertical-align: bottom;
}
._SocialAppDashboardPosts_1wadj_178 ._headerSection_1wadj_178 ._selectedCount_1wadj_222 {
  display: inline-block;
  vertical-align: text-bottom;
  padding-right: 2rem;
}
._SocialAppDashboardPosts_1wadj_178 ._headerSection_1wadj_178 ._selector_1wadj_227 {
  display: inline-block;
  width: 12.5rem;
}
._SocialAppDashboardPosts_1wadj_178 ._headerSection_1wadj_178 ._userFilterSelect_1wadj_231 {
  float: right;
}
._SocialAppDashboardPosts_1wadj_178 ._postsSection_1wadj_234 {
  position: absolute;
  top: 12.25rem;
  width: 100%;
  height: calc(100% - 18.9375rem);
  text-align: left;
}
._SocialAppDashboardPosts_1wadj_178 ._postsSection_1wadj_234 ._postsList_1wadj_241 {
  overflow-y: auto;
}
._SocialAppDashboardPosts_1wadj_178 ._postsSection_1wadj_234 ._noPostsNotice_1wadj_244 {
  margin-top: 2.5rem;
}
._SocialAppDashboardPosts_1wadj_178 ._postsSection_1wadj_234 ._loadMoreButton_1wadj_247 {
  display: block;
  padding-top: 0.75rem;
  padding-bottom: 1.5rem;
  text-align: center;
}
._SocialAppDashboardPosts_1wadj_178 ._postsSection_1wadj_234 ._postsSpinner_1wadj_253 {
  margin-top: 1.875rem;
}

._separator_1wadj_257 {
  border-top: 1px solid #dedede;
}