/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_6e60a_157 {
  display: flex;
}

._justify-content-space-between_6e60a_161 {
  justify-content: space-between;
}

._tabular-nums_6e60a_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_6e60a_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_6e60a_157 {
  display: flex;
}

._justify-content-space-between_6e60a_161 {
  justify-content: space-between;
}

._tabular-nums_6e60a_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_6e60a_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._dropDownButton_6e60a_355 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._dropDownButton_6e60a_355 {
  min-width: 2.25rem;
}
._dropDownButton_6e60a_355 > svg:first-of-type:not(:only-child) {
  margin-right: 0.25rem;
}

._dropDownButton_6e60a_355:focus, ._dropDownButton_6e60a_355:hover {
  border-color: #167cf4;
  color: #167cf4;
}

._menuRow_6e60a_377 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2.75rem;
}

._appName_6e60a_384 {
  padding-left: 0.625rem;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: -0.025rem;
}

._dropDownButton_6e60a_355 ._downArrow_6e60a_391 {
  margin-left: 0.25rem;
  color: #8f8d91;
}