/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1yzqk_157 {
  display: flex;
}

._justify-content-space-between_1yzqk_161 {
  justify-content: space-between;
}

._tabular-nums_1yzqk_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1yzqk_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._TermsAppHistory_1yzqk_178 ._countLabel_1yzqk_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._Empty_1yzqk_188 {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

._TermsAppHistory_1yzqk_178 {
  overflow: auto;
  width: 100%;
  height: 100%;
}
._TermsAppHistory_1yzqk_178 ._TermsAppHistory__content_1yzqk_200 {
  max-width: 100%;
  margin: 0 auto;
}
._TermsAppHistory_1yzqk_178 ._countLabel_1yzqk_178 {
  margin-top: 1.25rem;
  margin-bottom: 1.5rem;
}
._TermsAppHistory_1yzqk_178 ._LoadingSpinner_1yzqk_208 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}