/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_2bjxp_157 {
  display: flex;
}

._justify-content-space-between_2bjxp_161 {
  justify-content: space-between;
}

._tabular-nums_2bjxp_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_2bjxp_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._WooAddNew_2bjxp_178 ._title_2bjxp_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._WooAddNew_2bjxp_178 ._label_2bjxp_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._WooAddNew_2bjxp_178 ._description_2bjxp_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._WooAddNew_2bjxp_178 ._input_2bjxp_208 {
  margin-bottom: 0.5rem;
}
._WooAddNew_2bjxp_178 ._logo_2bjxp_211 {
  display: block;
  width: 5rem;
  height: 5rem;
  margin: auto;
  margin-bottom: 1rem;
}
._WooAddNew_2bjxp_178 ._title_2bjxp_178 {
  margin-bottom: 0.5rem;
  text-align: center;
}
._WooAddNew_2bjxp_178 ._description_2bjxp_198 {
  margin-bottom: 2.25rem;
  color: #8f8d91;
  text-align: center;
}
._WooAddNew_2bjxp_178 ._label_2bjxp_188 {
  margin-bottom: 0.5rem;
}