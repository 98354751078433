/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_lncy5_157 {
  display: flex;
}

._justify-content-space-between_lncy5_161 {
  justify-content: space-between;
}

._tabular-nums_lncy5_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_lncy5_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._InviteTemplatePage_lncy5_178 ._EmailComposer_lncy5_178 ._section_lncy5_178 ._label_lncy5_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_lncy5_157 {
  display: flex;
}

._justify-content-space-between_lncy5_161 {
  justify-content: space-between;
}

._tabular-nums_lncy5_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_lncy5_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._InviteTemplatePage_lncy5_178 ._EmailComposer_lncy5_178 ._section_lncy5_178 ._label_lncy5_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._InviteTemplatePage_lncy5_178 {
  margin: 0 auto;
  padding: 4rem;
}
._InviteTemplatePage_lncy5_178 ._EmailComposer_lncy5_178 {
  width: 100%;
  max-width: 43.75rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
  background: #fff;
}
._InviteTemplatePage_lncy5_178 ._EmailComposer_lncy5_178 ._section_lncy5_178 {
  padding: 1rem 1.5rem;
}
._InviteTemplatePage_lncy5_178 ._EmailComposer_lncy5_178 ._section_lncy5_178._editor_lncy5_389 {
  padding: 0;
}
._InviteTemplatePage_lncy5_178 ._EmailComposer_lncy5_178 ._section_lncy5_178 ._label_lncy5_178 {
  margin-right: 0.75rem;
  color: #8f8d91;
}
._InviteTemplatePage_lncy5_178 ._EmailComposer_lncy5_178 ._section_lncy5_178:not(:first-child) {
  border-top: 0.0625rem solid #dadcde;
}
._InviteTemplatePage_lncy5_178 ._footer_lncy5_399 {
  margin-top: 1.25rem;
}