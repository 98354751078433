/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_twtna_157 {
  display: flex;
}

._justify-content-space-between_twtna_161 {
  justify-content: space-between;
}

._tabular-nums_twtna_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_twtna_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_twtna_157 {
  display: flex;
}

._justify-content-space-between_twtna_161 {
  justify-content: space-between;
}

._tabular-nums_twtna_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_twtna_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._LandingPages_twtna_355 {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 0 3rem;
}
._LandingPages_twtna_355 ._leftPanel_twtna_361 {
  width: 22.3125rem;
  height: 100%;
  border-right: 0.0625rem solid #dadcde;
}
._LandingPages_twtna_355 ._rightPanel_twtna_366 {
  flex: 1;
  height: 100%;
}

._RightPanel_twtna_371 {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1rem 3rem;
}
._RightPanel_twtna_371 ._radioGroup_twtna_377 .ant-radio-button-wrapper {
  height: 2.5rem;
  line-height: 2.5rem;
}
._RightPanel_twtna_371 ._radioGroup_twtna_377 .ant-radio-button-wrapper span {
  font-weight: 500;
  font-size: 0.875rem;
}
._RightPanel_twtna_371 ._radioGroup_twtna_377 .ant-radio-button-wrapper-checked {
  border-color: #167cf4;
  background-color: #167cf4;
}
._RightPanel_twtna_371 ._layout_twtna_389 {
  flex: 1;
}
._RightPanel_twtna_371 ._frameLayout_twtna_392 {
  position: relative;
  flex: 1;
}
._RightPanel_twtna_371 ._mobileLayout_twtna_396 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
._RightPanel_twtna_371 ._mobileLayout_twtna_396 ._urlPanel_twtna_401 {
  width: 20rem;
}
._RightPanel_twtna_371 ._mobileLayout_twtna_396 ._urlPanel_twtna_401 ._url_twtna_401 {
  width: 19.375rem;
}
._RightPanel_twtna_371 ._mobileLayout_twtna_396 ._frameLayout_twtna_392 {
  width: 20rem;
}
._RightPanel_twtna_371 ._desktopLayout_twtna_410._forceDesktop_twtna_410 ._frameLayout_twtna_392 {
  height: calc((100% - 22.3125rem) * 0.5);
}
._RightPanel_twtna_371 ._header_twtna_413 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.3125rem;
}
._RightPanel_twtna_371 ._header_twtna_413 ._buttons_twtna_419 {
  display: flex;
  align-items: center;
  justify-content: right;
}
._RightPanel_twtna_371 ._urlPanel_twtna_401 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding-right: 0.875rem;
  border-radius: 0.75rem 0.75rem 0 0;
  background-color: #E9E8EA;
}
._RightPanel_twtna_371 ._urlPanel_twtna_401 ._url_twtna_401 {
  width: 70%;
  height: 1.5rem !important;
  padding-right: 1rem;
  padding-left: 1.75rem;
  background-color: #fdfdfd;
  text-align: center;
}
._RightPanel_twtna_371 ._urlPanel_twtna_401 ._favicon_twtna_441 {
  position: relative;
  left: 1.5rem;
  z-index: 1;
  width: 1rem;
  height: 1rem;
}

._SubmitModal_twtna_449 {
  z-index: 9401;
}
._SubmitModal_twtna_449 ._title_twtna_452 {
  margin-bottom: 0.625rem !important;
  font-weight: 600 !important;
  font-size: 1.25rem !important;
  line-height: 1.875rem !important;
  text-align: left !important;
}
._SubmitModal_twtna_449 ._dialog_twtna_459 {
  padding: 1.5625rem 2.5rem;
}
._SubmitModal_twtna_449 ._content_twtna_462 {
  padding-bottom: 2.5rem;
}