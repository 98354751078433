/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_14jwb_157 {
  display: flex;
}

._justify-content-space-between_14jwb_161 {
  justify-content: space-between;
}

._tabular-nums_14jwb_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_14jwb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._FormFields_14jwb_178 ._field_14jwb_178:not(:last-child) {
  margin-bottom: 1rem;
}
._FormFields_14jwb_178 ._header_14jwb_181 {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;
}
._FormFields_14jwb_178 ._title_14jwb_186 {
  color: #42474b;
  font-weight: 500;
  font-size: 0.875rem;
}
._FormFields_14jwb_178 ._seeAll_14jwb_191 {
  margin-left: auto;
  color: #3996e0;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
}
._FormFields_14jwb_178 ._sortableField_14jwb_198 {
  min-height: 0;
  padding: 0;
  padding-left: 1.5rem;
  border: 0;
}
._FormFields_14jwb_178 ._handle_14jwb_204 {
  left: 0 !important;
}