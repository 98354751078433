/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1xfxm_157 {
  display: flex;
}

._justify-content-space-between_1xfxm_161 {
  justify-content: space-between;
}

._tabular-nums_1xfxm_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1xfxm_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._EmailSignature_1xfxm_178 ._title_1xfxm_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._EmailSignature_1xfxm_178 ._text_1xfxm_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._EmailSignature_1xfxm_178 ._title_1xfxm_178 {
  margin-bottom: 0.75rem;
}
._EmailSignature_1xfxm_178 ._text_1xfxm_188 {
  margin-bottom: 1.5rem;
}
._EmailSignature_1xfxm_178 ._mode_1xfxm_235 {
  margin-bottom: 1.375rem;
}
._EmailSignature_1xfxm_178 ._editor_1xfxm_238 {
  min-height: 16.875rem;
  border: 0.0625rem solid #e7e7e7;
  border-radius: 0.5rem;
}
._EmailSignature_1xfxm_178 ._preview_1xfxm_243 {
  margin-top: 2.625rem;
}
._EmailSignature_1xfxm_178 ._saveButton_1xfxm_246 {
  margin-top: 2.625rem;
}