/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_r8e00_157 {
  display: flex;
}

._justify-content-space-between_r8e00_161 {
  justify-content: space-between;
}

._tabular-nums_r8e00_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_r8e00_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_r8e00_157 {
  display: flex;
}

._justify-content-space-between_r8e00_161 {
  justify-content: space-between;
}

._tabular-nums_r8e00_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_r8e00_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

:root {
  --primary: #167cf4;
  --primary-40: #3d73d7;
  --primary-10: #e6f7ff;
  --secondary: #f58984;
  --secondary-40: #fcbbae;
  --secondary-10: #fef0ed;
  --bay-grey: #989ea8;
  --grey-10: #fafafa;
  --grey-25: #e9e8ea;
  --grey-50: #aeaeae;
  --grey-75: #8f8f8f;
  --grey-100: #1a1b1c;
  --white: #fff;
  --black: #1a1b1c;
  --green: #006462;
  --sand: #f6f3ef;
  --yellow: #ffcd5b;
  --magenta-base: #ffddff;
  --red-base: #f5b3b4;
  --volcano-base: #ff9c93;
  --orange-base: #fabf95;
  --gold-base: #f1e5ac;
  --lime-base: #ebffd0;
  --sage-base: #c1e1c1;
  --cyan-base: #c9f2f2;
  --blue-base: #70d1f1;
  --geekblue-base: #a2aff1;
  --purple-base: #c5b3e3;
  --mauve-base: #c6b0ca;
  --coral-base: #ff8a8a;
  --apricot-base: #f5a287;
  --marigold-base: #fdaf1c;
  --butter-base: #f9eda1;
  --apple-base: #b7d275;
  --asparagus-base: #9db17c;
  --tiffany-base: #5fcdca;
  --baby-base: #a3ddf9;
  --carolina-base: #7eb3d8;
  --rose-base: #ffb4c7;
  --watermelon-base: #ffa1b6;
  --blue-0: #f4fcff;
  --blue-1: #e6f7ff;
  --blue-3: #91d5ff;
  --blue-5: #499dff;
  --blue-6: #167cf4;
  --blue-7: #2771c9;
  --gray-1: #fff;
  --gray-2: #fafafa;
  --gray-3: #f5f5f5;
  --gray-4: #f0f0f0;
  --gray-5: #d9d9d9;
  --gray-6: #bfbfbf;
  --gray-7: #8c8c8c;
  --gray-8: #505256;
  --gray-9: #1f1f21;
  --red-1: #fff1f0;
  --red-3: #ffa39e;
  --red-4: #ff7875;
  --red-5: #ff4d4f;
  --red-6: #f5222d;
  --gold-1: #fffbe6;
  --gold-3: #ffe58f;
  --gold-5: #ffc53d;
  --gold-6: #faad14;
  --gold-7: #d48806;
  --green-1: #f6ffed;
  --green-3: #b7eb8f;
  --green-5: #73d13d;
  --green-6: #52c41a;
  --green-7: #389e0d;
  --text-color: #505256;
  --text-color-secondary: #8c8c8c;
  --text-color-inverse: #fff;
  --icon-color: inherit;
  --icon-color-hover: rgba(26, 27, 28, 0.75);
  --heading-color: #1f1f21;
  --text-color-dark: rgba(255, 255, 255, 0.85);
  --text-color-secondary-dark: rgba(255, 255, 255, 0.65);
  --text-selection-bg: #167cf4;
  --link-color: #167cf4;
  --modal-mask-bg: rgba(79, 90, 114, 0.45);
}

._ProjectsMenu_r8e00_554 {
  border: 0;
  background-color: transparent;
}
._ProjectsMenu_r8e00_554 .ant-menu-sub {
  background: transparent;
}
._ProjectsMenu_r8e00_554 .ant-menu-submenu-title {
  align-items: center;
  padding-left: 1rem !important;
  font-weight: 500;
}
._ProjectsMenu_r8e00_554 .ant-menu-submenu-title .ant-menu-submenu-expand-icon {
  margin-right: 0;
}
._ProjectsMenu_r8e00_554 .ant-menu-submenu-open {
  background-color: transparent !important;
}
._ProjectsMenu_r8e00_554 .ant-menu-submenu-open .ant-menu-submenu-expand-icon {
  transform: rotateZ(180deg) translateY(0.375rem);
}
._ProjectsMenu_r8e00_554 .ant-menu-item {
  padding-left: 1rem !important;
}
._ProjectsMenu_r8e00_554 .ant-menu-submenu-expand-icon {
  font-size: 0.75rem;
}
._ProjectsMenu_r8e00_554 .ant-menu-title-content {
  display: flex;
  margin-left: 0rem !important;
  overflow: visible !important;
}
._ProjectsMenu_r8e00_554 .ant-menu-item-icon {
  margin-right: 0.625rem !important;
}

._ProjectsMenuWorklet_r8e00_590 {
  border: 0;
  background: var(--neutral-color-palette-gray-1, #FFF);
}
._ProjectsMenuWorklet_r8e00_590 .ant-menu-sub {
  background: transparent;
}
._ProjectsMenuWorklet_r8e00_590 .ant-menu-submenu-title {
  align-items: center;
  padding-left: 1rem !important;
  font-weight: 500;
}
._ProjectsMenuWorklet_r8e00_590 .ant-menu-submenu-title .ant-menu-submenu-expand-icon {
  margin-right: 0;
}
._ProjectsMenuWorklet_r8e00_590 .ant-menu-submenu-open {
  background-color: transparent !important;
}
._ProjectsMenuWorklet_r8e00_590 .ant-menu-submenu-open .ant-menu-submenu-expand-icon {
  transform: rotateZ(180deg) translateY(0.375rem);
}
._ProjectsMenuWorklet_r8e00_590 .ant-menu-item {
  padding-left: 1rem !important;
}
._ProjectsMenuWorklet_r8e00_590 .ant-menu-submenu-expand-icon {
  font-size: 0.75rem;
}
._ProjectsMenuWorklet_r8e00_590 .ant-menu-title-content {
  display: flex;
  margin-left: 0rem !important;
  overflow: visible !important;
  font-weight: 600;
  font-style: normal;
}
._ProjectsMenuWorklet_r8e00_590 .ant-menu-item-icon {
  margin-right: 0.625rem !important;
}

._item_r8e00_628 {
  display: flex;
  align-items: center;
}
._item_r8e00_628 .ant-menu-title-content:hover {
  color: #167cf4;
}
._item_r8e00_628 .ant-menu-title-content:hover ._accessory_r8e00_635 {
  color: inherit;
}
._item_r8e00_628 .anticon + span {
  display: inline-flex;
  flex: 1;
  flex-direction: row;
  font-size: 0.875rem;
}
._item_r8e00_628 ._totalCount_r8e00_644 {
  display: inline-flex;
  flex: 1;
  flex-direction: row;
  font-size: 0.875rem;
  color: #505256;
  font-weight: 400;
  font-style: normal;
}
._item_r8e00_628 ._totalCount_r8e00_644:hover {
  color: #167cf4 !important;
}
._item_r8e00_628 ._innerItemLabel_r8e00_656 {
  overflow: hidden;
  font-weight: 400;
  font-size: 0.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._item_r8e00_628 ._innerItemLabel_r8e00_656:hover {
  color: #167cf4;
}
._item_r8e00_628 ._innerItemLabel_r8e00_656 + ._accessory_r8e00_635 {
  font-weight: 400;
  font-size: 0.875rem;
}
._item_r8e00_628 ._innerItemLabelWorklet_r8e00_670 {
  font-weight: 400;
  font-size: 0.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex: 1;
}
._item_r8e00_628 ._innerItemLabelWorklet_r8e00_670:hover {
  color: #167cf4;
}
._item_r8e00_628 ._innerItemLabelWorklet_r8e00_670 + ._accessory_r8e00_635 {
  font-weight: 400;
  font-size: 0.875rem;
}
._item_r8e00_628 ._innerItemLabelError_r8e00_685 {
  color: #d9534f;
}
._item_r8e00_628._transient_r8e00_688 {
  fill: var(--green);
}
._item_r8e00_628 ._accessory_r8e00_635 {
  margin-right: 0.1875rem;
  margin-left: auto;
  color: var(--grey-75);
}
._item_r8e00_628 ._prospectsItemLabel_r8e00_696 {
  display: inline-block;
  padding-left: 1.625rem;
}
._item_r8e00_628 ._workletItemLabel_r8e00_700 {
  display: inline-block;
}
._item_r8e00_628 ._workletItemLabel_r8e00_700:hover {
  color: #167cf4;
}
._item_r8e00_628 ._overviewLabel_r8e00_706 {
  padding-left: 0;
}
._item_r8e00_628 ._subMenuItemLabel_r8e00_709 {
  font-weight: 600 !important;
}
._item_r8e00_628 ._findCreatorsLabel_r8e00_712 {
  padding-left: 0;
}
._item_r8e00_628 ._mainItemMenu_r8e00_715 {
  padding-left: 0;
  font-weight: 600 !important;
  color: #1f1f21 !important;
  font-style: normal;
}
._item_r8e00_628 ._mainItemMenu_r8e00_715:hover {
  color: #167cf4 !important;
}
._item_r8e00_628 ._findCreatorsLabelWorkletMenu_r8e00_724 {
  padding-left: 0;
  font-weight: 600;
}
._item_r8e00_628 ._gcrLabel_r8e00_728 {
  padding-left: 0;
}
._item_r8e00_628.ant-menu-item-selected {
  background-color: var(--primary-10) !important;
}
._item_r8e00_628.ant-menu-item-selected ._accessory_r8e00_635 {
  color: inherit;
}

._itemForWorklet_r8e00_738 {
  display: flex;
  align-items: center;
}
._itemForWorklet_r8e00_738 .ant-menu-title-content:hover {
  color: #167cf4;
}
._itemForWorklet_r8e00_738 .ant-menu-title-content:hover ._accessory_r8e00_635 {
  color: inherit;
}
._itemForWorklet_r8e00_738 .anticon + span {
  display: inline-flex;
  flex: 1;
  flex-direction: row;
  color: #505256;
  font-weight: 400;
  font-style: normal;
}
._itemForWorklet_r8e00_738 ._totalCount_r8e00_644 {
  display: inline-flex;
  flex: 1;
  flex-direction: row;
  font-size: 0.875rem;
  color: #505256;
  font-weight: 400;
  font-style: normal;
}
._itemForWorklet_r8e00_738 ._totalCount_r8e00_644:hover {
  color: #167cf4 !important;
}
._itemForWorklet_r8e00_738 ._innerItemLabel_r8e00_656 {
  overflow: hidden;
  font-weight: 400;
  font-size: 0.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._itemForWorklet_r8e00_738 ._innerItemLabel_r8e00_656:hover {
  color: #167cf4;
}
._itemForWorklet_r8e00_738 ._innerItemLabel_r8e00_656 + ._accessory_r8e00_635 {
  font-weight: 400;
  font-size: 0.875rem;
}
._itemForWorklet_r8e00_738 ._innerItemLabelWorklet_r8e00_670 {
  font-weight: 400;
  font-size: 0.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex: 1;
}
._itemForWorklet_r8e00_738 ._innerItemLabelWorklet_r8e00_670:hover {
  color: #167cf4;
}
._itemForWorklet_r8e00_738 ._innerItemLabelWorklet_r8e00_670 + ._accessory_r8e00_635 {
  font-weight: 400;
  font-size: 0.875rem;
}
._itemForWorklet_r8e00_738 ._innerItemLabelError_r8e00_685 {
  color: #d9534f;
}
._itemForWorklet_r8e00_738._transient_r8e00_688 {
  fill: var(--green);
}
._itemForWorklet_r8e00_738 ._accessory_r8e00_635 {
  margin-right: 0.1875rem;
  margin-left: auto;
  color: var(--grey-75);
}
._itemForWorklet_r8e00_738 ._prospectsItemLabel_r8e00_696 {
  display: inline-block;
  padding-left: 1.625rem;
}
._itemForWorklet_r8e00_738 ._workletItemLabel_r8e00_700 {
  display: inline-block;
}
._itemForWorklet_r8e00_738 ._workletItemLabel_r8e00_700:hover {
  color: #167cf4;
}
._itemForWorklet_r8e00_738 ._overviewLabel_r8e00_706 {
  padding-left: 0;
}
._itemForWorklet_r8e00_738 ._subMenuItemLabel_r8e00_709 {
  font-weight: 600 !important;
}
._itemForWorklet_r8e00_738 ._findCreatorsLabel_r8e00_712 {
  padding-left: 0;
}
._itemForWorklet_r8e00_738 ._mainItemMenu_r8e00_715 {
  padding-left: 0;
  font-weight: 600 !important;
  color: #1f1f21 !important;
  font-style: normal;
}
._itemForWorklet_r8e00_738 ._mainItemMenu_r8e00_715:hover {
  color: #167cf4 !important;
}
._itemForWorklet_r8e00_738 ._findCreatorsLabelWorkletMenu_r8e00_724 {
  padding-left: 0;
  font-weight: 600;
}
._itemForWorklet_r8e00_738 ._gcrLabel_r8e00_728 {
  padding-left: 0;
}
._itemForWorklet_r8e00_738.ant-menu-item-selected {
  background-color: var(--primary-10) !important;
}
._itemForWorklet_r8e00_738.ant-menu-item-selected ._accessory_r8e00_635 {
  color: inherit;
}

._divider_r8e00_850 {
  margin: 0;
}

._iconWrapper_r8e00_854 {
  position: relative;
}
._iconWrapper_r8e00_854 ._antIcon_r8e00_857 {
  margin-right: 0.0625rem;
  margin-left: 0.0625rem;
}

._overviewIcon_r8e00_862 {
  color: #006462;
  font-size: 1rem;
}

._findCreatorsIcon_r8e00_867 {
  color: #167cf4;
  font-size: 1rem;
}

._applicantsIcon_r8e00_872 {
  fill: #389e0d;
  font-size: 1rem;
}

._inProgressIcon_r8e00_877 {
  color: #167cf4;
}

._transientIcon_r8e00_881,
._completedIcon_r8e00_882 {
  color: #389e0d;
  fill: #389e0d;
  font-size: 1rem;
}

._nodeIcon_r8e00_888 {
  fill: transparent;
  stroke: var(--bay-grey);
}

._nodeSegmentIcon_r8e00_893 {
  fill: var(--bay-grey);
}

._errorIcon_r8e00_897 {
  fill: #d9534f;
}

._iconConnectorBefore_r8e00_901,
._iconConnectorAfter_r8e00_902 {
  position: relative;
}

._iconConnectorBefore_r8e00_901::before,
._iconConnectorAfter_r8e00_902::after {
  content: "";
  position: absolute;
  left: 0.4375rem;
  display: block;
  width: 0.125rem;
  height: 1.5rem;
  background-color: var(--bay-grey);
}

._iconConnectorBefore_r8e00_901::before {
  bottom: calc(100% - 0.0625rem);
}

._iconConnectorAfter_r8e00_902::after {
  top: calc(100% - 0.0625rem);
}

._notificationDot_r8e00_925 {
  position: absolute;
  top: 0.0625rem;
  right: -0.0625rem;
  display: block;
  box-sizing: border-box;
  width: 0.5rem;
  height: 0.5rem;
  border: 0.125rem solid #fdfdfd;
  border-radius: 50%;
  background-color: #d9534f;
}

._workletNotificationDot_r8e00_938 {
  position: absolute;
  top: 0.375rem;
  right: 0.625rem;
  display: block;
  box-sizing: border-box;
  width: 0.5rem;
  height: 0.5rem;
  border: 0.125rem solid #f4fcff;
  border-radius: 50%;
  background-color: #d9534f;
}

._FolderList_r8e00_951 {
  margin-top: 0.5rem;
  transition: opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1);
}
._FolderList_r8e00_951._loading_r8e00_955 {
  opacity: 0.4;
  cursor: wait;
}
._FolderList_r8e00_951._loading_r8e00_955 * {
  pointer-events: none;
}