/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._BrandedIntroModal_hjfsy_32 ._bkg_hjfsy_32 {
  display: block;
  width: 100%;
}
._BrandedIntroModal_hjfsy_32 ._welcome_hjfsy_36 ._logo_hjfsy_36 {
  position: relative;
  width: 70px;
  display: block;
  margin-top: -5.7%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  border: 4px solid #fdfdfd;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
._BrandedIntroModal_hjfsy_32 ._chat_hjfsy_47 {
  position: relative;
}
._BrandedIntroModal_hjfsy_32 ._chat_hjfsy_47 ._logo_hjfsy_36 {
  border-radius: 3px;
  position: absolute;
  left: 40%;
  top: 71.8%;
  width: 3.23%;
  transform: translateY(-50%);
}