/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ThreadMembers_5fqhe_32 ._group_5fqhe_32 {
  display: flex;
}
._ThreadMembers_5fqhe_32 ._group_5fqhe_32 ._item_5fqhe_35 ._singleItemName_5fqhe_35 {
  color: var(--ant-primary);
}
._ThreadMembers_5fqhe_32 ._group_5fqhe_32 ._item_5fqhe_35 ._memberNav_5fqhe_38 {
  color: #167cf4;
}
._ThreadMembers_5fqhe_32 ._group_5fqhe_32 ._item_5fqhe_35._moreContentItem_5fqhe_41 .ant-avatar {
  background-color: var(--gray-6);
}
._ThreadMembers_5fqhe_32 ._group_5fqhe_32 ._item_5fqhe_35:not(:first-child) {
  margin-right: -8px;
  transform: translateX(-8px);
}
._ThreadMembers_5fqhe_32 ._group_5fqhe_32 ._item_5fqhe_35 :hover {
  cursor: pointer;
}