/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_txy8s_157 {
  display: flex;
}

._justify-content-space-between_txy8s_161 {
  justify-content: space-between;
}

._tabular-nums_txy8s_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_txy8s_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_txy8s_157 {
  display: flex;
}

._justify-content-space-between_txy8s_161 {
  justify-content: space-between;
}

._tabular-nums_txy8s_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_txy8s_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._AddPage_txy8s_386 {
  display: flex;
  flex-direction: column;
  height: 100%;
}
._AddPage_txy8s_386._submitting_txy8s_391 {
  opacity: 0.4;
}
._AddPage_txy8s_386 ._header_txy8s_394 {
  display: flex;
  align-items: center;
  padding: 0 0.875rem;
  min-height: 4rem;
  background-color: white;
  border-bottom: 0.0625rem solid #dadcde;
}
._AddPage_txy8s_386 ._header_txy8s_394 ._backButton_txy8s_402 {
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
}
._AddPage_txy8s_386 ._header_txy8s_394 ._formTitle_txy8s_407 {
  margin: 0;
  font-weight: 600;
  font-size: 1.5rem;
}
._AddPage_txy8s_386 ._loadSpinner_txy8s_412 {
  margin-top: 20%;
}