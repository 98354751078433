/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_auw66_157 {
  display: flex;
}

._justify-content-space-between_auw66_161 {
  justify-content: space-between;
}

._tabular-nums_auw66_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_auw66_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._NewIntegrations_auw66_178 ._solution_auw66_178 ._name_auw66_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._NewIntegrations_auw66_178 ._solution_auw66_178 ._description_auw66_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

._NewIntegrations_auw66_178 {
  will-change: opacity;
  transition: opacity 0.1s ease-out;
}
._NewIntegrations_auw66_178._disabled_auw66_202 {
  pointer-events: none;
  opacity: 0.7;
}
._NewIntegrations_auw66_178 ._solution_auw66_178 {
  padding: 16px 8px;
  cursor: pointer;
  user-select: none;
  height: 174px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  will-change: background-color;
  transition: background-color 0.1s ease-out;
}
._NewIntegrations_auw66_178 ._solution_auw66_178:hover {
  background-color: #eff5f9;
}
._NewIntegrations_auw66_178 ._solution_auw66_178 ._imageWrapper_auw66_221 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
._NewIntegrations_auw66_178 ._solution_auw66_178 ._imageWrapper_auw66_221 ._image_auw66_221 {
  height: 40px;
  object-fit: contain;
  object-position: center;
}
._NewIntegrations_auw66_178 ._solution_auw66_178 ._name_auw66_178 {
  margin-bottom: 12px;
}
._NewIntegrations_auw66_178 ._solution_auw66_178 ._description_auw66_188 {
  color: #4f4f4f;
  text-align: center;
}