/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1uv1w_157 {
  display: flex;
}

._justify-content-space-between_1uv1w_161 {
  justify-content: space-between;
}

._tabular-nums_1uv1w_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1uv1w_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._FormSelect_1uv1w_178 {
  width: 100%;
}