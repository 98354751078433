/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_13zcq_157 {
  display: flex;
}

._justify-content-space-between_13zcq_161 {
  justify-content: space-between;
}

._tabular-nums_13zcq_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_13zcq_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

:root {
  --screen-xs: 480px;
  --screen-sm: 576px;
  --screen-md: 768px;
  --screen-lg: 992px;
  --screen-xl: 1200px;
  --screen-xxl: 1600px;
}

._TopNavbar_13zcq_227 {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 0.25rem;
}

._Left_13zcq_234 {
  display: flex;
  align-items: center;
}
._Left_13zcq_234 ._logo_13zcq_238 {
  height: 2rem;
  padding: 0 1rem;
}
._Left_13zcq_234 ._logo_13zcq_238 img {
  height: 100%;
}

._Right_13zcq_246 {
  display: flex;
  align-items: center;
}
._Right_13zcq_246 .ant-btn {
  margin-right: 0.5rem;
}
._Right_13zcq_246 .ant-icon {
  font-size: 1.5rem;
}
._Right_13zcq_246 ._name_13zcq_256 {
  font-size: 1rem;
  display: inline-block;
  padding-right: 1rem;
}
@media only screen and (max-width: 576px) {
  ._Right_13zcq_246 ._name_13zcq_256 {
    width: max-content;
  }
}
._Right_13zcq_246 ._userMenu_13zcq_266 {
  margin-right: 1.5rem;
  line-height: 3.25rem;
  text-align: center;
  cursor: pointer;
}
._Right_13zcq_246 ._userMenu_13zcq_266:hover {
  background-color: #f4fcff;
  color: #1890ff;
}
._Right_13zcq_246 ._userMenu_13zcq_266._selected_13zcq_276:not(:hover) {
  background-color: #e6f7ff;
  color: #002766;
}
._Right_13zcq_246 ._userMenu_13zcq_266 > span {
  font-size: 14px !important;
}
._Right_13zcq_246 .ant-dropdown-open {
  background-color: #f4fcff !important;
  color: #1890ff !important;
}

._TopNavMemberSearch_13zcq_288 > div:first-child {
  height: 3.25rem;
  border-radius: 0 !important;
}
._TopNavMemberSearch_13zcq_288 > div:first-child:hover {
  background-color: #f4fcff !important;
  color: #1890ff !important;
}