/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_ve3tj_157 {
  display: flex;
}

._justify-content-space-between_ve3tj_161 {
  justify-content: space-between;
}

._tabular-nums_ve3tj_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_ve3tj_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ContractsListPage_ve3tj_178 {
  padding-top: 1.5rem;
}
._ContractsListPage_ve3tj_178 ._mainTitle_ve3tj_181 {
  position: absolute;
  top: -1.125rem;
  padding-top: 2rem;
  color: #1a1818;
  font-weight: bold;
  font-style: normal;
  font-size: 1.25rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.5rem;
  letter-spacing: 0.009375rem;
}