/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_wqdu0_157 {
  display: flex;
}

._justify-content-space-between_wqdu0_161 {
  justify-content: space-between;
}

._tabular-nums_wqdu0_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_wqdu0_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._container_wqdu0_178 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
._container_wqdu0_178 ._steps_wqdu0_184 {
  flex: 0 auto;
  width: 100%;
  padding: 1.5rem 2rem;
}
._container_wqdu0_178 ._steps_wqdu0_184 .ant-steps-item-icon .ant-steps-icon {
  top: -0.0625rem;
}
._container_wqdu0_178 ._content_wqdu0_192 {
  flex: 1 auto;
  overflow: hidden;
  overflow-y: auto;
  padding: 2rem;
}
._container_wqdu0_178 ._footer_wqdu0_198 {
  display: flex;
  flex: 0 auto;
  align-items: center;
  justify-content: center;
  padding: 2.25rem 1.5rem;
  box-shadow: 0 -0.25rem 0.375rem var(--grey-25);
}
._container_wqdu0_178 ._footer_wqdu0_198 ._footerButton_wqdu0_206 {
  min-width: 13.125rem;
  margin: 0 0.75rem;
}
._container_wqdu0_178 ._footer_wqdu0_198 ._footerButton_wqdu0_206 .anticon {
  font-size: 0.75rem;
}
._container_wqdu0_178 ._footer_wqdu0_198 ._footerButton_wqdu0_206._footerButtonRight_wqdu0_213 {
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}
._container_wqdu0_178 ._footer_wqdu0_198 ._footerButton_wqdu0_206._footerButtonRight_wqdu0_213:hover:not(:disabled), ._container_wqdu0_178 ._footer_wqdu0_198 ._footerButton_wqdu0_206._footerButtonRight_wqdu0_213:focus:not(:disabled) {
  border-color: var(--ant-primary) !important;
  background-color: var(--ant-primary) !important;
}
._container_wqdu0_178 ._footer_wqdu0_198 ._footerButton_wqdu0_206._footerButtonRight_wqdu0_213 .anticon-right {
  margin-left: 0.75rem;
}

._tooltipList_wqdu0_227 {
  margin: 0;
  padding: 0 1.125rem;
}
._tooltipList_wqdu0_227 li {
  margin: 0.375rem 0;
  line-height: 1.125rem;
}