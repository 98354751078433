/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_xi774_157 {
  display: flex;
}

._justify-content-space-between_xi774_161 {
  justify-content: space-between;
}

._tabular-nums_xi774_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_xi774_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._SystemMessageList_xi774_209 {
  display: flex;
  flex-direction: column;
  height: 100%;
}
._SystemMessageList_xi774_209 ._threadHeader_xi774_214 {
  flex-shrink: 0;
}
._SystemMessageList_xi774_209 ._list_xi774_217 {
  flex: 1;
  overflow-y: auto;
  padding: 1rem 1.375rem;
}
._SystemMessageList_xi774_209 ._list_xi774_217 ._item_xi774_222 {
  margin-bottom: 1.25rem;
}
._SystemMessageList_xi774_209 ._applicationList_xi774_225 {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  height: 3.875rem;
  padding: 0 1.5rem;
  border-top: 0.0625rem solid #dadcde;
  background: #f7f8fa;
}
._SystemMessageList_xi774_209 ._followUp_xi774_235 {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  height: 3.875rem;
  padding: 0 1.5rem 2rem;
  margin-top: 1.0625rem;
}
._SystemMessageList_xi774_209 ._followUp_xi774_235 ._title_xi774_244 {
  /* identical to box height, or 121% */
  display: flex;
  align-items: center;
  padding-bottom: 1.125rem;
  color: #8f8d91;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: -0.025rem;
  text-align: center;
}
._SystemMessageList_xi774_209 ._followUp_xi774_235 ._replyOptions_xi774_256 {
  display: flex;
  flex-direction: row;
  padding-bottom: 2.25rem;
}
._SystemMessageList_xi774_209 ._followUp_xi774_235 ._replyOptions_xi774_256 ._otherApps_xi774_261 {
  margin-left: 1rem;
}