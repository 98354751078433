/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_fpp5e_157 {
  display: flex;
}

._justify-content-space-between_fpp5e_161 {
  justify-content: space-between;
}

._tabular-nums_fpp5e_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_fpp5e_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._OrderCreationWizard_fpp5e_178 {
  padding: 1.625rem 2.8125rem;
  overflow: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: opacity 0.1s ease-out;
  will-change: opacity;
}
._OrderCreationWizard_fpp5e_178._disabled_fpp5e_187 {
  opacity: 0.7;
  pointer-events: none;
}
._OrderCreationWizard_fpp5e_178 ._container_fpp5e_191 {
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
  overflow: hidden;
  height: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
}

._NoShopifyModal_fpp5e_201 ._button_fpp5e_201 {
  margin: 0.5rem;
}