:root {
  --primary: #167cf4;
  --primary-40: #3d73d7;
  --primary-10: #e6f7ff;
  --secondary: #f58984;
  --secondary-40: #fcbbae;
  --secondary-10: #fef0ed;
  --bay-grey: #989ea8;
  --grey-10: #fafafa;
  --grey-25: #e9e8ea;
  --grey-50: #aeaeae;
  --grey-75: #8f8f8f;
  --grey-100: #1a1b1c;
  --white: #fff;
  --black: #1a1b1c;
  --green: #006462;
  --sand: #f6f3ef;
  --yellow: #ffcd5b;
  --magenta-base: #ffddff;
  --red-base: #f5b3b4;
  --volcano-base: #ff9c93;
  --orange-base: #fabf95;
  --gold-base: #f1e5ac;
  --lime-base: #ebffd0;
  --sage-base: #c1e1c1;
  --cyan-base: #c9f2f2;
  --blue-base: #70d1f1;
  --geekblue-base: #a2aff1;
  --purple-base: #c5b3e3;
  --mauve-base: #c6b0ca;
  --coral-base: #ff8a8a;
  --apricot-base: #f5a287;
  --marigold-base: #fdaf1c;
  --butter-base: #f9eda1;
  --apple-base: #b7d275;
  --asparagus-base: #9db17c;
  --tiffany-base: #5fcdca;
  --baby-base: #a3ddf9;
  --carolina-base: #7eb3d8;
  --rose-base: #ffb4c7;
  --watermelon-base: #ffa1b6;
  --blue-0: #f4fcff;
  --blue-1: #e6f7ff;
  --blue-3: #91d5ff;
  --blue-5: #499dff;
  --blue-6: #167cf4;
  --blue-7: #2771c9;
  --gray-1: #fff;
  --gray-2: #fafafa;
  --gray-3: #f5f5f5;
  --gray-4: #f0f0f0;
  --gray-5: #d9d9d9;
  --gray-6: #bfbfbf;
  --gray-7: #8c8c8c;
  --gray-8: #505256;
  --gray-9: #1f1f21;
  --red-1: #fff1f0;
  --red-3: #ffa39e;
  --red-4: #ff7875;
  --red-5: #ff4d4f;
  --red-6: #f5222d;
  --gold-1: #fffbe6;
  --gold-3: #ffe58f;
  --gold-5: #ffc53d;
  --gold-6: #faad14;
  --gold-7: #d48806;
  --green-1: #f6ffed;
  --green-3: #b7eb8f;
  --green-5: #73d13d;
  --green-6: #52c41a;
  --green-7: #389e0d;
  --text-color: #505256;
  --text-color-secondary: #8c8c8c;
  --text-color-inverse: #fff;
  --icon-color: inherit;
  --icon-color-hover: rgba(26, 27, 28, 0.75);
  --heading-color: #1f1f21;
  --text-color-dark: rgba(255, 255, 255, 0.85);
  --text-color-secondary-dark: rgba(255, 255, 255, 0.65);
  --text-selection-bg: #167cf4;
  --link-color: #167cf4;
  --modal-mask-bg: rgba(79, 90, 114, 0.45);
}

:root {
  --screen-xs: 480px;
  --screen-sm: 576px;
  --screen-md: 768px;
  --screen-lg: 992px;
  --screen-xl: 1200px;
  --screen-xxl: 1600px;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_7pyjd_343 {
  display: flex;
}

._justify-content-space-between_7pyjd_347 {
  justify-content: space-between;
}

._tabular-nums_7pyjd_351 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_7pyjd_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MemberSection_7pyjd_364 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
._MemberSection_7pyjd_364 ._mainSection_7pyjd_371 {
  display: flex;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  flex: 1;
}
._MemberSection_7pyjd_364 ._mainSection_7pyjd_371 ._content_7pyjd_378 {
  flex: 1;
  max-height: 100%;
  background: var(--gray-1);
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
}
._MemberSection_7pyjd_364 ._mainSection_7pyjd_371 ._content_7pyjd_378 .ant-tabs-nav-wrap {
  padding: 10px 24px 0;
}
._MemberSection_7pyjd_364 ._mainSection_7pyjd_371 ._content_7pyjd_378 .ant-tabs-extra-content {
  padding-right: 24px;
}
._MemberSection_7pyjd_364 ._mainSection_7pyjd_371 ._content_7pyjd_378 .ant-tabs-nav {
  margin-bottom: 0;
}
._MemberSection_7pyjd_364 ._mainSection_7pyjd_371 ._content_7pyjd_378 .ant-tabs-content-holder,
._MemberSection_7pyjd_364 ._mainSection_7pyjd_371 ._content_7pyjd_378 .ant-tabs-content,
._MemberSection_7pyjd_364 ._mainSection_7pyjd_371 ._content_7pyjd_378 .ant-tabs {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  flex: 1;
}
._MemberSection_7pyjd_364 ._mainSection_7pyjd_371 ._content_7pyjd_378 .ant-tabs-content {
  flex: 1;
}
._MemberSection_7pyjd_364 ._mainSection_7pyjd_371 ._content_7pyjd_378 .ant-tabs-tabpane {
  overflow: hidden;
  overflow-y: auto;
  padding: 0;
  flex: 1;
  max-height: 100%;
}
._MemberSection_7pyjd_364 ._mainSection_7pyjd_371 ._content_7pyjd_378 ._inboxPlaceHolder_7pyjd_414 {
  max-height: 100%;
  height: fit-content;
  background: #fff;
  display: flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  overflow: auto;
}
._MemberSection_7pyjd_364 ._mainSection_7pyjd_371 ._content_7pyjd_378 ._inboxPlaceHolder_7pyjd_414 ._title_7pyjd_424 {
  padding: 28px 20px 8px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 12px;
}
._MemberSection_7pyjd_364 ._mainSection_7pyjd_371 ._content_7pyjd_378 ._inboxPlaceHolder_7pyjd_414 ._actions_7pyjd_431 {
  border-top: 1px solid #d9d9d9;
  padding: 16px 24px;
}
._MemberSection_7pyjd_364 ._mainSection_7pyjd_371 ._content_7pyjd_378 ._inboxPlaceHolder_7pyjd_414 ._activities_7pyjd_435 {
  flex: 1;
  overflow: auto;
}
._MemberSection_7pyjd_364 ._mainSection_7pyjd_371 ._content_7pyjd_378 ._tabsExtraContent_7pyjd_439 {
  display: flex;
  align-items: center;
  justify-content: center;
}
._MemberSection_7pyjd_364 ._mainSection_7pyjd_371 ._content_7pyjd_378 ._tabsExtraContent_7pyjd_439 ._openProfileButton_7pyjd_444 {
  margin-left: 0.5rem;
}
._MemberSection_7pyjd_364 ._mainSection_7pyjd_371 ._memberInfo_7pyjd_447 {
  width: 400px;
  flex-shrink: 0;
  height: 100%;
  overflow-y: scroll;
}
._MemberSection_7pyjd_364 ._mainSection_7pyjd_371 ._sideBarPlaceHolder_7pyjd_453 {
  width: 350px;
  height: 100%;
  overflow: auto;
  border-left: 1px solid #d9d9d9;
  flex-shrink: 0;
}