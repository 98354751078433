/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1ff0w_157 {
  display: flex;
}

._justify-content-space-between_1ff0w_161 {
  justify-content: space-between;
}

._tabular-nums_1ff0w_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1ff0w_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Welcome_1ff0w_209 {
  display: flex;
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100vw;
}
._Welcome_1ff0w_209:before {
  background: #F6F3EF;
  border-bottom-right-radius: 12.5rem;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 0;
  width: 65.1666666667vw;
}
._Welcome_1ff0w_209 ._content_1ff0w_228 {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  z-index: 1;
}
._Welcome_1ff0w_209 ._contentInner_1ff0w_235 {
  color: #1A1818;
  margin-left: -16%;
  max-width: 28.75rem;
  position: relative;
}
@media (max-width: 62.5rem) {
  ._Welcome_1ff0w_209 ._contentInner_1ff0w_235 {
    margin-left: 0;
    padding-right: 20%;
  }
}
._Welcome_1ff0w_209 ._contentInner_1ff0w_235 ._logo_1ff0w_247 {
  left: 0;
  position: absolute;
  width: 6.25rem;
  top: -4.25rem;
}
._Welcome_1ff0w_209 ._contentInner_1ff0w_235 h1 {
  font-size: 1.875rem;
  font-weight: 300;
  line-height: 2.375rem;
}
._Welcome_1ff0w_209 ._contentInner_1ff0w_235 h1 strong {
  display: block;
  font-weight: 600;
}
._Welcome_1ff0w_209 ._contentInner_1ff0w_235 p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}
._Welcome_1ff0w_209 ._contentInner_1ff0w_235 ._ctaButton_1ff0w_266 {
  background: #117a48;
  border: none;
  width: 26.875rem;
}
._Welcome_1ff0w_209 ._contentInner_1ff0w_235 ._ctaButton_1ff0w_266:hover {
  background: #13450d;
}
._Welcome_1ff0w_209 ._contentInner_1ff0w_235 ._ctaButton_1ff0w_266:focus-visible {
  border: 0.125rem solid #13450d;
}
._Welcome_1ff0w_209 ._visual_1ff0w_277 {
  min-width: 22.1875rem;
  position: relative;
  user-select: none;
  width: 35.5555555556vw;
  z-index: 1;
}
._Welcome_1ff0w_209 ._visualInner_1ff0w_284 {
  left: -9rem;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 34.0625rem;
}
._Welcome_1ff0w_209 ._visualPic1_1ff0w_292, ._Welcome_1ff0w_209 ._visualPic2_1ff0w_292, ._Welcome_1ff0w_209 ._visualPic3_1ff0w_292 {
  position: absolute;
}
._Welcome_1ff0w_209 ._visualPic1_1ff0w_292 {
  bottom: 17%;
  left: 6%;
  width: 36.6972477064%;
}
._Welcome_1ff0w_209 ._visualPic2_1ff0w_292 {
  right: 16%;
  top: 13.5%;
  width: 36.6972477064%;
}
._Welcome_1ff0w_209 ._visualPic3_1ff0w_292 {
  left: 13%;
  top: 18%;
  width: 68.6930091185%;
}
._Welcome_1ff0w_209 ._illustration_1ff0w_310 {
  width: 100%;
}
._Welcome_1ff0w_209._success_1ff0w_313:before {
  border-bottom-right-radius: 25rem;
  bottom: 4rem;
  max-width: 100%;
  width: 100%;
}
@media (max-width: 62.5rem) {
  ._Welcome_1ff0w_209._success_1ff0w_313 ._contentInner_1ff0w_235 {
    margin-left: 0;
    padding-right: 20%;
  }
}
._Welcome_1ff0w_209._success_1ff0w_313 ._visualInner_1ff0w_284 {
  max-width: 41.125rem;
  min-width: 28.5rem;
  overflow: visible;
  right: 6.25rem;
  top: 52%;
  width: 38.94444vw;
}