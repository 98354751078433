/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1wt6o_157 {
  display: flex;
}

._justify-content-space-between_1wt6o_161 {
  justify-content: space-between;
}

._tabular-nums_1wt6o_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1wt6o_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._composeMessage_1wt6o_178 {
  position: relative;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}
._composeMessage_1wt6o_178 ._scrollable_1wt6o_185 {
  padding: 1.375rem 1.25rem;
  padding-bottom: 5.9375rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #EFF5F9;
}
._composeMessage_1wt6o_178 ._messageForm_1wt6o_193 {
  overflow: auto;
}
._composeMessage_1wt6o_178 ._messageForm_1wt6o_193._hide_1wt6o_196 {
  height: 0;
}
._composeMessage_1wt6o_178 ._previewContainer_1wt6o_199 {
  padding: 1.375rem 1.25rem;
  overflow: auto;
  background-color: #fdfdfd;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 1px 1px;
}