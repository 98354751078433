/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1w2td_157 {
  display: flex;
}

._justify-content-space-between_1w2td_161 {
  justify-content: space-between;
}

._tabular-nums_1w2td_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1w2td_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._CreatorOffers_1w2td_178 div {
  width: 100%;
}
._CreatorOffers_1w2td_178 ._creatorOfferItem_1w2td_181 {
  margin-bottom: 0.75rem;
}