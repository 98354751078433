/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1kjkq_157 {
  display: flex;
}

._justify-content-space-between_1kjkq_161 {
  justify-content: space-between;
}

._tabular-nums_1kjkq_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1kjkq_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._errorNotice_1kjkq_178 ._actions_1kjkq_178 ._actionButton_1kjkq_178, ._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._errorNotice_1kjkq_178 ._content_1kjkq_178 ._errorText_1kjkq_178, ._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._errorNotice_1kjkq_178 ._content_1kjkq_178 ._errorTitle_1kjkq_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ThreadFolderSection_1kjkq_178 {
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 100%;
  background-color: var(--gray-2);
  border-right: 1px solid var(--gray-5);
  overflow: hidden;
}
._ThreadFolderSection_1kjkq_178 ._dropdownWrapper_1kjkq_228 {
  padding: 6.5px 8px;
  border-top: 1px solid var(--gray-5);
  border-bottom: 1px solid var(--gray-5);
}
._ThreadFolderSection_1kjkq_178 ._actionsWrapper_1kjkq_233 {
  padding: 1rem 1rem 0.75rem;
  width: 100%;
  height: 6.25rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
._ThreadFolderSection_1kjkq_178 ._actionsWrapper_1kjkq_233 ._toggleWrapper_1kjkq_241 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
._ThreadFolderSection_1kjkq_178 ._actionsWrapper_1kjkq_233 ._toggleWrapper_1kjkq_241 ._toggleWrapperTitle_1kjkq_246 {
  font-weight: 600;
  font-size: 18px;
  color: var(--gray-9);
}
._ThreadFolderSection_1kjkq_178 ._actionsWrapper_1kjkq_233 ._composerWrapper_1kjkq_251 ._messageComposer_1kjkq_251 {
  flex: 1;
}
._ThreadFolderSection_1kjkq_178 ._actionsWrapper_1kjkq_233 ._composerWrapper_1kjkq_251 ._messageComposer_1kjkq_251 .ant-btn {
  width: 100%;
  height: 32px;
}
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  padding: 0;
}
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._filterItem_1kjkq_264 {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  line-height: 1;
  transition: all 0.25s ease;
}
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._filterItem_1kjkq_264 ._iconWrapper_1kjkq_273 {
  width: 18px;
  font-size: 18px;
}
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._filterItem_1kjkq_264 ._iconWrapper_1kjkq_273 ._avatar_1kjkq_277 {
  background-color: #113C0B;
}
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._filterItem_1kjkq_264 ._label_1kjkq_280 {
  font-size: 14px;
  font-weight: 600;
  color: var(--gray-9);
  flex: 1;
}
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._filterItem_1kjkq_264 ._count_1kjkq_286 {
  font-size: 14px;
  font-weight: 600;
  color: var(--gray-9);
}
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._filterItem_1kjkq_264:hover ._label_1kjkq_280,
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._filterItem_1kjkq_264:hover ._count_1kjkq_286,
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._filterItem_1kjkq_264:hover ._iconWrapper_1kjkq_273, ._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._filterItem_1kjkq_264:focus ._label_1kjkq_280,
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._filterItem_1kjkq_264:focus ._count_1kjkq_286,
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._filterItem_1kjkq_264:focus ._iconWrapper_1kjkq_273 {
  color: var(--blue-6);
}
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._filterItem_1kjkq_264._active_1kjkq_298, ._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._filterItem_1kjkq_264:focus {
  background-color: var(--blue-1);
}
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._filterItem_1kjkq_264._secondary_1kjkq_301 {
  padding-left: 32px;
}
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._filterItem_1kjkq_264._secondary_1kjkq_301 ._label_1kjkq_280, ._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._filterItem_1kjkq_264._secondary_1kjkq_301 ._count_1kjkq_286 {
  font-weight: 400;
}
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._errorNotice_1kjkq_178 {
  overflow: hidden;
  margin: 1.25rem 1rem 0.25rem;
  border-radius: 0.5rem;
  background: #f1515f;
}
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._errorNotice_1kjkq_178 ._content_1kjkq_178 {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
}
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._errorNotice_1kjkq_178 ._content_1kjkq_178 ._errorTitle_1kjkq_178 {
  margin-bottom: 0.5rem;
  color: #fdfdfd;
  font-weight: bold;
}
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._errorNotice_1kjkq_178 ._content_1kjkq_178 ._errorText_1kjkq_178 {
  margin-bottom: 0.5rem;
  color: #fdfdfd;
}
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._errorNotice_1kjkq_178 ._actions_1kjkq_178 {
  display: flex;
  flex-direction: row;
  height: 2.25rem;
}
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._errorNotice_1kjkq_178 ._actions_1kjkq_178 ._actionButton_1kjkq_178 {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #f96672;
  color: #fdfdfd;
  font-weight: bold;
  line-height: 1;
  cursor: pointer;
  user-select: none;
  transition: color 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: color;
}
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._errorNotice_1kjkq_178 ._actions_1kjkq_178 ._actionButton_1kjkq_178:hover {
  color: #7fd4f4;
}
._ThreadFolderSection_1kjkq_178 ._threadFilter_1kjkq_178 ._errorNotice_1kjkq_178 ._actions_1kjkq_178 ._reconnect_1kjkq_350 {
  margin-right: 0.0625rem;
}
._ThreadFolderSection_1kjkq_178:not(._expanded_1kjkq_353) {
  width: 56px;
}
._ThreadFolderSection_1kjkq_178:not(._expanded_1kjkq_353) ._actionsWrapper_1kjkq_233 {
  padding: 16px 8px;
  gap: 16px;
  align-items: center;
}
._ThreadFolderSection_1kjkq_178:not(._expanded_1kjkq_353) ._messageComposer_1kjkq_251 .ant-btn {
  min-width: 32px !important;
  width: 32px !important;
}
._ThreadFolderSection_1kjkq_178:not(._expanded_1kjkq_353) ._errorNotice_1kjkq_178,
._ThreadFolderSection_1kjkq_178:not(._expanded_1kjkq_353) ._dropdownWrapper_1kjkq_228,
._ThreadFolderSection_1kjkq_178:not(._expanded_1kjkq_353) ._toggleWrapperTitle_1kjkq_246 {
  display: none;
}
._ThreadFolderSection_1kjkq_178:not(._expanded_1kjkq_353) ._filterItem_1kjkq_264 {
  justify-content: center;
}
._ThreadFolderSection_1kjkq_178:not(._expanded_1kjkq_353) ._filterItem_1kjkq_264 ._label_1kjkq_280, ._ThreadFolderSection_1kjkq_178:not(._expanded_1kjkq_353) ._filterItem_1kjkq_264 ._count_1kjkq_286, ._ThreadFolderSection_1kjkq_178:not(._expanded_1kjkq_353) ._filterItem_1kjkq_264 ._folderToggleIcon_1kjkq_373 {
  display: none;
}
._ThreadFolderSection_1kjkq_178:not(._expanded_1kjkq_353) ._filterItem_1kjkq_264._secondary_1kjkq_301 {
  display: none;
}