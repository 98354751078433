/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_136yh_157 {
  display: flex;
}

._justify-content-space-between_136yh_161 {
  justify-content: space-between;
}

._tabular-nums_136yh_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_136yh_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._addPostLink_136yh_209 {
  padding-top: 1rem;
  flex-grow: inherit;
}
._addPostLink_136yh_209 ._header_136yh_213 {
  padding-bottom: 2.625rem;
  text-align: center;
}
._addPostLink_136yh_209 ._header_136yh_213 h1 {
  color: #1a1818;
  font-weight: bold;
  font-style: normal;
  font-size: 1.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.8125rem;
}
._addPostLink_136yh_209 ._header_136yh_213 p {
  margin-bottom: 0;
  color: #1a1818;
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.375rem;
}
._addPostLink_136yh_209 ._form_wrap_136yh_234 {
  max-width: 31.25rem;
  margin: 0 auto;
}
._addPostLink_136yh_209 label {
  display: block;
  padding-bottom: 0.75rem;
  color: #1a1818;
  font-weight: bold;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.00625rem;
}
._addPostLink_136yh_209 ._disclaimer_136yh_248 {
  margin-bottom: 1rem;
}
._addPostLink_136yh_209 ._input_standard_136yh_251 {
  display: block;
  width: 100%;
  margin-right: 0.75rem;
  padding: 0.625rem 0.75rem;
  border: 0;
  border-radius: 6.25rem;
  background-color: #eff5f9;
  color: #1a1818;
  outline: none;
  font-weight: normal;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.00625rem;
}
._addPostLink_136yh_209 ._input_standard_136yh_251:hover, ._addPostLink_136yh_209 ._input_standard_136yh_251:focus {
  background-color: #e6edf2;
}
._addPostLink_136yh_209 ._input_standard_136yh_251:focus {
  box-shadow: 0 0 0 0.125rem #3996e0;
}
._addPostLink_136yh_209 ._next_button_136yh_273 {
  align-items: center;
  margin-bottom: 2rem;
  color: #fdfdfd;
  font-weight: 600;
  font-style: normal;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.125rem;
  letter-spacing: 0.021875rem;
}