/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_779th_157 {
  display: flex;
}

._justify-content-space-between_779th_161 {
  justify-content: space-between;
}

._tabular-nums_779th_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_779th_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Steps_779th_178 ._container_779th_178 {
  display: flex;
  flex-direction: column;
  width: 62%;
  padding-left: 2rem;
}
@media (max-width: 75rem) {
  ._Steps_779th_178 ._container_779th_178 {
    width: 90%;
    margin: 0 auto;
    padding: 0 0.625rem;
  }
}