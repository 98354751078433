._AddContentGuidelines_1oyqv_1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
._AddContentGuidelines_1oyqv_1 > * + * {
  margin-block: 15px;
}
._AddContentGuidelines_1oyqv_1 button {
  margin: auto;
}