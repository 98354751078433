/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1f0wk_157 {
  display: flex;
}

._justify-content-space-between_1f0wk_161 {
  justify-content: space-between;
}

._tabular-nums_1f0wk_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1f0wk_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._tagContainer_1f0wk_209 {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 0.0625rem #dadcde;
}
._tagContainer_1f0wk_209 ._tag_1f0wk_209 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2.1875rem;
  margin: 0.375rem;
  border: 0;
  border-radius: 0.5rem;
  background-color: #ecf2f8;
  color: #1a1818;
}
._tagContainer_1f0wk_209 ._tag_1f0wk_209 .ant-tag-close-icon {
  height: 1rem;
  margin-left: 0.5rem;
}
._tagContainer_1f0wk_209 ._tag_1f0wk_209 ._closeIcon_1f0wk_230 {
  color: #bdbdbd;
}
._tagContainer_1f0wk_209 ._anticon_1f0wk_233 {
  color: #1a1818;
}
._tagContainer_1f0wk_209 ._tagArea_1f0wk_236 {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  padding: 0.3125rem;
  border-radius: 0.5rem;
  color: #4f4f4f;
}
._tagContainer_1f0wk_209 ._icon_1f0wk_246 {
  width: 1rem;
  margin-right: 0.375rem;
}
._tagContainer_1f0wk_209 ._appName_1f0wk_250 {
  color: #4f4f4f;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-left: 0.5rem;
}
._tagContainer_1f0wk_209 ._rightButton_1f0wk_257 {
  position: absolute;
  right: 0.375rem;
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
  border: solid 0.0625rem #ececec;
  background-color: #fdfdfd;
  box-shadow: 0 0.125rem 0.25rem #dadcde;
}
._tagContainer_1f0wk_209 ._leftButton_1f0wk_267 {
  position: absolute;
  left: 0.375rem;
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
  border: solid 0.0625rem #ececec;
  background-color: #fdfdfd;
  box-shadow: 0 0.125rem 0.25rem #dadcde;
}