/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1mp6o_157 {
  display: flex;
}

._justify-content-space-between_1mp6o_161 {
  justify-content: space-between;
}

._tabular-nums_1mp6o_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1mp6o_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SocialAppSettings_1mp6o_178 ._OauthNotice_1mp6o_178 {
  padding: 3.125rem;
}