/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1dpur_157 {
  display: flex;
}

._justify-content-space-between_1dpur_161 {
  justify-content: space-between;
}

._tabular-nums_1dpur_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1dpur_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._AutomationState_1dpur_178 {
  position: absolute;
  z-index: 100;
  right: 0.625rem;
  padding: 1rem;
  top: 0.625rem;
  border-radius: 0.5rem;
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  width: 21.5625rem;
  row-gap: 1.5rem;
  display: flex;
  flex-direction: column;
}
._AutomationState_1dpur_178 ._stateHeaderContainer_1dpur_192 {
  display: flex;
  justify-content: space-between;
}
._AutomationState_1dpur_178 ._stateHeaderContainer_1dpur_192 ._stateHeader_1dpur_192 {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
}
._AutomationState_1dpur_178 ._stateHeaderContainer_1dpur_192 ._stateHeader_1dpur_192 ._title_1dpur_201 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
._AutomationState_1dpur_178 ._statCards_1dpur_205 {
  display: flex;
  justify-content: space-around;
}