/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1i3h1_157 {
  display: flex;
}

._justify-content-space-between_1i3h1_161 {
  justify-content: space-between;
}

._tabular-nums_1i3h1_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1i3h1_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._appDashboardHeader_1i3h1_209 {
  align-items: center;
  padding: 0 var(--spacing-lg);
  height: 4.0625rem;
  border-bottom: 1px solid var(--gray-5);
}

._appLogoLink_1i3h1_216 {
  display: flex;
  align-items: center;
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
._appLogoLink_1i3h1_216:hover {
  opacity: 0.8;
}
._appLogoLink_1i3h1_216 ._appIcon_1i3h1_225 {
  display: inline-block;
  width: 2rem;
  margin-right: 0.75rem;
}

._navList_1i3h1_231 {
  display: flex;
  margin: 0;
  padding: 0 0 0 var(--spacing-lg);
}
._navList_1i3h1_231 li {
  list-style-type: none;
}
._navList_1i3h1_231 a {
  display: inline-block;
  padding: 1.1875rem;
  border-bottom: 0.125rem solid #fdfdfd;
  color: #8f8d91;
  font-weight: bold;
  font-style: normal;
  font-size: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.5rem;
}
._navList_1i3h1_231 a:hover {
  border-bottom: 0.125rem solid #e5ecf1;
  background-color: #eff5f9;
  color: #3996e0;
}
._navList_1i3h1_231 ._active_1i3h1_255 {
  border-bottom: 0.125rem solid #3996e0;
  color: #3996e0;
}
._navList_1i3h1_231 ._active_1i3h1_255:hover {
  border-bottom: 0.125rem solid #3996e0;
}

._sidebarComponents_1i3h1_263 {
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
}