/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_snoxq_157 {
  display: flex;
}

._justify-content-space-between_snoxq_161 {
  justify-content: space-between;
}

._tabular-nums_snoxq_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_snoxq_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ProgramList_snoxq_178 ._list_snoxq_178 ._item_snoxq_178._selected_snoxq_178, ._ProgramList_snoxq_178 ._header_snoxq_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._ProgramList_snoxq_178 ._list_snoxq_178 ._item_snoxq_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._ProgramList_snoxq_178 ._header_snoxq_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -0.625rem;
  padding-bottom: 1.25rem;
  padding-left: 1rem;
}
._ProgramList_snoxq_178 ._header_snoxq_178 ._add_snoxq_206 {
  margin-left: auto;
}
._ProgramList_snoxq_178 ._emptyMsg_snoxq_209 {
  padding: 0.75rem 0.75rem 0.75rem 0;
  border-radius: 0.5rem;
  background-color: #e9e8ea;
  color: #8f8d91;
  text-align: center;
}
._ProgramList_snoxq_178 ._list_snoxq_178 ._item_snoxq_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.3125rem;
  padding: 1rem;
  border-radius: 0.5rem;
  color: #8f8d91;
  cursor: pointer;
}
._ProgramList_snoxq_178 ._list_snoxq_178 ._item_snoxq_178 ._title_snoxq_226 {
  flex: 1;
}
._ProgramList_snoxq_178 ._list_snoxq_178 ._item_snoxq_178 ._icon_snoxq_229 {
  margin-left: auto;
  transform: rotateZ(180deg);
}
._ProgramList_snoxq_178 ._list_snoxq_178 ._item_snoxq_178._selected_snoxq_178 {
  background-color: #e6edf2;
  color: #3996e0;
}