/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1fc43_157 {
  display: flex;
}

._justify-content-space-between_1fc43_161 {
  justify-content: space-between;
}

._tabular-nums_1fc43_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1fc43_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._TermsAppMessage_1fc43_178 ._title_1fc43_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._TermsAppMessage_1fc43_178 ._header_1fc43_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._TermsAppMessage_1fc43_178 ._subtitle_1fc43_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._TermsAppMessage_1fc43_178 ._message_1fc43_208 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._TermsAppMessage_1fc43_178 {
  overflow: auto;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
._TermsAppMessage_1fc43_178 ._title_1fc43_178 {
  text-align: center;
  margin-top: 1.5rem;
}
._TermsAppMessage_1fc43_178 ._subtitle_1fc43_198 {
  color: #8f8d91;
  font-weight: 400;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
}
._TermsAppMessage_1fc43_178 ._header_1fc43_188 {
  margin: 1rem auto;
  text-align: center;
}
._TermsAppMessage_1fc43_178 ._message_1fc43_208 {
  margin: 0 auto 1.5rem;
  text-align: center;
}