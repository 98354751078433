._MessageComposer_1g8cf_1 {
  display: flex;
  flex-direction: column;
}
._MessageComposer_1g8cf_1 > * + * {
  margin-block: 25px;
}
._MessageComposer_1g8cf_1 ._EmailComposer_1g8cf_8 {
  min-height: 500px;
  border: 1px solid #DADCDE;
}
._MessageComposer_1g8cf_1 ._EmailComposer_1g8cf_8._hide_1g8cf_12 {
  display: none;
}