._Wizard_1fb9e_1 {
  display: flex;
  flex-direction: column;
  height: 100%;
}
._Wizard_1fb9e_1 ._mainContentWrapper_1fb9e_6 {
  flex: 1 1 auto;
  min-height: 1px;
  overflow: hidden scroll;
}
._Wizard_1fb9e_1 ._footer_1fb9e_11 {
  flex: 0 0 128px;
  height: 128px;
  border-top: 1px solid #d9d9d9;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
._Wizard_1fb9e_1 ._footer_1fb9e_11 ._footerButton_1fb9e_21 {
  width: 270px;
}
._Wizard_1fb9e_1 ._footer_1fb9e_11 .ant-space {
  width: 100%;
}
._Wizard_1fb9e_1 ._footer_1fb9e_11 .ant-space-item {
  width: 100%;
}
._Wizard_1fb9e_1 ._footer_1fb9e_11 .ant-space-item:last-child {
  width: auto;
}