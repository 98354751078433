/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1xadj_157 {
  display: flex;
}

._justify-content-space-between_1xadj_161 {
  justify-content: space-between;
}

._tabular-nums_1xadj_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1xadj_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._FieldListItem_1xadj_178 ._type_1xadj_178 ._label_1xadj_178, ._FieldListItem_1xadj_178 ._name_1xadj_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._FieldListItem_1xadj_178 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
}
._FieldListItem_1xadj_178:not(._existing_1xadj_197):hover ._btn_1xadj_197 {
  display: inline-flex;
}
._FieldListItem_1xadj_178:not(._existing_1xadj_197) ._btn_1xadj_197 {
  display: none;
}
._FieldListItem_1xadj_178._existing_1xadj_197 ._btn_1xadj_197 {
  display: inline-flex;
}
._FieldListItem_1xadj_178 ._fieldForm_1xadj_206 {
  width: 100%;
}
._FieldListItem_1xadj_178 ._btn_1xadj_197 {
  min-width: 5rem;
}
._FieldListItem_1xadj_178 ._text_1xadj_212 {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}
._FieldListItem_1xadj_178 ._name_1xadj_178 {
  overflow: hidden;
  width: 60%;
  padding-right: 1.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._FieldListItem_1xadj_178 ._type_1xadj_178 {
  flex: 1;
  margin-left: auto;
  padding-right: 12.5rem;
  white-space: nowrap;
}
._FieldListItem_1xadj_178 ._actions_1xadj_231 {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
}
._FieldListItem_1xadj_178 ._actions_1xadj_231 > *:not(:last-child) {
  margin-right: 0.625rem;
}