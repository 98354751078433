/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_429e0_157 {
  display: flex;
}

._justify-content-space-between_429e0_161 {
  justify-content: space-between;
}

._tabular-nums_429e0_165 {
  font-variant-numeric: tabular-nums;
}

._Histogram_429e0_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_429e0_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Histogram_429e0_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Histogram_429e0_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Histogram_429e0_169 ::-webkit-scrollbar-track, ._Histogram_429e0_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Histogram_429e0_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._Histogram_429e0_169 ._title_429e0_204 {
  position: relative;
  top: 25px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  color: #1a2430;
}
._Histogram_429e0_169 ._rectGroup_429e0_212 rect {
  fill: #67a3ee;
  cursor: pointer;
  will-change: fill, y, height;
  transition: fill 0.2s ease, y 0.15s cubic-bezier(0, 1, 0.5, 1) 0s, height 0.15s cubic-bezier(0, 1, 0.5, 1) 0s;
}
._Histogram_429e0_169 ._rectGroup_429e0_212 rect:hover {
  fill: #97bff0;
}
._Histogram_429e0_169 ._axis_429e0_221 path, ._Histogram_429e0_169 ._axis_429e0_221 line {
  stroke: none;
}
._Histogram_429e0_169 ._axis_429e0_221 text {
  font-size: 14px;
  fill: #92a5b1;
}

._tooltip_429e0_229 ._amount_429e0_229 {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #67a3ee;
}
._tooltip_429e0_229 ._date_429e0_235 {
  text-align: center;
  font-size: 12px;
  color: #c9ccd2;
}