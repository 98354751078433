/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1u851_157 {
  display: flex;
}

._justify-content-space-between_1u851_161 {
  justify-content: space-between;
}

._tabular-nums_1u851_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1u851_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ContentDeliverableTypes_1u851_209 {
  display: flex;
  flex-direction: column;
  padding: 12px;
}
._ContentDeliverableTypes_1u851_209 > * + * {
  margin-block-start: 10px;
}
._ContentDeliverableTypes_1u851_209 .ant-space-vertical {
  border: 2px solid #ececec;
  padding: 12px;
  border-radius: 4px;
  width: 100%;
}