/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1moak_157 {
  display: flex;
}

._justify-content-space-between_1moak_161 {
  justify-content: space-between;
}

._tabular-nums_1moak_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1moak_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._About_1moak_178 {
  padding-bottom: 1.5rem;
}
._About_1moak_178 ._row_1moak_181 {
  margin-top: 1rem;
  padding-bottom: 16px;
}
._About_1moak_178 ._row_1moak_181 ._subrow_1moak_185:not(:first-child) {
  margin-top: 24px;
}
._About_1moak_178 ._row_1moak_181:not(:last-child) {
  border-bottom: 1px solid var(--gray-5);
}
._About_1moak_178 ._subtitle_1moak_191 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
._About_1moak_178 ._subtitle_1moak_191 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  font-size: 0.875rem;
}