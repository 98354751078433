/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_gj4w7_157 {
  display: flex;
}

._justify-content-space-between_gj4w7_161 {
  justify-content: space-between;
}

._tabular-nums_gj4w7_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_gj4w7_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._confirmationPage_gj4w7_178 {
  flex: 1;
  overflow: hidden;
}
._confirmationPage_gj4w7_178._embedded_gj4w7_182 ._container_gj4w7_182 {
  padding: 0rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}
._confirmationPage_gj4w7_178._embedded_gj4w7_182 ._container_gj4w7_182 ._scrollable_gj4w7_188 {
  padding: 0rem;
  flex: 1;
  overflow: auto;
}
._confirmationPage_gj4w7_178._embedded_gj4w7_182 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 {
  padding: 0rem;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1.375rem 0.6875rem;
  background: #eff5f9;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 {
  padding: 0rem 0.6875rem;
  padding-bottom: 4.6875rem;
  flex: 1;
  overflow: auto;
  background: #eff5f9;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 {
  min-height: 100%;
  background: #fdfdfd;
  padding: 1.5rem 1.5rem 4.625rem;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._title_gj4w7_216 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.025rem;
  margin-bottom: 2rem;
  color: #1a1818;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._subTitle_gj4w7_223 {
  margin-bottom: 1rem;
  font-weight: 800;
  font-size: 0.6875rem;
  line-height: 1rem;
  letter-spacing: 0.078125rem;
  color: #8f8d91;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._section_gj4w7_231 ._checkbox_gj4w7_231 {
  margin-top: 1rem;
  display: flex;
  align-items: flex-start;
  margin-bottom: 3rem;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._section_gj4w7_231 ._checkboxLabel_gj4w7_237 {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: -0.02rem;
  color: #1a1818;
  margin-bottom: 0.25rem;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._section_gj4w7_231 ._checkboxDetails_gj4w7_245 {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #4f4f4f;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._section_gj4w7_231 ._boldText_gj4w7_251 {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: -0.02rem;
  color: #1a1818;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._section_gj4w7_231 ._textarea_gj4w7_258 {
  margin: 0.875rem 0 2rem 0;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._section_gj4w7_231 ._memberRow_gj4w7_261 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._section_gj4w7_231 ._memberRow_gj4w7_261 ._iconSquare_gj4w7_268 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  box-sizing: border-box;
  width: 3rem;
  height: 3rem;
  border: 0.0625rem solid #e9e8ea;
  border-radius: 0.5rem;
  color: #bdbdbd;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._productSection_gj4w7_280 {
  flex: 1;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._productSection_gj4w7_280 ._orders_gj4w7_283 ._order_gj4w7_283 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._productSection_gj4w7_280 ._orders_gj4w7_283 ._order_gj4w7_283 ._image_gj4w7_289 {
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 0.625rem;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._productSection_gj4w7_280 ._orders_gj4w7_283 ._order_gj4w7_283 ._details_gj4w7_294 {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 1rem;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._productSection_gj4w7_280 ._orders_gj4w7_283 ._order_gj4w7_283 ._details_gj4w7_294 ._productTitle_gj4w7_300 {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: -0.02rem;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._productSection_gj4w7_280 ._orders_gj4w7_283 ._order_gj4w7_283 ._details_gj4w7_294 ._sku_gj4w7_306 {
  display: flex;
  flex-direction: row;
  color: #8f8d91;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.025rem;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._productSection_gj4w7_280 ._orders_gj4w7_283 ._order_gj4w7_283 ._price_gj4w7_315 {
  display: flex;
  flex-direction: row;
  padding-left: 6.25rem;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.025rem;
  color: #1a1818;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._productSection_gj4w7_280 ._orders_gj4w7_283 ._order_gj4w7_283 ._price_gj4w7_315 ._priceDetails_gj4w7_327 {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin: 0 0.625rem;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._productSection_gj4w7_280 ._orders_gj4w7_283 ._order_gj4w7_283 ._price_gj4w7_315 ._priceDetails_gj4w7_327._quantitySelect_gj4w7_335 {
  width: 2.8125rem;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._productSection_gj4w7_280 ._orders_gj4w7_283 ._order_gj4w7_283 ._price_gj4w7_315 ._priceDetails_gj4w7_327 ._customizeBtn_gj4w7_338 {
  padding: 0;
  height: initial;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._productSection_gj4w7_280 ._orders_gj4w7_283 ._order_gj4w7_283 ._price_gj4w7_315 ._total_gj4w7_342 {
  flex: 1;
  width: 168px;
  text-align: right;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: -0.02rem;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._productSection_gj4w7_280 ._orders_gj4w7_283 ._order_gj4w7_283 ._price_gj4w7_315 ._removeButton_gj4w7_351 {
  width: 2.5rem;
  min-width: unset;
  border: 0;
  background: transparent;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._totals_gj4w7_357 ._totalSection_gj4w7_357 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._totals_gj4w7_357 ._totalSection_gj4w7_357 ._placeholder_gj4w7_363 {
  flex: 2;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._totals_gj4w7_357 ._totalSection_gj4w7_357 ._totalDetails_gj4w7_366 {
  flex: 1;
  text-align: right;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}
._confirmationPage_gj4w7_178 ._container_gj4w7_182 ._scrollable_gj4w7_188 ._products_gj4w7_193 ._totals_gj4w7_357 ._totalSection_gj4w7_357 ._total_gj4w7_342 {
  flex: 0.5;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: -0.02rem;
  text-align: right;
}

._PopoverOverlay_gj4w7_382 {
  width: 21.875rem;
}

._popoverContent_gj4w7_386 {
  padding: 0.75rem;
}
._popoverContent_gj4w7_386 ._popoverTitle_gj4w7_389 {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem;
  text-align: left;
}
._popoverContent_gj4w7_386 ._popoverDetails_gj4w7_397 {
  margin-bottom: 0.875rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  text-align: left;
  color: #8f8d91;
}
._popoverContent_gj4w7_386 ._popoverRow_gj4w7_406 {
  display: flex;
}
._popoverContent_gj4w7_386 ._popoverRow_gj4w7_406 ._inputWrapper_gj4w7_409 {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}
._popoverContent_gj4w7_386 ._popoverRow_gj4w7_406 ._inputWrapper_gj4w7_409 ._popoverInput_gj4w7_415 {
  border: solid 1px #eff5f9;
  background-color: #eff5f9;
  border-radius: 624.9375rem;
  padding: 0.5625rem;
  padding-left: 1.625rem;
  flex: 1;
  border: 0;
  outline: none;
  appearance: none;
}
._popoverContent_gj4w7_386 ._popoverRow_gj4w7_406 ._inputWrapper_gj4w7_409 input::-webkit-outer-spin-button,
._popoverContent_gj4w7_386 ._popoverRow_gj4w7_406 ._inputWrapper_gj4w7_409 input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
._popoverContent_gj4w7_386 ._popoverRow_gj4w7_406 ._inputWrapper_gj4w7_409 ._popoverInput_gj4w7_415:hover {
  border-color: #e6edf2;
  background-color: #e6edf2;
}
._popoverContent_gj4w7_386 ._popoverRow_gj4w7_406 ._inputWrapper_gj4w7_409 ._popoverInput_gj4w7_415:focus {
  border-color: #e6edf2;
  background-color: #e6edf2;
}
._popoverContent_gj4w7_386 ._popoverRow_gj4w7_406 ._inputWrapper_gj4w7_409 ._prefix_gj4w7_439 {
  z-index: 9999;
  position: absolute;
  left: 0.75rem;
  color: #8f8d91;
  font-size: 1rem;
}