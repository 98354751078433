/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1w9f3_157 {
  display: flex;
}

._justify-content-space-between_1w9f3_161 {
  justify-content: space-between;
}

._tabular-nums_1w9f3_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1w9f3_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._EmailComposer_1w9f3_178 ._info_1w9f3_178 ._fromAndTo_1w9f3_178 ._to_1w9f3_178 ._memberCount_1w9f3_178, ._EmailComposer_1w9f3_178 ._section_1w9f3_178 ._label_1w9f3_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._editor_1w9f3_219 > div {
  padding: 0 !important;
}

._EmailComposer_1w9f3_178 {
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
  background-color: #fdfdfd;
  height: 18.75rem;
}
._EmailComposer_1w9f3_178._editorExpanded_1w9f3_229 {
  height: 45rem;
}
._EmailComposer_1w9f3_178 ._section_1w9f3_178 {
  padding: 1rem 1.5rem;
}
._EmailComposer_1w9f3_178 ._section_1w9f3_178:not(:first-child) {
  border-top: 0.0625rem solid #dadcde;
}
._EmailComposer_1w9f3_178 ._section_1w9f3_178 ._label_1w9f3_178 {
  margin-right: 0.75rem;
  color: #8f8d91;
}
._EmailComposer_1w9f3_178 ._info_1w9f3_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}
._EmailComposer_1w9f3_178 ._info_1w9f3_178 ._fromAndTo_1w9f3_178 {
  flex: 1;
}
._EmailComposer_1w9f3_178 ._info_1w9f3_178 ._fromAndTo_1w9f3_178 ._from_1w9f3_178 {
  margin-bottom: 1rem;
}
._EmailComposer_1w9f3_178 ._info_1w9f3_178 ._fromAndTo_1w9f3_178 ._to_1w9f3_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._EmailComposer_1w9f3_178 ._info_1w9f3_178 ._fromAndTo_1w9f3_178 ._to_1w9f3_178 ._memberCount_1w9f3_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.5rem;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  background: #e2e2e2;
}
._EmailComposer_1w9f3_178 ._info_1w9f3_178 ._fromAndTo_1w9f3_178 ._to_1w9f3_178 ._memberInput_1w9f3_268 {
  flex: 1;
  margin-right: 1.25rem;
}
._EmailComposer_1w9f3_178 ._info_1w9f3_178 ._messageTypeSelect_1w9f3_272 {
  flex-shrink: 0;
  width: 7.8125rem;
  margin-left: auto;
}
._EmailComposer_1w9f3_178 ._subject_1w9f3_277 {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 0.0625rem solid #dadcde;
}
._EmailComposer_1w9f3_178 ._composeButton_1w9f3_283 {
  width: 9.375rem;
}
._EmailComposer_1w9f3_178 ._bulkComposeButton_1w9f3_286 {
  margin-left: 0.75rem;
}