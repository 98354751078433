._ProjectApplicationPagePicker_oq2r8_1 ._navigate_oq2r8_1 {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 35px;
  border-bottom: 1px solid var(--gray-5);
  background-color: var(--white);
}
._ProjectApplicationPagePicker_oq2r8_1 ._navigate_oq2r8_1 ._backButton_oq2r8_10 {
  margin-right: 24px;
}
._ProjectApplicationPagePicker_oq2r8_1 ._navigate_oq2r8_1 ._title_oq2r8_13 {
  margin: 0 48px 0 16px;
  color: var(--grey-100);
  font-weight: 600;
  font-size: 24px;
}
._ProjectApplicationPagePicker_oq2r8_1 ._navigate_oq2r8_1 ._splashImage_oq2r8_19 {
  width: 48px;
  height: 48px;
  border-radius: var(--spacing-xxs);
}
._ProjectApplicationPagePicker_oq2r8_1 ._content_oq2r8_24 {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  padding: 24px 48px;
  background-color: var(--gray-2);
}
._ProjectApplicationPagePicker_oq2r8_1 ._content_oq2r8_24 ._container_oq2r8_32 {
  padding: 48px 48px 24px;
  border: 1px solid var(--gray-4);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
}
._ProjectApplicationPagePicker_oq2r8_1 ._content_oq2r8_24 ._section_oq2r8_42 {
  width: 100%;
}
._ProjectApplicationPagePicker_oq2r8_1 ._content_oq2r8_24 ._section_oq2r8_42:not(:last-child) {
  margin-bottom: 16px;
}
._ProjectApplicationPagePicker_oq2r8_1 ._content_oq2r8_24 ._section_oq2r8_42 ._header_oq2r8_48 {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
._ProjectApplicationPagePicker_oq2r8_1 ._content_oq2r8_24 ._section_oq2r8_42 ._header_oq2r8_48 ._projectStatusSelect_oq2r8_53 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
}
._ProjectApplicationPagePicker_oq2r8_1 ._content_oq2r8_24 ._section_oq2r8_42 ._header_oq2r8_48 ._title_oq2r8_13 {
  font-weight: 500;
  font-size: 20px;
  color: var(--gray-8);
  width: 100%;
}
._ProjectApplicationPagePicker_oq2r8_1 ._content_oq2r8_24 ._section_oq2r8_42 ._listGridView_oq2r8_65 {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
}
._ProjectApplicationPagePicker_oq2r8_1 ._content_oq2r8_24 ._section_oq2r8_42 ._listGridView_oq2r8_65 ._listItem_oq2r8_72 {
  position: relative;
  width: 30%;
  max-width: 320px;
  margin-right: 24px;
  margin-bottom: 24px;
}
._ProjectApplicationPagePicker_oq2r8_1 ._content_oq2r8_24 ._section_oq2r8_42 ._listGridView_oq2r8_65 ._listItem_oq2r8_72 ._image_oq2r8_79 {
  object-fit: cover;
  width: 100%;
  height: 210px;
  border-radius: 6px 6px 0 0;
}
._ProjectApplicationPagePicker_oq2r8_1 ._content_oq2r8_24 ._section_oq2r8_42 ._listGridView_oq2r8_65 ._listItem_oq2r8_72 ._archivedImage_oq2r8_85 {
  opacity: 0.5;
}
._ProjectApplicationPagePicker_oq2r8_1 ._content_oq2r8_24 ._section_oq2r8_42 ._listGridView_oq2r8_65 ._listItem_oq2r8_72 ._ctaBtns_oq2r8_88 {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  display: none;
  width: 100%;
  height: 210px;
  flex-direction: column;
  transform: translate(-50%, 0);
  align-items: center;
  justify-content: center;
}
._ProjectApplicationPagePicker_oq2r8_1 ._content_oq2r8_24 ._section_oq2r8_42 ._listGridView_oq2r8_65 ._listItem_oq2r8_72 ._ctaBtns_oq2r8_88 button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 208px;
  padding: 18px;
  cursor: pointer;
}
._ProjectApplicationPagePicker_oq2r8_1 ._content_oq2r8_24 ._section_oq2r8_42 ._listGridView_oq2r8_65 ._listItem_oq2r8_72 ._ctaBtns_oq2r8_88 button:not(:last-child) {
  margin-bottom: 10px;
}
._ProjectApplicationPagePicker_oq2r8_1 ._content_oq2r8_24 ._section_oq2r8_42 ._listGridView_oq2r8_65 ._listItem_oq2r8_72 ._listItemTitle_oq2r8_112 {
  padding: 16px;
  border: 1px solid var(--gray-4);
  border-radius: 0 0 16px 16px;
  color: var(--gray-9);
  font-weight: 600;
  transition: box-shadow 0.2s ease;
}
._ProjectApplicationPagePicker_oq2r8_1 ._content_oq2r8_24 ._section_oq2r8_42 ._listGridView_oq2r8_65 ._listItem_oq2r8_72:hover ._image_oq2r8_79 {
  filter: brightness(50%);
}
._ProjectApplicationPagePicker_oq2r8_1 ._content_oq2r8_24 ._section_oq2r8_42 ._listGridView_oq2r8_65 ._listItem_oq2r8_72:hover ._listItemTitle_oq2r8_112 {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}
._ProjectApplicationPagePicker_oq2r8_1 ._content_oq2r8_24 ._section_oq2r8_42 ._listGridView_oq2r8_65 ._listItem_oq2r8_72:hover ._ctaBtns_oq2r8_88 {
  display: flex;
}
._ProjectApplicationPagePicker_oq2r8_1._alignedLeftContent_oq2r8_129 ._container_oq2r8_32 {
  align-items: flex-start;
}
._ProjectApplicationPagePicker_oq2r8_1._alignedLeftContent_oq2r8_129 ._container_oq2r8_32 ._listGridView_oq2r8_65 {
  justify-content: left;
}
._ProjectApplicationPagePicker_oq2r8_1._alignedLeftContent_oq2r8_129 ._container_oq2r8_32 ._title_oq2r8_13 {
  text-align: left;
}