._ChartLoading_1um7e_1 {
  padding: 16px;
  gap: 16px;
  width: 100%;
  height: 150px;
  background: var(--gray-2);
  border-radius: 6px;
}

._TrendLoading_1um7e_10 {
  margin-top: var(--spacing-xs);
  padding-top: var(--spacing-xs);
  border-top: 1px solid var(--gray-5);
  min-width: 60px;
}

._AlertLoading_1um7e_17 {
  background: var(--gray-2);
  border-radius: 6px;
  width: 100%;
  padding: 12px;
  gap: 12px;
}

._ImpactLoading_1um7e_25 {
  width: 100%;
  padding: var(--spacing-lg);
  justify-content: center;
}
._ImpactLoading_1um7e_25 .ant-skeleton-paragraph {
  margin: 0;
}
._ImpactLoading_1um7e_25 ._label_1um7e_33 {
  width: 120px;
}
._ImpactLoading_1um7e_25 ._label_1um7e_33 .ant-skeleton-title {
  margin: 0;
  padding: 0;
}