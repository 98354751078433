/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_5t9pk_157 {
  display: flex;
}

._justify-content-space-between_5t9pk_161 {
  justify-content: space-between;
}

._tabular-nums_5t9pk_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_5t9pk_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._AdditionalMethods_5t9pk_209 {
  display: flex;
  flex-direction: column;
  width: 62%;
  margin: 0;
  padding-bottom: 1.875rem;
  padding-left: 2rem;
}
@media (max-width: 75rem) {
  ._AdditionalMethods_5t9pk_209 {
    width: 90%;
    margin: 0 auto;
    padding: 0 0.625rem 1.875rem;
  }
}
._AdditionalMethods_5t9pk_209 h2 {
  margin-bottom: 0.25rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}
._AdditionalMethods_5t9pk_209 p {
  margin-bottom: 1rem;
  color: #505256;
  font-size: 0.875rem;
}
._AdditionalMethods_5t9pk_209 ._methodsList_5t9pk_235 {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}